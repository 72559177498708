import React from "react";
import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { useParams, Link, useHistory } from "react-router-dom";
import { theme } from "../themes/variables";
import { Headers } from "../helpers/layout";
import { SkillLevel, getLevelColor, getLevel } from "../helpers/level";
import { Header } from "../components/Header";
import { Wrapper } from "../components/Wrapper";
import { Block } from "../components/Block";
import { Card } from "../components/Card";
import { SEO } from "../components/SEO";
import { Button } from "../components/Button";
import { CircularProgressBar } from "../components/CircularProgressBar";
import { ProgressBar } from "../components/ProgressBar";
import { ProgressBarSection } from "../components/ProgressBarSection";

import { useFetch } from "../actions/useAPI";
import { getSkillAnalytics } from "../actions/skills/getSkillAnalytics";
import { getCompetencies } from "../actions/competence/getCompetencies";

import { ReactComponent as PlayCircleIcon } from "../assets/svg/PlayCircle.svg";
import { ReactComponent as CupIcon } from "../assets/svg/Cup.svg";
import { ReactComponent as SkillIcon } from "../assets/svg/Skill.svg";
import { PageQueryHandler } from "./PageQueryHandler";
import { useResponsive } from "../hooks/useResponsive";
import { SkillAnalytics } from "../types/Skill";
import { Tabs } from "../components/Tabs";

function SkillsComparison(props: { skills: SkillAnalytics }) {
  const { t } = useTranslation();
  const { skills } = props;
  const { isMobile } = useResponsive();

  return (
    <Card
      css={css`
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 40px 30px 30px 30px;
        margin-top: 20px;
        text-align: center;

        ${isMobile &&
        css`
          padding: 20px;
        `}
      `}
    >
      <Headers.H4
        css={css`
          margin-bottom: 40px;
          max-width: 220px;
        `}
      >
        {t("skills.competence-compare")}:
      </Headers.H4>

      <div
        css={css`
          width: 100%;
        `}
      >
        <Card
          css={css`
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding: 24px;
            background-color: ${theme.colors.gray1};
          `}
        >
          <div>
            <div
              css={css`
                display: flex;
                justify-content: space-between;
              `}
            >
              <div>
                <div
                  css={css`
                    margin-bottom: 11px;
                    font-size: 14px;
                    line-height: 22px;
                    display: flex;
                    align-items: center;
                  `}
                >
                  <span>{t("skills.my-competence")}</span>

                  {skills.global_stats.my_competence >
                    skills.global_stats.average_competence && (
                    <CupIcon
                      css={css`
                        margin-left: 8px;
                      `}
                      width={16}
                      height={16}
                      color={theme.colors.primary}
                    />
                  )}
                </div>
              </div>

              <Headers.H4>{skills.global_stats.my_competence}%</Headers.H4>
            </div>

            <ProgressBar
              color={
                skills.global_stats.my_competence >
                skills.global_stats.average_competence
                  ? theme.colors.success
                  : theme.colors.warning
              }
              emptyColor={theme.colors.transparent}
              percent={skills.global_stats.my_competence}
              height={16}
            />
          </div>

          <div
            css={css`
              margin-top: 30px;
            `}
          >
            <div
              css={css`
                display: flex;
                justify-content: space-between;
              `}
            >
              <div>
                <div
                  css={css`
                    margin-bottom: 11px;
                    font-size: 14px;
                    line-height: 22px;
                  `}
                >
                  {t("skills.average-competence")}
                </div>
              </div>

              <Headers.H4>{skills.global_stats.average_competence}%</Headers.H4>
            </div>

            <ProgressBar
              color={theme.colors.blueBackground}
              emptyColor={theme.colors.transparent}
              percent={skills.global_stats.average_competence}
              height={16}
            />
          </div>
        </Card>
      </div>
    </Card>
  );
}

export function SkillsPage(props: {}) {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();
  const history = useHistory();

  const params = useParams<{
    competenceId?: string;
  }>();

  const {
    data: competencies,
    error: competenciesError,
    isLoading: competenciesIsLoading,
  } = useFetch(getCompetencies);

  const {
    data: skills,
    error: skillsError,
    isLoading: skillsIsLoading,
  } = useFetch(getSkillAnalytics, {
    competence_id: params.competenceId ? +params.competenceId : undefined,
  });

  if (!skills || skillsError || skillsIsLoading) {
    return <PageQueryHandler error={skillsError} isLoading={skillsIsLoading} />;
  }

  if (!competencies || competenciesError || competenciesIsLoading) {
    return (
      <PageQueryHandler
        error={competenciesError}
        isLoading={competenciesIsLoading}
      />
    );
  }

  if (!params.competenceId && competencies.length) {
    history.replace(`/me/skills/${competencies[0].id}`);
  }

  const chosenCompetence = competencies.find(
    (el) => el.id === Number(params.competenceId)
  );

  return (
    <Wrapper>
      <SEO>
        <title>{t("skills.my-skills")}</title>
      </SEO>
      <Header />

      <div
        css={css`
          background: #fff;
          padding: 35px 40px;
          margin-bottom: 25px;
          border-radius: 20px;

          ${isMobile &&
          css`
            padding: 20px;
          `}
        `}
      >
        <div
          css={css`
            display: flex;
            max-width: 100%;
            width: 100%;
            flex-flow: row wrap;
            justify-content: space-between;

            ${isMobile &&
            css`
              flex-flow: column nowrap;
            `}
          `}
        >
          <Headers.H4
            css={css`
              width: 250px;

              ${isMobile &&
              css`
                width: 100%;
                margin-bottom: 20px;
              `}
            `}
          >
            {t("skills.my-skills")}
          </Headers.H4>

          <div
            css={css`
              display: flex;
              flex-direction: column;
              flex: 1 0 10%;
              min-width: 0;

              ${isMobile &&
              css`
                width: 100%;
              `}
            `}
          >
            <div>
              <Tabs
                tabs={competencies.map((el) => {
                  return {
                    link: `/me/skills/${el.id}`,
                    title: el.name,
                    forceHightlight: !!(
                      params.competenceId && +params.competenceId === el.id
                    ),
                  };
                })}
              />
            </div>

            {chosenCompetence && chosenCompetence.description && (
              <div
                css={css`
                  font-size: 22px;
                  line-height: 28px;
                  margin-top: 32px;
                `}
              >
                {chosenCompetence.description}
              </div>
            )}
          </div>
        </div>
      </div>

      <Block
        css={css`
          background-color: ${theme.colors.gray1};
          padding: 40px;
          display: flex;
          flex-flow: row wrap;

          ${isMobile &&
          css`
            padding: 20px;
          `}
        `}
      >
        <div
          css={css`
            flex: 1 0 10%;
            margin-right: 20px;

            ${isMobile &&
            css`
              margin-right: 0;
              order: 2;
            `}
          `}
        >
          {skills.personal_stats.map((el) => {
            return (
              <Card
                key={el.skill_id}
                css={css`
                  padding: 40px;
                  margin-bottom: 20px;

                  &:last-child {
                    margin-bottom: 0;
                  }

                  ${isMobile &&
                  css`
                    padding: 20px;
                  `}
                `}
              >
                <div
                  css={css`
                    display: flex;
                    margin-bottom: 40px;

                    ${isMobile &&
                    css`
                      flex-flow: row wrap;
                      justify-content: space-between;
                    `}
                  `}
                >
                  <div
                    css={css`
                      margin-right: 22px;
                    `}
                  >
                    <div
                      css={css`
                        width: 60px;
                        height: 60px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        overflow: hidden;
                      `}
                    >
                      {el.icon ? (
                        <img
                          src={el.icon.image}
                          alt={el.name}
                          css={css`
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                          `}
                        />
                      ) : (
                        <SkillIcon
                          color={theme.colors.primary}
                          width={60}
                          height={60}
                        />
                      )}
                    </div>
                  </div>

                  <div
                    css={css`
                      width: 100%;

                      ${isMobile &&
                      css`
                        order: 2;
                        flex: 0 0 100%;
                        margin-top: 20px;
                      `}
                    `}
                  >
                    <Headers.H4
                      css={css`
                        margin-bottom: 4px;
                      `}
                    >
                      {el.name}
                    </Headers.H4>

                    <div
                      css={css`
                        margin-bottom: 12px;
                      `}
                    >
                      <span
                        css={css`
                          color: ${theme.colors.gray6};
                          font-size: 16px;
                        `}
                      >
                        {t("skills.current-skill-level")}:
                      </span>
                      <span
                        css={css`
                          margin-left: 4px;
                          color: ${theme.colors.primary};
                          font-size: 16px;
                          font-weight: bold;
                        `}
                      >
                        {getLevel(el.completeness) === SkillLevel.BEGINNER
                          ? t("skills.skill-level.beginner")
                          : getLevel(el.completeness) ===
                            SkillLevel.INTERMEDIATE
                          ? t("skills.skill-level.intermediate")
                          : t("skills.skill-level.advanced")}
                      </span>
                    </div>

                    <div
                      css={css`
                        max-width: 350px;
                        font-size: 16px;
                      `}
                    >
                      {t(el.advice)}
                    </div>
                  </div>

                  <div
                    css={css`
                      color: ${getLevelColor(getLevel(el.completeness))};
                      font-size: 30px;
                      line-height: 46px;
                    `}
                  >
                    {el.completeness}%
                  </div>
                </div>

                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    flex-flow: row wrap;

                    ${isMobile &&
                    css`
                      flex-flow: column;
                      align-items: flex-start;
                    `}
                  `}
                >
                  <div
                    css={css`
                      flex: 1 0 10%;

                      ${isMobile &&
                      css`
                        width: 100%;
                        margin-bottom: 20px;
                      `}
                    `}
                  >
                    <ProgressBarSection
                      numberOfSections={3}
                      percent={el.completeness || 0}
                      padding={9}
                      color={getLevelColor(getLevel(el.completeness))}
                      emptyColor={theme.colors.gray1}
                    />
                  </div>

                  {el.course_ids.length > 0 && (
                    <div
                      css={css`
                        ${isMobile &&
                        css`
                          margin-top: 20px;
                        `}
                      `}
                    >
                      <Link to={`/course/${el.course_ids[0]}`}>
                        <Button
                          size="small"
                          color={theme.colors.dark}
                          background={theme.colors.transparent}
                          hoverStyles={`opacity: 0.85;`}
                          icon={
                            <PlayCircleIcon
                              width={20}
                              height={20}
                              color={theme.colors.primary}
                            />
                          }
                          css={css`
                            white-space: nowrap;
                            margin-left: 39px;
                            padding: 0 15px;

                            ${isMobile &&
                            css`
                              margin-left: 0;
                              padding: 0;
                            `}
                          `}
                        >
                          {t("actions.continue")}
                        </Button>
                      </Link>
                    </div>
                  )}
                </div>
              </Card>
            );
          })}
        </div>

        <div
          css={css`
            width: 360px;

            ${isMobile &&
            css`
              width: 100%;
              order: 1;
              margin-bottom: 20px;
            `}
          `}
        >
          <Card
            css={css`
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              padding: 40px;
              text-align: center;

              ${isMobile &&
              css`
                padding: 30px 20px;
              `}
            `}
          >
            <CircularProgressBar
              percent={skills.global_stats.my_competence}
              size={100}
              strokeWidth={8}
              strokeWidthInner={4}
            >
              <div
                css={css`
                  font-size: 30px;
                  line-height: 46px;
                `}
              >
                <span
                  css={css`
                    color: ${theme.colors.blueMain};
                  `}
                >
                  {skills.global_stats.my_competence}
                </span>
                <span
                  css={css`
                    color: ${theme.colors.blueBackground};
                  `}
                >
                  %
                </span>
              </div>
            </CircularProgressBar>

            <Headers.H3
              css={css`
                margin-top: 24px;
              `}
            >
              {t("skills.competence")}
            </Headers.H3>
            {skills.global_stats.my_competence > 0 && (
              <div
                css={css`
                  margin-top: 12px;
                `}
              >
                {t("skills.doing-great")}
              </div>
            )}
            {skills.personal_stats[0] &&
              skills.personal_stats[0].course_ids.length > 0 && (
                <Link to={`/course/${skills.personal_stats[0].course_ids[0]}`}>
                  <Button
                    css={css`
                      margin-top: 24px;
                    `}
                    color={theme.colors.white}
                  >
                    {t("cheats_sheet.continue")}
                  </Button>
                </Link>
              )}
          </Card>

          {!isMobile && <SkillsComparison skills={skills} />}
        </div>
      </Block>
      {isMobile && <SkillsComparison skills={skills} />}
    </Wrapper>
  );
}
