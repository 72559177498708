import React from "react";
import { useTranslation } from "react-i18next";
import { useParams, useHistory, Link } from "react-router-dom";
import { css } from "styled-components/macro";
import { toast } from "react-toastify";
import { Formik, Form, FormikHelpers } from "formik";
import * as Yup from "yup";
import { Address } from "../../types/Address";
import { theme } from "../../themes/variables";
import { serverErrorHandler } from "../../helpers/serverErrorHandler";
import { Headers } from "../../helpers/layout";
import { Steps, Step } from "../../components/Steps";
import { Header } from "../../components/Header";
import { Wrapper } from "../../components/Wrapper";
import { SEO } from "../../components/SEO";
import { Card } from "../../components/Card";
import { Button } from "../../components/Button";
import { VerticalSeparator } from "../../components/Separator";
import { OrderReview } from "../../components/Payment/OrderReview";
import { InputField } from "../../forms/fields/InputField";
import { SelectField } from "../../forms/fields/SelectField2";

import { useFetch } from "../../actions/useAPI";
import { getOrder, updateOrderBillingAddress } from "../../actions/order";

import { ReactComponent as LeftIcon } from "../../assets/svg/Left.svg";

import countries from "./countries";
import { PageQueryHandler } from "../PageQueryHandler";
import { useResponsive } from "../../hooks/useResponsive";

export type FormValues = Address;

export function BillingInformationPage(props: {}) {
  const params = useParams<{
    orderId: string;
  }>();

  const { t } = useTranslation();
  const { isMobile } = useResponsive();

  const history = useHistory();

  const {
    data: order,
    error: orderError,
    isLoading: orderIsLoading,
  } = useFetch(getOrder, {
    orderId: params.orderId,
  });

  if (!order || orderError || orderIsLoading) {
    return <PageQueryHandler error={orderError} isLoading={orderIsLoading} />;
  }

  const stepsData: Step[] = [
    {
      title: t("payment.payment-method"),
      link: `/order/${params.orderId}/payment-method`,
      complete: true,
    },
    {
      title: t("payment.billing-information"),
      link: `/order/${params.orderId}/billing-information`,
      current: true,
    },
    {
      title: t("payment.purchase"),
    },
    {
      title: t("payment.start-learning"),
    },
  ];

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().label(t("address.first_name")).required(),
    last_name: Yup.string().label(t("address.last_name")).required(),
    company_name: Yup.string().label(t("address.company_name")),
    email: Yup.string().email().label(t("address.email")).required(),
    phone_number: Yup.string()
      .label(t("address.phone_number"))
      .required(t("validation.phone-number")),
    country: Yup.string().label(t("address.country")).required(),
    city: Yup.string().label(t("address.city")).required(),
    street_address: Yup.string().label(t("address.street_address")).required(),
    state: Yup.string().label(t("address.state")),
    postal_code: Yup.string().label(t("address.postal_code")).required(),
  });

  const onSubmit = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    try {
      setSubmitting(true);

      await updateOrderBillingAddress(params.orderId, {
        ...values,
      });

      history.push(`/order/${params.orderId}/purchase`);
    } catch (error: any) {
      toast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Wrapper>
      <SEO>
        <title>{t("payment.billing-information")}</title>
      </SEO>
      <Header />

      <Steps steps={stepsData} />

      <Card>
        <Formik<FormValues>
          enableReinitialize
          initialValues={{
            first_name: order.billing_address?.first_name || "",
            last_name: order.billing_address?.last_name || "",
            city: order.billing_address?.city || "",
            company_name: order.billing_address?.company_name || "",
            country: order.billing_address?.country || "DE",
            email: order.billing_address?.email || "",
            phone_number: order.billing_address?.phone_number || "",
            postal_code: order.billing_address?.postal_code || "",
            state: order.billing_address?.state || "",
            street_address: order.billing_address?.street_address || "",
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, isValid, values }) => {
            return (
              <Form noValidate>
                <div
                  css={css`
                    display: flex;
                    flex-direction: ${isMobile ? "column" : "row"};
                    justify-content: space-between;
                    position: relative;
                  `}
                >
                  <div
                    css={css`
                      flex: 0 0 calc(50% - 100px);
                    `}
                  >
                    <div
                      css={css`
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                      `}
                    >
                      <div>
                        <Headers.H4
                          css={css`
                            line-height: 30px;
                            margin-bottom: ${isMobile ? "40px" : "32px"};
                          `}
                        >
                          {t("payment.fill-billing-information")}
                        </Headers.H4>

                        <div>
                          <div
                            css={css`
                              display: flex;
                              flex-direction: ${isMobile ? "column" : "row"};
                              margin-bottom: ${isMobile ? "24px" : "32px"};
                            `}
                          >
                            <div
                              css={css`
                                margin-right: 12px;
                                margin-bottom: ${isMobile ? "24px" : "0"};
                                width: 100%;
                              `}
                            >
                              <InputField
                                name="first_name"
                                label={t("address.first_name")}
                              />
                            </div>

                            <div
                              css={css`
                                width: 100%;
                              `}
                            >
                              <InputField
                                name="last_name"
                                label={t("address.last_name")}
                              />
                            </div>
                          </div>

                          <div
                            css={css`
                              margin-bottom: ${isMobile ? "40px" : "60px"};
                            `}
                          >
                            <InputField
                              name="company_name"
                              label={t("address.company_name")}
                            />
                          </div>

                          <div
                            css={css`
                              margin-bottom: ${isMobile ? "24px" : "32px"};
                            `}
                          >
                            <InputField
                              name="email"
                              label={t("address.email")}
                            />
                          </div>

                          <div
                            css={css`
                              margin-bottom: ${isMobile ? "40px" : "60px"};
                            `}
                          >
                            <InputField
                              name="phone_number"
                              label={t("address.phone_number")}
                            />
                          </div>

                          <div
                            css={css`
                              display: flex;
                              flex-direction: ${isMobile ? "column" : "row"};
                              margin-bottom: ${isMobile ? "40px" : "60px"};
                            `}
                          >
                            <div
                              css={css`
                                margin-right: 12px;
                                margin-bottom: ${isMobile ? "24px" : "0"};
                                width: 100%;
                              `}
                            >
                              <SelectField
                                name="country"
                                label={t("address.country")}
                                small
                                choices={countries.map((country) => {
                                  return {
                                    label: t(
                                      `countries.${country.code}`
                                    ) as string,
                                    value: country.code,
                                  };
                                })}
                              />
                            </div>

                            <div
                              css={css`
                                width: 100%;
                              `}
                            >
                              <InputField
                                name="city"
                                label={t("address.city")}
                              />
                            </div>
                          </div>

                          <div
                            css={css`
                              margin-bottom: 32px;
                            `}
                          >
                            <InputField
                              name="street_address"
                              label={t("address.street_address")}
                            />
                          </div>

                          <div
                            css={css`
                              display: flex;
                              flex-direction: ${isMobile ? "column" : "row"};
                              margin-bottom: ${isMobile ? "0" : "70px"};
                            `}
                          >
                            <div
                              css={css`
                                margin-right: 12px;
                                margin-bottom: ${isMobile ? "24px" : "0"};
                                width: 100%;
                              `}
                            >
                              <InputField
                                name="state"
                                label={t("address.state")}
                              />
                            </div>

                            <div
                              css={css`
                                width: 100%;
                              `}
                            >
                              <InputField
                                name="postal_code"
                                label={t("address.postal_code")}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {isMobile && (
                        <Button
                          css={css`
                            margin-top: 30px;
                          `}
                          background={theme.colors.dark}
                          color={theme.colors.white}
                          type="submit"
                          isSubmitting={isSubmitting}
                          disabled={!isValid || isSubmitting}
                          icon={
                            <LeftIcon
                              width={14}
                              height={14}
                              color={theme.colors.primary}
                            />
                          }
                          iconRight
                          hoverStyles={`color: ${theme.colors.white}; background: ${theme.colors.primary};`}
                        >
                          {t("payment.proceed-purchase")}
                        </Button>
                      )}

                      <Link
                        to={`/order/${params.orderId}/payment-method`}
                        css={css`
                          margin-top: 30px;
                        `}
                      >
                        <Button
                          background={theme.colors.transparent}
                          color={theme.colors.dark}
                          icon={
                            <LeftIcon
                              width={20}
                              height={20}
                              color={theme.colors.primary}
                              css={css`
                                transform: rotate(180deg);
                              `}
                            />
                          }
                          css={css`
                            padding: 0px;
                          `}
                          hoverStyles={`opacity: 0.85;`}
                        >
                          {t("payment.back-to-payment-method")}
                        </Button>
                      </Link>
                    </div>
                  </div>
                  {!isMobile && (
                    <VerticalSeparator
                      css={css`
                        left: calc(50% + 50px);
                      `}
                    />
                  )}

                  {!isMobile && order.items.length > 0 && (
                    <OrderReview
                      values={{
                        payment_type: order.payment_type,
                        billing_address: values,
                      }}
                      courseId={order.items[0].course.uuid}
                      isSubmitting={isSubmitting}
                      isValid={isValid}
                    >
                      <Button
                        css={css`
                          margin-top: 60px;
                        `}
                        background={theme.colors.dark}
                        color={theme.colors.white}
                        type="submit"
                        isSubmitting={isSubmitting}
                        disabled={!isValid || isSubmitting}
                        icon={
                          <LeftIcon
                            width={14}
                            height={14}
                            color={theme.colors.primary}
                          />
                        }
                        iconRight
                        hoverStyles={`color: ${theme.colors.white}; background: ${theme.colors.primary};`}
                      >
                        {t("payment.proceed-purchase")}
                      </Button>
                    </OrderReview>
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>
      </Card>
    </Wrapper>
  );
}
