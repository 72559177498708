import React from "react";
import { css, FlattenSimpleInterpolation } from "styled-components/macro";
import { Loader } from "./Loader";

export type TableColumn = {
  label: string;
  orderFields?: string[];
  align?: "left" | "center" | "right";
  columnCss?: FlattenSimpleInterpolation;
  format?: (label: string) => React.ReactNode | string | null;
};

export type TableRow<T> = {
  data: T;
  row: {
    value: React.ReactNode | React.ReactNode[] | string | null;
  }[];
};

export type TableColumnStats = {
  index: number;
  label: string;
  orderFields?: TableColumn["orderFields"];
};

export function MobileTable<T>(props: {
  columns: TableColumn[];
  rows?: TableRow<T>[];
  loading?: boolean;
  noDataMessage?: string;
  loadingMessage?: string;
  className?: string;
}) {
  if (props.loading) {
    return <Loader absoluteCenter>{props.loadingMessage}</Loader>;
  }

  if (props.rows?.length === 0) {
    return (
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: center;
        `}
      >
        {props.noDataMessage}
      </div>
    );
  }

  return (
    <div>
      {props.rows?.map((el, idx) => {
        return (
          <table
            className={props.className}
            css={css`
              width: 100%;
              margin-bottom: 20px;
              border: 1px solid #cdcdcd;
              box-sizing: border-box;
              border-radius: 20px;
              border-spacing: 0;
              overflow: hidden;

              tr {
                td {
                  padding: 10px;
                  border-bottom: 1px solid #cdcdcd;
                  font-size: 14px;
                }
                &:last-child {
                  td {
                    border-bottom: none;
                  }
                }
              }
            `}
            role="table"
          >
            <tbody>
              {el.row.map(({ value }, key) => {
                return (
                  <tr key={idx}>
                    <td
                      css={css`
                        background: #f2f2f2;
                        border-right: 1px solid #cdcdcd;
                      `}
                    >
                      {props.columns[key].label}
                    </td>
                    <td key={key} css={css``}>
                      {value}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        );
      })}
    </div>
  );
}
