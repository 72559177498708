import { useEffect, useState } from "react";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router";
import { reduce } from "lodash";
import { buildQueryString } from "../helpers/buildQueryString";

export type Filters = {
  [key: string]: string;
};

export function useFilters<T extends Filters>() {
  const location = useLocation();
  const history = useHistory();
  const [filters, setFilters] = useState<T>(
    queryString.parse(location.search) as T
  );

  useEffect(() => {
    history.push(location.pathname + "?" + buildQueryString(filters));
  }, [filters, history, location.pathname]);

  return {
    filters,
    setFilter: (key: string, value: string) => {
      setFilters((filters) => {
        return {
          ...filters,
          [key]: value,
        };
      });
    },
    resetFilters: () => {
      setFilters((filters) => {
        return reduce(
          filters,
          (filters, value, key) => {
            return {
              ...filters,
              [key]: "",
            };
          },
          {} as T
        );
      });
    },
  };
}
