import React, { useState } from "react";

const AccessibilityContext = React.createContext<{
  biggerFontSize: boolean;
  highContrast: boolean;
  setBiggerFontSize: () => void;
  setHighContrast: () => void;
}>({
  biggerFontSize: false,
  highContrast: false,
  setBiggerFontSize: () => {},
  setHighContrast: () => {},
});

export function AccessibilityProvider(props: { children: React.ReactNode }) {
  const [biggerFontSize, setBiggerFontSize] = useState(false);
  const [highContrast, setHighContrast] = useState(false);

  return (
    <AccessibilityContext.Provider
      value={{
        biggerFontSize,
        setBiggerFontSize: () => {
          setBiggerFontSize((v) => !v);
        },
        highContrast,
        setHighContrast: () => {
          setHighContrast((v) => !v);
        },
      }}
    >
      {props.children}
    </AccessibilityContext.Provider>
  );
}

export function useAccessibility() {
  const context = React.useContext(AccessibilityContext);

  if (context === undefined) {
    throw new Error(
      "useAccessibility must be used within a AccessibilityProvider"
    );
  }

  return context;
}
