import { authorised } from "../../request";
import { ILesson } from "../../types/Lesson";

type Args = {
  courseId: string;
};

type Response = {
  id: number;
  lessons: Pick<ILesson, "name" | "uuid">[];
};

export async function enrolToCourse({ courseId }: Args) {
  const API = authorised();

  return API.request<Response>({
    method: "POST",
    url: `/api/campus/courses/${courseId}/enrol/`,
  });
}
