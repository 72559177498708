import React from "react";
import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { Spacing } from "../helpers/layout";

interface IDangerZone {
  title?: string | null;
  subTitle?: string | null;
  className?: string;
  children?: React.ReactNode;
}

export function DangerZone(props: IDangerZone) {
  const { t } = useTranslation();

  const {
    title = `${t("danger-zone.title")}:`,
    subTitle = t("danger-zone.subtitle"),
    children,
  } = props;

  return (
    <div className={props.className}>
      <div
        css={css`
          height: 1px;
          background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23${"979797"}' stroke-width='2' stroke-dasharray='6%2c 20' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
        `}
      ></div>

      <div
        css={css`
          display: flex;
          align-items: center;
          margin-top: 30px;
        `}
      >
        <div
          css={css`
            margin: 0 auto;
            width: 100%;
            max-width: 1280px;
          `}
        >
          {title && (
            <div
              css={css`
                font-weight: bold;
                margin-bottom: ${Spacing.s};
              `}
            >
              {title}
            </div>
          )}

          {subTitle && (
            <div
              css={css`
                margin-bottom: ${Spacing.xl};
              `}
            >
              {subTitle}
            </div>
          )}
          {children}
        </div>
      </div>
    </div>
  );
}
