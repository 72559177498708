import { useTranslation } from "react-i18next";
import { useParams, RouteComponentProps, useHistory } from "react-router-dom";
import { css } from "styled-components/macro";
import { Helmet } from "react-helmet";
import { useFetch } from "../actions/useAPI";

import { ELessonType } from "../types/Lesson";
import { theme } from "../themes/variables";
import { LessonVideo } from "../components/Lesson/LessonVideo";
import { LessonCarousel } from "../components/Lesson/LessonCarousel";
import { LessonBlock } from "../components/Lesson/LessonBlock";
import { Wrapper } from "../components/Wrapper";
import { Block } from "../components/Block";
import { Breadcrumbs } from "../components/Breadcrumbs";
import { Header } from "../components/Header";
import { InstructorCard } from "../components/InstructorCard";
import { Spacing } from "../helpers/layout";
import { LessonCustomCode } from "../components/Lesson/LessonCustomCode";
import { Podcast } from "../components/Podcast";
import { getLessonPreview } from "../actions/course/getLessonPreview";
import { TextRenderer } from "../components/TextRenderer";
import { CourseCTA } from "../components/Course/CourseCTA";
import { getCourse } from "../actions/course/getCourse";
import queryString from "query-string";
import { PageQueryHandler } from "./PageQueryHandler";

export function LessonPreviewPage(props: RouteComponentProps) {
  const params = useParams<{
    courseId: string;
    lessonId: string;
  }>();

  const { t } = useTranslation();
  const history = useHistory();

  const queryParams = queryString.parse(props.location.search);
  const previewToken = queryParams.preview_token;

  const {
    data: lesson,
    error: lessonError,
    isLoading: lessonIsLoading,
  } = useFetch(getLessonPreview, {
    courseId: params.courseId,
    lessonId: params.lessonId,
    previewToken: previewToken,
  });

  const {
    data: course,
    error: courseError,
    isLoading: courseIsLoading,
  } = useFetch(getCourse, {
    courseId: params.courseId,
    previewToken: previewToken,
  });

  if (!course || courseError || courseIsLoading) {
    return <PageQueryHandler error={courseError} isLoading={courseIsLoading} />;
  }

  if (!lesson || lessonError || lessonIsLoading) {
    return <PageQueryHandler error={lessonError} isLoading={lessonIsLoading} />;
  }

  const breadcrumbsItems = [
    {
      label: t("lesson.courses"),
      url: "/courses",
    },
    {
      label: lesson?.course.name,
      url: `/course/${lesson?.course.uuid}/${
        previewToken ? `?preview_token=${previewToken}` : ""
      }`,
    },
  ];

  const isFullwidth =
    lesson.type === ELessonType.CUSTOM_CODE ||
    lesson.type === ELessonType.CAROUSEL ||
    lesson.type === ELessonType.BLOCK;

  const onComplete = () => {
    if (lesson.next) {
      history.push(
        `/course/${lesson.course.uuid}/lesson-preview/${lesson.next.uuid}${
          previewToken ? `?preview_token=${previewToken}` : ""
        }`
      );
    } else {
      history.push(
        `/course/${lesson.course.uuid}${
          previewToken ? `?preview_token=${previewToken}` : ""
        }&congratulation`
      );
    }
  };

  return (
    <Wrapper>
      <Helmet title={lesson.name}></Helmet>
      <Header />
      <Block
        css={css`
          background-color: ${theme.colors.white};
          padding: 30px;
          min-height: auto;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: space-between;
            align-items: center;
          `}
        >
          <Breadcrumbs items={breadcrumbsItems} />
        </div>
        <div
          css={css`
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 20px;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              margin-bottom: 100px;
              width: ${isFullwidth ? "100%" : "70%"};
            `}
          >
            {lesson.type === ELessonType.TEXT && (
              <TextRenderer text={lesson.text_content} />
            )}
            {lesson.type === ELessonType.CAROUSEL && (
              <LessonCarousel content={lesson.carousel_content} />
            )}
            {lesson.type === ELessonType.EMBED_VIDEO && (
              <LessonVideo
                url={lesson.video_url}
                lessonUuid={lesson.uuid}
                downloadUrl={lesson.video_download_url}
                isDemo
              />
            )}
            {lesson.type === ELessonType.PODCAST && lesson.audio && (
              <Podcast audio={lesson.audio} />
            )}
            {lesson.type === ELessonType.BLOCK && (
              <LessonBlock item={lesson} isDemo onComplete={onComplete} />
            )}
            {lesson.type === ELessonType.CUSTOM_CODE && (
              <LessonCustomCode
                code={lesson.custom_code}
                onComplete={onComplete}
              />
            )}

            {lesson.instructors.length > 0 && (
              <div
                css={
                  !isFullwidth
                    ? css`
                        position: absolute;
                        width: 25%;
                        right: 0;
                      `
                    : css`
                        position: relative;
                        margin-top: ${Spacing.xl};
                      `
                }
              >
                {lesson.instructors.map((instructor, key) => {
                  return (
                    <div
                      key={key}
                      css={css`
                        margin-bottom: ${Spacing.l};
                      `}
                    >
                      <InstructorCard
                        instructor={instructor}
                        key={`experts--${instructor.id}`}
                        small
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
        <div
          css={css`
            display: flex;
            margin-top: 30px;
          `}
        >
          <CourseCTA course={course} />
        </div>
      </Block>
    </Wrapper>
  );
}
