import { authorised } from "../request";
import { LessonTest } from "../types/LessonTest";

export async function getScheduledTests() {
  const API = authorised();

  return API.request<LessonTest[]>({
    method: "GET",
    url: `/api/campus/test-scheduler/`,
  });
}
