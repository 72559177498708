import React from "react";
import {
  loadStripe,
  StripeElementsOptions,
  StripeElementLocale,
} from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { theme } from "../../themes/variables";
import { useEnterprise } from "../../contexts/EnterpriseContext";

export function StripeElements(props: {
  clientSecret: string;
  children: React.ReactNode | React.ReactNode[] | string | null;
}) {
  const { enterprise } = useEnterprise();

  const stripePromise = loadStripe(enterprise.stripe_public_api_key);

  const options: StripeElementsOptions = {
    clientSecret: props.clientSecret,
    locale: (process.env.REACT_APP_LANGUAGE as StripeElementLocale) || "en",
    fonts: [
      {
        family: "Cadiz",
        src: "url('./assets/fonts/Cadiz-Book.woff2') format('woff2'), url('./assets/fonts/Cadiz-Book.woff') format('woff'), url('./assets/fonts/Cadiz-Book.otf') format('opentype')",
        weight: "400",
      },
    ],
    appearance: {
      theme: "none",
      rules: {
        ".Input": {
          border: `1px solid ${theme.colors.gray4}`,
          padding: "6px 20px",
          color: theme.colors.dark,
        },
        ".Label": {
          fontSize: "16px",
          lineHeight: "18px",
          fontWeight: "bold",
          marginBottom: "16px",
        },
      },
      variables: {
        colorPrimary: theme.colors.primary,
        colorBackground: theme.colors.white,
        colorText: theme.colors.dark,
        fontLineHeight: "22px",
        borderRadius: "20px",
        spacingUnit: "6px 20px",
        spacingTab: "0px",
      },
    },
  };

  return (
    <Elements options={options} stripe={stripePromise}>
      {props.children}
    </Elements>
  );
}
