import { authorised } from "../../request";
import { CourseSummary } from "../../types/Course";

type Args = {
  courseId: string;
};

export async function getCourseSummary({ courseId }: Args) {
  const API = authorised();

  return API.request<CourseSummary>({
    method: "GET",
    url: `/api/campus/courses/${courseId}/summary/`,
  });
}
