import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { Bookmark } from "react-feather";
import { ILessonData } from "../../types/Lesson";
import { theme } from "../../themes/variables";
import { Headers, Text } from "../../helpers/layout";
import { useResponsive } from "../../hooks/useResponsive";

export function LessonHeader(props: {
  item: ILessonData;
  onBookmark: () => {};
}) {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();
  const onBookmark = () => {
    props.onBookmark();
  };

  return (
    <div
      css={css`
        display: flex;
        flex-direction: ${isMobile ? "column" : "row"};
        margin-bottom: ${isMobile ? "20px" : "30px"};
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          width: ${isMobile ? "auto" : "100%"};
          padding: ${isMobile ? "20px" : "40px"};
          background-color: ${theme.colors.white};
          border-radius: 20px;
        `}
      >
        <div
          css={css`
            max-width: 70%;
          `}
        >
          <Headers.H2
            css={css`
              display: flex;
            `}
          >
            {props.item.lesson.name}
          </Headers.H2>
        </div>
        <div
          css={css`
            display: flex;
            cursor: pointer;
            align-items: center;
            margin-top: 5px;
          `}
          onClick={() => onBookmark()}
        >
          {!isMobile && (
            <Text.CTA>
              {props.item.lesson.is_bookmarked
                ? t("lesson.bookmarked")
                : t("lesson.add_to_bookmarks")}
            </Text.CTA>
          )}
          <Bookmark
            size={20}
            color={theme.colors.primary}
            fill={
              props.item.lesson.is_bookmarked
                ? theme.colors.primary
                : theme.colors.white
            }
            css={css`
              margin-left: 10px;
            `}
          />
        </div>
      </div>
    </div>
  );
}
