import { authorised } from "../../request";
import { Submission, SubmissionResponse } from "../../types/LessonTest";

type Args = {
  enrolmentID: string;
  lessonId?: string;
  data: Submission;
};

export async function submitLessonExam({ enrolmentID, lessonId, data }: Args) {
  const API = authorised();

  return API.request<SubmissionResponse>({
    method: "POST",
    url: `/api/campus/course-enrolments/${enrolmentID}/lessons/${lessonId}/exam/`,
    data,
  });
}

export async function submitExam({ enrolmentID, data }: Args) {
  const API = authorised();

  return API.request<SubmissionResponse>({
    method: "POST",
    url: `/api/campus/course-enrolments/${enrolmentID}/exam/`,
    data,
  });
}
