import { useCallback, useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { toast } from "react-toastify";
import {
  resendConfirmationEmail,
  USER_CONFIRM_EMAIL,
} from "../../actions/auth/resendConfirmationEmail";
import { Headers } from "../../helpers/layout";
import { serverErrorHandler } from "../../helpers/serverErrorHandler";
import { theme } from "../../themes/variables";
import { Block } from "../../components/Block";
import { Button } from "../../components/Button";
import { Header } from "../../components/Header";
import { Wrapper } from "../../components/Wrapper";
import { confirmEmail } from "../../actions/auth/confirmEmail";
import { Loader } from "../../components/Loader";
import { SEO } from "../../components/SEO";

export function ConfirmEmailPage(props: RouteComponentProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const params = new URLSearchParams(props.location.search);
  const token = params.get("token");
  const [isSubmitting, setSubmitting] = useState(false);
  const [isConfirming, setConfirming] = useState(token ? true : false);

  const userEmail = localStorage.getItem(USER_CONFIRM_EMAIL);

  const sendEmailConfirm = useCallback(
    async (token) => {
      try {
        setConfirming(true);

        const response = await confirmEmail(token);

        if (response.data.is_verified) {
          history.push("/login");
        } else {
          history.push("/verification-info");
        }
      } catch (error) {
        toast.error(
          t("status.error", {
            error: serverErrorHandler(error),
          })
        );
      } finally {
        setConfirming(false);
      }
    },
    [history, t]
  );

  const resendConfirmEmail = async () => {
    if (!userEmail) return;

    setSubmitting(true);

    try {
      await resendConfirmationEmail(userEmail);
    } catch (error) {
      toast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (!token) return;

    sendEmailConfirm(token);
  }, [token, sendEmailConfirm]);

  return (
    <Wrapper>
      <SEO>
        <title>{t("confirm-email.title")}</title>
      </SEO>
      <Header />
      <Block
        css={css`
          background-color: ${theme.colors.white};
        `}
      >
        <div
          css={css`
            width: 100%;
            margin: 0 auto;
            padding: 160px 0;
            text-align: center;
          `}
        >
          {isConfirming ? (
            <Loader />
          ) : (
            <>
              <div
                css={css`
                  margin-bottom: 40px;
                  text-align: center;
                `}
              >
                <Headers.H1>{t("confirm-email.title")}</Headers.H1>
              </div>
              <div>
                <p>
                  {t("confirm-email.email-sent", {
                    email: userEmail,
                  })}
                  <br />
                  {t("confirm-email.click-on-link")}
                </p>
                <br />
                <p>
                  <b>{t("confirm-email.not-receiving")}</b>
                </p>
                <div
                  css={css`
                    margin-top: 20px;
                  `}
                >
                  <Button
                    size="small"
                    disabled={!userEmail}
                    isSubmitting={isSubmitting}
                    onClick={resendConfirmEmail}
                  >
                    {t("actions.resend_email")}
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>
      </Block>
    </Wrapper>
  );
}
