import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { css } from "styled-components/macro";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import { getLesson } from "../actions/course/getLesson";
import { useFetch } from "../actions/useAPI";
import { completeLesson } from "../actions/course/completeLesson";
import { bookmarkLesson } from "../actions/course/bookmarkLesson";
import { ELessonType } from "../types/Lesson";
import { serverErrorHandler } from "../helpers/serverErrorHandler";
import { theme } from "../themes/variables";
import { LessonFooter } from "../components/Lesson/LessonFooter";
import { LessonHeader } from "../components/Lesson/LessonHeader";
import { LessonVideo } from "../components/Lesson/LessonVideo";
import { LessonNavigation } from "../components/Lesson/LessonNavigation";
import { LessonText } from "../components/Lesson/LessonText";
import { LessonCarousel } from "../components/Lesson/LessonCarousel";
import { LessonBlock } from "../components/Lesson/LessonBlock";
import { Wrapper } from "../components/Wrapper";
import { Block } from "../components/Block";
import { Breadcrumbs } from "../components/Breadcrumbs";
import { Header } from "../components/Header";
import { InstructorCard } from "../components/InstructorCard";
import { LessonCustomCode } from "../components/Lesson/LessonCustomCode";
import { Podcast } from "../components/Podcast";
import { submitLessonExam } from "../actions/course/submitExam";
import { TestForm } from "../components/Test/Form";
import { SEO } from "../components/SEO";
import { PageQueryHandler } from "./PageQueryHandler";
import CheatsSheetsModal from "../components/CheatsSheetsModal";
import { useResponsive } from "../hooks/useResponsive";
import { ScrollToTopOnMount } from "../components/ScrollToTopOnMount";

export function LessonPage(props: {}) {
  const params = useParams<{
    enrolmentID: string;
    lessonID: string;
  }>();

  const { isMobile } = useResponsive();
  const { t } = useTranslation();
  const history = useHistory();

  const {
    data: lesson,
    error: lessonError,
    isLoading: lessonIsLoading,
    refetch,
    setData,
  } = useFetch(getLesson, {
    enrolmentID: params.enrolmentID,
    lessonId: params.lessonID,
  });

  const [finished, setFinished] = useState(false);
  const [cheatsModal, setCheatsModal] = useState<
    "next-lesson" | "show-modal" | null
  >(null);

  const onBookmark = useCallback(async () => {
    try {
      await bookmarkLesson({
        enrolmentId: params.enrolmentID,
        lessonId: params.lessonID,
      });

      const isbookmarked = lesson?.lesson.is_bookmarked;

      if (lesson) {
        setData({
          ...lesson,
          lesson: {
            ...lesson.lesson,
            is_bookmarked: !isbookmarked,
          },
        });
      } else {
        refetch();
      }

      toast.success(t("status.success"));
    } catch (error: any) {
      toast.error(
        t("status.error", {
          error: serverErrorHandler,
        })
      );
    }
  }, [lesson, params.enrolmentID, params.lessonID, refetch, setData, t]);

  useEffect(() => {
    if (
      lesson &&
      lesson.lesson.type !== ELessonType.PODCAST &&
      lesson.lesson.type !== ELessonType.EMBED_VIDEO
    ) {
      setFinished(true);
    }
  }, [lesson]);

  if (!lesson || lessonError || lessonIsLoading) {
    return <PageQueryHandler error={lessonError} isLoading={lessonIsLoading} />;
  }

  const onFinish = () => {
    setFinished(true);
  };

  const breadcrumbsItems = [
    {
      label: t("lesson.courses"),
      url: "/courses",
    },
    {
      label: lesson?.lesson.course.name,
      url: `/course/${lesson?.lesson.course.uuid}/`,
    },
  ];

  const goNext = () => {
    if (lesson.lesson.next) {
      history.push(
        `/course-enrolments/${params.enrolmentID}/lessons/${lesson.lesson.next.uuid}`
      );
    } else if (lesson.lesson.has_exam_tests) {
      history.push(`/course-enrolments/${params.enrolmentID}/exam`);
    } else {
      history.push(`/course/${lesson.lesson.course.uuid}?congratulation`);
    }

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const onComplete = async () => {
    if (!lesson) return;

    if (!lesson.completed) {
      try {
        await completeLesson({
          enrollmentId: params.enrolmentID,
          lessonId: params.lessonID,
        });
      } catch (error: any) {
        toast.error(
          t("status.error", {
            error: serverErrorHandler(error),
          })
        );
      }
    }

    if (!lesson.completed && lesson.lesson.cheat_sheets.length > 0) {
      setCheatsModal("next-lesson");
    } else {
      goNext();
    }
  };

  const isFullwidth =
    lesson.lesson.type === ELessonType.CUSTOM_CODE ||
    lesson.lesson.type === ELessonType.CAROUSEL ||
    lesson.lesson.type === ELessonType.BLOCK;

  return (
    <Wrapper>
      <ScrollToTopOnMount />
      <SEO>
        <title>{lesson.lesson.name}</title>
      </SEO>
      <Header />
      <Block>
        <div
          css={css`
            display: flex;
            flex-direction: ${isMobile ? "column" : "row"};
            width: 100%;
            justify-content: space-between;
            align-items: ${isMobile ? "left" : "center"};
            margin-bottom: ${isMobile ? "20px" : "30px"};
          `}
        >
          <Breadcrumbs items={breadcrumbsItems} />

          <LessonNavigation item={lesson} onComplete={onComplete} />
        </div>
        <LessonHeader item={lesson} onBookmark={onBookmark} />

        <div
          css={css`
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 20px;
            padding: ${isMobile ? "20px" : "40px"};
            background-color: ${theme.colors.white};
            border-radius: 20px;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              width: ${isFullwidth || isMobile ? "100%" : "70%"};
            `}
          >
            {lesson.lesson.type === ELessonType.TEXT && (
              <LessonText item={lesson} />
            )}
            {lesson.lesson.type === ELessonType.CAROUSEL && (
              <LessonCarousel content={lesson.lesson.carousel_content} />
            )}
            {lesson.lesson.type === ELessonType.EMBED_VIDEO && (
              <LessonVideo
                url={lesson.lesson.video_url}
                tests={lesson.lesson.tests}
                onFinish={onFinish}
                lessonUuid={lesson.lesson.uuid}
                enrolmentID={params.enrolmentID}
                downloadUrl={lesson.lesson.video_download_url}
              />
            )}
            {lesson.lesson.type === ELessonType.PODCAST &&
              lesson.lesson.audio && (
                <Podcast audio={lesson.lesson.audio} onFinish={onFinish} />
              )}
            {lesson.lesson.type === ELessonType.BLOCK && (
              <LessonBlock
                item={lesson.lesson}
                onFinish={onFinish}
                enrolmentID={params.enrolmentID}
                onComplete={onComplete}
              />
            )}
            {lesson.lesson.type === ELessonType.CUSTOM_CODE && (
              <LessonCustomCode
                code={lesson.lesson.custom_code}
                onComplete={onComplete}
              />
            )}
            {lesson.lesson.tests.length > 0 && (
              <div
                css={css`
                  background-color: ${theme.colors.lightBlue};
                  padding: 50px;
                  border-radius: 50px;
                  box-sizing: border-box;

                  ${isMobile &&
                  css`
                    padding: 20px;
                    border-radius: 20px;
                  `}

                  ${lesson.lesson.type !== ELessonType.TEST &&
                  css`
                    margin-top: 50px;
                  `}
                `}
              >
                <TestForm
                  key={lesson.lesson.uuid}
                  tests={lesson.lesson.tests}
                  onSubmit={async (examState) => {
                    const response = await submitLessonExam({
                      enrolmentID: params.enrolmentID,
                      lessonId: lesson.lesson.uuid,
                      data: examState,
                    });

                    return response.data;
                  }}
                  onRefetch={() => {
                    refetch();
                  }}
                />
              </div>
            )}

            <LessonFooter
              item={lesson}
              enrolmentId={params.enrolmentID}
              lessonId={params.lessonID}
              onComplete={onComplete}
              onShowCheats={() => {
                setCheatsModal("show-modal");
              }}
              finished={finished}
            />

            {lesson.lesson.instructors.length > 0 && (
              <div
                css={`
                  margin-top: ${isMobile ? "20px" : "40px"};
                  display: flex;
                  flex-direction: ${isMobile ? "column" : "row"};
                  flex-wrap: wrap;
                  margin-right: ${isMobile ? "0" : "-40px"};
                `}
              >
                {lesson.lesson.instructors.map((instructor, key) => {
                  return (
                    <div
                      key={key}
                      css={css`
                        width: ${isMobile ? "auto" : "50%"};
                      `}
                    >
                      <InstructorCard
                        key={`experts--${instructor.id}`}
                        instructor={instructor}
                        css={css`
                          margin-right: ${isMobile ? "0" : "40px"};
                          margin-bottom: ${isMobile ? "12px" : "0"};
                        `}
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </Block>
      {cheatsModal && (
        <CheatsSheetsModal
          cheatsSheets={lesson.lesson.cheat_sheets}
          isActive={!!cheatsModal}
          onClose={() => setCheatsModal(null)}
          onComplete={() => {
            setCheatsModal(null);

            if (cheatsModal === "next-lesson") {
              goNext();
            }
          }}
        />
      )}
    </Wrapper>
  );
}
