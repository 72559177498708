import React from "react";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { Check, Printer } from "react-feather";
import { Filters } from "../../hooks/useFilters";
import { CheatsSheet } from "../../types/CheatsSheets";
import { Headers } from "../../helpers/layout";
import { theme } from "../../themes/variables";
import { Button } from "../Button";
import FilterItem from "../FilterItem";
import Cheatsheet from "./../../assets/img/Cheatsheet.png";
import { useResponsive } from "../../hooks/useResponsive";

export type Option = {
  value: string;
  label: string;
};

export default function CheatsActions({
  items,
  activeFilters,
  selectedCheats,
  onFilterChange,
  onOpenPDF,
}: {
  items: CheatsSheet[];
  activeFilters: Filters;
  selectedCheats: CheatsSheet[];
  onFilterChange: (key: string, value: string) => void;
  onOpenPDF: (cheats: CheatsSheet[]) => void;
}) {
  const { journey } = activeFilters;
  const { isMobile } = useResponsive();

  const { t } = useTranslation();

  const journeys = items.reduce((items: Option[], item) => {
    if (!item.lesson.course.journey) return items;

    const journey = {
      value: item.lesson.course.journey.uuid,
      label: item.lesson.course.journey.name,
    };

    if (
      !items.some(
        (item: Option) =>
          item.label === journey.label && item.value === journey.value
      )
    ) {
      items.push(journey);
    }
    return items;
  }, []);

  const journeysOption = [
    {
      value: "",
      label: t("cheats_sheet.all"),
    },
    ...journeys,
  ];

  return (
    <div
      css={css`
        display: flex;
        flex-direction: ${isMobile ? "column" : "row"};
        width: 100%;
        align-items: ${isMobile ? "left" : "center"};
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <img
          src={Cheatsheet}
          alt={t("cheats_sheet.cheats")}
          css={css`
            width: ${isMobile ? "44px" : "60px"};
          `}
        />
        <Headers.H3
          css={css`
            font-size: ${isMobile ? "16px" : "30px"};
            margin: 0 26px;
          `}
        >
          {t("cheats_sheet.title")}
        </Headers.H3>
      </div>
      <div
        css={css`
          display: flex;
          margin: ${isMobile ? "30px 0" : "0 auto"};
        `}
      >
        <FilterItem
          label={t("cheats_sheet.filter")}
          options={journeysOption}
          defaultOption={journey}
          onChangeOption={(e) => onFilterChange("journey_id", e.target.value)}
        />
      </div>
      <div
        css={css`
          display: flex;
          justify-content: ${isMobile ? "space-between" : "center"};
          align-items: center;
        `}
      >
        <Button
          color={
            selectedCheats.length <= 0 ? theme.colors.gray5 : theme.colors.white
          }
          background={theme.colors.primary}
          border={`1px solid ${
            selectedCheats.length <= 0
              ? theme.colors.transparentBlack1
              : theme.colors.primary
          }`}
          css={css`
            margin-right: 16px;
          `}
          disabled={selectedCheats.length <= 0}
          icon={
            <Check
              size={12}
              color={
                selectedCheats.length <= 0
                  ? theme.colors.gray5
                  : theme.colors.white
              }
            />
          }
          iconRight
          onClick={() => onOpenPDF(selectedCheats)}
        >
          {t("cheats_sheet.print_selected")}
        </Button>
        <Button
          color={items.length <= 0 ? theme.colors.gray5 : theme.colors.white}
          background={theme.colors.black}
          border={`1px solid ${
            items.length <= 0
              ? theme.colors.transparentBlack1
              : theme.colors.black
          }`}
          hoverStyles={`color: ${
            items.length <= 0 ? theme.colors.gray5 : theme.colors.white
          }; background: ${theme.colors.primary}; border: 1px solid ${
            items.length <= 0
              ? theme.colors.transparentBlack1
              : theme.colors.primary
          };`}
          disabled={items.length <= 0}
          icon={
            <Printer
              size={12}
              color={
                items.length <= 0 ? theme.colors.gray6 : theme.colors.white
              }
            />
          }
          iconRight
          onClick={() => onOpenPDF(items)}
        >
          {t("cheats_sheet.print_all")}
        </Button>
      </div>
    </div>
  );
}
