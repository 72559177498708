import React from "react";
import { css } from "styled-components/macro";
import { Spacing } from "../../helpers/layout";
import { ILesson } from "../../types/Lesson";
import { BlockType, Block } from "../../types/Block";
import { LessonCustomCode } from "./LessonCustomCode";
import { LessonColumns } from "./LessonColumns";
import { Podcast } from "../Podcast";
import { LessonVideo } from "./LessonVideo";
import { LessonCarousel } from "./LessonCarousel";
import { LessonCollapse } from "./LessonCollapse";
import { useResponsive } from "../../hooks/useResponsive";
import { LessonPPTBlock } from "./LessonPPTBlock";

const BlockWrapper = (props: {
  block: Block;
  children: React.ReactNode;
  isMobile?: boolean;
}) => {
  return (
    <div
      css={css`
        background-image: url("${props.block.background_image?.image}");
        background-size: ${props.block.background_size};
        background-color: ${props.block.background_color};
        background-position: ${props.block.background_position};
        background-repeat: no-repeat;
        padding: ${props.isMobile ? "32px 24px" : Spacing.xxl};
        margin-bottom: ${props.isMobile ? "20px" : Spacing.xxl};
        border-radius: 40px;

        p:last-child {
          margin-bottom: 0;
        }
      `}
    >
      {props.children}
    </div>
  );
};

export function LessonBlock(props: {
  item: ILesson;
  onFinish?: () => void;
  enrolmentID?: string;
  isDemo?: boolean;
  onComplete: () => void;
}) {
  const { isMobile } = useResponsive();

  return (
    <div>
      {props.item.lesson_blocks.map((el, idx) => {
        const { block } = el;

        return (
          <div key={idx}>
            {block.type === BlockType.COLUMNS && block && (
              <BlockWrapper block={block} isMobile={isMobile}>
                <LessonColumns block={block} />
              </BlockWrapper>
            )}

            {block.type === BlockType.PODCAST && block.data.audio && (
              <BlockWrapper block={block} isMobile={isMobile}>
                <Podcast audio={block.data.audio} />
              </BlockWrapper>
            )}

            {block.type === BlockType.EMBED_VIDEO && block.data.url && (
              <BlockWrapper block={block} isMobile={isMobile}>
                <LessonVideo
                  url={block.data.url}
                  tests={
                    props.isDemo
                      ? []
                      : props.item.tests.filter((el) =>
                          block.data.tests?.map((el) => el.id).includes(el.uuid)
                        )
                  }
                  lessonUuid={props.item.uuid}
                  enrolmentID={props.enrolmentID}
                  isDemo={props.isDemo}
                />
              </BlockWrapper>
            )}

            {!isMobile &&
              block.type === BlockType.CUSTOM_CODE &&
              block.data.code && (
                <BlockWrapper block={block} isMobile={isMobile}>
                  <LessonCustomCode
                    code={block.data.code}
                    onComplete={props.onComplete}
                  />
                </BlockWrapper>
              )}

            {block.type === BlockType.CAROUSEL && block.data.blocks && (
              <BlockWrapper block={block} isMobile={isMobile}>
                <LessonCarousel content={block.data.blocks} html />
              </BlockWrapper>
            )}

            {block.type === BlockType.COLLAPSE_BLOCK && block.data.blocks && (
              <BlockWrapper block={block} isMobile={isMobile}>
                <LessonCollapse
                  blocks={block.data.blocks}
                  title={block.data.title}
                />
              </BlockWrapper>
            )}

            {block.type === BlockType.PPT_BLOCK && block.data.file && (
              <BlockWrapper block={block} isMobile={isMobile}>
                <LessonPPTBlock block={block} />
              </BlockWrapper>
            )}
          </div>
        );
      })}
    </div>
  );
}
