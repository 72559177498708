import React from "react";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { theme } from "../../themes/variables";
import { hexToRgb } from "../../helpers/color";
import { Headers } from "../../helpers/layout";
import { Card } from "../../components/Card";
import { useResponsive } from "../../hooks/useResponsive";

export function PaymentProcessing(props: { message: string }) {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();

  const { r, g, b } = hexToRgb(theme.colors.blueBackground);
  const CardRgbaColor = `rgba(${r}, ${g}, ${b}, 0.4)`;

  return (
    <Card
      css={css`
        background-color: ${CardRgbaColor};
        padding: ${isMobile ? "30px 24px 24px 24px" : "40px"};
      `}
    >
      <Headers.H3>{t("payment.processing")}</Headers.H3>

      {props.message && (
        <div
          id="payment-message"
          css={css`
            margin-top: 16px;
          `}
        >
          {props.message}
        </div>
      )}
    </Card>
  );
}
