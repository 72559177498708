import { notAuthorised } from "../../request";
import { Page } from "../../types/Page";

type Args = {
  pageSlug: string;
};

export async function getPage({ pageSlug }: Args) {
  const API = notAuthorised;

  return API.request<Page>({
    method: "GET",
    url: `/api/campus/pages/${pageSlug}/`,
  });
}
