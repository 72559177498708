import { Headers } from "./layout";

const addBreaklines = (children: HTMLElement[]) =>
  children.map((child) => [child, <br />]);

export const renderers = {
  inline: {
    BOLD: (children: HTMLElement, { key }: any) => (
      <strong key={key}>{children}</strong>
    ),
    ITALIC: (children: HTMLElement, { key }: any) => (
      <em key={key}>{children}</em>
    ),
    UNDERLINE: (children: HTMLElement, { key }: any) => (
      <u key={key}>{children}</u>
    ),
    CODE: (children: HTMLElement, { key }: any) => (
      <span key={key} className="code">
        {children}
      </span>
    ),
  },
  blocks: {
    unstyled: (children: HTMLElement[]) =>
      children.map((child, key) => <p key={key}>{child}</p>),
    blockquote: (children: HTMLElement[], key: number) => (
      <div key={key}>
        <blockquote>{children}</blockquote>
      </div>
    ),
    "header-one": (children: HTMLElement[]) =>
      children.map((child, key) => <Headers.H1 key={key}>{child}</Headers.H1>),
    "header-two": (children: HTMLElement[]) =>
      children.map((child, key) => <Headers.H2 key={key}>{child}</Headers.H2>),
    "header-three": (children: HTMLElement[]) =>
      children.map((child, key) => <Headers.H3 key={key}>{child}</Headers.H3>),
    "header-four": (children: HTMLElement[]) =>
      children.map((child, key) => <Headers.H4 key={key}>{child}</Headers.H4>),
    "header-five": (children: HTMLElement[]) =>
      children.map((child, key) => <Headers.H5 key={key}>{child}</Headers.H5>),
    "header-six": (children: HTMLElement[]) =>
      children.map((child, key) => <Headers.H6 key={key}>{child}</Headers.H6>),
    "code-block": (children: HTMLElement[], { keys }: any) => (
      <pre key={keys[0]}>{addBreaklines(children)}</pre>
    ),
    "unordered-list-item": (children: HTMLElement[], { depth, keys }: any) => (
      <ul key={keys[keys.length - 1]} className={`ul-level-${depth}`}>
        {children.map((child, index) => (
          <li key={keys[index]}>{child}</li>
        ))}
      </ul>
    ),
    "ordered-list-item": (children: HTMLElement[], { depth, keys }: any) => (
      <ol key={keys.join("|")} className={`ol-level-${depth}`}>
        {children.map((child, index) => (
          <li key={keys[index]}>{child}</li>
        ))}
      </ol>
    ),
  },
  entities: {
    LINK: (
      children: HTMLElement,
      data: { url: string | undefined },
      { key }: any
    ) => (
      <a key={key} href={data.url}>
        {children}
      </a>
    ),
    IMAGE: (
      name: string,
      data: { alt: string | undefined; src: string | undefined },
      { key }: any
    ) => <img key={key} src={data.src} alt={data.alt} title={data.alt} />,
  },
};
