import React, { useEffect, useMemo, useState } from "react";
import { css } from "styled-components/macro";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import { serverErrorHandler } from "../../helpers/serverErrorHandler";
import { TestRenderer } from "./Renderer";
import {
  LessonTest,
  Submission,
  SubmissionResponse,
} from "../../types/LessonTest";
import { Row, Spacing } from "../../helpers/layout";
import { TextInCircle } from "../../components/TextInCircle";
import { Button } from "../../components/Button";
import { theme } from "../../themes/variables";
import { EnumTestType, TestVariant } from "../../types/Test";
import { useResponsive } from "../../hooks/useResponsive";

type TestRequirement = LessonTest & {
  variant: TestVariant;
};

const TestFormContext = React.createContext<{
  tests: TestRequirement[];
  state: Submission;
  setState: React.Dispatch<React.SetStateAction<Submission>>;
  submission: SubmissionResponse | null;
  isSubmitting: boolean;
  onRefresh: () => void;
  onSubmit: () => Promise<void>;
  onContinue?: () => void;
}>({
  tests: [],
  state: [],
  setState: () => {},
  onRefresh: () => {},
  onContinue: () => {},
  onSubmit: async () => {},
  submission: null,
  isSubmitting: false,
});

export function useTestForm() {
  const context = React.useContext(TestFormContext);

  if (context === undefined) {
    throw new Error("useTestForm must be used within a TestFormProvider");
  }

  return context;
}

export function TestFormUI(props: {
  styleVariant?: "default" | "card";
  hideResponse?: boolean;
}) {
  const { isMobile } = useResponsive();
  const { t } = useTranslation();
  const {
    tests,
    state,
    setState,
    isSubmitting,
    submission,
    onSubmit,
    onRefresh,
    onContinue,
  } = useTestForm();

  return (
    <div>
      {tests.map((test) => {
        const testState = state.find((st) => {
          return st.variant_id === test.variant.id;
        });

        if (!testState) return null;

        return (
          <div
            key={`form-question--${test.uuid}`}
            css={css`
              width: 100%;
              margin-top: ${Spacing.xxl};

              ${isMobile &&
              css`
                margin-top: 20px;
              `}

              &:first-child {
                margin-top: 0;
              }
            `}
          >
            <TestRenderer
              test={test}
              state={testState.answers}
              styleVariant={props.styleVariant}
              submission={submission}
              onStateChange={(answers) => {
                setState((state) => {
                  const newState = state.map((testState) => {
                    if (testState.variant_id === test.variant.id) {
                      return {
                        ...testState,
                        answers,
                      };
                    }

                    return testState;
                  });

                  return newState;
                });
              }}
            />
          </div>
        );
      })}
      <Row
        justify="center"
        align="center"
        css={css`
          margin-top: 20px;
        `}
      >
        {submission ? (
          !props.hideResponse ? (
            <Row align="center" wrap="wrap">
              <TextInCircle
                css={css`
                  background-color: ${submission.passed
                    ? theme.colors.greenMain
                    : theme.colors.redMain};
                `}
              >
                {submission.score.toFixed()}%
              </TextInCircle>

              <div
                css={css`
                  flex: 1 0 10%;
                `}
              >
                {submission.passed
                  ? t("exam.passed_message")
                  : t("exam.not_passed_message")}
              </div>
              {!submission.passed && (
                <div
                  css={
                    isMobile &&
                    css`
                      flex: 0 0 100%;
                      margin-top: 20px;
                    `
                  }
                >
                  <Button disabled={isSubmitting} onClick={() => onRefresh()}>
                    {t("actions.try-again")}
                  </Button>
                </div>
              )}
              {submission.passed && onContinue && (
                <div>
                  <Button
                    disabled={isSubmitting}
                    onClick={() => onContinue && onContinue()}
                  >
                    {t("actions.continue")}
                  </Button>
                </div>
              )}
            </Row>
          ) : null
        ) : (
          <Button disabled={isSubmitting} onClick={onSubmit}>
            {t("actions.submit")}
          </Button>
        )}
      </Row>
    </div>
  );
}

export function TestFormProvider(props: {
  tests: LessonTest[];
  onSubmit: (examState: Submission) => Promise<SubmissionResponse>;
  onContinue?: () => void;
  onRefetch: () => void;
  style?: "default" | "card";
  hideResponse?: boolean;
  children: React.ReactNode;
}) {
  // Prevent infinite loop
  const tests = useMemo(
    () => props.tests.filter((test) => test.variant) as TestRequirement[],
    [props.tests]
  );
  const { t } = useTranslation();
  const [state, setState] = useState<Submission>([]);
  const [submission, setSubmission] = useState<null | SubmissionResponse>(null);
  const [isSubmitting, setSubmitting] = useState(false);

  useEffect(() => {
    setState(
      tests.map((test) => {
        return {
          variant_id: test.variant.id,
          answers: test.variant.answers.map((answer) => {
            return {
              id: answer.id,
              answer:
                test.question_type === EnumTestType.MULTIPLE_CHOICE
                  ? false
                  : null,
            };
          }),
        };
      })
    );
  }, [tests]);

  const onSubmit = async () => {
    setSubmitting(true);

    try {
      const response = await props.onSubmit(state);

      setSubmission(response);
    } catch (error: any) {
      toast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    } finally {
      setSubmitting(false);
    }
  };

  const onRefresh = () => {
    setSubmission(null);
    setState([]);
    setSubmitting(false);
    props.onRefetch();
  };

  return (
    <TestFormContext.Provider
      value={{
        tests,
        state,
        setState,
        isSubmitting,
        submission,
        onSubmit,
        onRefresh,
        onContinue: props.onContinue,
      }}
    >
      {props.children}
    </TestFormContext.Provider>
  );
}

export function TestForm(props: {
  tests: LessonTest[];
  onSubmit: (examState: Submission) => Promise<SubmissionResponse>;
  onContinue?: () => void;
  onRefetch: () => void;
  styleVariant?: "default" | "card";
  hideResponse?: boolean;
}) {
  return (
    <TestFormProvider {...props}>
      <TestFormUI />
    </TestFormProvider>
  );
}
