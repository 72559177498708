import React, { useEffect, useState } from "react";
import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { ITranscript, EnumAcceptedMimeTypes, IFile } from "../types/File";
import { AudioPlayer } from "./AudioPlayer";
import { Badge } from "./Badge";
import { theme } from "../themes/variables";
import { ReactComponent as DownSvg } from "../assets/svg/Down.svg";

const nextShowAmount = 4;

function getTranscriptTime(hours: string, minutes: string, seconds: string) {
  return +hours * 3600 + +minutes * 60 + +seconds;
}
function getBeautifulTime(hours: string, minutes: string, seconds: string) {
  let time = "";

  if (+hours) {
    time += +hours + ":";
  }
  time += +minutes + ":";
  time += seconds;

  return time;
}

function Transcripts(props: {
  data: ITranscript[];
  className?: string;
  time: number | null;
}) {
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState(false);

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const index = props.data.findIndex((el, idx) => {
      const [H, M, S] = el.timecode.split(":");
      const transcriptStartTime = getTranscriptTime(H, M, S);

      let transcriptEndTime = 0;

      if (idx < props.data.length - 1) {
        const [H, M, S] = props.data[idx + 1].timecode.split(":");
        transcriptEndTime = getTranscriptTime(H, M, S);
      }

      if (typeof props.time === "number") {
        if (!transcriptEndTime && props.time >= transcriptStartTime) {
          return true;
        } else {
          return (
            props.time >= transcriptStartTime && props.time < transcriptEndTime
          );
        }
      }

      return false;
    });

    if (index !== -1) {
      setCurrentIndex(index);
    }
  }, [props.data, props.time]);

  const showExpand = currentIndex >= nextShowAmount;

  return (
    <div className={props.className}>
      <TransitionGroup>
        {props.data
          .filter((_, idx) => {
            if (expanded) {
              return idx <= currentIndex;
            }

            if (showExpand) {
              return idx <= currentIndex && idx > currentIndex - nextShowAmount;
            }

            return idx <= currentIndex;
          })
          .reverse()
          .map((transcript, idx) => {
            const [H, M, S] = transcript.timecode.split(":");

            return (
              <CSSTransition
                key={transcript.timecode}
                timeout={400}
                classNames="item"
              >
                <div
                  css={css`
                    display: flex;
                    position: relative;
                    margin-bottom: 12px;
                    :last-of-type {
                      margin-bottom: 0;
                    }
                  `}
                >
                  <div
                    css={css`
                      display: flex;
                      flex-direction: column;
                    `}
                  >
                    <Badge
                      background={theme.colors.gray1}
                      color={
                        idx === 0
                          ? theme.colors.dark
                          : expanded
                          ? theme.colors.gray4
                          : idx === 1
                          ? theme.colors.gray6
                          : idx === 2
                          ? theme.colors.gray5
                          : idx === 3
                          ? theme.colors.gray4
                          : theme.colors.gray3
                      }
                      bold
                    >
                      {getBeautifulTime(H, M, S)}
                    </Badge>
                  </div>

                  <p
                    css={css`
                      margin: 0;
                      margin-left: 20px;
                      padding: 0 5px;
                      border-radius: 3px;
                      font-size: 14px;
                      line-height: 14px;
                      display: inline;
                      color: ${idx === 0
                        ? theme.colors.dark
                        : expanded
                        ? theme.colors.gray4
                        : idx === 1
                        ? theme.colors.gray6
                        : idx === 2
                        ? theme.colors.gray5
                        : idx === 3
                        ? theme.colors.gray4
                        : theme.colors.gray3};
                    `}
                  >
                    {transcript.content}
                  </p>
                </div>
              </CSSTransition>
            );
          })}
      </TransitionGroup>

      <div>
        {showExpand && (
          <button
            css={css`
              display: flex;
              align-items: center;
              align-self: center;
              padding: 3px 20px;
              margin: 24px auto 0 auto;
              border-radius: 20px;
              border: 1px solid ${theme.colors.dark};
              background: ${theme.colors.white};
              cursor: pointer;
            `}
            onClick={() => setExpanded(!expanded)}
          >
            <div
              css={css`
                font-weight: bold;
                font-size: 16px;
              `}
            >
              {expanded ? t("actions.show_less") : t("actions.show_more")}
            </div>

            <DownSvg
              css={css`
                margin-left: 10px;
                ${expanded && `transform: rotate(180deg);`}
              `}
              height={14}
              width={14}
            />
          </button>
        )}
      </div>
    </div>
  );
}

export function Podcast(props: { audio: IFile; onFinish?: () => void }) {
  const [time, setTime] = useState<number | null>(null);
  const { audio } = props;

  return (
    <div
      css={css`
        position: relative;
        width: 100%;
      `}
    >
      {audio && audio.mime_type === EnumAcceptedMimeTypes.MP3 && (
        <AudioPlayer
          src={audio.file}
          title={audio.title || audio.upload_name}
          onListen={(time) => setTime(time)}
          onFinish={props.onFinish}
        />
      )}

      {audio.transcript && !!audio.transcript.length && (
        <Transcripts
          data={audio.transcript}
          time={time}
          css={css`
            margin-top: 40px;
            border: 1px solid ${theme.colors.gray1};
            background-color: ${theme.colors.white};
            border-radius: 12px;
            padding: 24px;
          `}
        />
      )}
    </div>
  );
}
