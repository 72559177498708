import { css } from "styled-components/macro";
import { useResponsive } from "../hooks/useResponsive";
import { CourseListItem } from "../types/Course";
import CourseCard from "./CourseCard";

export function PopularCourses(props: {
  id?: number;
  data?: CourseListItem[];
}) {
  const { isMobile } = useResponsive();

  return (
    <div
      css={css`
        display: grid;
        grid-template-columns: ${isMobile ? "1fr" : "repeat(2, 1fr)"};
        grid-template-rows: 1fr;
        grid-gap: ${isMobile ? "20px" : "40px"};
      `}
    >
      {props.data &&
        props.data
          .map((popCourse, i) => <CourseCard item={popCourse} key={i} />)
          .slice(0, 2)}
    </div>
  );
}
