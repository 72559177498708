import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Form, Formik } from "formik";
import { css } from "styled-components/macro";
import * as Yup from "yup";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useLocation } from "react-router-dom";
import { SignupField, SignupFieldType } from "../../types/SignupField";
import { FormValues, registration } from "../../actions/auth/registration";
import { USER_CONFIRM_EMAIL } from "../../actions/auth/resendConfirmationEmail";
import { useAuth } from "../../contexts/UserContext";
import { Column, Row, Spacing } from "../../helpers/layout";
import { serverErrorHandler } from "../../helpers/serverErrorHandler";
import { TextField } from "../fields/TextField";
import { TextareaField } from "../fields/TextareaField2";
import { SelectField } from "../fields/SelectField2";
import { RadioFields } from "../fields/RadioFields";
import { CheckboxFields } from "../fields/CheckboxFields";
import { Headers } from "../../helpers/layout";
import { theme } from "../../themes/variables";
import { Button } from "../../components/Button";
import { CheckboxField } from "../../components/CheckboxField";
import PasswordInstructions from "../../components/PasswordInstructions";
import { useEnterprise } from "../../contexts/EnterpriseContext";
import {
  getSignupFieldsInitialEmptyValues,
  getSignupFieldsSchema,
  getSignupFieldsServerValues,
} from "../../helpers/signupFields";
import { pageLoading } from "../../helpers/pageLoading";
import queryString from "query-string";

export function RegistrationForm(props: {
  referral: string | null;
  initialValues: Partial<FormValues>;
}) {
  const { t } = useTranslation();
  const { authenticate } = useAuth();
  const { enterprise } = useEnterprise();
  const history = useHistory();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const re = new RegExp("[a-zA-Z-]");
  const passwordRe = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#&$%^*+,.:;<=>?({//\\\\})\\[\\]`~|\"'_-])(?=.{9,150})"
  );

  const [signupFields, setSignupFields] = useState<SignupField[] | null>(null);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const getSignupFields = async () => {
      if (!isLoading && !signupFields) {
        try {
          setLoading(true);
          const { data: signupFields } = await axios.get<SignupField[]>(
            "/api/campus/signup-field/",
            {
              baseURL: process.env.REACT_APP_BACKEND_URL,
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "accept-language": process.env.REACT_APP_LANGUAGE,
              },
            }
          );

          setSignupFields(signupFields);
        } catch (e) {
          toast.error(
            t("status.error", {
              error: serverErrorHandler(e),
            })
          );
        } finally {
          setLoading(false);
        }
      }
    };

    getSignupFields();
  }, [isLoading, signupFields, t]);

  if (!signupFields) {
    return pageLoading("");
  }

  const dynamicSchema = getSignupFieldsSchema(signupFields, t);

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().matches(re).label(t("auth.first_name")).required(),
    last_name: Yup.string().matches(re).label(t("auth.last_name")).required(),
    email: Yup.string().email().label(t("auth.email")).required(),
    password: Yup.string()
      .matches(passwordRe)
      .label(t("auth.password"))
      .required(),
    password2: Yup.string()
      .label(t("auth.repeated_password"))
      .oneOf([Yup.ref("password"), null], t("auth.pwd_not_match"))
      .required(),
    checkbox: Yup.boolean().oneOf([true], t("auth.accept_checkbox")).required(),
    signup_fields: Yup.object().shape(dynamicSchema),
  });

  const initialValues: FormValues = {
    first_name: props.initialValues.first_name || "",
    last_name: props.initialValues.last_name || "",
    email: props.initialValues.email || "",
    password: "",
    password2: "",
    referral: props.referral,
    signup_fields: getSignupFieldsInitialEmptyValues(signupFields),
  };

  return (
    <div>
      <Formik<FormValues>
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values: FormValues, { setSubmitting }) => {
          try {
            const signupFieldsValues = getSignupFieldsServerValues(
              values.signup_fields,
              signupFields
            );

            setSubmitting(true);

            const response = await registration({
              ...values,
              signup_fields: signupFieldsValues,
            });

            if (!response.data.email_confirmed) {
              localStorage.setItem(USER_CONFIRM_EMAIL, values.email);

              history.push("/confirm-email");
            } else if (!response.data.is_verified) {
              history.push("/verification-info");
            } else {
              authenticate(response.data.token, response.data.userprofile);

              const redirect_to = queryParams.redirect_to || "/";

              if (redirect_to) {
                history.push(redirect_to);
              }
            }
          } catch (error: any) {
            toast.error(
              t("status.error", {
                error: serverErrorHandler(error),
              })
            );
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, dirty, isValid, values }) => (
          <Form noValidate>
            <Column gutter={Spacing.none}>
              <label
                css={css`
                  font-size: 16px;
                  line-height: 2;
                  color: ${theme.colors.black};
                  letter-spacing: 0.01em;
                  font-weight: 500;
                `}
              >
                {t("auth.personal_info")}
              </label>
              <Row gutter={Spacing.none} justify="flex-start">
                <TextField
                  name="first_name"
                  type="text"
                  placeholder={t("auth.first_name")}
                  css={css`
                    border-radius: 10px 0 0 0;
                  `}
                />
                <TextField
                  name="last_name"
                  type="text"
                  placeholder={t("auth.last_name")}
                  css={css`
                    border-radius: 0 10px 0 0;
                    margin-left: -1px;
                  `}
                />
              </Row>
              <TextField
                name="email"
                type="email"
                placeholder={t("auth.email")}
                css={css`
                  margin-top: 1px;
                `}
              />
              <div
                css={css`
                  width: 100%;
                `}
              >
                <div
                  css={css`
                    background-color: ${theme.colors.gray1};
                    color: ${theme.colors.gray4};
                    margin-bottom: -1px;
                    padding: 7px 10px;
                    font-size: 14px;
                    border: 1px solid ${theme.colors.transparentBlack3};
                    margin-top: -1px;
                    margin-bottom: 20px;
                    border-radius: 0 0 10px 10px;
                  `}
                >
                  {t("auth.email_hint")}
                </div>
              </div>
              <TextField
                label={t("auth.create_password")}
                name="password"
                type="password"
                hideTooltip
                placeholder={t("auth.password")}
                css={css`
                  border-radius: 10px 10px 0 0;
                `}
              />
              <TextField
                name="password2"
                type="password"
                placeholder={t("auth.repeated_password")}
                css={css`
                  margin-top: 1px;
                `}
              />
              <PasswordInstructions
                password={values.password}
                password2={values.password2}
              />
            </Column>

            <Column>
              {signupFields.map((field) => {
                return (
                  <React.Fragment key={field.order}>
                    {field.type === SignupFieldType.TEXT_FIELD && (
                      <TextField
                        label={field.name}
                        name={`signup_fields[${field.uuid}][0]`}
                        hint={field.hint || ""}
                        placeholder={field.placeholder || ""}
                        css={css`
                          border-radius: 10px;
                        `}
                      />
                    )}
                    {field.type === SignupFieldType.TEXT_AREA && (
                      <TextareaField
                        label={field.name}
                        name={`signup_fields[${field.uuid}][0]`}
                        hint={field.hint || ""}
                        placeholder={field.placeholder || ""}
                        css={css`
                          border-radius: 10px;
                        `}
                      />
                    )}
                    {field.type === SignupFieldType.SELECT && (
                      <SelectField
                        label={field.name}
                        name={`signup_fields[${field.uuid}][0]`}
                        hint={field.hint || ""}
                        choices={field.options.map((option) => {
                          return {
                            label: option.value,
                            value: option.value,
                          };
                        })}
                      />
                    )}
                    {field.type === SignupFieldType.RADIO && (
                      <RadioFields
                        label={field.name}
                        labelRight
                        name={`signup_fields[${field.uuid}][0]`}
                        hint={field.hint || ""}
                        choices={field.options.map((option) => {
                          return {
                            label: option.value,
                            value: option.value,
                          };
                        })}
                      />
                    )}
                    {field.type === SignupFieldType.CHECKBOX && (
                      <CheckboxFields
                        label={field.name}
                        name={`signup_fields[${field.uuid}]`}
                        hint={field.hint || ""}
                        choices={field.options.map((option) => {
                          return {
                            label: option.value,
                            value: String(option.id),
                          };
                        })}
                      />
                    )}
                  </React.Fragment>
                );
              })}
            </Column>

            <Column
              css={css`
                margin-top: 20px;
              `}
            >
              <TextField
                name="referral"
                type="text"
                label={t("auth.optional")}
                placeholder={t("auth.referral")}
                css={css`
                  border-radius: 10px;
                `}
              />
            </Column>
            <CheckboxField name="checkbox">
              {
                <p
                  css={css`
                    a:hover {
                      text-decoration: underline;
                    }
                  `}
                >
                  {t("auth.i_agree_to")}&nbsp;
                  <a
                    href={enterprise.agb_link!}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("auth.terms_of_service")}
                  </a>
                  &nbsp;{t("auth.acknowledge")}&nbsp;
                  <a
                    href={enterprise.data_protection_link!}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("auth.privacy_policy")}
                  </a>
                </p>
              }
            </CheckboxField>
            <div
              css={css`
                margin-top: 10px;
                display: flex;
                flex-direction: column;
              `}
            >
              <Button
                isSubmitting={isSubmitting}
                type="submit"
                color={theme.colors.primary}
                disabled={!dirty || !isValid}
                css={css`
                  margin: 0 auto;
                  color: ${theme.colors.white};
                `}
              >
                {t("auth.register")}
              </Button>

              <Headers.H4
                css={css`
                  display: flex;
                  justify-content: center;
                  margin-top: 50px;
                  margin-bottom: 10px;
                `}
              >
                {t("auth.or")}
              </Headers.H4>
              <Link
                css={css`
                  display: flex;
                  text-decoration: underline;
                  justify-content: center;
                `}
                to="/login"
              >
                {t("auth.login")}
              </Link>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
