import { authorised } from "../../request";
import { IUserDetails } from "../../types/UserProfile";

type UserData = {
  first_name: string;
  last_name: string;
  introduction?: string;
  city: string;
};

export async function updatePersonalInfo(userData: UserData) {
  const API = authorised();

  return await API.request<IUserDetails>({
    method: "PUT",
    url: `/api/campus/me/`,
    data: userData,
  });
}
