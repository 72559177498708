export const theme = {
  colors: {
    /* Primary Colors */
    primary: "#FF812F",
    primaryLighter: "#f5cdb3",
    success: "#A6D673",
    error: "#F85959",
    info: "#337FDE",
    warning: "#E1C52C",

    /* All Colors */
    white: "#FFFFFF",
    black: "#000000",
    dark: "#242424",
    orange: "#fb8c00",

    /* Gray Scale */
    gray1: "#F9F9F9",
    gray2: "#E2E2E2",
    gray3: "#CBCBCB",
    gray4: "#B3B3B3",
    gray5: "#9C9C9C",
    gray6: "#858585",
    gray7: "#F2F2F2",

    grayBlue: "#BECBD1",

    /* Blue */
    blueMain: "#194071",
    blueBackground: "#90B4E0",

    /* Light Blue */
    lightBlue: "#e9f0f9",
    lightBlueBackground: "#f3f7fb",
    lightBlueHover: "#92b6e1",

    /* Dark Green */
    darkGreenMain: "#25816F",
    darkGreenHover: "#0F4E42",
    darkGreenFocus: "#45B49E",
    darkGreenBackground: "#F1FFFC",

    /* Violet */
    violetMain: "#715785",
    violetHover: "#4D395C",
    violetFocus: "#9678AE",
    violetBackground: "#FCF9FF",

    /* Yellow */
    yellowMain: "#E5E12E",
    yellowHover: "#E5E12E",
    yellowFocus: "#FFFC70",
    yellowBackground: "#FFFEEA",
    yellowSelected: "#FCFCEA",

    /* Light red */
    lightRedMain: "#E99082",
    lightRedHover: "#B6695D",
    lightRedFocus: "#F5C1B9",
    lightRedBackground: "#FFF5F3",

    /* Green */
    greenMain: "#7CB341",
    greenHover: "#548025",
    greenFocus: "#A8E665",
    greenBackground: "#F8FFF0",

    haki: "#D8E8C6",

    /* Red */
    redMain: "#DB2136",
    redHover: "#970415",
    redFocus: "#F96575",
    redBackground: "#FFE9EB",

    /* Transparent */
    transparent: "transparent",
    transparentBlack1: "rgba(0, 0, 0, 0.08)",
    transparentBlack2: "rgba(0, 0, 0, 0.14)",
    transparentBlack3: "rgba(0, 0, 0, 0.20)",
    transparentBlack4: "rgba(0, 0, 0, 0.26)",
    transparentBlack5: "rgba(0, 0, 0, 0.32)",
  },
};
