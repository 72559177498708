import React, { useRef, useState } from "react";
import { css } from "styled-components/macro";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth } from "../contexts/UserContext";
import { theme } from "../themes/variables";
import { Avatar } from "./Avatar";
import { useClickOutside } from "../hooks/useClickOutside";

export function UserDropdown(props: {}) {
  const { userprofile, deauthenticate } = useAuth();
  const [opened, setOpened] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();

  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleClose = () => {
    if (opened) setOpened(false);
  };

  useClickOutside(dropdownRef, handleClose);

  const logout = () => {
    deauthenticate();
    history.push("/login");
  };

  return (
    <div
      ref={dropdownRef}
      css={css`
        position: relative;
      `}
    >
      <button
        css={css`
          border: none;
          background: none;
          display: flex;
          align-items: center;
          cursor: pointer;
          border-radius: 10px;
          padding: 5px 10px;
          transition: background-color 0.1s ease-in-out;
          :hover {
            background: ${theme.colors.lightBlueBackground};
          }
        `}
        onClick={() => {
          setOpened((opened) => !opened);
        }}
      >
        {userprofile && userprofile.first_name && (
          <div
            css={css`
              display: flex;
              flex-direction: column;
              text-align: right;
            `}
          >
            <div
              css={css`
                margin: 0;
                font-size: 16px;
                font-weight: 400;
              `}
            >
              {userprofile.first_name}
            </div>
            <div
              css={css`
                margin: 0;
                font-size: 16px;
                font-weight: 400;
              `}
            >
              {userprofile.last_name}
            </div>
          </div>
        )}

        <div
          css={css`
            margin-left: 12px;
            width: 40px;
          `}
          id="navigation-user-id"
        >
          <Avatar path={userprofile?.avatar?.image} />
        </div>
      </button>
      <div
        css={css`
          position: absolute;
          background: #fff;
          top: 100%;
          right: 0;
          width: 150px;
          margin-top: 20px;
          box-sizing: border-box;
          visibility: hidden;
          opacity: 0;
          transition: visibility 0.3s linear, opacity 0.3s linear;
          z-index: 9999;
          border-radius: 10px;
          font-size: 14px;

          ${opened &&
          css`
            visibility: visible;
            opacity: 1;
          `}

          ul {
            list-style: none;
            margin: 0;
            padding: 0;
            border-radius: 10px;
            box-shadow: 0 3px 8px 0 rgb(19 35 65 / 15%);
          }

          li {
            border-bottom: 1px solid ${theme.colors.gray4};

            :hover {
              background: ${theme.colors.lightBlueBackground};
              font-weight: 600;
            }
            :first-of-type {
              border-top-left-radius: 10px;
              border-top-right-radius: 10px;
            }
            :last-of-type {
              border-bottom-left-radius: 10px;
              border-bottom-right-radius: 10px;
            }
            > a {
              display: flex;
              align-items: center;
              padding: 10px;
            }
            > button {
              display: flex;
              align-items: center;
              width: 100%;
              padding: 10px;
            }
          }
        `}
      >
        <div
          css={css`
            width: 22px;
            height: 22px;
            position: absolute;
            top: -15px;
            right: 0%;
            transform: translateX(-50%);
            overflow: hidden;

            &::after {
              content: "";
              position: absolute;
              width: 20px;
              height: 20px;
              background: white;
              transform: translateX(-50%) translateY(-50%) rotate(45deg);
              top: 100%;
              left: 50%;
              box-shadow: 0px 0px 10px -4px rgba(0, 0, 0, 0.15);
              border-radius: 1px;
            }
          `}
        ></div>
        <ul
          css={css`
            position: relative;
            z-index: 2;
          `}
        >
          <li>
            <Link to="/account">{t("header.my_profile.account")}</Link>
          </li>
          <li>
            <Link to="/me/skills/">
              {t("header.my_profile.my-competencies")}
            </Link>
          </li>
          <li>
            <Link to="/me/orders">{t("orders.my_orders")}</Link>
          </li>
          <li>
            <Link to="/cheats">{t("cheats_sheet.cheats")}</Link>
          </li>
          <li>
            <button
              css={css`
                background: none;
                color: inherit;
                border: none;
                padding: 0;
                font: inherit;
                cursor: pointer;
                outline: inherit;
              `}
              onClick={() => logout()}
            >
              {t("header.my_profile.logout")}
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
}
