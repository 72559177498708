import { authorised } from "../../request";
import { IUserBadges } from "../../types/UserBadge";

export async function getBadgeList() {
  const API = authorised();

  return API.request<IUserBadges[]>({
    method: "GET",
    url: `/api/campus/badges/`
  });
}
