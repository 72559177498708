import React from "react";
import {
  SelectComponent,
  SelectOption,
} from "../../components/SelectComponent";
import { Field, FieldProps } from "formik";

export function SelectField(props: {
  name: string;
  id?: string;
  label?: string;
  hint?: string;
  choices: SelectOption[];
  small?: boolean;
  className?: string;
  disabled?: boolean;
  validate?: (value: string) => Promise<void | string>;
}) {
  const { validate, ...other } = props;
  return (
    <Field name={other.name} validate={validate}>
      {({ field }: FieldProps) => {
        return <SelectComponent field={field} {...other} />;
      }}
    </Field>
  );
}
