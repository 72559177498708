import React, { useRef, useState } from "react";
import { css } from "styled-components/macro";
import redraft from "redraft";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ChevronRight, ChevronLeft } from "react-feather";
import { renderers } from "../../helpers/textLessonFormatter";
import { theme } from "../../themes/variables";
import { Pagination } from "../../components/Pagination";
import { useResponsive } from "../../hooks/useResponsive";

const settings = {
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  adaptiveHeight: true,
};

const arrowSize = 40;
const arrowPadding = 10;

export function LessonCarousel(props: { content: string[]; html?: boolean }) {
  const { isMobile } = useResponsive();
  const [sliderPage, setSliderPage] = useState(0);

  const ref = useRef<Slider>(null);

  const onNext = () => {
    if (!ref.current) return;
    ref.current.slickNext();
  };

  const onPrev = () => {
    if (!ref.current) return;
    ref.current.slickPrev();
  };

  const onChose = (index: number) => {
    if (!ref.current) return;
    ref.current.slickGoTo(index);
  };

  const onBeforeChange = (oldIndex: number, newIndex: number) => {
    setSliderPage(newIndex);
  };

  return (
    <div
      css={css`
        position: relative;
      `}
    >
      {props.content.length > 1 && (
        <div>
          {!isMobile && (settings.infinite || sliderPage > 0) && (
            <div
              css={css`
                position: absolute;
                display: flex;
                flex-direction: column;
                justify-content: center;
                height: 100%;
                left: 0;
                cursor: pointer;
                border-top-left-radius: 16px;
                border-bottom-left-radius: 16px;
                padding: 0 ${arrowPadding}px;
                z-index: 10;
                :hover {
                  background: rgba(0, 0, 0, 0.12);
                }
              `}
              onClick={onPrev}
            >
              <ChevronLeft size={arrowSize} color={theme.colors.dark} />
            </div>
          )}

          {!isMobile &&
            (settings.infinite || sliderPage < props.content.length - 1) && (
              <div
                css={css`
                  position: absolute;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  height: 100%;
                  right: 0;
                  cursor: pointer;
                  border-top-right-radius: 16px;
                  border-bottom-right-radius: 16px;
                  padding: 0 ${arrowPadding}px;
                  z-index: 10;
                  :hover {
                    background: rgba(0, 0, 0, 0.12);
                  }
                `}
                onClick={onNext}
              >
                <ChevronRight size={arrowSize} color={theme.colors.dark} />
              </div>
            )}

          <div
            css={css`
              position: absolute;
              bottom: 0;
              height: 50px;
              width: 100%;
              display: flex;
              justify-content: center;
            `}
          >
            <Pagination
              currentItemIndex={sliderPage}
              itemsCount={props.content.length}
              onNext={onNext}
              onPrev={onPrev}
              onChose={onChose}
              infinite={settings.infinite}
              css={css`
                li {
                  font-size: 16px;
                }
              `}
            />
          </div>
        </div>
      )}
      <div
        css={css`
          padding: ${isMobile
            ? "20px 20px 60px 20px"
            : `0 ${arrowSize + 8 + arrowPadding * 2}px 50px;`};
        `}
      >
        <Slider {...settings} ref={ref} beforeChange={onBeforeChange}>
          {props.content.map((el, i) => {
            return (
              <div key={i} className="editor-container">
                {props.html ? (
                  <div
                    css={css`
                      p {
                        margin: 0;
                      }
                    `}
                    dangerouslySetInnerHTML={{
                      __html: el || "",
                    }}
                  ></div>
                ) : (
                  redraft(el, renderers)
                )}
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
}
