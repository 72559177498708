import React from "react";
import { css } from "styled-components/macro";
import { theme } from "../themes/variables";
import { Loader } from "./Loader";

export function Button(props: {
  type?: "button" | "submit";
  children?: React.ReactNode;
  title?: string;
  name?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  className?: string;
  hoverStyles?: string;
  activeStyles?: string;
  disableStyles?: string;
  color?: string;
  background?: string;
  border?: string;
  size?: "small" | "large";
  flat?: boolean;
  icon?: React.ReactNode;
  iconRight?: boolean;
  isSubmitting?: boolean;
  disabled?: boolean;
  readonly?: boolean;
}) {
  const hoverColorMatch = props.hoverStyles
    ? props.hoverStyles.match(/color: (.*?);/)
    : "";

  const hoverColor = hoverColorMatch ? hoverColorMatch[1] : "";

  return (
    <button
      type={props.type || "button"}
      name={props.name}
      title={props.title}
      css={css`
        padding: ${props.flat
          ? "0"
          : props.size === "small"
          ? "6px 20px"
          : props.size === "large"
          ? "15px 25px"
          : "10px 20px"};
        background: ${props.background || theme.colors.dark};
        color: ${props.color || theme.colors.white};
        border-radius: ${props.flat ? "0px" : "30px"};
        border: ${props.border ? props.border : "none"};
        font-weight: bold;
        cursor: pointer;

        :hover {
          ${props.hoverStyles
            ? props.hoverStyles
            : css`
                background: ${theme.colors.primary};
              `}
        }
        :hover svg {
          ${!!(props.icon && hoverColor) ? `color: ${hoverColor}` : ""}
        }
        :active {
          ${props.activeStyles}
        }
        :disabled {
          ${props.disableStyles
            ? props.disableStyles
            : css`
                cursor: default;
                background: ${theme.colors.gray2};
              `}
        }
      `}
      disabled={props.disabled || props.isSubmitting}
      className={props.className}
      onClick={props.onClick}
    >
      {props.isSubmitting ? (
        <Loader size={15} />
      ) : (
        <div
          css={css`
            display: flex;
            justify-content: center;
            align-items: center;
          `}
        >
          {props.icon && !props.iconRight && (
            <span
              css={css`
                display: flex;
                ${props.children &&
                css`
                  margin-right: 10px;
                `}
              `}
            >
              {props.icon}
            </span>
          )}

          <span
            css={css`
              display: flex;
              vertical-align: middle;
            `}
          >
            {props.children}
          </span>

          {props.icon && props.iconRight && (
            <span
              css={css`
                display: flex;
                ${props.children &&
                css`
                  margin-left: 10px;
                `}
              `}
            >
              {props.icon}
            </span>
          )}
        </div>
      )}
    </button>
  );
}
