import React from "react";
import { css } from "styled-components/macro";
import { Spacing } from "../../helpers/layout";

export function ModalFooter(props: {
  children?: React.ReactNode | React.ReactNode[] | string;
}) {
  return (
    <div
      css={css`
        margin-top: ${Spacing.m};
        display: flex;
        align-items: center;
        justify-content: flex-end;
        > button {
          margin: 0 ${Spacing.s};
          :last-of-type {
            margin: 0;
            margin-left: ${Spacing.s};
          }
        }
      `}
    >
      {props.children}
    </div>
  );
}
