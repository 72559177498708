import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { SignupSelfAssessment } from "../components/SignupSelfAssessment";
import { theme } from "../themes/variables";
import { Block } from "../components/Block";
import { Header } from "../components/Header";
import { Wrapper } from "../components/Wrapper";
import { SEO } from "../components/SEO";
import { CourseSelfAssessment } from "../components/CourseSelfAssessment";
import { useResponsive } from "../hooks/useResponsive";

export default function SelfAssessmentPage(props: {
  location: { search: string };
}) {
  const { t } = useTranslation();

  const params = useParams<{
    id: string;
  }>();

  const query = new URLSearchParams(props.location.search);
  const redirect_param = query.get("redirect_to");
  const { isMobile } = useResponsive();

  return (
    <Wrapper>
      <SEO>
        <title>{t("self_assessment.label")}</title>
      </SEO>
      <Header />
      <Block
        css={css`
          background-color: ${theme.colors.white};
          width: 100%;
          padding: 60px;
          box-sizing: border-box;

          ${isMobile &&
          css`
            padding: 40px 20px;
          `}
        `}
      >
        {params.id ? (
          <CourseSelfAssessment
            redirect_to={redirect_param}
            courseId={params.id}
          />
        ) : (
          <SignupSelfAssessment redirect_to={redirect_param} />
        )}
      </Block>
    </Wrapper>
  );
}
