export const downloadBlob = (url: string, filename?: string) => {
  var a = document.createElement("a");
  if (filename) {
    a.download = filename;
  }
  a.href = url;
  document.body.appendChild(a);
  a.click();
  a.remove();
};

export const downloadResource = (url: string, filename?: string) => {
  const name = filename || url.split("/").pop();

  if (filename) {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        let blobUrl = window.URL.createObjectURL(blob);
        downloadBlob(blobUrl, name);
      })
      .catch((e) => console.error(e));
  }
};