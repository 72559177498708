import React, {
  ChangeEvent,
  FormEvent,
  KeyboardEventHandler,
  useState,
} from "react";
import { css } from "styled-components/macro";
import { FieldInputProps } from "formik";
import { theme } from "../themes/variables";
import { Hint } from "./Hint";

export function InputComponent(props: {
  id?: string;
  name?: string;
  type?:
    | "text"
    | "email"
    | "search"
    | "password"
    | "number"
    | "tel"
    | "url"
    | "select"
    | "time";
  label?: string;
  hint?: string;
  value?: string;
  placeholder?: string;
  disabled?: boolean;
  readonly?: boolean;
  className?: string;
  field?: FieldInputProps<any>;
  icon?: React.ReactNode;
  error?: boolean;
  predefinedText?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onInput?: (event: FormEvent<HTMLInputElement>) => void;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  onMouseEnter?: (e: React.MouseEvent<HTMLLabelElement, MouseEvent>) => void;
  onMouseLeave?: (e: React.MouseEvent<HTMLLabelElement, MouseEvent>) => void;
}) {
  const [focused, setFocused] = useState(false);
  return (
    <label onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave}>
      {props.label && (
        <div
          css={css`
            margin-bottom: 16px;
            font-size: 16px;
            font-weight: bold;
          `}
        >
          {props.label}
        </div>
      )}
      <div
        css={css`
          position: relative;
          display: flex;
          align-items: center;
          width: 100%;
          ${props.predefinedText && "padding-left: 24px;"}
          background-color: ${theme.colors.white};
          border: 1px solid
            ${props.disabled
              ? theme.colors.gray2
              : props.error
              ? theme.colors.redMain
              : focused
              ? theme.colors.dark
              : theme.colors.gray4};

          border-radius: 20px;
          overflow: hidden;
        `}
      >
        {props.predefinedText && (
          <span
            css={css`
              font-size: 16px;
              line-height: 22px;
              color: ${theme.colors.gray4};
              white-space: nowrap;
            `}
          >
            {props.predefinedText}
          </span>
        )}
        <input
          id={props.id}
          type={props.type || "text"}
          value={props.value}
          placeholder={props.placeholder || ""}
          readOnly={props.readonly || false}
          disabled={props.disabled || false}
          aria-label={props.name || ""}
          css={css`
            flex-grow: 1;
            padding: ${props.predefinedText
              ? "6px 20px 6px 0"
              : "6px 20px 6px 20px"};
            border: none;
            display: block;
            width: 100%;
            height: 1rem;
            ${props.icon && `padding-right: 42px;`}
            background-color: ${theme.colors.white};
            line-height: 1.5;
            color: ${theme.colors.dark};
            outline: none;
          `}
          {...props.field}
          className={props.className}
          onChange={(e) => {
            props.field && props.field.onChange(e);

            if (props.onChange) {
              props.onChange(e);
            }
          }}
          onFocus={() => setFocused(true)}
          onBlur={(e) => {
            if (props.field) {
              props.field.onBlur(e);
            }
            setFocused(false);
          }}
          onKeyDown={props.onKeyDown}
          onInput={props.onInput}
        />
        {props.icon && (
          <div
            css={css`
              position: absolute;
              top: 50%;
              right: 0;
              transform: translate(0%, -50%);
              height: 100%;
              padding: 0 15px;
              display: flex;
              justify-content: center;
              align-items: center;
            `}
          >
            {props.icon}
          </div>
        )}
      </div>

      {props.hint && <Hint>{props.hint}</Hint>}
    </label>
  );
}
