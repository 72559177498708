import { css } from "styled-components/macro";
import { theme } from "../../themes/variables";
import { Field, FieldProps } from "formik";
import { Hint } from "../../components/Hint";

interface ITextareaField {
  name: string;
  id?: string;
  type?:
    | "text"
    | "email"
    | "search"
    | "password"
    | "number"
    | "tel"
    | "url"
    | "select";
  label?: string;
  hint?: string;
  placeholder?: string;
  disabled?: boolean;
  readonly?: boolean;
  maxLength?: number;
  className?: string;
  validate?: (value: string) => Promise<void | string>;
}

export function TextareaField({
  id,
  name,
  label,
  hint,
  disabled,
  readonly,
  placeholder,
  type,
  maxLength,
  className,
  validate,
}: ITextareaField) {
  return (
    <Field name={name} validate={validate}>
      {({ field, meta }: FieldProps) => {
        return (
          <div className={className}>
            <label>
              <span
                css={css`
                  display: block;
                  font-size: 16px;
                  margin-bottom: 8px;
                  color: ${theme.colors.black};
                  letter-spacing: 0.01em;
                  font-weight: 500;
                `}
              >
                {label}
              </span>
              <textarea
                placeholder={placeholder || ""}
                readOnly={readonly}
                disabled={disabled}
                css={css`
                  display: block;
                  width: calc(100% - 35px);
                  min-height: 150px;
                  padding: 10px 16px;
                  line-height: 1.5;
                  color: ${theme.colors.dark};
                  background-color: ${theme.colors.white};
                  background-clip: padding-box;
                  border: 1px solid ${theme.colors.gray3};
                  border-radius: 20px;
                  outline: none;
                  resize: none;
                  font-weight: 400;
                  font-style: normal;
                  font-size: 14px;
                `}
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                }}
              />

              {hint && <Hint>{hint}</Hint>}
            </label>
            <div
              css={css`
                display: flex;
                align-items: center;
              `}
            >
              {meta.error && meta.touched && (
                <div
                  css={css`
                    color: ${theme.colors.redMain};
                    font-size: 1rem;
                  `}
                >
                  {meta.error}
                </div>
              )}
            </div>
          </div>
        );
      }}
    </Field>
  );
}
