import React from "react";
import { css } from "styled-components/macro";
import { useResponsive } from "../hooks/useResponsive";
import { theme } from "../themes/variables";

export function Card(props: {
  className?: string;
  square?: boolean;
  bordered?: boolean;
  shadowed?: boolean;
  children: React.ReactNode | React.ReactNode[] | string | null;
  onClick?: () => void;
}) {
  const { isMobile } = useResponsive();

  return (
    <div
      onClick={props.onClick}
      css={css`
        padding: ${isMobile ? "32px 24px" : "60px"};
        background-color: ${theme.colors.white};
        border-radius: ${props.square ? 0 : 20}px;
        ${props.bordered &&
        `border: 1px solid ${theme.colors.transparentBlack1};`}
        ${props.shadowed &&
        `box-shadow: 0 1px 4px ${theme.colors.transparentBlack1} 0 2px 2px ${theme.colors.transparentBlack1} 0 3px 1px -2px ${theme.colors.transparentBlack2};`}
      `}
      className={props.className}
    >
      {props.children}
    </div>
  );
}
