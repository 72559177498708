import React, { useState } from "react";
import ReactPlayer from "react-player";
import { Download } from "react-feather";
import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { postQuizAnswer } from "../../actions/quiz/postQuizAnswer";
import { theme } from "../../themes/variables";
import { LessonTest } from "../../types/LessonTest";
import { TestForm } from "../Test/Form";
import { downloadBlob } from "../../helpers/download";

type TVideoMetaData = {
  loaded: number;
  loadedSeconds: number;
  played: number;
  playedSeconds: number;
};

export interface QuizValues {
  test_id: string;
  test_variant_id: string;
  answer?: string;
}

export function LessonVideo(props: {
  url: string;
  enrolmentID?: string;
  lessonUuid: string;
  tests?: LessonTest[];
  downloadUrl?: string;
  onFinish?: () => void;
  isDemo?: boolean;
}) {
  const { t } = useTranslation();

  const [play, setPlay] = useState(false);
  const [quiz, setQuiz] = useState<LessonTest | null>(null);
  const [passedTestIds, setPassedTestIds] = useState<string[]>([]);
  const tests = props.tests ? props.tests.filter((el) => el.in_video) : [];

  const onProgress = (metaData: TVideoMetaData) => {
    const videoCurrentSecond = Math.floor(metaData.playedSeconds);
    const test = tests.find((test) => {
      const testTime = test.timecode
        .split(":")
        .reverse()
        .reduce((prev, curr, i) => prev + +curr * Math.pow(60, i), 0);

      if (videoCurrentSecond === testTime) {
        return test;
      }
      return null;
    });

    if (test && !passedTestIds.includes(test.uuid)) {
      setPlay(false);
      setPassedTestIds((prev) => [...prev, test.uuid]);
      setQuiz(test);
    }
  };

  const onContinue = () => {
    setQuiz(null);
    setPlay(true);
  };

  return (
    <div
      css={css`
        position: relative;
        width: 100%;
        max-width: 854px;
        margin: 0 auto;
      `}
    >
      {props.downloadUrl && (
        <div>
          <button
            css={css`
              display: block;
              padding: 5px 10px;
              border: 1px solid ${theme.colors.gray4};
              border-radius: 6px;
              height: 30px;
              line-height: 1;
              background-color: ${theme.colors.white};
              cursor: pointer;
              margin-bottom: 10px;
              :hover {
                background-color: ${theme.colors.gray4};
              }
            `}
            type="button"
            title={t("actions.download")}
            onClick={() => props.downloadUrl && downloadBlob(props.downloadUrl)}
          >
            <Download size={15} />
          </button>
        </div>
      )}

      <ReactPlayer
        playing={play}
        onPlay={() => {
          setPlay(true);
        }}
        onEnded={props.onFinish}
        progressInterval={1000}
        onProgress={onProgress}
        controls={true}
        url={props.url}
        height={500}
        width="100%"
      />

      {quiz && quiz.variant && !props.isDemo && (
        <div
          css={css`
            position: absolute;
            width: 100%;
            height: 100%;
            padding: 0 50px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: ${theme.colors.gray2};
            top: 0;
            left: 0;
            opacity: 0.97;
            animation-duration: 0.75s;
            animation-name: createBox;

            @keyframes createBox {
              from {
                opacity: 0;
              }
              to {
                opacity: 0.97;
              }
            }
          `}
        >
          <div
            css={css`
              width: 100%;
            `}
          >
            <TestForm
              tests={[quiz]}
              styleVariant={"card"}
              onSubmit={async (state) => {
                const data = state[0];
                const response = await postQuizAnswer(
                  quiz.uuid,
                  quiz.variant!.id,
                  data.answers
                );

                return {
                  answers: [response.data],
                  score: response.data.is_correct ? 100 : 0,
                  passed: response.data.is_correct,
                  next_course: null,
                };
              }}
              onContinue={onContinue}
              onRefetch={() => {
                setQuiz((quiz) => {
                  // It allows to rerun the test as TestForm sees it as new test object

                  if (quiz) {
                    return {
                      ...quiz,
                    };
                  }

                  return quiz;
                });
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}
