import React, { useEffect } from "react";
import hotkeys from "hotkeys-js";
import { useEnterprise } from "../contexts/EnterpriseContext";
import { SEO } from "./SEO";

export function DRM(props: {}) {
  const { enterprise } = useEnterprise();

  useEffect(() => {
    if (enterprise.is_text_copying_disabled) {
      const body = document.querySelector("body");
      if (body) {
        body.oncopy = () => false;
        body.oncut = () => false;
      }
    }

    if (enterprise.is_image_saving_disabled) {
      const allImages = document.querySelectorAll("img");
      allImages.forEach((value) => {
        value.oncontextmenu = (e) => {
          e.preventDefault();
        };
      });
    }

    if (enterprise.is_page_download_disabled) {
      document.oncontextmenu = (e) => {
        e.preventDefault();
      };
      hotkeys("ctrl+s,command+s", function (e) {
        e.preventDefault();
      });
    }

    if (enterprise.is_printing_disabled) {
      hotkeys("ctrl+p,command+p", function (e) {
        e.preventDefault();
      });
    }
  });

  return (
    <SEO>
      <body
        className={`${
          enterprise.is_text_copying_disabled && "prevent-copypaste"
        } ${enterprise.is_image_saving_disabled && "prevent-image-download"}
              ${enterprise.is_printing_disabled && "prevent-printing"}
            `}
      ></body>
    </SEO>
  );
}
