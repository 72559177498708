import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { Headers } from "../helpers/layout";
import { useFetch } from "../actions/useAPI";
import { getDashboard } from "../actions/course/getDashboard";
import { Header } from "../components/Header";
import { Wrapper } from "../components/Wrapper";
import { Block } from "../components/Block";
import { RecommendationSlider } from "../components/RecommendationSlider";
import { PopularCourses } from "../components/PopularCourses";
import { SEO } from "../components/SEO";
import Announcements from "../components/Announcements";
import { PageQueryHandler } from "./PageQueryHandler";
import { useResponsive } from "../hooks/useResponsive";
import { useHistory } from "react-router-dom";
import FeaturedSlider from "../components/FeaturedSlider";
import { ISliderItem } from "../types/SliderItem";
import { Announcement } from "../types/Announcements";
import { HomepageCompetence } from "../types/HomePage";
import CourseCard from "../components/CourseCard";
import { CompetenceCard } from "../components/CompetenceCard";

const emptySlide: ISliderItem = {
  title: "Hier kann der Titel deines Kurses stehen",
  description: "Hier kannst du den Kurs genauer beschreiben",
  link_label: "Mit diesem Button geht es direkt zum Kurs",
  link_url: "#",
  author_avatar: {
    uuid: "",
    image: "",
    upload_name: "",
    mime_type: "",
    width: 100,
    height: 100,
  },
  testimonial_author_subtitle: "Zusatz zum Namen",
  testimonial_author_title: "Expert:in",
};

const emptySlides: ISliderItem[] = [emptySlide, emptySlide, emptySlide];

const emptyAnnouncement: Announcement = {
  title: "Hier kannst du deine User auf Neuigkeiten hinweisen",
  short_description: "Hier kannst du ausführen, um was es geht",
  url: "",
  publish_date: "",
};

const emptyAnnouncements: Announcement[] = [
  emptyAnnouncement,
  emptyAnnouncement,
  emptyAnnouncement,
];

const emptyCompetencies: HomepageCompetence[] = [
  {
    points: 75,
    name: "Hier definierst du eine Kompetenz",
    description: "Hier kannst du die Kompetenz genauer beschreiben",
    course_id: "",
  },
  {
    points: 55,
    name: "Hier definierst du eine Kompetenz",
    description: "Hier kannst du die Kompetenz genauer beschreiben",
    course_id: "",
  },
  {
    points: 29,
    name: "Hier definierst du eine Kompetenz",
    description: "Hier kannst du die Kompetenz genauer beschreiben",
    course_id: "",
  },
];

export function DashboardPage(props: {}) {
  const { t } = useTranslation();
  const history = useHistory();
  const { isMobile } = useResponsive();

  const {
    data: dashboard,
    error: dashboardError,
    isLoading: dashboardIsLoading,
  } = useFetch(getDashboard);
  if (!dashboard || dashboardError || dashboardIsLoading) {
    return (
      <PageQueryHandler error={dashboardError} isLoading={dashboardIsLoading} />
    );
  }

  const slides =
    dashboard.top_slider && dashboard.top_slider.length > 0
      ? dashboard.top_slider
      : emptySlides;

  const announcements =
    dashboard.announcements && dashboard.announcements.length > 0
      ? dashboard.announcements
      : emptyAnnouncements;

  const competencies =
    dashboard && dashboard.user_competence.length > 0
      ? dashboard.user_competence
      : emptyCompetencies;

  return (
    <Wrapper>
      <SEO>
        <title>{t("header.navigation.dashboard")}</title>
      </SEO>
      <Header />

      <Block>
        {dashboard && (
          <>
            <div
              css={css`
                display: flex;
                flex-direction: ${isMobile ? "column" : "row"};
                margin-top: ${isMobile ? "0" : "60px"};
              `}
            >
              <RecommendationSlider data={slides} />
              <Announcements announcements={announcements} />
            </div>

            <FeaturedSlider
              items={competencies}
              title={
                <Headers.H2
                  css={css`
                    margin-bottom: 0;
                    cursor: pointer;
                  `}
                >
                  {t("payment.competencies")}
                </Headers.H2>
              }
              css={css`
                margin-top: ${isMobile ? "40px" : "60px"};
              `}
            >
              {(item, index) => (
                <CompetenceCard
                  key={index}
                  competence={item}
                  css={css`
                    width: ${isMobile ? "65vw" : "400px"};
                    height: 400px;
                    margin-right: ${isMobile ? "20px" : "40px"};
                    box-sizing: border-box;
                  `}
                />
              )}
            </FeaturedSlider>
          </>
        )}
        <div>
          {dashboard &&
            dashboard.featured_journeys.map(
              (journey, key) =>
                journey.courses.length > 0 && (
                  <FeaturedSlider
                    key={key}
                    items={journey.courses}
                    css={css`
                      margin-top: ${isMobile ? "40px" : "60px"};
                    `}
                    title={
                      <Headers.H2
                        css={css`
                          margin-bottom: 0;
                          cursor: pointer;
                        `}
                        onClick={() => history.push(`journey/${journey.uuid}`)}
                      >
                        {journey.name}
                      </Headers.H2>
                    }
                  >
                    {(item, index) => (
                      <CourseCard
                        key={index}
                        item={item}
                        css={css`
                          width: ${isMobile ? "65vw" : "450px"};
                          height: 377px;
                          margin-right: ${isMobile ? "20px" : "40px"};
                        `}
                      />
                    )}
                  </FeaturedSlider>
                )
            )}
        </div>
        {dashboard && dashboard.popular_courses.length > 0 && (
          <div
            css={css`
              margin-top: ${isMobile ? "40px" : "60px"};
            `}
          >
            <Headers.H2
              css={css`
                margin-bottom: ${isMobile ? "20px" : "30px"};
              `}
            >
              {t("dashboard.popular")}
            </Headers.H2>

            <PopularCourses data={dashboard.popular_courses} />
          </div>
        )}
      </Block>
    </Wrapper>
  );
}
