import React from "react";
import { css } from "styled-components/macro";
import { theme } from "../themes/variables";
import { Spacing } from "../helpers/layout";

export function Badge(props: {
  className?: string;
  color?: string;
  background?: string;
  bold?: boolean;
  transparent?: boolean;
  children?: React.ReactNode;
}) {
  return (
    <div
      className={props.className}
      css={css`
        display: inline-flex;
        align-items: center;
        flex-wrap: nowrap;
        white-space: nowrap;
        padding: ${Spacing.xs} ${Spacing.m};
        border-radius: 10px;
        font-size: 12px;
        line-height: 1.15;
        min-height: 12px;
        font-weight: ${props.bold ? "bold" : "normal"};
        color: ${props.color || theme.colors.white};
        background: ${props.background || theme.colors.primary};
        ${props.transparent && "opacity: 0.8;"}
      `}
    >
      {props.children}
    </div>
  );
}
