import styled from "styled-components/macro";
import { theme } from "../../themes/variables";

export const TooltipText = styled.span`
  visibility: hidden;
  width: 220px;
  background-color: ${theme.colors.primary};
  color: ${theme.colors.white};
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  padding: 0 10px;
  right: -15px;

  :after {
    content: "";
    position: absolute;
    top: 100%;
    right: 20px;
    border-width: 5px;
    border-style: solid;
    border-color: ${theme.colors.primary} transparent transparent transparent;
  }
`;
