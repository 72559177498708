import styled, { css } from "styled-components/macro";
import { ChevronRight } from "react-feather";
import { useState } from "react";
import { HeaderText } from "./HeaderText";
import { useTranslation } from "react-i18next";
import { theme } from "../themes/variables";

const SCollapsedContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  overflow: hidden;
  position: relative;
  width: 1260px;
  margin-top: 10px;
  :after {
    content: "";
    border: none;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;

    outline: none;
  }
`;

const ExpansionedContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
`;

const ExpansionContainerWrapper = (props: {
  expanded: boolean;
  children: JSX.Element | JSX.Element[] | string;
}) => {
  if (props.expanded) {
    return <ExpansionedContainer>{props.children}</ExpansionedContainer>;
  }

  return <SCollapsedContainer>{props.children}</SCollapsedContainer>;
};

export function ExpansionContainer<T>({
  link,
  hasLastItemLink,
  hasIntro,
  hasArrow,
  arrowTop,
  values,
  journeyName,
  onExpand,
  children,
  isExpandedByDefault,
}: {
  link?: string;
  hasLastItemLink?: boolean;
  hasIntro?: boolean;
  hasArrow?: boolean;
  values: T[];
  journeyName?: string;
  arrowTop?: number;
  onExpand?: (expanded: boolean) => void;
  children: (props: {
    idx: number;
    value: T;
  }) => JSX.Element | JSX.Element[] | string;
  isExpandedByDefault?: boolean;
}) {
  const [expanded, setExpanded] = useState(
    typeof isExpandedByDefault === "undefined" ? !hasArrow : isExpandedByDefault
  );

  const { t } = useTranslation();

  const expand = () => {
    setExpanded(!expanded);
    if (onExpand) {
      onExpand(expanded);
    }
  };

  return (
    <div
      css={css`
        position: relative;
        margin-top: 60px;
      `}
    >
      <HeaderText
        isClickable={link ? true : false}
        link={link}
        text={hasIntro ? t("dashboard.journeys.intro") : journeyName}
      />
      <ExpansionContainerWrapper expanded={expanded}>
        {values.map((value, idx) => {
          return (
            <div key={idx}>
              {children({
                idx,
                value,
              })}
            </div>
          );
        })}
      </ExpansionContainerWrapper>
      {hasArrow && values && values.length > 4 && (
        <div
          css={css`
            position: absolute;
            top: ${arrowTop + "px"};
            right: -60px;
            transform: translate(0, -100%);
            cursor: pointer;
            :hover {
              opacity: 0.6;
            }
            :active {
              opacity: 1;
            }
          `}
          onClick={expand}
        >
          <ChevronRight
            css={css`
              transition: transform 0.2s linear;
              ${expanded && "transform: rotate(90deg);"}
            `}
            size={64}
          />
        </div>
      )}
      {hasLastItemLink && values && values.length > 3 && (
        <div
          css={css`
            position: absolute;
            width: 296px;
            height: 220px;
            background-color: ${theme.colors.lightBlueBackground};
            opacity: 0.9;
            border-radius: 20px;
            top: 306px;
            right: 27px;
            transform: translate(0, -100%);
            cursor: pointer;
            :hover {
              opacity: 1;
            }
            :active {
              opacity: 1;
            }
          `}
          onClick={expand}
        >
          <div
            css={css`
              font-size: 52px;
              font-weight: 600;
              opacity: 1;
              z-index: 9999;
              transform: translate(35%, 75%);
            `}
          >
            10+
          </div>
        </div>
      )}
    </div>
  );
}
