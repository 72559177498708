import { Image } from "./Image";
import { IFile } from "./File";

type BlockCommonProps = {
  background_image?: Image | null;
  background_size?: BackgroundSize;
  background_position?: BackgroundPosition;
  background_color?: string;
};

export enum BlockType {
  COLUMNS = "COLUMNS",
  CUSTOM_CODE = "CUSTOM_CODE",
  EMBED_VIDEO = "EMBED_VIDEO",
  PODCAST = "PODCAST",
  CAROUSEL = "CAROUSEL",
  COLLAPSE_BLOCK = "COLLAPSE_BLOCK",
  PPT_BLOCK = "PPT_BLOCK",
}

export interface CollapseBlock {
  title: string;
  content: string;
}

export interface Column {
  content?: string;
  image?: Image | null;
}

export enum BackgroundSize {
  AUTO = "auto",
  CONTAIN = "contain",
  COVER = "cover",
}

export enum BackgroundPosition {
  CENTER = "center",
  TOP = "top",
  TOP_LEFT = "top left",
  TOP_RIGHT = "top right",
  BOTTOM = "bottom",
  BOTTOM_LEFT = "bottom left",
  BOTTOM_RIGHT = "bottom right",
  RIGHT = "right",
  LEFT = "left",
}

export type CustomColumnsBlock = {
  uuid: string;
  type: BlockType.COLUMNS;
  data: {
    columns?: Column[];
    layout?: string;
  };
} & BlockCommonProps;

export type CustomCodeBlock = {
  uuid: string;
  type: BlockType.CUSTOM_CODE;
  data: {
    code?: string;
  };
} & BlockCommonProps;

export type CustomEmbedBlock = {
  uuid: string;
  type: BlockType.EMBED_VIDEO;
  data: {
    url?: string;
    duration?: string;
    tests?: {
      id: string;
      label: string;
    }[];
  };
} & BlockCommonProps;

export type CustomPodcastBlock = {
  uuid: string;
  type: BlockType.PODCAST;
  data: {
    audio?: IFile | null;
    duration?: string;
  };
} & BlockCommonProps;

export type CustomCarouselBlock = {
  uuid: string;
  type: BlockType.CAROUSEL;
  data: {
    blocks?: string[];
  };
} & BlockCommonProps;

export type CustomCollapseBlock = {
  uuid: string;
  type: BlockType.COLLAPSE_BLOCK;
  data: {
    title: string;
    blocks?: CollapseBlock[];
  };
} & BlockCommonProps;

export type CustomPPTBlock = {
  uuid: string;
  type: BlockType.PPT_BLOCK;
  data: {
    file?: IFile | null;
  };
} & BlockCommonProps;

export type Block =
  | CustomColumnsBlock
  | CustomCodeBlock
  | CustomEmbedBlock
  | CustomPodcastBlock
  | CustomCarouselBlock
  | CustomPPTBlock
  | CustomCollapseBlock;
