import React, { ChangeEvent } from "react";
import { css } from "styled-components/macro";
import { theme } from "../themes/variables";
import { Spacing } from "../helpers/layout";
import { FieldInputProps } from "formik";

export function CheckboxComponent(props: {
  id?: string;
  name?: string;
  label?: string;
  labelRight?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  value?: boolean;
  className?: string;
  field?: FieldInputProps<any>;
  checkedColor?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  children?: React.ReactNode;
}) {
  return (
    <label
      css={css`
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        position: relative;
        user-select: none;
        height: ${Spacing.l};
        ${props.labelRight ? "flex-direction: row-reverse;" : ""}
        input:not(:checked) ~ .checkmark {
          border: 1px solid ${theme.colors.gray4};
        }
        input:checked ~ .checkmark:after {
          display: block;
        }
        :hover input ~ .checkmark {
          background-color: ${theme.colors.gray2};
        }
        input:checked ~ .checkmark {
          background-color: ${props.checkedColor || theme.colors.primary};
          border: 1px solid transparent;
        }
        .checkmark:after {
          width: 100%;
          height: 100%;
        }
      `}
      className={props.className || ""}
    >
      <span
        css={css`
          padding-left: 35px;
        `}
      >
        {props.label}
      </span>

      <input
        id={props.id}
        name={props.name || ""}
        type="checkbox"
        readOnly={props.readonly || false}
        disabled={props.disabled || false}
        checked={props.value || false}
        css={css`
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        `}
        {...props.field}
        onChange={(e) => {
          if (props.field) {
            props.field.onChange(e);
          }

          if (props.onChange) {
            props.onChange(e);
          }
        }}
      />
      <span
        className="checkmark"
        css={css`
          position: absolute;
          top: 0;
          left: 0;
          height: ${Spacing.l};
          width: ${Spacing.l};
          border-radius: ${Spacing.s};
          :after {
            content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmZmZmYiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBjbGFzcz0iZmVhdGhlciBmZWF0aGVyLWNoZWNrIj48cG9seWxpbmUgcG9pbnRzPSIyMCA2IDkgMTcgNCAxMiI+PC9wb2x5bGluZT48L3N2Zz4=");
            position: absolute;
            display: none;
          }
        `}
      ></span>

      {props.children}
    </label>
  );
}
