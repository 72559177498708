import React, { useState } from "react";
import { useHistory } from "react-router";
import styled, { css } from "styled-components/macro";
import { useAuth } from "../contexts/UserContext";
import { theme } from "../themes/variables";
import { SearchComponent } from "./Search";
import { Link } from "react-router-dom";
import { useEnterprise } from "../contexts/EnterpriseContext";
import { ReactComponent as BurgerIcon } from "../assets/svg/Burger.svg";
import { useTranslation } from "react-i18next";
import SpacedRepetitionModal from "./SpacedRepetitionModal";
import { ReactComponent as PowerOffSvg } from "../assets/svg/PowerOff.svg";
import { X } from "react-feather";
import { User } from "react-feather";
import { useFetch } from "../actions/useAPI";
import { getScheduledTests } from "../actions/testScheduler";

export function HeaderMobile(props: {}) {
  const history = useHistory();
  const { isAuthenticated } = useAuth();
  const { enterprise } = useEnterprise();

  const [active, setActive] = useState(false);

  const toggleBurger = () => {
    if (active) {
      document.body.classList.remove("lock-scroll");
    } else {
      document.body.classList.add("lock-scroll");
    }
    setActive(!active);
  };

  if (!isAuthenticated) {
    return (
      <header
        css={css`
          padding: 30px 0;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 10;
        `}
      >
        <Link to="/">
          <img
            css={css`
              width: auto;
              height: auto;
              max-width: 134px;
              max-height: 40px;
            `}
            src={enterprise.logo?.image}
            alt={`${enterprise?.name} Logo`}
          />
        </Link>
      </header>
    );
  }

  return (
    <header
      css={css`
        position: relative;
        margin-bottom: 14px;
        padding: 17px 20px;
        border-radius: ${active ? "20px 20px 0 0" : "20px"};
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
        background-color: ${theme.colors.white};
        z-index: 100;
      `}
    >
      <img
        css={css`
          cursor: pointer;
          width: auto;
          height: auto;
          max-width: 114px;
          max-height: 40px;
        `}
        src={enterprise.logo?.image}
        alt={`${enterprise?.name} Logo`}
        onClick={() => history.push("/")}
      />
      <div
        css={css`
          margin: 0 0 0 auto;
        `}
      >
        <div onClick={() => toggleBurger()}>
          {!active ? (
            <BurgerIcon />
          ) : (
            <X
              css={css`
                vertical-align: middle;
              `}
              size={30}
            />
          )}
        </div>
        {active && <MobileMenu />}
      </div>
    </header>
  );
}

function MobileMenu() {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        width: 100%;
        position: absolute;
        right: 0;
        top: 100%;
        background-color: ${theme.colors.white};
        height: calc(100vh - 100px);
        border-radius: 0 0 20px 20px;
      `}
    >
      <div
        css={css`
          display: flex;
          width: 100%;
        `}
      >
        <SearchComponent
          hasDropDown
          css={css`
            width: 100%;
            background: ${theme.colors.white};

            .search-dropdown {
              background: ${theme.colors.white};

              &.focused {
                height: 400px;
                max-height: 400px;
              }
            }
          `}
        />
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          flex: 1 0 10%;
        `}
      >
        <NavigationMobile />
      </div>
      <div
        css={css`
          flex: 0 0 auto;
        `}
      >
        <BurgerFooter />
      </div>
    </div>
  );
}

function BurgerFooter() {
  const { userprofile, deauthenticate } = useAuth();
  const history = useHistory();

  const logout = () => {
    deauthenticate();
    history.push("/login");
  };

  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background-color: #f2f2f2;
        padding: 20px;
        border-radius: 0 0 20px 20px;
        margin: auto 0 0 0;
      `}
    >
      <div
        css={css`
          align-items: center;
          display: flex;
          flex-direction: row;
        `}
        onClick={() => logout()}
      >
        <PowerOffSvg color={"#E95B2E"} />
        <div
          css={css`
            font-size: 18px;
            margin-left: 9px;
          `}
        >
          Log out
        </div>
      </div>
      <div
        css={css`
          align-items: center;
          display: flex;
          flex-direction: row;
        `}
        onClick={() => history.push("/account")}
      >
        <div
          css={css`
            font-size: 18px;
            margin-right: 12px;
          `}
        >
          {userprofile?.first_name}
        </div>
        {userprofile?.avatar ? (
          <div
            css={css`
              position: relative;
              display: flex;
              border-radius: 50%;
              width: 30px;
              height: 30px;

              background-image: url(${userprofile.avatar.image});
              background-repeat: no-repeat;
              background-size: cover;
              background-position: center center;
            `}
          />
        ) : (
          <User
            css={css`
              width: 30px;
              height: 30px;
              border: 1px solid ${theme.colors.gray4};
              border-radius: 50%;
              padding: 3px;
            `}
          />
        )}
      </div>
    </div>
  );
}

function NavigationMobile() {
  const history = useHistory();
  const { t } = useTranslation();

  const [modal, setModal] = useState(false);
  const { data: testsData, error: testsError } = useFetch(getScheduledTests);
  const tests = testsError
    ? []
    : testsData
    ? testsData.filter((test) => test.variant)
    : [];

  return (
    <Nav>
      <ul>
        <li>
          <Link to="/">{t("header.navigation.dashboard")}</Link>
        </li>
        <li>
          <Link to="/courses">{t("header.navigation.courses")}</Link>
        </li>
        <li>
          <Link to="/cheats">{t("cheats_sheet.cheats")}</Link>
        </li>
        <li>
          <Link to={history.location.pathname} onClick={() => setModal(true)}>
            {t("header.navigation.updates")}
          </Link>
        </li>
        <li>
          <Link to="/account">{t("header.my_profile.account")}</Link>
        </li>
        <li>
          <Link to="/me/skills/">{t("header.my_profile.my-competencies")}</Link>
        </li>
        <li>
          <Link to="/me/orders">{t("orders.my_orders")}</Link>
        </li>
      </ul>
      {modal && (
        <SpacedRepetitionModal
          isActive={modal}
          tests={tests}
          onClose={() => setModal(false)}
        />
      )}
    </Nav>
  );
}

const Nav = styled.nav`
  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    margin: 0;
  }

  li {
    margin-bottom: 15px;
  }

  a {
    font-size: 16px;
    text-decoration: none;
    line-height: 1.6;
  }
`;
