import React from "react";
import { useParams } from "react-router-dom";
import { css } from "styled-components/macro";
import { getPage } from "../actions/page";
import { useFetch } from "../actions/useAPI";
import { Block } from "../components/Block";
import { Header } from "../components/Header";
import { Wrapper } from "../components/Wrapper";
import { SEO } from "../components/SEO";

import { Headers } from "../helpers/layout";
import { theme } from "../themes/variables";
import { PageQueryHandler } from "./PageQueryHandler";

export function CustomPage(props: {}) {
  const params = useParams<{
    pageSlug: string;
  }>();

  const {
    data: page,
    error: pageError,
    isLoading: pageIsLoading,
  } = useFetch(getPage, {
    pageSlug: params.pageSlug,
  });

  if (!page || pageError || pageIsLoading) {
    return <PageQueryHandler error={pageError} isLoading={pageIsLoading} />;
  }

  return (
    <Wrapper>
      <SEO>
        <title>{page.title}</title>
        <meta name="description" content={page.description} />
        <meta name="og:image" content={page.image?.image} />
      </SEO>
      <Header />
      <Block
        css={css`
          background-color: ${theme.colors.white};
          padding: 30px;

          h1 {
            margin-bottom: 30px;
          }
        `}
      >
        <Headers.H1>{page.title}</Headers.H1>

        <div
          dangerouslySetInnerHTML={{
            __html: page.content,
          }}
        ></div>
      </Block>
    </Wrapper>
  );
}
