import React, { useEffect } from "react";
import { Route, Redirect, RouteComponentProps } from "react-router-dom";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { useAuth } from "../contexts/UserContext";

export const PrivateRoute: React.FC<{
  component: React.FC<RouteComponentProps<any>>;
  path: string;
  exact?: boolean;
}> = ({ component, ...rest }) => {
  const { isAuthenticated } = useAuth();

  const { trackPageView } = useMatomo();

  useEffect(() => {
    trackPageView({ href: rest.path });
  }, [rest.path, trackPageView]);

  return (
    <Route
      {...rest}
      render={(props: RouteComponentProps<any>) => {
        if (isAuthenticated === null) {
          return null;
        }

        if (isAuthenticated) {
          return React.createElement(component, props);
        }

        props.history.replace({
          pathname: "/login",
          search: `redirect_to=${props.location.pathname}`,
        });

        return null;
      }}
    />
  );
};

export const PublicRoute: React.FC<{
  component: React.FC<RouteComponentProps<any>>;
  path: string;
  exact?: boolean;
}> = ({ component, ...rest }) => {
  const { isAuthenticated } = useAuth();

  const { trackPageView } = useMatomo();

  useEffect(() => {
    trackPageView({ href: rest.path });
  }, [rest.path, trackPageView]);

  return (
    <Route
      {...rest}
      render={(props: RouteComponentProps<any>) =>
        isAuthenticated === null ? null : isAuthenticated ? (
          <Redirect
            to={{
              pathname: "/",
            }}
          />
        ) : (
          React.createElement(component, props)
        )
      }
    />
  );
};

export const isExternalLink = (url: string) => {
  const tmp = document.createElement("a");
  tmp.href = url;
  return tmp.host !== window.location.host;
};
