import React from "react";
import { css } from "styled-components/macro";

export function ProgressBar(props: {
  percent: number;
  color: string;
  emptyColor: string;
  height?: number;
  className?: string;
}) {
  const { percent, color, emptyColor, height = 8 } = props;
  return (
    <div
      css={css`
        background: ${emptyColor};
        border-radius: 20px;
        height: ${height}px;
        width: 100%;
        :after {
          content: "";
          display: block;
          background: ${color};
          width: ${percent}%;
          height: 100%;
          border-radius: 20px;
        }
      `}
      className={props.className}
    ></div>
  );
}
