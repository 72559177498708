import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { css } from "styled-components/macro";
import { Text } from "../helpers/layout";
import { useResponsive } from "../hooks/useResponsive";
import { theme } from "../themes/variables";

interface BreadcrumbItem {
  label: string | undefined;
  url: string;
}

interface IBreadcrumbs {
  items: BreadcrumbItem[];
  className?: string;
}

export function Breadcrumbs(props: IBreadcrumbs) {
  const { isMobile } = useResponsive();

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        flex-flow: row wrap;
        margin: ${isMobile ? "0 0 20px 0" : "14px 0 0 0"};
      `}
    >
      {props.items.map((item, idx) => {
        const isNotLast = idx < props.items.length - 1;
        const isLast = idx === props.items.length - 1;

        return (
          <Fragment key={idx}>
            {item.url ? (
              <Link
                css={css`
                  white-space: nowrap;
                `}
                to={item.url}
              >
                <Text.Notifications
                  css={css`
                    font-weight: ${isLast ? "bold" : "normal"};
                    color: ${isLast ? theme.colors.dark : theme.colors.gray6};
                  `}
                >
                  {item.label}
                </Text.Notifications>
              </Link>
            ) : (
              <div
                css={css`
                  white-space: nowrap;
                `}
              >
                <Text.Notifications
                  css={css`
                    font-weight: ${isLast ? "bold" : "normal"};
                    color: ${isLast ? theme.colors.dark : theme.colors.gray6};
                  `}
                >
                  {item.label}
                </Text.Notifications>
              </div>
            )}
            {(isNotLast || props.items.length === 1) && (
              <div
                css={css`
                  color: ${theme.colors.gray6};
                `}
              >
                &nbsp;&nbsp;{">"}&nbsp;&nbsp;
              </div>
            )}
          </Fragment>
        );
      })}
    </div>
  );
}
