import React from "react";
import { Field, FieldProps } from "formik";
import { RadioComponent } from "../../components/RadioComponent";

export function RadioField(props: {
  name: string;
  id?: string;
  label?: React.ReactNode | React.ReactNode[] | string;
  labelRight?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  value: string | number | boolean;
  checkedColor?: string;
  notSelectedTransparent?: boolean;
  className?: string;
  validate?: (value: string) => Promise<void | string>;
}) {
  const { validate, value, ...other } = props;
  return (
    <Field
      name={other.name}
      type="radio"
      validate={validate}
      value={value}
      component="div"
    >
      {({ field, form }: FieldProps) => {
        return (
          <RadioComponent
            {...other}
            field={field}
            onChange={(e) => {
              const val = e.target.value;

              if (val === "true" || val === "false") {
                form.setFieldValue(props.name, val === "true" ? true : false);
              } else {
                form.setFieldValue(props.name, val);
              }
            }}
          />
        );
      }}
    </Field>
  );
}
