import React from "react";
import { css } from "styled-components/macro";
import { ProgressBar } from "./ProgressBar";

export function ProgressBarSection(props: {
  percent: number;
  numberOfSections: number;
  padding: number;
  color: string;
  emptyColor: string;
}) {
  const { color, emptyColor, numberOfSections, padding = 9, percent } = props;
  const totalPercent = percent * numberOfSections;
  const sections = new Array(numberOfSections).fill(0).map((_, key) => {
    const localPercent = (key + 1) * 100;
    const sectionPercent =
      totalPercent > localPercent
        ? 100
        : totalPercent - key * 100 < 0
        ? 0
        : totalPercent - key * 100;

    return {
      percent: sectionPercent,
    };
  });

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        width: 100%;
        > div {
          margin-right: ${padding}px;
          :last-of-type {
            margin-right: 0;
          }
        }
      `}
    >
      {sections.map((section, key) => (
        <ProgressBar
          key={key}
          color={color}
          emptyColor={emptyColor}
          percent={section.percent}
        />
      ))}
    </div>
  );
}
