import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { css } from "styled-components/macro";
import { Order, OrderPaymentOptions } from "../../types/Order";
import { theme } from "../../themes/variables";
import { hexToRgb } from "../../helpers/color";
import { Headers, Text } from "../../helpers/layout";
import { Button } from "../../components/Button";
import { Card } from "../../components/Card";
import { LongDashedSeparator } from "../../components/Separator";

import PaymentPreview from "../../assets/img/Payment-Preview.png";
import { ReactComponent as LeftIcon } from "../../assets/svg/Left.svg";
import { useResponsive } from "../../hooks/useResponsive";

export function PaymentSuccess(props: { order: Order }) {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();

  const getPaymentMethod = (paymentType: OrderPaymentOptions | undefined) => {
    switch (paymentType) {
      case OrderPaymentOptions.PAYPAL:
        return "PayPal";
      case OrderPaymentOptions.CREDIT_CARD:
        return t("payment.credit-card");
      case OrderPaymentOptions.DIRECT_DEBIT:
        return t("payment.direct-debit");
      case OrderPaymentOptions.BANK_TRANSFER:
        return t("payment.invoice");
      default:
        return "";
    }
  };

  const { r, g, b } = hexToRgb(theme.colors.haki);
  const CardRgbaColor = `rgba(${r}, ${g}, ${b}, 0.4)`;

  return (
    <div
      css={css`
        display: flex;
        flex-direction: ${isMobile ? "column" : "row"};
        justify-content: space-between;
        position: relative;
      `}
    >
      <div
        css={css`
          flex: 0 0 calc(50% - 100px);
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            max-width: 380px;
          `}
        >
          <div>
            <Headers.H1
              css={css`
                margin-top: ${isMobile ? "0" : "40px"};
                margin-bottom: 17px;
              `}
            >
              {t("payment.success.start-learning")}
            </Headers.H1>

            <Headers.H4>{t("payment.success.congratulations")}</Headers.H4>
          </div>

          <div
            css={css`
              margin-bottom: ${isMobile ? "30px" : "40px"};
              margin-top: ${isMobile ? "30px" : "0"};
            `}
          >
            <Link to={`/course/${props.order.items[0].course.uuid}`}>
              <Button
                color={theme.colors.white}
                iconRight
                icon={
                  <LeftIcon
                    width={14}
                    height={14}
                    color={theme.colors.white}
                  ></LeftIcon>
                }
              >
                {t("payment.success.start")}
              </Button>
            </Link>
          </div>
        </div>
      </div>

      <Card
        css={css`
          background-color: ${CardRgbaColor};
          padding: ${isMobile ? "30px 24px 24px 24px" : "40px"};
        `}
      >
        <Headers.H3
          css={css`
            margin-bottom: 12px;
          `}
        >
          {t("payment.success.thank")}
        </Headers.H3>

        <Text.Notifications
          css={css`
            margin-bottom: ${isMobile ? "30px" : "40px"};
          `}
        >
          {t("payment.success.email-confirm")}
        </Text.Notifications>

        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;
            `}
          >
            <div
              css={css`
                display: flex;
                align-items: center;
              `}
            >
              <img
                src={PaymentPreview}
                alt="preview"
                width={40}
                height={40}
                css={css`
                  margin-right: 16px;
                `}
              />

              <Text.CTA>{props.order.items[0].course.name}</Text.CTA>
            </div>

            <Text.ParagraphBold>{props.order.total} €</Text.ParagraphBold>
          </div>
        </div>

        <LongDashedSeparator
          color={theme.colors.gray2}
          css={css`
            margin-top: 24px;
            margin-bottom: 24px;
          `}
        />

        {props.order.payment_type && (
          <div
            css={css`
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-size: 16px;
            `}
          >
            <Text.Notifications>
              {getPaymentMethod(props.order.payment_type)}
            </Text.Notifications>
            <Text.CTA>{t("payment.for-free")}</Text.CTA>
          </div>
        )}

        {props.order.billing_address && (
          <Text.Notifications
            css={css`
              margin-top: 16px;
            `}
          >
            <Text.Notifications>
              {props.order.billing_address.first_name}{" "}
              {props.order.billing_address.last_name}
            </Text.Notifications>

            <Text.Notifications>
              {props.order.billing_address.company_name}
            </Text.Notifications>

            <Text.Notifications>
              {props.order.billing_address.email}
            </Text.Notifications>

            <Text.Notifications>
              {props.order.billing_address.city + ", "}
              {props.order.billing_address.street_address}
            </Text.Notifications>
          </Text.Notifications>
        )}
      </Card>
    </div>
  );
}
