import { authorised } from "../../request";
import { Order } from "../../types/Order";

type Args = {
  orderId: string;
};

export async function getOrder({ orderId }: Args) {
  const API = authorised();

  return API.request<Order>({
    method: "GET",
    url: `/api/campus/orders/${orderId}/`,
  });
}
