import { css } from "styled-components/macro";
import { useHistory } from "react-router-dom";
import { theme } from "../themes/variables";
import { Headers } from "../helpers/layout";
import { Check } from "react-feather";
import lockBadge from "../assets/img/Locked.png";

interface ExpansionItemProps {
  title: string;
  link?: string;
  icon: string;
  hasLockedState?: boolean;
  isLocked?: boolean;
  hasCompletedState?: boolean;
  isCompleted?: boolean;
  width: number;
  height?: number;
  marginRight?: number;
}

export function ExpansionItem(props: ExpansionItemProps) {
  const history = useHistory();

  const width = props.width || 270;
  const marginRight = props.marginRight || 315 - width;
  const height = props.height || 175;

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        position: relative;
        width: ${width}px;
        margin-right: ${marginRight}px;
        margin-bottom: 30px;
      `}
      onClick={() => {
        if (props.link) {
          history.push(props.link);
        }
      }}
    >
      <div
        css={css`
          position: relative;
          cursor: pointer;
        `}
      >
        <div
          css={css`
            width: ${width}px;
            border-radius: 20px;
            min-height: ${height}px;
            object-fit: cover;
            display: flex;
            flex-direction: column;
            background-color: ${theme.colors.white};
            box-shadow: 0 3px 0 0 ${theme.colors.transparentBlack2};
            ${props.hasCompletedState && props.isCompleted && "opacity: 0.9;"}
            ${props.hasLockedState && !props.isCompleted && "opacity: 0.4;"}
          `}
        >
          <div
            css={css`
              display: flex;
              margin: 20px 20px 0 20px;
              height: 60px;
            `}
          >
            <div
              css={css`
                display: flex;
                width: 60px;
                background-size: contain;
                background-repeat: no-repeat;
                ${props.hasLockedState && !props.isCompleted
                  ? css`
                      background-image: url(${lockBadge});
                    `
                  : props.icon
                  ? css`
                      background-image: url(${props.icon});
                    `
                  : css`
                      background-image: none;
                    `};
              `}
            />
          </div>
          <Headers.H4
            css={css`
              margin-top: 15px;
              cursor: pointer;
              margin: 20px;
              font-size: 18px;
              ${props.hasCompletedState && props.isCompleted && "opacity: 0.6;"}
            `}
          >
            {props.title}
          </Headers.H4>
        </div>
        {props.hasCompletedState && props.isCompleted && (
          <div
            css={css`
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: ${theme.colors.greenMain};
              opacity: 0.9;
              width: 100%;
              height: 100%;
              border-radius: 20px;
            `}
          >
            <Check size={50} color={theme.colors.white} />
          </div>
        )}
      </div>
    </div>
  );
}
