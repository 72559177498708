
export function hexToRgb(hex: string) {
  let bigint = 0;

  if (hex.match('#')) {
    bigint = parseInt(hex.slice(1), 16);
  } else {
    bigint = parseInt(hex, 16);
  }

  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return {r, g, b}
}

export function rgbToHex(r: number, g: number, b: number) {
  return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}
