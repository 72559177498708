import React from "react";
import styled, { css } from "styled-components/macro";
import { theme } from "../themes/variables";

const checkboxButtonLabel = css`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  a {
    font-weight: bold;
  }
  p {
    margin-left: 40px;
    font-size: 14px;
  }
`;
const CheckboxButton = styled.input`
  position: absolute;
  appearance: none;
  border: 1px solid ${theme.colors.primary};
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  &:after {
    content: "✔";
    display: none;
    color: white;
    font-size: 100%;
  }
  &:checked {
    background-color: ${theme.colors.primary};
    &:after {
      display: block;
    }
  }
`;

export function CheckboxPlainField(props: {
  name: string;
  checked: boolean;
  children: React.ReactNode | React.ReactNode[];
  onChange: (checked: boolean) => void;
}) {
  return (
    <label css={checkboxButtonLabel}>
      <CheckboxButton
        name={props.name}
        type="checkbox"
        checked={props.checked}
        onChange={(event) => {
          props.onChange(event.target.checked);
        }}
      />
      {props.children}
    </label>
  );
}
