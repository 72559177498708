import React, { useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { Address } from "../../types/Address";
import { Order } from "../../types/Order";
import { theme } from "../../themes/variables";
import { Headers } from "../../helpers/layout";
import { Button } from "../../components/Button";
import { VerticalSeparator } from "../../components/Separator";
import { OrderReview } from "../../components/Payment/OrderReview";
import { PaymentError } from "../../components/Payment/PaymentError";

import { ReactComponent as LeftIcon } from "../../assets/svg/Left.svg";
import { useResponsive } from "../../hooks/useResponsive";

export type FormValues = Address;

export function PurchaseCreditCard(props: {
  clientSecret: string;
  order: Order & {
    billing_address: Address;
  };
}) {
  const { t } = useTranslation();

  const { isMobile } = useResponsive();

  const stripe = useStripe();

  const elements = useElements();

  const [isLoading, setIsLoading] = useState(false);

  const [message, setMessage] = useState<string | null>(null);

  const onSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        payment_method_data: {
          billing_details: {
            address: {
              country: props.order.billing_address.country,
              city: props.order.billing_address.city,
              postal_code: props.order.billing_address.postal_code,
              state: props.order.billing_address.state,
              line1: props.order.billing_address.street_address,
            },
            email: props.order.billing_email,
            name: `${props.order.billing_address.first_name} ${props.order.billing_address.last_name}`,
            phone: props.order.billing_address.phone_number,
          },
        },
        return_url: `${window.location.origin}/order/${props.order.uuid}/payment-status`,
      },
    });

    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message || t("payment.status.something-wrong"));
    }

    setIsLoading(false);
  };

  return (
    <div
      css={css`
        display: flex;
        flex-direction: ${isMobile ? "column" : "row"};
        justify-content: space-between;
        position: relative;
      `}
    >
      <div
        css={css`
          flex: 0 0 calc(50% - 100px);
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
          `}
        >
          <div>
            <Headers.H4
              css={css`
                font-size: 20px;
                line-height: 30px;
                margin-bottom: 32px;
              `}
            >
              {t("payment.credit-card-info")}:
            </Headers.H4>

            <PaymentElement
              id="payment-element"
              options={{
                wallets: {
                  applePay: "never",
                  googlePay: "never",
                },
              }}
            />

            {message && (
              <PaymentError
                css={css`
                  margin-top: 16px;
                `}
                orderId={props.order.uuid}
              >
                {message}
              </PaymentError>
            )}
          </div>

          {!isMobile && (
            <Link
              to={`/order/${props.order.uuid}/billing-information`}
              css={css`
                margin-top: 30px;
              `}
            >
              <Button
                background={theme.colors.transparent}
                color={theme.colors.dark}
                icon={
                  <LeftIcon
                    width={20}
                    height={20}
                    color={theme.colors.primary}
                    css={css`
                      transform: rotate(180deg);
                    `}
                  />
                }
                css={css`
                  padding: 0px;
                `}
                hoverStyles={`opacity: 0.85;`}
              >
                {t("payment.back-to-billing-information")}
              </Button>
            </Link>
          )}
        </div>
      </div>

      {!isMobile && <VerticalSeparator />}

      {props.order.items.length > 0 && (
        <OrderReview
          values={props.order}
          courseId={props.order.items[0].course.uuid}
          isSubmitting={isLoading}
        >
          <Button
            css={css`
              margin-top: ${isMobile ? "30px" : "60px"};
            `}
            background={theme.colors.dark}
            color={theme.colors.white}
            type="submit"
            isSubmitting={isLoading}
            disabled={isLoading || !stripe || !elements}
            icon={
              <LeftIcon width={14} height={14} color={theme.colors.primary} />
            }
            iconRight
            onClick={(e) => onSubmit(e)}
            hoverStyles={`color: ${theme.colors.white}; background: ${theme.colors.primary};`}
          >
            {t("payment.place-order")}
          </Button>
        </OrderReview>
      )}

      {isMobile && (
        <Link
          to={`/order/${props.order.uuid}/billing-information`}
          css={css`
            margin-top: 30px;
          `}
        >
          <Button
            background={theme.colors.transparent}
            color={theme.colors.dark}
            icon={
              <LeftIcon
                width={20}
                height={20}
                color={theme.colors.primary}
                css={css`
                  transform: rotate(180deg);
                `}
              />
            }
            css={css`
              padding: 0px;
            `}
            hoverStyles={`opacity: 0.85;`}
          >
            {t("payment.back-to-billing-information")}
          </Button>
        </Link>
      )}
    </div>
  );
}
