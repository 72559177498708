import { css } from "styled-components/macro";
import { Spacing, Headers } from "../helpers/layout";
import { theme } from "../themes/variables";
import { Button } from "../components/Button";
import { Modal } from "./Modal/Modal";
import { useTranslation } from "react-i18next";
import { useState } from "react";

type ModalBtn = {
  color?: string;
  background?: string;
  border?: string;
  disabled?: boolean;
};

export function ConfirmModal(props: {
  modalIsOpen: boolean;
  onClose?: () => void;
  onConfirm?: (() => void) | (() => Promise<void>);
  onError?: (e: Error) => void;
  title?: string;
  children?: string | JSX.Element | JSX.Element[];
  disableCancelBtn?: boolean;
  disableConfirmBtn?: boolean;
  cancelBtnText?: string;
  confirmBtnText?: string;
  customConfirmBtn?: ModalBtn;
  customCancelBtn?: ModalBtn;
  customTitle?: string | JSX.Element | JSX.Element[] | null;
}) {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  async function onConfirm() {
    if (props.onConfirm) {
      try {
        setLoading(true);
        await props.onConfirm();
      } catch (e: any) {
        props.onError && props.onError(e);
      } finally {
        setLoading(false);
      }
    }
  }

  return (
    <div>
      <Modal modalIsOpen={props.modalIsOpen} onClose={props.onClose}>
        <Headers.H3
          css={css`
            font-weight: 500;
            margin: 0 40px 0 0;
          `}
        >
          {props.title}
        </Headers.H3>
        <Headers.H4
          css={css`
            margin: 5px 40px 0 0;
            color: ${theme.colors.gray4};
          `}
        >
          {props.children}
        </Headers.H4>

        <div
          css={css`
            margin-top: ${Spacing.xl};
            display: flex;
            align-items: center;
            justify-content: flex-end;
            > button {
              margin: 0 ${Spacing.s};
              :last-of-type {
                margin: 0;
                margin-left: ${Spacing.s};
              }
            }
          `}
        >
          {!props.disableCancelBtn && (
            <Button
              background={theme.colors.white}
              color={theme.colors.dark}
              border={`1px solid ${theme.colors.dark}`}
              hoverStyles={`color: ${theme.colors.white}; background: ${theme.colors.primary}; border: 1px solid ${theme.colors.primary};`}
              onClick={props.onClose}
              {...props.customCancelBtn}
              css={css`
                padding: 10px 20px;
              `}
            >
              {props.cancelBtnText || t("modal.cancel")}
            </Button>
          )}

          {!props.disableConfirmBtn && (
            <Button
              onClick={onConfirm}
              isSubmitting={loading}
              {...props.customConfirmBtn}
              css={css`
                padding: 10px 20px;
              `}
            >
              {props.confirmBtnText || t("modal.confirm")}
            </Button>
          )}
        </div>
      </Modal>
    </div>
  );
}
