import React from "react";
import { css } from "styled-components/macro";
import { Link } from "react-router-dom";
import { theme } from "../themes/variables";

import { ReactComponent as ReadIcon } from "../assets/svg/Read.svg";
import { useResponsive } from "../hooks/useResponsive";

export type Step = {
  title: string;
  link?: string;
  current?: boolean;
  complete?: boolean;
};

export function StepComponent(props: {
  step: Step;
  index: number;
  link?: boolean;
  isMobile?: boolean;
}) {
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        position: relative;
        ${props.link &&
        css`
          cursor: pointer;
        `}
      `}
    >
      {props.isMobile ? (
        <>
          <div
            css={css`
              display: flex;
              align-items: center;
              justify-content: center;
              overflow: hidden;
              width: 40px;
              height: 40px;
              border: 2px solid
                ${props.step.complete
                  ? theme.colors.success
                  : theme.colors.gray2};
              border-radius: 50%;
              color: ${props.step.complete
                ? theme.colors.gray5
                : props.step.current
                ? theme.colors.dark
                : theme.colors.gray2};
              background: ${theme.colors.white};
              z-index: 1;
            `}
          >
            {props.index + 1}
          </div>

          {props.step.complete && (
            <div
              css={css`
                position: absolute;
                background: ${theme.colors.white};
                top: 4px;
                right: 10px;
                overflow: hidden;
                z-index: 1;
                border-radius: 50%;
                transform: translate(50%, -50%);
              `}
            >
              <ReadIcon color={theme.colors.success} width={20} height={20} />
            </div>
          )}
        </>
      ) : (
        <>
          <div
            css={css`
              margin: 0;
              margin-right: 40px;
              padding-bottom: 3px;
              font-size: 16px;
              line-height: 22px;
              font-weight: 400;
              color: ${props.step.current
                ? theme.colors.dark
                : theme.colors.gray5};
              ${props.step.current &&
              css`
                border-bottom: 2px solid ${theme.colors.primary};
              `}
            `}
          >
            {props.index + 1}. {props.step.title}
          </div>

          {props.step.complete && (
            <ReadIcon
              color={theme.colors.success}
              width={14}
              height={14}
              css={css`
                position: absolute;
                bottom: 25px;
                right: 28px;
              `}
            />
          )}
        </>
      )}
    </div>
  );
}

export function Steps(props: { steps: Step[]; className?: string }) {
  const { isMobile } = useResponsive();

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        padding: ${isMobile ? "20px" : "24px"};
        margin: ${isMobile ? "20px auto" : "40px auto"};
        background: ${theme.colors.white};
        border-radius: 20px;
      `}
      className={props.className}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          ${isMobile &&
          css`
            width: 100%;
            justify-content: space-between;
            position: relative;
          `}
        `}
      >
        {props.steps.map((step, i) =>
          step.link ? (
            <Link to={step.link} key={i}>
              <StepComponent step={step} index={i} link isMobile={isMobile} />
            </Link>
          ) : (
            <StepComponent step={step} index={i} key={i} isMobile={isMobile} />
          )
        )}

        {isMobile && (
          <div
            css={css`
              position: absolute;
              top: 50%;
              left: 0;
              transform: translate(0, -50%);
              width: 100%;
              height: 8px;
              background ${theme.colors.gray2};
            `}
          >
            <div
              css={css`
                width: ${(props.steps.findIndex((el) => el.current) /
                  (props.steps.length - 1)) *
                100}%;
                height: 8px;
                background: ${theme.colors.success};
              `}
            ></div>
          </div>
        )}
      </div>
    </div>
  );
}
