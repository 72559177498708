import { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { SliderElement } from "./SliderElement";
import { css } from "styled-components/macro";
import { ISliderItem } from "../types/SliderItem";
import { theme } from "../themes/variables";
import { useResponsive } from "../hooks/useResponsive";

export function RecommendationSlider(props: {
  id?: number;
  data?: ISliderItem[];
}) {
  const { isMobile } = useResponsive();
  const ref = useRef<any>(null);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 7000,
    dots: true,
    appendDots: (dots: JSX.Element) => (
      <div
        css={css`
          width: fit-content;
          position: absolute;
          bottom: 25px;
          right: 40px;

          li.slick-active div {
            background-color: ${theme.colors.black} !important;
          }
        `}
      >
        {dots}
      </div>
    ),
    customPaging: () => (
      <div
        css={css`
          width: 10px;
          height: 10px;
          border: 1px solid ${theme.colors.black};
          border-radius: 50%;
        `}
      />
    ),
  };

  return (
    <div
      css={css`
        width: 65%;
        flex: 0 0 65%;
        min-width: 0;

        ${isMobile &&
        css`
          width: 100%;
          flex: 0 0 100%;

          .slick-dots {
            bottom: 38px;

            li {
              width: 10px;
            }
          }
        `}
      `}
    >
      <Slider {...settings} ref={ref}>
        {props.data &&
          props.data.map((sliderElement, i) => (
            <SliderElement item={sliderElement} key={i} />
          ))}
      </Slider>
    </div>
  );
}
