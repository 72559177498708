import React, { useRef } from "react";
import { css } from "styled-components/macro";
import { theme } from "../themes/variables";
import { CourseCheatSheetSuggestions } from "../types/CourseSuggestions";
import { Button } from "./Button";
import CourseCardSuggestion from "./CourseCardSuggestion";
import { ReactComponent as LeftSvg } from "../assets/svg/LeftOrange.svg";
import { useHistory } from "react-router";
import Slider from "react-slick";
import { useResponsive } from "../hooks/useResponsive";

export default function SuggestionsBlock({
  title,
  subtitle,
  button_text,
  button_link,
  background_color,
  courses,
}: {
  title?: string;
  subtitle?: string;
  button_text?: string;
  button_link?: string;
  background_color?: string;
  courses: CourseCheatSheetSuggestions[];
}) {
  const history = useHistory();
  const { isMobile } = useResponsive();

  const ref = useRef<any>(null);

  const settings = {
    infinite:
      courses.filter((course) => course.course.cheat_sheets.length).length > 2,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <LeftSvg />,
    prevArrow: (
      <LeftSvg
        css={css`
          transform: rotate(180deg);
        `}
      />
    ),
    dots: true,
  };

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        padding: ${isMobile ? "24px" : "40px"};
        background-color: ${background_color
          ? background_color
          : theme.colors.white};
        border-radius: 20px;
      `}
    >
      {title && subtitle && (
        <div
          css={css`
            margin-left: 10px;
          `}
        >
          <div
            css={css`
              font-size: 30px;
              line-height: 42px;
            `}
          >
            {title}
          </div>
          <div
            css={css`
              font-size: 20px;
              line-height: 30px;
            `}
          >
            {subtitle}
          </div>
        </div>
      )}
      {courses?.length > 0 && (
        <div
          css={css`
            margin-top: 32px;
            padding-bottom: 30px;
          `}
        >
          {isMobile ? (
            <div
              css={css`
                display: flex;
                flex-direction: column;
              `}
            >
              {courses.map(
                (course, index) =>
                  course.course.cheat_sheets.length > 0 && (
                    <CourseCardSuggestion key={index} course={course} />
                  )
              )}
            </div>
          ) : (
            <Slider
              {...settings}
              ref={ref}
              css={css`
                .slick-track {
                  position: relative;
                  top: 0;
                  left: 0;
                  display: block;
                  margin-left: 0;
                  margin-right: auto;
                }
                .slick-dots {
                  bottom: -45px;
                }
              `}
            >
              {courses.map(
                (course, index) =>
                  course.course.cheat_sheets.length && (
                    <CourseCardSuggestion key={index} course={course} />
                  )
              )}
            </Slider>
          )}
        </div>
      )}

      {courses.length < 1 && button_link && button_text && (
        <div
          css={css`
            display: flex;
            margin-top: 30px;
            margin-left: 10px;
          `}
        >
          <Button
            css={css`
              display: flex;
              flex-direction: row;
              padding: 10px 20px;
              background-color: ${theme.colors.white};
              border: 1px solid ${theme.colors.black};
              border-radius: 20px;
              align-items: center;
            `}
            color={theme.colors.black}
            hoverStyles={`background: ${theme.colors.primary}; color: ${theme.colors.white}; border: 1px solid ${theme.colors.primary};`}
            onClick={() => history.push(button_link)}
          >
            <div>{button_text}</div>
            <LeftSvg
              css={css`
                margin-left: 13px;
              `}
            />
          </Button>
        </div>
      )}
    </div>
  );
}
