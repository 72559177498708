import styled, { css } from "styled-components/macro";
import { theme } from "../themes/variables";

export const Separator = styled.hr`
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  flex-grow: 1;
  border-bottom: 1px solid ${theme.colors.gray3};
`;

/* To make vertical separator work correctly make parent node position: relative */
export const VerticalSeparator = styled.hr`
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  border-left: 1px solid ${theme.colors.gray3};
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
`;

export const DashedSeparator = styled.hr`
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  flex-grow: 1;
  border-bottom: 1px dashed ${theme.colors.gray3};
`;

export function LongDashedSeparator(props: {
  color?: string;
  className?: string;
}) {
  const color = props.color?.replace("#", "");

  return (
    <div
      css={css`
        height: 1px;
        flex-grow: 1;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23${color ||
        "CBCBCB"}' stroke-width='2' stroke-dasharray='6%2c 20' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
      `}
      className={props.className}
    ></div>
  );
}
