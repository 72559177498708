import { useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { updateAvatar } from "../actions/account/updateAvatar";
import { serverErrorHandler } from "../helpers/serverErrorHandler";
import { Image } from "../types/Image";
import { theme } from "../themes/variables";
import { DropImage, DroppedImage } from "../components/DropImage";
import { Button } from "../components/Button";
import { Modal, ModalBody, ModalFooter } from "../components/Modal/";

const checkDroppedImage = (p: any): p is DroppedImage => {
  return p && p.hasOwnProperty("file");
};

export function ImageUploadModal(props: {
  isVisible: boolean;
  onClose: () => void;
}) {
  const { t } = useTranslation();

  const [selectedImage, setSelectedImage] = useState<
    Image | DroppedImage | null
  >(null);

  const [droppedImage, setDroppedImage] = useState<DroppedImage | null>(null);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  const onUploadProgress = (progressEvent: any) => {
    const percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );

    setProgress(percentCompleted);
  };

  const onUpload = async (image: DroppedImage) => {
    try {
      setLoading(true);
      setProgress(0);

      await updateAvatar({
        file: image.file,
        onUploadProgress,
      });

      setLoading(false);
      setProgress(0);
      window.location.reload();
    } catch (error) {
      toast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    }
  };

  const onConfirm = async () => {
    if (checkDroppedImage(selectedImage) && droppedImage) {
      await onUpload(droppedImage);
    }

    onClose();
  };

  const onClose = () => {
    props.onClose();
    setSelectedImage(null);
    setDroppedImage(null);
  };

  return (
    <Modal modalIsOpen={props.isVisible} onClose={onClose}>
      <ModalBody>
        <DropImage
          onImageDropped={(image) => {
            setDroppedImage(image);
            setSelectedImage(image);
          }}
          onImageDeleted={() => {
            setDroppedImage(null);
            if (checkDroppedImage(selectedImage)) {
              setSelectedImage(null);
            }
          }}
          selectedImage={
            checkDroppedImage(selectedImage) ? selectedImage : null
          }
          onClickPreview={() => setSelectedImage(droppedImage)}
        />
      </ModalBody>

      <ModalFooter>
        <Button
          background={theme.colors.white}
          color={theme.colors.dark}
          border={`1px solid ${theme.colors.dark}`}
          hoverStyles={`color: ${theme.colors.white}; background: ${theme.colors.primary}; border: 1px solid ${theme.colors.primary};`}
          onClick={onClose}
        >
          {t("actions.cancel")}
        </Button>

        <Button
          onClick={() => onConfirm()}
          disabled={!selectedImage || loading}
          background={
            loading
              ? `linear-gradient(90deg, ${theme.colors.primary} ${progress}%, ${theme.colors.gray3} 0%)`
              : !!selectedImage
              ? theme.colors.primary
              : theme.colors.gray4
          }
          color={theme.colors.white}
        >
          {t("actions.add")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
