import { Loader } from "../components/Loader";
import { Error } from "../components/Error";

export function pageLoading(backendError: string | null, humanError?: string) {
  if (backendError) {
    return <Error>{humanError || backendError}</Error>;
  }

  return <Loader absoluteCenter></Loader>;
}
