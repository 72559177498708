import { authorised } from "../../request";
import { CourseCheatSheetSuggestions } from "../../types/CourseSuggestions";

export async function getCoursesSuggestionsByCheats() {
  const API = authorised();

  return API.request<CourseCheatSheetSuggestions[]>({
    method: "GET",
    url: `/api/campus/cheatsheet/suggestions/`,
  });
}
