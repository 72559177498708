import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { css } from "styled-components/macro";
import { toast } from "react-toastify";
import { CheckSquare } from "react-feather";
import { humanize } from "../../helpers/humanize";
import { Headers, Text } from "../../helpers/layout";
import { ICourse } from "../../types/Course";
import { ELessonIcon, ILesson } from "../../types/Lesson";
import { theme } from "../../themes/variables";
import lessonText from "../../assets/img/Lesson-Text.png";
import lessonVideo from "../../assets/img/Lesson-Video.png";
import lessonQuiz from "../../assets/img/lessonQuiz.svg";
import LessonPodcast from "../../assets/img/podcast.png";
import { ConfirmModal } from "../ConfirmModal";
import { Card } from "../Card";
import { ReactComponent as WatchSvg } from "../../assets/svg/Watch.svg";
import { ReactComponent as ReadSvg } from "../../assets/svg/Read.svg";
import { ReactComponent as DownSvg } from "../../assets/svg/Down.svg";
import { CourseCTA, onEnroll } from "./CourseCTA";
import { useResponsive } from "../../hooks/useResponsive";

const elementsToShow = 6;

interface ElementLesson {
  type: "lesson";
  data: ILesson;
}

interface ElementExam {
  type: "exam";
}

interface ElementSummary {
  type: "summary";
}

type Element = ElementLesson | ElementExam | ElementSummary;

export function CourseSyllabus(props: {
  course: ICourse;
  previewToken?: string;
}) {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();

  const [courseSummaryModal, setCourseSummaryModal] = useState(false);

  const history = useHistory();

  const { course } = props;

  const elements: Element[] = course.lessons.map((el) => {
    return {
      type: "lesson",
      data: el,
    };
  });
  if (course.has_exam_tests) {
    const exam: ElementExam = {
      type: "exam",
    };
    elements.push(exam);
  }

  if (course.has_summary) {
    const summary: ElementSummary = {
      type: "summary",
    };
    elements.push(summary);
  }

  const [enrolModal, setEnrolModal] = useState(false);
  const [purchaseModal, setPurchaseModal] = useState(false);
  const [expanded, setExpanded] = useState(elements.length <= elementsToShow);

  const onPurchaseModal = () => {
    setPurchaseModal(false);
    history.push(`/course/${course.uuid}/purchase/`);
  };

  const onErrorModal = () => {
    toast.error(
      t("status.error", {
        name: course.name,
      })
    );
  };

  const lessonActions = (uuid: string) => {
    if (!purchaseModal && !course.is_purchased) {
      setPurchaseModal(true);
    } else if (!enrolModal && course.is_purchased && !course.enrolment_id) {
      setEnrolModal(true);
    } else {
      if (
        !course.self_assessment_passed_id &&
        course.has_self_assessment &&
        course.enrolment_id
      ) {
        history.push(
          `/course/${course.uuid}/self-assessment?redirect_to=/course-enrolments/${course.enrolment_id}/lessons/${uuid}`
        );
      } else if (course.enrolment_id) {
        history.push(
          `/course-enrolments/${course.enrolment_id}/lessons/${uuid}/`
        );
      } else {
        history.push(`/course/${course.uuid}/`);
      }
    }
  };

  function capitalizeFirstLetters(text: string) {
    return text
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  return (
    <Card
      css={css`
        margin-bottom: ${isMobile ? "40px" : "60px"};
      `}
    >
      <Headers.H2
        css={css`
          margin-bottom: ${isMobile ? "32px" : "48px"};
        `}
      >
        {t("course.what_learn")}
      </Headers.H2>

      <div
        css={css`
          width: 100%;
          margin-top: 10px;
          background-color: ${theme.colors.white};
          border-radius: 20px;
        `}
      >
        <ul
          css={css`
            padding: 0;
            margin: 0;
          `}
        >
          {course.lessons.length === 0
            ? null
            : elements.map((el, key) => {
                if (
                  course.enrolment_id &&
                  elements.length >= elementsToShow &&
                  key >= elementsToShow &&
                  !expanded
                ) {
                  return null;
                }

                if (el.type === "lesson") {
                  const duration = Number(el.data.duration);
                  const humanizedDuration = humanize(duration * 1000);

                  return (
                    <li
                      css={css`
                        list-style: none;
                        font-size: 16px;
                        line-height: 16px;
                        cursor: pointer;
                        margin-bottom: 12px;
                        :last-of-type {
                          margin-bottom: 0;
                        }
                      `}
                      key={key}
                      onClick={() => {
                        props.previewToken
                          ? history.push(
                              `/course/${course.uuid}/lesson-preview/${el.data.uuid}?preview_token=${props.previewToken}`
                            )
                          : !course.enrolment_id && el.data.without_enrolment
                          ? history.push(
                              `/course/${course.uuid}/lesson-preview/${el.data.uuid}`
                            )
                          : lessonActions(el.data.uuid);
                      }}
                    >
                      <Card
                        css={css`
                          padding: 25px;
                          background-color: ${theme.colors.gray1};
                        `}
                      >
                        <div
                          css={css`
                            display: flex;
                          `}
                        >
                          {!el.data.is_completed ? (
                            <div
                              css={css`
                                display: flex;
                                width: 20px;
                                height: 20px;
                              `}
                            >
                              <img
                                css={css`
                                  width: 20px;
                                `}
                                src={
                                  el.data.icon === ELessonIcon.TEXT
                                    ? lessonText
                                    : el.data.icon === ELessonIcon.QUIZ
                                    ? lessonQuiz
                                    : el.data.icon === ELessonIcon.PODCAST
                                    ? LessonPodcast
                                    : lessonVideo
                                }
                                alt={ELessonIcon.TEXT}
                              />
                            </div>
                          ) : (
                            <ReadSvg
                              width={20}
                              css={css`
                                min-width: 20px;
                              `}
                              color={theme.colors.primary}
                            />
                          )}

                          <div
                            css={css`
                              margin-left: 16px;
                            `}
                          >
                            <Text.CTA
                              css={css`
                                margin-bottom: 12px;
                              `}
                            >
                              {el.data.name}
                            </Text.CTA>

                            <Text.Notifications
                              css={css`
                                margin-bottom: 12px;
                              `}
                            >
                              {el.data.description}
                            </Text.Notifications>

                            {el.data.duration > 0 && (
                              <div
                                css={css`
                                  display: flex;
                                  align-items: center;
                                `}
                              >
                                <WatchSvg
                                  color={theme.colors.dark}
                                  width={20}
                                />

                                <Text.Notifications
                                  css={css`
                                    margin-left: 8px;
                                  `}
                                >
                                  {capitalizeFirstLetters(humanizedDuration)}
                                </Text.Notifications>
                              </div>
                            )}
                            {el.data.without_enrolment && !course.enrolment_id && (
                              <div
                                css={css`
                                  font-weight: bold;
                                  background-color: ${theme.colors.dark};
                                  width: fit-content;
                                  margin-top: 12px;
                                  color: ${theme.colors.white};
                                  padding: 5px 20px;
                                  border-radius: 20px;
                                `}
                              >
                                {t("lesson.demo")}
                              </div>
                            )}
                          </div>
                        </div>
                      </Card>
                    </li>
                  );
                }

                if (el.type === "exam") {
                  return (
                    <li
                      css={css`
                        list-style: none;
                        font-size: 16px;
                        line-height: 16px;
                        cursor: ${course.enrolment_id ? "pointer;" : ""};
                        margin-bottom: 12px;
                        :last-of-type {
                          margin-bottom: 0;
                        }
                      `}
                      key={key}
                      onClick={() => {
                        course.enrolment_id &&
                          history.push(
                            `/course-enrolments/${course.enrolment_id}/exam/`
                          );
                      }}
                    >
                      <Card
                        css={css`
                          padding: 25px;
                          background-color: ${theme.colors.gray1};
                        `}
                      >
                        <div
                          css={css`
                            display: flex;
                            align-items: center;
                          `}
                        >
                          {course.is_exam_passed ? (
                            <ReadSvg width={20} color={theme.colors.primary} />
                          ) : (
                            <CheckSquare
                              size={20}
                              color={
                                course.enrolment_id
                                  ? theme.colors.black
                                  : theme.colors.transparentBlack2
                              }
                            />
                          )}

                          <Text.Paragraph
                            css={css`
                              margin-left: 16px;
                              color: ${course.enrolment_id
                                ? theme.colors.black
                                : theme.colors.transparentBlack2};
                            `}
                          >
                            {t("course.exam")}
                          </Text.Paragraph>
                        </div>
                      </Card>
                    </li>
                  );
                }

                if (el.type === "summary") {
                  return (
                    <li
                      css={css`
                        list-style: none;
                        font-size: 16px;
                        line-height: 16px;
                        cursor: pointer;
                        margin-bottom: 12px;
                        :last-of-type {
                          margin-bottom: 0;
                        }
                      `}
                      key={key}
                      onClick={() => {
                        if (!course.is_completed) {
                          setCourseSummaryModal(true);
                        } else {
                          history.push(`/course/${course.uuid}/summary/`);
                        }
                      }}
                    >
                      <Card
                        css={css`
                          padding: 25px;
                          background-color: ${theme.colors.gray1};
                        `}
                      >
                        <div
                          css={css`
                            display: flex;
                          `}
                        >
                          <img
                            src={lessonText}
                            alt={t("course-summary.syllabus.title")}
                            css={css`
                              width: 20px;
                            `}
                          />

                          <Text.Paragraph
                            css={css`
                              margin-left: 16px;
                            `}
                          >
                            {t("course-summary.syllabus.title")}
                          </Text.Paragraph>
                        </div>
                      </Card>
                    </li>
                  );
                }
                return null;
              })}
        </ul>

        {course.enrolment_id && !expanded && (
          <button
            css={css`
              display: flex;
              align-items: center;
              align-self: center;
              padding: 3px 20px;
              margin: 24px auto 0 auto;
              border-radius: 20px;
              border: 1px solid ${theme.colors.dark};
              background: ${theme.colors.white};
              cursor: pointer;
            `}
            onClick={() => setExpanded(true)}
          >
            <div>{t("actions.show_more")}</div>

            <DownSvg
              css={css`
                margin-left: 10px;
              `}
              height={14}
              width={14}
            />
          </button>
        )}

        {!props.course.enrolment_id && <CourseCTA course={props.course} />}
      </div>

      <ConfirmModal
        modalIsOpen={courseSummaryModal}
        onClose={() => setCourseSummaryModal(false)}
        onConfirm={() => {
          setCourseSummaryModal(false);
        }}
        confirmBtnText={t("actions.got-it")}
        disableCancelBtn
      >
        {t("course-summary.modal.text")}
      </ConfirmModal>

      <ConfirmModal
        modalIsOpen={purchaseModal}
        onClose={() => setPurchaseModal(false)}
        onConfirm={onPurchaseModal}
        onError={onErrorModal}
        confirmBtnText={t("modal.purchase")}
        title={`${t("modal.purchase_title")}`}
      >
        {t("modal.purchase_text")}
      </ConfirmModal>

      <ConfirmModal
        modalIsOpen={enrolModal}
        onClose={() => setEnrolModal(false)}
        onConfirm={async () => {
          await onEnroll(course, history, t);

          setEnrolModal(false);
        }}
        onError={onErrorModal}
        confirmBtnText={t("modal.enrol")}
        title={`${t("modal.enrol_title")}`}
      >
        {t("modal.enrol_text")}
      </ConfirmModal>
    </Card>
  );
}
