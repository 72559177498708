import { authorised } from "../../request";
import { IFAQItems } from "../../types/Course";

export async function getFaqList() {
  const API = authorised();

  return API.request<IFAQItems[]>({
    method: "GET",
    url: `/api/campus/faq/`
  });
}
