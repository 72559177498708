export enum SelfAssessmentType {
  OPEN_QUESTION = "OPEN_QUESTION",
  SINGLE_CHOICE = "SINGLE_CHOICE",
  MULTIPLE_CHOICE = "MULTIPLE_CHOICE",
}

export type SelfAssessmentAnswer = {
  id: string;
  answer: string;
};

export type SelfAssessment = {
  uuid?: string;
  title: string;
  type: SelfAssessmentType;
  answers: SelfAssessmentAnswer[];
  entity_id?: string | null;
};

export type SelfAssessmentSubmition = {
  item: SelfAssessment;
  answers: SelfAssessmentAnswer[];
};

export type SelfAssessmentReadOnly = {
  answers: SelfAssessmentAnswer[];
  question: SelfAssessment;
};
