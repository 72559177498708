import * as Formik from "formik";
import React from "react";
import { AlertCircle } from "react-feather";
import { css } from "styled-components/macro";
import { theme } from "../themes/variables";
import { CheckboxPlainField } from "./CheckboxPlainField";
import { Tooltip } from "./Tooltip";

export function CheckboxField(props: {
  name: string;
  value?: string | number | boolean;
  children?: React.ReactNode;
}) {
  return (
    <div>
      <Formik.Field name={props.name}>
        {({ form, field, meta }: Formik.FieldProps) => {
          return (
            <CheckboxPlainField
              name={props.name}
              checked={field.value}
              onChange={(checked) => {
                form.setFieldValue(props.name, checked);
              }}
            >
              {props.children}
              {meta.error && (
                <div
                  css={css`
                    cursor: pointer;
                    margin-left: 10px;
                  `}
                >
                  <Tooltip text={meta.error}>
                    <AlertCircle size={18} color={theme.colors.primary} />
                  </Tooltip>
                </div>
              )}
            </CheckboxPlainField>
          );
        }}
      </Formik.Field>
    </div>
  );
}
