import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { useFetch } from "../actions/useAPI";
import { Header } from "../components/Header";
import { Wrapper } from "../components/Wrapper";
import { theme } from "../themes/variables";
import { getProfile } from "../actions/auth/getProfile";
import { SEO } from "../components/SEO";
import { PageQueryHandler } from "./PageQueryHandler";
import { Tabs } from "../components/Tabs";
import { SignupSelfAssessment } from "../components/SignupSelfAssessment";

export function UserSelfAssessment(props: {}) {
  const { t } = useTranslation();

  const {
    data: user,
    error: userError,
    isLoading: userIsLoading,
  } = useFetch(getProfile);

  if (!user || userError || userIsLoading) {
    return <PageQueryHandler error={userError} isLoading={userIsLoading} />;
  }

  const tabsData = [
    {
      title: "General information",
      link: `/account`,
    },
    {
      title: t("account.personal_info"),
      link: `/account/data`,
    },
    {
      title: t("account.security"),
      link: `/account/security`,
    },
    {
      title: t("account.assessment"),
      link: `/account/self-assessment`,
      forceHightlight: true,
    },
  ];

  return (
    <>
      <Wrapper>
        <SEO>
          <title>{t("account.assessment")}</title>
        </SEO>

        <Header />
        <Tabs isWrapped tabs={tabsData} />

        <div
          css={css`
            display: flex;
            background-color: ${theme.colors.white};
            border-radius: 20px;
          `}
        >
          <div
            css={css`
              border-radius: 20px;
              background-color: ${theme.colors.white};
              width: 100%;
              padding-bottom: 4rem;
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: column;
                width: 100%;
              `}
            >
              {user && <SignupSelfAssessment />}
            </div>
          </div>
        </div>
      </Wrapper>
    </>
  );
}
