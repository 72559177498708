import { authorised } from "../../request";

type Args = {
  orderId: string;
};

export async function confirmStripePaymentIntent(args: Args) {
  const API = authorised();

  return API.request({
    method: "POST",
    url: `/api/campus/orders/${args.orderId}/stripe-confirm-payment-intent/`,
  });
}
