import { Headers } from "../../helpers/layout";
import { css } from "styled-components/macro";
import { TextRenderer } from "../TextRenderer";

export function TestTitle(props: {
  styleVariant: "default" | "card";
  children: string;
}) {
  if (props.styleVariant === "card") {
    return (
      <Headers.H2
        css={css`
          text-align: center;
        `}
      >
        <TextRenderer text={props.children} html withoutClassName />
      </Headers.H2>
    );
  }

  return (
    <Headers.H4
      css={css`
        font-weight: bold;
      `}
    >
      <TextRenderer text={props.children} html withoutClassName />
    </Headers.H4>
  );
}
