import { authorised } from "../../request";
import { IExam } from "../../types/Exam";

type Args = {
  enrolmentID: string;
};

export async function getExam({ enrolmentID }: Args) {
  const API = authorised();

  return API.request<IExam>({
    method: "GET",
    url: `/api/campus/course-enrolments/${enrolmentID}/exam/`,
  });
}
