import { authorised } from "../../request";
import { IUserDetails } from "../../types/UserProfile";

type Args = {
  file: File;
  onUploadProgress?: (progressEvent: any) => void;
};

export async function updateAvatar({ file, onUploadProgress }: Args) {
  const API = authorised();
  const formData = new FormData();

  formData.append("file", file);

  const response = await API.request<IUserDetails>({
    method: "POST",
    url: `/api/campus/me/update-avatar/`,
    data: formData,
    onUploadProgress,
  });

  return response.data;
}
