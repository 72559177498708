import React from "react";
import { css } from "styled-components/macro";
import { Link } from "react-router-dom";
import { theme } from "../themes/variables";
import Slider, { Settings } from "react-slick";
import { ReactComponent as LeftIcon } from "../assets/svg/LeftOrange.svg";
import { useResponsive } from "../hooks/useResponsive";

export type Tab = {
  title: string;
  link: string;
  forceHightlight?: boolean;
};

export function Tabs(props: {
  tabs: Tab[];
  className?: string;
  isWrapped?: boolean;
}) {
  const { isMobile } = useResponsive();

  const settings: Settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    variableWidth: true,
    nextArrow: (
      <LeftIcon
        width={20}
        height={20}
        color={theme.colors.dark}
        css={css`
          transform: translate(0, -50%);
          color: ${theme.colors.dark};
          :hover {
            color: ${theme.colors.dark};
            opacity: 0.8;
          }
        `}
      />
    ),
    prevArrow: (
      <LeftIcon
        css={css`
          transform: translate(0, -50%) rotate(180deg);
          color: ${theme.colors.dark};
          :hover {
            color: ${theme.colors.dark};
            opacity: 0.8;
          }
        `}
        className="skills"
        width={20}
        height={20}
        color={theme.colors.dark}
      />
    ),
  };

  return (
    <div
      css={css`
        padding-right: 40px;

        ${props.isWrapped &&
        css`
          padding: 35px 60px;
          padding-right: 100px;
          margin: 40px auto;
          background: ${theme.colors.white};
          border-radius: 20px;

          ${isMobile &&
          css`
            padding: 20px;
            padding-right: 60px;
            margin: 20px auto;
          `}
        `}
      `}
    >
      <Slider
        {...settings}
        css={css`
          max-width: 100%;
          .slick-track {
            position: relative;
            top: 0;
            left: 0;
            display: block;
            margin-left: 0;
            margin-right: auto;
          }
          .slick-disabled {
            display: none !important;
          }

          .slick-next {
            right: -40px;
          }
        `}
      >
        {props.tabs.map((el, index) => {
          return (
            <div key={index}>
              <Link to={el.link}>
                <div
                  css={css`
                    padding-bottom: 3px;
                    margin-right: 24px;
                    font-size: 16px;
                    line-height: 22px;
                    color: ${theme.colors.gray5};
                    cursor: pointer;
                    width: fit-content;
                    ${el.forceHightlight &&
                    css`
                      border-bottom: 3px solid ${theme.colors.primary};
                      color: ${theme.colors.dark};
                    `}
                  `}
                >
                  {el.title}
                </div>
              </Link>
            </div>
          );
        })}
      </Slider>
    </div>
  );
}
