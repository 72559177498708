import React, { useEffect, useState, useImperativeHandle, useRef } from "react";
import { css, FlattenSimpleInterpolation } from "styled-components/macro";
import { AxiosResponse } from "axios";
import { Pagination } from "./Pagination";
import { Error } from "./Error";
import {
  Table,
  TableColumn,
  TableRow,
  TableOrdering,
  TableOrderingSort,
  TableColumnStats,
} from "./Table";
import { Headers, Row } from "../helpers/layout";
import { usePagination } from "../hooks/usePagination";
import { useResponsive } from "../hooks/useResponsive";
import { MobileTable } from "./MobileTable";

export type TableHandlerRow<T> = {
  css?: FlattenSimpleInterpolation;
  render: (row: T) => React.ReactNode | React.ReactNode[] | string | null;
};

export type TableHandlerColumn<T> = TableColumn & TableHandlerRow<T>;

function getFieldsString<T>(
  column: TableHandlerColumn<T>,
  ordering: TableOrdering
): string | undefined {
  return ordering.sort === TableOrderingSort.ASC
    ? column.orderFields?.join(",")
    : column.orderFields?.map((el) => `-${el}`).join(",");
}

export function TableHandler<T, Q>(props: {
  forwardRef?: React.Ref<{
    refetch: () => void;
  }>;
  tableData: {
    columns: TableHandlerColumn<T>[];
    className?: string;
  };
  getFunction: (args: Q) => Promise<
    AxiosResponse<{
      count: number;
      next: null | string;
      previous: null | string;
      results: T[];
    }>
  >;
  options?: {
    limit?: number;
    internalArgs?: Omit<Q, "limit" | "offset">;
  };
  title?: string;
  noDataMessage?: string;
  loadingMessage?: string;
}) {
  const { isMobile } = useResponsive();
  const containerRef = useRef(null);
  const {
    response: { error, data, isLoading, refetch },
    pagination: {
      onChose,
      onNext,
      onPrev,
      count,
      page,
      setQueryArg,
      setPage,
      queryArgs,
    },
  } = usePagination(props.getFunction, { ...props.options });

  const fields = queryArgs.ordering
    ? queryArgs.ordering.split(",").map((el) => el.replace("-", ""))
    : null;

  const sortOfFields = queryArgs.ordering
    ? queryArgs.ordering.split(",").some((el) => el.startsWith("-"))
      ? TableOrderingSort.DESC
      : TableOrderingSort.ASC
    : TableOrderingSort.ASC;

  const [ordering, setOrdering] = useState<TableOrdering>({
    orderFields: fields,
    index: null,
    sort: sortOfFields,
  });

  useImperativeHandle(props.forwardRef, () => ({
    refetch() {
      refetch();
    },
    setQueryArgs(
      key: Exclude<keyof Q, "limit" | "offset"> | "page" | "ordering",
      value?: string | undefined
    ) {
      setQueryArg(key, value);
    },
    queryArgs,
  }));

  useEffect(() => {
    if (ordering.orderFields) {
      const column = props.tableData.columns.find(
        (el) => el.orderFields === ordering.orderFields
      );

      if (column) {
        const orderingFields = getFieldsString<T>(column, ordering);

        setQueryArg("ordering", orderingFields);
      }
    } else {
      const column = props.tableData.columns.find(
        (_, key) => key === ordering.index
      );

      if (column) {
        const orderingFields = getFieldsString<T>(column, ordering);

        setQueryArg("ordering", orderingFields);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordering, setPage, setQueryArg]);

  if (error) {
    return <Error>{error}</Error>;
  }

  const tableRows: TableRow<T>[] | undefined = data?.results.map((el) => {
    return {
      data: el,
      row: props.tableData.columns.map((row) => {
        return {
          rowCss: row.css,
          value: row.render(el),
        };
      }),
    };
  });

  const addOrdering = (
    _: React.MouseEvent<HTMLTableCellElement, MouseEvent>,
    stats: TableColumnStats
  ) => {
    setOrdering((prev) => {
      if (
        prev.index === stats.index ||
        prev.orderFields === stats.orderFields
      ) {
        return {
          orderFields: stats.orderFields,
          index: stats.index,
          sort:
            stats.sort === TableOrderingSort.ASC
              ? TableOrderingSort.DESC
              : TableOrderingSort.ASC,
        };
      }

      return {
        orderFields: stats.orderFields,
        index: stats.index,
        sort: TableOrderingSort.ASC,
      };
    });
  };

  return (
    <div ref={containerRef}>
      {props.title && <Headers.H2>{props.title}</Headers.H2>}

      {isMobile ? (
        <MobileTable<T>
          columns={props.tableData.columns}
          rows={tableRows}
          loading={isLoading}
          noDataMessage={props.noDataMessage}
          loadingMessage={props.loadingMessage}
          className={props.tableData.className}
        />
      ) : (
        <Table<T>
          columns={props.tableData.columns}
          rows={tableRows}
          loading={isLoading}
          noDataMessage={props.noDataMessage}
          loadingMessage={props.loadingMessage}
          className={props.tableData.className}
          onClickColumn={addOrdering}
          ordering={ordering}
        />
      )}

      <Row align="center" justify="center">
        <Pagination
          css={css`
            margin-top: 60px;
          `}
          arrows
          currentItemIndex={page}
          itemsCount={count}
          onChose={onChose}
          onNext={onNext}
          onPrev={onPrev}
        />
      </Row>
    </div>
  );
}

/*
  HOW DOES IT WORK?

  Table Handler Props
    tableData: {
      columns: [
        label: string;                        Label to display in the table header
        fields?: string;                      Allow ordering. Field name's to use as a key in ordering and statistics. Must be the same as the field name at backend
        format?: () => Node | string | null;  Function to format the value
        align?: "left" | "center" | "right";  Align the value
        css?:                                 CSS styles for <th>
        render: (row: User) => Node | string | null;  Value to display in the table. You can return any node you want
        css:                                          CSS styles for <td>
      ]
      className?: string;                               CSS styles for <Table />
    }
    getFunction:                              Function to get data from backend. The same usage as in usePagination or useNewFetch
    options: arguments of getFunction         The same usage as in usePagination
    title?: string;                           Title above table
    noDataMessage?: string;                   Message to display when there is no data
    loadingMessage?: string;                  Message to display when is loading

    Example:
      Here we have 3 fields. Two of them we want to be orderable.
      For the first one we want to order by 2 fields at backend. It's id and name. But sorting gonna be only by id.
      For the second one we use field name the same as on backend. It will automatically sort by name.
      The last one is not orderable.

      const tableHandlerColumns: TableHandlerColumn<User>[] = [
        {
          field: ["id"],
          label: "ID",
          render: (row) => <div>{row.id}</div>,
        },
        {
          label: "Name",
          fields: ["first_name", "last_name"],
          render: (row) => <div>{row.first_name} {row.last_name}</div>,
        },
        {
          label: "Is featured",
          render: (row) => <div>{user.is_admin ? <CheckIcon /> : <StopIcon />}</div>,
          css: css`
            padding: 10px 30px;
          `
        },
      ];

      JSX:
        <TableHandler
          tableData={{
            columns: tableHandlerColumns,
          }}
          getFunction={getUsers}
          title="Users"
          noDataMessage="No data yet"
          loadingMessage="Loading..."
        />
*/
