import React, { useState } from "react";
import { useStripe, useElements, IbanElement } from "@stripe/react-stripe-js";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { Address } from "../../types/Address";
import { Order } from "../../types/Order";
import { theme } from "../../themes/variables";
import { Headers } from "../../helpers/layout";
import { Button } from "../../components/Button";
import { CheckboxComponent } from "../../components/CheckboxComponent";
import { VerticalSeparator } from "../../components/Separator";
import { OrderReview } from "../../components/Payment/OrderReview";

import { ReactComponent as LeftIcon } from "../../assets/svg/Left.svg";
import { useResponsive } from "../../hooks/useResponsive";

export type FormValues = Address;

export function PurchaseDirectDebit(props: {
  clientSecret: string;
  order: Order & {
    billing_address: Address;
  };
}) {
  const { t } = useTranslation();

  const { isMobile } = useResponsive();

  const history = useHistory();

  const stripe = useStripe();

  const elements = useElements();

  const [isLoading, setIsLoading] = useState(false);

  const [checked, setChecked] = useState(false);

  const [message, setMessage] = useState<string | null>(null);

  const onSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const iban = elements.getElement(IbanElement);

    setIsLoading(true);

    if (iban) {
      const { error } = await stripe.confirmSepaDebitPayment(
        props.clientSecret,
        {
          payment_method: {
            sepa_debit: iban,
            billing_details: {
              address: {
                country: props.order.billing_address.country,
                city: props.order.billing_address.city,
                postal_code: props.order.billing_address.postal_code,
                state: props.order.billing_address.state,
                line1: props.order.billing_address.street_address,
              },
              email: props.order.billing_email,
              name: `${props.order.billing_address.first_name} ${props.order.billing_address.last_name}`,
              phone: props.order.billing_address.phone_number,
            },
          },
        }
      );

      if (error) {
        setMessage(error.message || t("payment.status.something-wrong"));
      } else {
        history.push(
          `/order/${props.order.uuid}/payment-status?payment_intent_client_secret=${props.clientSecret}`
        );
      }
    }

    setIsLoading(false);
  };

  const IBAN_STYLE = {
    base: {
      color: theme.colors.dark,
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
      ":-webkit-autofill": {
        color: "#32325d",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
      ":-webkit-autofill": {
        color: "#fa755a",
      },
    },
  };

  const IBAN_ELEMENT_OPTIONS = {
    supportedCountries: ["SEPA"],
    placeholderCountry: "DE",
    style: IBAN_STYLE,
  };

  return (
    <div
      css={css`
        display: flex;
        flex-direction: ${isMobile ? "column" : "row"};
        justify-content: space-between;
        position: relative;
      `}
    >
      <div
        css={css`
          flex: 0 0 calc(50% - 100px);
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            margin-bottom: ${isMobile ? "60px" : "0"};
          `}
        >
          <div>
            <Headers.H4
              css={css`
                font-size: 20px;
                line-height: 30px;
                margin-bottom: 32px;
              `}
            >
              {t("payment.iban-info")}:
            </Headers.H4>

            <div
              css={css`
                border: 1px solid ${theme.colors.gray4};
                border-radius: 20px;
                padding: 6px 20px;
              `}
            >
              <IbanElement id="payment-iban" options={IBAN_ELEMENT_OPTIONS} />
            </div>

            {message && (
              <div
                id="payment-message"
                css={css`
                  margin-top: 16px;
                `}
              >
                {message}
              </div>
            )}

            <CheckboxComponent
              css={css`
                margin-top: 25px;
              `}
              label={t("payment.iban-agree")}
              value={checked}
              onChange={(e) => setChecked(e.target.checked)}
            />
          </div>

          {!isMobile && (
            <Link
              to={`/order/${props.order.uuid}/billing-information`}
              css={css`
                margin-top: 30px;
              `}
            >
              <Button
                background={theme.colors.transparent}
                color={theme.colors.dark}
                icon={
                  <LeftIcon
                    width={20}
                    height={20}
                    color={theme.colors.primary}
                    css={css`
                      transform: rotate(180deg);
                    `}
                  />
                }
                css={css`
                  padding: 0px;
                `}
                hoverStyles={`opacity: 0.85;`}
              >
                {t("payment.back-to-billing-information")}
              </Button>
            </Link>
          )}
        </div>
      </div>

      {!isMobile && <VerticalSeparator />}

      {props.order.items.length > 0 && (
        <OrderReview
          values={props.order}
          courseId={props.order.items[0].course.uuid}
          isSubmitting={isLoading}
        >
          <Button
            css={css`
              margin-top: ${isMobile ? "30px" : "60px"};
            `}
            background={theme.colors.dark}
            color={theme.colors.white}
            type="submit"
            isSubmitting={isLoading}
            disabled={isLoading || !stripe || !elements || !checked}
            icon={
              <LeftIcon width={14} height={14} color={theme.colors.primary} />
            }
            iconRight
            onClick={(e) => onSubmit(e)}
            hoverStyles={`color: ${theme.colors.white}; background: ${theme.colors.primary};`}
          >
            {t("payment.place-order")}
          </Button>
        </OrderReview>
      )}
      {isMobile && (
        <Link
          to={`/order/${props.order.uuid}/billing-information`}
          css={css`
            margin-top: 30px;
          `}
        >
          <Button
            background={theme.colors.transparent}
            color={theme.colors.dark}
            icon={
              <LeftIcon
                width={20}
                height={20}
                color={theme.colors.primary}
                css={css`
                  transform: rotate(180deg);
                `}
              />
            }
            css={css`
              padding: 0px;
            `}
            hoverStyles={`opacity: 0.85;`}
          >
            {t("payment.back-to-billing-information")}
          </Button>
        </Link>
      )}
    </div>
  );
}
