import { authorised } from "../../request";
import { LessonFeedback } from "../../types/Lesson";

type Args = {
  enrolmentId: string;
  lessonId: string;
  data: LessonFeedback;
};

export async function postLessonFeedback({
  enrolmentId,
  lessonId,
  data,
}: Args) {
  const API = authorised();

  return API.request({
    method: "POST",
    url: `/api/campus/course-enrolments/${enrolmentId}/lessons/${lessonId}/feedback/`,
    data,
  });
}
