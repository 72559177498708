import React from "react";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router-dom";
import { css } from "styled-components/macro";
import { toast } from "react-toastify";
import { Formik, Form, FormikHelpers } from "formik";
import * as Yup from "yup";
import { useEnterprise } from "../../contexts/EnterpriseContext";
import { Order, OrderPaymentOptions } from "../../types/Order";
import { theme } from "../../themes/variables";
import { serverErrorHandler } from "../../helpers/serverErrorHandler";
import { Headers, Text } from "../../helpers/layout";
import { Steps, Step } from "../../components/Steps";
import { Header } from "../../components/Header";
import { Wrapper } from "../../components/Wrapper";
import { SEO } from "../../components/SEO";
import { Card } from "../../components/Card";
import { Button } from "../../components/Button";
import { VerticalSeparator } from "../../components/Separator";
import { OrderReview } from "../../components/Payment/OrderReview";
import { RadioField } from "../../forms/fields/RadioField";
import { createOrder, updateOrderPaymentType } from "../../actions/order";

// import PaymentPaypal from "../../assets/img/Payment-Paypal.png";
import PaymentAmerican from "../../assets/img/Payment-American.png";
import PaymentMaestro from "../../assets/img/Payment-Maestro.png";
import PaymentVisa from "../../assets/img/Payment-Visa.png";

import { ReactComponent as LeftIcon } from "../../assets/svg/Left.svg";
import { useResponsive } from "../../hooks/useResponsive";

export type FormValues = Pick<Order, "payment_type">;

export function PaymentMethod(props: {
  payment_type?: OrderPaymentOptions;
  courseId?: string;
}) {
  const params = useParams<{
    courseId?: string;
    orderId?: string;
  }>();

  const { enterprise } = useEnterprise();
  const { isMobile } = useResponsive();

  const { t } = useTranslation();

  const history = useHistory();

  const stepsData: Step[] = [
    {
      title: t("payment.payment-method"),
      link: `/course/${params.courseId}/purchase`,
      current: true,
    },
    {
      title: t("payment.billing-information"),
    },
    {
      title: t("payment.purchase"),
    },
    {
      title: t("payment.start-learning"),
    },
  ];

  const validationSchema = Yup.object().shape({
    payment_type: Yup.string().label(t("payment.select-payment")).required(),
  });

  const paymentMethodOptions: {
    label: React.ReactNode | string | number;
    value: OrderPaymentOptions;
  }[] = [];

  if (enterprise.stripe_public_api_key) {
    paymentMethodOptions.push({
      label: (
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          {t("payment.credit-card")}

          <img
            src={PaymentVisa}
            alt="Visa mastercard"
            width={80}
            height={20}
            css={css`
              margin-left: 8px;
            `}
          />
          <img
            src={PaymentMaestro}
            alt="Maestro"
            width={27}
            height={16}
            css={css`
              margin-left: 8px;
            `}
          />
          <img
            src={PaymentAmerican}
            alt="American express"
            width={58}
            height={16}
            css={css`
              margin-left: 8px;
            `}
          />
        </div>
      ),
      value: OrderPaymentOptions.CREDIT_CARD,
    });

    paymentMethodOptions.push({
      label: t("payment.direct-debit"),
      value: OrderPaymentOptions.DIRECT_DEBIT,
    });
  }

  const onSubmit = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    try {
      setSubmitting(true);
      if (params.courseId) {
        const order = await createOrder(params.courseId, {
          ...values,
          courses: [params.courseId],
        });

        history.push(`/order/${order.data.uuid}/billing-information`);
      } else if (params.orderId) {
        await updateOrderPaymentType(params.orderId, {
          payment_type: values.payment_type,
        });

        history.push(`/order/${params.orderId}/billing-information`);
      }
    } catch (error: any) {
      toast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    } finally {
      setSubmitting(false);
    }
  };

  const courseId = params.courseId || props.courseId;

  return (
    <Wrapper>
      <SEO>
        <title>{t("payment.payment-method")}</title>
      </SEO>
      <Header />

      <Steps steps={stepsData} />

      <Card>
        <Formik<FormValues>
          enableReinitialize
          initialValues={{
            payment_type: props.payment_type || OrderPaymentOptions.CREDIT_CARD,
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, isValid, values }) => {
            return (
              <Form noValidate>
                <div
                  css={css`
                    display: flex;
                    flex-direction: ${isMobile ? "column" : "row"};
                    justify-content: space-between;
                    position: relative;
                  `}
                >
                  <div
                    css={css`
                      flex: 0 0 calc(50% - 100px);
                    `}
                  >
                    <Headers.H4
                      css={css`
                        font-size: 20px;
                        line-height: 30px;
                      `}
                    >
                      {t("payment.select-payment")}:
                    </Headers.H4>

                    <Text.Notifications
                      css={css`
                        margin-bottom: 40px;
                      `}
                    >
                      * {t("payment.all-payments-free")}
                    </Text.Notifications>

                    <div
                      css={css`
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        > label {
                          margin-bottom: 30px;
                          :last-child {
                            margin-bottom: 0;
                          }
                        }
                      `}
                    >
                      {paymentMethodOptions.map(({ label, value }, index) => (
                        <RadioField
                          labelRight
                          name={`payment_type`}
                          label={label}
                          value={value}
                          notSelectedTransparent
                          key={index}
                        ></RadioField>
                      ))}
                    </div>
                  </div>

                  {!isMobile && (
                    <VerticalSeparator
                      css={css`
                        left: calc(50% + 50px);
                      `}
                    />
                  )}

                  {courseId && !isMobile && (
                    <OrderReview
                      values={values}
                      courseId={courseId}
                      isSubmitting={isSubmitting}
                      isValid={isValid}
                    >
                      <Button
                        css={css`
                          margin-top: 60px;
                        `}
                        background={theme.colors.dark}
                        color={theme.colors.white}
                        type="submit"
                        isSubmitting={isSubmitting}
                        disabled={!isValid || isSubmitting}
                        icon={
                          <LeftIcon
                            width={14}
                            height={14}
                            color={theme.colors.primary}
                          />
                        }
                        iconRight
                        hoverStyles={`color: ${theme.colors.white}; background: ${theme.colors.primary};`}
                      >
                        {t("payment.proceed-billing")}
                      </Button>
                    </OrderReview>
                  )}
                  {isMobile && (
                    <Button
                      css={css`
                        margin-top: 60px;
                      `}
                      background={theme.colors.dark}
                      color={theme.colors.white}
                      type="submit"
                      isSubmitting={isSubmitting}
                      disabled={!isValid || isSubmitting}
                      icon={
                        <LeftIcon
                          width={14}
                          height={14}
                          color={theme.colors.primary}
                        />
                      }
                      iconRight
                      hoverStyles={`color: ${theme.colors.white}; background: ${theme.colors.primary};`}
                    >
                      {t("payment.proceed-billing")}
                    </Button>
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>
      </Card>
    </Wrapper>
  );
}
