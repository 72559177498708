import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { Block } from "../../components/Block";
import { Header } from "../../components/Header";
import { Wrapper } from "../../components/Wrapper";
import { SEO } from "../../components/SEO";
import { theme } from "../../themes/variables";
import { useResponsive } from "../../hooks/useResponsive";

export function VerificationInfoPage(props: {}) {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();

  return (
    <Wrapper>
      <SEO>
        <title>{t("verification-info.thank-you")}</title>
      </SEO>
      <Header />
      <Block
        css={css`
          background-color: ${theme.colors.white};
          padding: ${isMobile ? "20px" : "0"};
        `}
      >
        <div
          css={css`
            width: ${isMobile ? "100%" : "370px"};
            margin: 0 auto;
            padding: 160px 0;
          `}
        >
          <div
            css={css`
              text-align: center;
              margin-bottom: 2rem;
              font-size: 1.2rem;
              font-weight: bold;
            `}
          >
            {t("verification-info.thank-you")}
          </div>

          <div
            css={css`
              margin-top: 2rem;
              text-align: center;
            `}
          >
            {t("verification-info.being-verified")}
          </div>
          <div
            css={css`
              text-align: center;
            `}
          >
            {t("verification-info.email")}
          </div>
        </div>
      </Block>
    </Wrapper>
  );
}
