import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import styled, { css } from "styled-components/macro";
import { postCheatSheet } from "../actions/course/postCheatSheet";
import { serverErrorHandler } from "../helpers/serverErrorHandler";
import { CheatsSheet } from "../types/CheatsSheets";
import { theme } from "../themes/variables";
import { TextRenderer } from "./TextRenderer";
import { Button } from "./Button";
import { ModalFooter } from "./Modal/ModalFooter";
import { ModalBody } from "./Modal/ModalBody";
import { Modal } from "./Modal/Modal";
import { ModalHeader } from "./Modal/ModalHeader";
import Cheatsheet from "./../assets/img/Cheatsheet.png";
import CheckMark from "./../assets/img/CheckMark.png";
import { useResponsive } from "../hooks/useResponsive";

const CheckboxButton = styled.input`
  appearance: none;
  border: 1px solid ${theme.colors.gray4};
  border-radius: 4px;
  cursor: pointer;
  width: 24px;
  height: 24px;
  &:after {
    display: none;
    color: white;
    font-size: 100%;
  }
  &:checked {
    background-image: url(${CheckMark});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 15px;

    &:after {
      display: block;
    }
  }
`;

function ColumnItems(props: {
  items: CheatsSheet[];
  selectedItems: CheatsSheet[];
  select: (item: CheatsSheet) => void;
}) {
  const { isMobile } = useResponsive();

  return (
    <div
      css={css`
        flex-wrap: wrap;
        flex-direction: column;
        width: 49%;

        ${isMobile &&
        css`
          width: 100%;
        `}
      `}
    >
      {props.items &&
        props.items.map((cheat, key) => (
          <div
            onClick={() => props.select(cheat)}
            css={css`
              display: flex;
              padding: 24px 16px;
              margin-bottom: 12px;
              cursor: pointer;
              border-radius: 20px;
              font-size: 18px;
              background-color: ${theme.colors.gray1};
            `}
            key={key}
          >
            <div
              css={css`
                width: 30px;
              `}
            >
              <CheckboxButton
                name={cheat.name}
                type="checkbox"
                checked={props.selectedItems.includes(cheat)}
                onChange={() => {}}
              />
            </div>
            <div
              css={css`
                margin-left: 16px;
                display: flex;
                flex-direction: column;
                overflow: auto;
              `}
            >
              <div
                css={css`
                  margin-bottom: 10px;
                  overflow-wrap: break-word;
                `}
              >
                {cheat.name}
              </div>
              <div
                css={css`
                  overflow-wrap: break-word;
                `}
              >
                <TextRenderer text={cheat.text_content} withoutClassName html />
              </div>
            </div>
          </div>
        ))}
    </div>
  );
}

export default function CheatsSheetsModal(props: {
  cheatsSheets: CheatsSheet[];
  isActive: boolean;
  onClose: () => void;
  onComplete: () => void;
}) {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();
  const middleItem = Math.round(props.cheatsSheets.length / 2);
  const leftItems = props.cheatsSheets.slice(0, middleItem);
  const rightItems = props.cheatsSheets.slice(middleItem);

  const [selectedCheats, setSelectedCheats] = useState<CheatsSheet[]>([]);

  const selectItems = (item: CheatsSheet) => {
    if (!selectedCheats.includes(item)) {
      setSelectedCheats([...selectedCheats, item]);
    } else {
      setSelectedCheats(
        selectedCheats.filter((value) => value.uuid !== item.uuid)
      );
    }
  };

  const saveCheats = async (cheats: CheatsSheet[]) => {
    try {
      await postCheatSheet({
        data: { items: cheats.map((item) => item.uuid) },
      });
      toast.success(t("status.success"));
      props.onComplete();
    } catch (error: any) {
      toast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    }
  };

  return (
    <Modal modalIsOpen={props.isActive} onClose={() => props.onClose()}>
      <ModalHeader
        closeIcon
        onClose={() => props.onClose()}
        css={css`
          justify-content: flex-end;
        `}
      ></ModalHeader>
      <ModalBody>
        <div
          css={css`
            position: relative;

            ${isMobile &&
            css`
              margin-top: 10px;
            `}
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              width: 100%;

              ${isMobile &&
              css`
                flex-flow: row wrap;
                align-items: center;
              `}
            `}
          >
            <img
              src={Cheatsheet}
              alt={t("cheats_sheet.title")}
              css={css`
                width: 60px;
                margin: 0 auto;

                ${isMobile &&
                css`
                  width: 30px;
                  margin: 0 20px 0 0;
                `}
              `}
            />
            <div
              css={css`
                margin: 20px auto 0;
                font-weight: 600;
                line-height: 1.4;

                ${isMobile &&
                css`
                  margin: 0;
                `}
              `}
            >
              {t("cheats_sheet.title")}
            </div>
          </div>
          <div
            onClick={() => setSelectedCheats(props.cheatsSheets)}
            css={css`
              position: absolute;
              display: flex;
              right: 24px;
              align-items: center;
              cursor: pointer;
              height: 25px;

              ${isMobile &&
              css`
                top: 0;
                right: 0;
              `}
            `}
          >
            {!isMobile && (
              <div
                css={css`
                  font-size: 18px;
                  margin-right: 10px;
                  color: ${theme.colors.primary};
                `}
              >
                {t("cheats_sheet.select")}
              </div>
            )}
            <img
              src={CheckMark}
              alt={"CheckMark"}
              css={css`
                width: 12px;
                height: 12px;
              `}
            />
          </div>
        </div>
        <div
          css={css`
            margin-top: 30px;
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            width: 100%;
            max-height: 400px;
            overflow: auto;

            ::-webkit-scrollbar {
              width: 20px;
            }

            ::-webkit-scrollbar-thumb {
              background-color: ${theme.colors.primary};
              border-radius: 20px;
              border: 6px solid transparent;
              background-clip: content-box;
            }
            ::-webkit-scrollbar-track {
              background-color: ${theme.colors.gray3};
              border: 9px solid transparent;
              background-clip: content-box;
            }
          `}
        >
          <ColumnItems
            items={leftItems}
            selectedItems={selectedCheats}
            select={(item: CheatsSheet) => selectItems(item)}
          />

          <ColumnItems
            items={rightItems}
            selectedItems={selectedCheats}
            select={(item: CheatsSheet) => selectItems(item)}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <div
          css={css`
            display: flex;
            position: relative;
            margin: 32px 0;
            width: 100%;
            align-items: center;
            justify-content: center;
          `}
        >
          <Button
            flat
            css={css`
              margin-right: 30px;
              text-transform: uppercase;
            `}
            background="transparent"
            hoverStyles="background-color: transparent;"
            color={theme.colors.black}
            onClick={() => props.onComplete()}
          >
            {t("cheats_sheet.skip")}
          </Button>
          <Button
            css={css`
              color: ${theme.colors.white};
            `}
            disabled={!selectedCheats.length}
            onClick={() => saveCheats(selectedCheats)}
          >
            {t("cheats_sheet.save")}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
}
