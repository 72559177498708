import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { getFaqList } from "../actions/faq";
import { useFetch } from "../actions/useAPI";
import { FAQColumns } from "../components/FAQColumns";
import { Header } from "../components/Header";
import { Wrapper } from "../components/Wrapper";
import { HeaderText } from "../components/HeaderText";
import { SEO } from "../components/SEO";
import { PageQueryHandler } from "./PageQueryHandler";
import { useResponsive } from "../hooks/useResponsive";

export function FaqPage(props: {}) {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();

  const {
    data: faqItems,
    error: faqItemsError,
    isLoading: faqItemsIsLoading,
  } = useFetch(getFaqList);

  if (!faqItems || faqItemsError || faqItemsIsLoading) {
    return (
      <PageQueryHandler error={faqItemsError} isLoading={faqItemsIsLoading} />
    );
  }

  return (
    <Wrapper>
      <SEO>
        <title>{t("course.faq")}</title>
      </SEO>
      <Header />
      <div
        css={css`
          display: flex;
          flex-direction: column;
          margin-bottom: 100px;

          ${isMobile &&
          css`
            margin-bottom: 20px;
          `}
        `}
      >
        <div
          css={css`
            margin: 20px 0;
          `}
        >
          <HeaderText title={t("course.our")} text={t("course.faq")} />
        </div>

        <FAQColumns items={faqItems} />
      </div>
    </Wrapper>
  );
}
