import { buildQueryString } from "../../helpers/buildQueryString";
import { authorised } from "../../request";
import { CourseListItem } from "../../types/Course";

type Args = {
  limit?: number;
  offset?: number;
  status?: string;
};

export async function getMyCourses(args: Args) {
  const API = authorised();

  return API.request<{
    count: number;
    next: string | null;
    previous: string | null;
    results: CourseListItem[];
  }>({
    method: "GET",
    url: `/api/campus/courses/?${buildQueryString(args)}`,
  });
}
