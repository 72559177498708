import defaultAvatar from "../assets/img/Default_Avatar.png";
import { css } from "styled-components/macro";

export function Avatar(props: { path?: string; alt?: string }) {
  return (
    <div
      css={css`
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 100%;
        border-radius: 50%;
        overflow: hidden;
      `}
    >
      <img
        css={css`
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          top: 0;
          left: 0;
        `}
        src={props.path ? props.path : defaultAvatar}
        alt={props.alt}
      />
    </div>
  );
}
