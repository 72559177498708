import { Address } from "./Address";
import { OrderItem } from "./OrderItem";

export enum OrderPaymentOptions {
  PAYPAL = "PAYPAL",
  CREDIT_CARD = "CREDIT_CARD",
  DIRECT_DEBIT = "DIRECT_DEBIT",
  BANK_TRANSFER = "BANK_TRANSFER",
}

export enum OrderStatus {
  PENDING_PAYMENT = "PENDING_PAYMENT",
  COMPLETED = "COMPLETED",
  REFUNDED = "REFUNDED",
  CANCELED = "CANCELED",
  PAYMENT_FAILED = "PAYMENT_FAILED",
  DRAFT = "DRAFT",
  PROCESSING = "PROCESSING",
}

export interface Order {
  uuid: string;
  total: string;
  net_price: string | null;
  vat_price: string | null;
  items: OrderItem[];
  billing_email: string;
  billing_address: Address | null;
  invoice_link: string;
  first_course_uuid: string;
  payment_type: OrderPaymentOptions;
  stripe_payment_intent_id: string | null;
  status: OrderStatus;
}

export type MyOrder = Pick<Order, "uuid" | "items" | "status" | "total"> & {
  payment_date: string;
  invoice_number: string;
};
