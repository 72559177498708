import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Address } from "../../types/Address";
import { OrderPaymentOptions, Order } from "../../types/Order";
import { Steps, Step } from "../../components/Steps";
import { Header } from "../../components/Header";
import { Wrapper } from "../../components/Wrapper";
import { SEO } from "../../components/SEO";
import { Card } from "../../components/Card";
import { PurchaseCreditCard } from "../../components/Payment/PurchaseCreditCard";
import { PurchaseDirectDebit } from "../../components/Payment/PurchaseDirectDebit";
import { StripeElements } from "../../components/Payment/StripeElements";

import { useFetch } from "../../actions/useAPI";
import { getOrder, createStripePaymentIntent } from "../../actions/order";
import { PageQueryHandler } from "../PageQueryHandler";

export type FormValues = Address;

export function PurchasePage(props: {}) {
  const params = useParams<{
    orderId: string;
  }>();

  const { t } = useTranslation();

  const {
    data: order,
    error: orderError,
    isLoading: orderIsLoading,
  } = useFetch(getOrder, {
    orderId: params.orderId,
  });

  const [stripeClientSecret, setStripeClientSecret] = useState("");

  useEffect(() => {
    const getClientSecret = async () => {
      const {
        data: { clientSecret },
      } = await createStripePaymentIntent(params.orderId);

      setStripeClientSecret(clientSecret);
    };

    getClientSecret();
  }, [params.orderId]);

  if (!order || orderError || orderIsLoading) {
    return <PageQueryHandler error={orderError} isLoading={orderIsLoading} />;
  }

  const stepsData: Step[] = [
    {
      title: t("payment.payment-method"),
      link: `/order/${params.orderId}/payment-method`,
      complete: true,
    },
    {
      title: t("payment.billing-information"),
      link: `/order/${params.orderId}/billing-information`,
      complete: true,
    },
    {
      title: t("payment.purchase"),
      current: true,
    },
    {
      title: t("payment.start-learning"),
    },
  ];

  return (
    <Wrapper>
      <SEO>
        <title>{t("payment.purchase")}</title>
      </SEO>
      <Header />

      <Steps steps={stepsData} />

      <Card>
        {stripeClientSecret && (
          <StripeElements clientSecret={stripeClientSecret}>
            {order.payment_type === OrderPaymentOptions.CREDIT_CARD && (
              <PurchaseCreditCard
                clientSecret={stripeClientSecret}
                order={
                  order as Order & {
                    billing_address: Address;
                  }
                }
              />
            )}

            {order.payment_type === OrderPaymentOptions.DIRECT_DEBIT && (
              <PurchaseDirectDebit
                clientSecret={stripeClientSecret}
                order={
                  order as Order & {
                    billing_address: Address;
                  }
                }
              />
            )}
          </StripeElements>
        )}
      </Card>
    </Wrapper>
  );
}
