import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { useResponsive } from "../../hooks/useResponsive";
import { theme } from "../../themes/variables";
import { Journey } from "../../types/Journey";
import { HeaderText } from "../HeaderText";

export function JourneyAbout(props: { journey: Journey }) {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();

  return (
    <div
      css={css`
        display: flex;
        flex-direction: ${isMobile ? "column" : "row"};
        margin-bottom: 50px;
      `}
    >
      <HeaderText title={t("course.about")} text={t("course.the_course")} />
      <div
        css={css`
          width: ${isMobile ? "auto" : " 100%"};
          margin-top: 10px;
          font-size: 1.125rem;
          background-color: ${theme.colors.white};
          border-radius: 40px;
          padding: 30px;
        `}
      >
        {props.journey.description}
      </div>
    </div>
  );
}
