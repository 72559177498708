export function buildQueryString<T>(args: T) {
  const query = [];

  for (let [key, value] of Object.entries(args)) {
    if (value || value === 0) {
      query.push(`${key}=${value}`);
    }
  }

  return query.join("&");
}
