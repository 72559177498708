import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { CheckSquare } from "react-feather";
import { theme } from "../themes/variables";
import { CourseListItem } from "../types/Course";
import { Headers, Text } from "../helpers/layout";
import { humanize } from "../helpers/humanize";
import { Button } from "./Button";
import { ReactComponent as StudySvg } from "../assets/svg/Study.svg";
import { ReactComponent as StopwatchSvg } from "../assets/svg/Stopwatch.svg";
import { ReactComponent as LeftSvg } from "../assets/svg/LeftOrange.svg";
import { ReactComponent as EllipseSvg } from "../assets/svg/Ellipse.svg";
import { ReactComponent as ReadSvg } from "../assets/svg/Read.svg";
import { ReactComponent as CourseSummarySvg } from "../assets/svg/SummaryLine.svg";
import { ReactComponent as PlaySvg } from "../assets/svg/Play.svg";
import { useResponsive } from "../hooks/useResponsive";

function Label({ text, color }: { text: string; color: string }) {
  return (
    <Text.CTA
      css={css`
        padding: 4px 16px;
        width: fit-content;
        white-space: nowrap;
        background-color: ${color};
        color: white;
        border-radius: 20px;
      `}
    >
      {text}
    </Text.CTA>
  );
}

export function ProgressBar({
  progress,
  isExamNotPassed,
  fitContent,
}: {
  progress: number;
  isExamNotPassed: boolean;
  fitContent?: boolean;
}) {
  const { isMobile } = useResponsive();

  return (
    <div
      css={css`
        width: ${isMobile || fitContent ? "100%" : "65%"};
        min-width: 0;
        height: 8px;
        border-radius: 20px;
        background-color: ${theme.colors.gray1};
        margin-bottom: ${isMobile ? "20px" : "0"};
      `}
    >
      <div
        css={css`
          position: relative;
          height: 100%;
          width: ${progress + "%"};
          background-color: ${isExamNotPassed
            ? theme.colors.warning
            : progress === 100 && !isExamNotPassed
            ? theme.colors.success
            : theme.colors.black};
          border-radius: inherit;
        `}
      >
        <div
          css={css`
            position: absolute;
            right: -5px;
            top: ${progress === 100 ? "-6px" : "-8px"};
            left: ${progress === 0 ? "0" : "auto"};
            width: fit-content;
          `}
        >
          <EllipseSvg
            width={progress === 100 ? 20 : 16}
            height={progress === 100 ? 20 : 16}
            color={
              isExamNotPassed
                ? theme.colors.warning
                : progress === 100 && !isExamNotPassed
                ? theme.colors.success
                : theme.colors.blueBackground
            }
          />
        </div>
        {progress === 100 && (
          <div
            css={css`
              position: absolute;
              right: -10px;
              top: -16px;
              width: fit-content;
              height: 20px;
              background-color: white;
            `}
          >
            <ReadSvg
              color={
                isExamNotPassed
                  ? theme.colors.warning
                  : progress === 100 && !isExamNotPassed
                  ? theme.colors.success
                  : theme.colors.blueBackground
              }
              width={16}
              height={16}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default function CourseCard({
  item,
  className,
  hasBorders,
}: {
  item: CourseListItem;
  className?: string;
  hasBorders?: boolean;
}) {
  const history = useHistory();
  const { t } = useTranslation();
  const { isMobile } = useResponsive();

  const timeTotal = humanize(Number(item.duration) * 1000);
  const timeLeft = humanize(Number(item.time_left) * 1000);

  const isExamNotPassed =
    item.enrolment_id !== null &&
    item.has_exam_tests &&
    !item.is_exam_passed &&
    item.completion_percentage === 100;

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        border: 1px solid
          ${hasBorders ? theme.colors.gray2 : theme.colors.white};
        border-radius: 20px;
        background-color: ${theme.colors.white};
        max-height: fit-content;
        padding: ${isMobile ? "29px 20px 34px 20px" : "35px 40px"};
      `}
      className={className}
    >
      <div
        css={css`
          display: flex;
          width: 100%;
          flex-flow: row wrap;
          max-height: fit-content;
          align-items: flex-start;
          justify-content: space-between;
          min-height: 60px;
          margin-bottom: ${item.logo ||
          item.is_new ||
          item.is_completed ||
          item.time_left > 0
            ? "30px;"
            : "0;"};
        `}
      >
        {item.logo && (
          <img
            src={item.logo.image}
            css={css`
              flex: 0 0 auto;
              max-height: 60px;
              max-width: 180px;
              margin: 0 auto 15px 0;
              margin-right: 20px;
            `}
            alt={item.name}
          />
        )}
        <div
          css={css`
            flex: 0 0 auto;
            height: fit-content;
            margin-bottom: 15px;
          `}
        >
          {item.is_new && !item.enrolment_id ? (
            <Label text={t("course.new")} color={theme.colors.lightRedMain} />
          ) : item.enrolment_id && !item.is_completed ? (
            <Button
              flat
              css={css`
                color: ${theme.colors.black};
                background-color: transparent;
                font-weight: normal;
                text-align: left;

                ${isMobile &&
                css`
                  width: 130px;
                `}
              `}
              hoverStyles={`background:transparent`}
              icon={<StopwatchSvg color={theme.colors.black} />}
            >
              {timeLeft} {t("course.left")}
            </Button>
          ) : isExamNotPassed ? (
            <Label
              text={t("course.exam_not_passed")}
              color={theme.colors.warning}
            />
          ) : item.is_completed ? (
            <Label
              text={t("course.completed_all")}
              color={theme.colors.success}
            />
          ) : (
            ""
          )}
        </div>
      </div>
      {item.journey && (
        <Text.Notifications
          css={css`
            color: ${theme.colors.gray6};
            cursor: pointer;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            :hover {
              color: ${theme.colors.black};
            }
          `}
          onClick={() => history.push(`/journey/${item.journey!.uuid}`)}
        >
          {item.journey.name}
        </Text.Notifications>
      )}
      <Headers.H3
        css={css`
          cursor: pointer;
          margin-bottom: 0;
        `}
        onClick={() => {
          history.push("/course/" + item.uuid);
        }}
      >
        {item.name}
      </Headers.H3>
      {!item.enrolment_id && (
        <div
          css={css`
            display: flex;
            flex-direction: ${isMobile ? "column" : "row"};
          `}
        >
          <div
            css={css`
              display: inline-flex;
              margin-top: 5px;
              font-size: 16px;
              line-height: 22px;
              white-space: nowrap;
            `}
          >
            <StudySvg
              width={20}
              height={20}
              color={isMobile ? theme.colors.dark : theme.colors.primary}
            />
            <Text.Notifications
              css={css`
                margin: 2px 25px 0 10px;
              `}
            >
              {item.lessons.length + " " + t("course.lessons")}
            </Text.Notifications>
            <StopwatchSvg
              width={20}
              height={20}
              color={isMobile ? theme.colors.dark : theme.colors.primary}
            />
            <Text.Notifications
              css={css`
                margin: 2px 25px 0 10px;
              `}
            >
              {timeTotal}
            </Text.Notifications>
          </div>

          <Text.CTA
            css={css`
              color: ${theme.colors.blueBackground};
              margin-top: ${isMobile ? "10px" : "9px"};
            `}
          >
            {t(`course.level.${item.level}`)}
          </Text.CTA>
        </div>
      )}
      <Text.Notifications
        css={css`
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          margin-top: 20px;
          margin-bottom: 35px;
          overflow: hidden;
        `}
      >
        {item.description}
      </Text.Notifications>
      {!item.enrolment_id ? (
        <div
          css={css`
            display: flex;
            margin-top: auto;
          `}
        >
          <Button
            onClick={() => {
              history.push("/course/" + item.uuid);
            }}
            size="small"
            icon={<LeftSvg />}
            iconRight
            css={css`
              background-color: ${theme.colors.white};
              color: ${theme.colors.black};
              border: 1px solid ${theme.colors.black};

              :hover {
                background-color: ${theme.colors.primary};
                color: ${theme.colors.white};
                border: 1px solid ${theme.colors.primary};
              }
            `}
          >
            {t("course.start_learning")}
          </Button>
        </div>
      ) : (
        <div
          css={css`
            display: flex;
            flex-direction: ${isMobile ? "column" : "row"};
            align-items: ${isMobile ? "left" : "center"};
            width: 100%;
            margin: auto 0 0 0;
            justify-content: space-between;
          `}
        >
          <ProgressBar
            progress={item.completion_percentage}
            isExamNotPassed={isExamNotPassed}
          />

          {isExamNotPassed && (
            <Button
              css={css`
                background-color: transparent;
                color: ${theme.colors.black};
                width: fit-content;
                margin-left: 20px;
              `}
              hoverStyles={`background: transparent`}
              flat
              iconRight
              icon={<CheckSquare size={20} color={theme.colors.primary} />}
              onClick={() =>
                history.push(`/course-enrolments/${item.enrolment_id}/exam/`)
              }
            >
              {t("course.take_exam")}
            </Button>
          )}
          {!isExamNotPassed &&
            item.completion_percentage === 100 &&
            item.has_summary && (
              <Button
                css={css`
                  background-color: transparent;
                  color: ${theme.colors.black};
                  width: fit-content;
                  margin-left: 20px;
                `}
                hoverStyles={`background: transparent`}
                flat
                iconRight
                icon={
                  <CourseSummarySvg
                    width={20}
                    height={20}
                    color={theme.colors.primary}
                  />
                }
                onClick={() => history.push(`/course/${item.uuid}/summary/`)}
              >
                {t("course.summary")}
              </Button>
            )}
          {item.enrolment_id && item.completion_percentage !== 100 && (
            <Button
              css={css`
                background-color: transparent;
                color: ${theme.colors.black};
                width: fit-content;
                margin-left: ${isMobile ? "0" : "20px"};
              `}
              hoverStyles={`background: transparent`}
              flat
              iconRight
              icon={
                <PlaySvg width={20} height={20} color={theme.colors.primary} />
              }
              onClick={() => history.push(`/course/${item.uuid}/`)}
            >
              {t("course.continue")}
            </Button>
          )}
        </div>
      )}
    </div>
  );
}
