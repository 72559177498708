import React, { useState } from "react";
import { css } from "styled-components/macro";
import { theme } from "../themes/variables";
import { Announcement } from "../types/Announcements";
import { Headers } from "../helpers/layout";
import { ReactComponent as RightSvg } from "../assets/svg/Right.svg";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { isExternalLink } from "../helpers/router";
import { useResponsive } from "../hooks/useResponsive";
import { Text } from "../helpers/layout";

function AnnouncementItem({
  announcement,
}: {
  announcement: Announcement;
  isMobile?: boolean;
}) {
  const [isHover, setIsHover] = useState(false);
  const { isMobile } = useResponsive();

  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-right: 20px;
        margin-bottom: 16px;
        border-bottom: 1px solid ${theme.colors.gray2};
        cursor: ${announcement.url ? "pointer" : "default"};
      `}
      onClick={() => {
        if (announcement.url) {
          if (isExternalLink(announcement.url)) {
            window.open(announcement.url, "_blank");
          } else {
            window.location.href = announcement.url;
          }
        }
      }}
      onMouseOver={() => announcement.url && setIsHover(true)}
      onMouseLeave={() => announcement.url && setIsHover(false)}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          width: 100%;
        `}
      >
        <Text.ParagraphBold
          css={css`
            color: ${isHover ? theme.colors.primary : theme.colors.black};
            margin-bottom: 4px;
          `}
        >
          {announcement.title}
        </Text.ParagraphBold>
        <Text.Notifications
          css={css`
            color: ${theme.colors.gray4};
            margin-bottom: 12px;
          `}
        >
          {DateTime.fromISO(announcement.publish_date).toFormat("dd/MM/yyyy")}
        </Text.Notifications>
        <Text.Notifications
          css={css`
            margin-bottom: 20px;
            font-size: 16px;
            line-height: 22px;
          `}
        >
          {announcement.short_description}
        </Text.Notifications>
      </div>
      <div
        css={css`
          display: flex;
          height: 100%;
          width: 50px;
          align-items: center;
          justify-content: flex-end;
        `}
      >
        {(isHover || isMobile) && announcement.url && (
          <RightSvg width={20} height={20} color={theme.colors.primary} />
        )}
      </div>
    </div>
  );
}

export default function Announcements({
  announcements,
}: {
  announcements: Announcement[];
}) {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        width: ${isMobile ? "100%" : "35%"};
        height: 515px;
        padding: 24px 9px 0 24px;
        border-radius: 20px;
        margin-left: ${isMobile ? "0" : "40px"};
        background-color: ${theme.colors.white};
        box-sizing: border-box;
      `}
    >
      <Headers.H4
        css={css`
          margin-bottom: 30px;
        `}
      >
        {t("dashboard.announcements")}
      </Headers.H4>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          width: 100%;
          overflow: auto;

          ::-webkit-scrollbar {
            width: 20px;
          }

          ::-webkit-scrollbar-thumb {
            background-color: ${theme.colors.primary};
            border-radius: 20px;
            border: 6px solid transparent;
            background-clip: content-box;
          }
          ::-webkit-scrollbar-track {
            background-color: ${theme.colors.gray2};
            border: 9px solid transparent;
            background-clip: content-box;
          }
        `}
      >
        {announcements.map((announcement, key) => (
          <AnnouncementItem
            key={key}
            announcement={announcement}
            isMobile={isMobile}
          />
        ))}
      </div>
    </div>
  );
}
