import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useParams } from "react-router-dom";
import { css } from "styled-components/macro";
import { getExam } from "../actions/course/getExam";
import { useFetch } from "../actions/useAPI";
import { submitExam } from "../actions/course/submitExam";
import { theme } from "../themes/variables";
import { Headers } from "../helpers/layout";
import { Breadcrumbs } from "../components/Breadcrumbs";
import { Header } from "../components/Header";
import { Wrapper } from "../components/Wrapper";
import { Button } from "../components/Button";
import {
  TestFormProvider,
  TestFormUI,
  useTestForm,
} from "../components/Test/Form";
import { SEO } from "../components/SEO";
import { IExam } from "../types/Exam";
import { PageQueryHandler } from "./PageQueryHandler";
import { useResponsive } from "../hooks/useResponsive";

export function ExamPageContent(props: { exam: IExam }) {
  const history = useHistory();
  const { exam } = props;
  const { submission, isSubmitting, onRefresh } = useTestForm();
  const { t } = useTranslation();
  const { isMobile } = useResponsive();

  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 20px;
        flex-flow: row wrap;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          margin-bottom: 100px;
          width: 70%;
          background-color: #ffffff;
          border-radius: 40px;
          padding: 50px 30px;
          box-sizing: border-box;

          ${isMobile &&
          css`
            width: 100%;
            margin-bottom: 20px;
          `}
        `}
      >
        <TestFormUI hideResponse />

        {!isMobile && (
          <div
            css={css`
              display: flex;
              justify-content: space-between;
            `}
          >
            <div
              css={css`
                padding-right: 40px;
              `}
            >
              {submission && (
                <Headers.H4
                  css={css`
                    color: ${submission.passed
                      ? theme.colors.greenMain
                      : theme.colors.redMain};
                  `}
                >
                  {submission.passed && t("exam.passed_message")}
                </Headers.H4>
              )}
            </div>
            <div>
              {submission && (
                <div>
                  {submission.passed ? (
                    <Button
                      disabled={isSubmitting}
                      onClick={() => {
                        exam.course.has_summary
                          ? history.push(`/course/${exam.course.uuid}/summary`)
                          : submission.next_course &&
                            submission.next_course.uuid
                          ? history.push(
                              `/course/${submission.next_course.uuid}/`
                            )
                          : history.push(
                              `/course/${exam.course.uuid}?congratulation`
                            );
                      }}
                    >
                      {t("actions.continue")}
                    </Button>
                  ) : (
                    <div>
                      <Button
                        disabled={isSubmitting}
                        onClick={() => {
                          history.push(`/course/${exam?.course.uuid}/`);
                        }}
                        css={css`
                          margin-right: 1rem;
                        `}
                      >
                        {t("exam.return-to-course")}
                      </Button>

                      <Button
                        disabled={isSubmitting}
                        onClick={() => onRefresh()}
                      >
                        {t("actions.try-again")}
                      </Button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div
        css={css`
          display: flex;
          flex: 1 0 20%;
          margin-left: 10px;
          display: flex;
          justify-content: flex-end;

          ${isMobile &&
          css`
            width: 100%;
          `}
        `}
      >
        {submission && (
          <div
            css={css`
              ${isMobile &&
              css`
                width: 100%;
                border-radius: 20px;
                padding: 50px 20px;
                box-sizing: border-box;

                ${submission.passed
                  ? css`
                      background: #eaf7c7;
                    `
                  : css`
                      background: #f9efe0;
                    `}
              `}
            `}
          >
            <div
              css={css`
                font-weight: bold;
                text-align: center;

                ${isMobile &&
                css`
                  position: relative;
                `}

                ${!isMobile &&
                css`
                  position: sticky;
                  top: 30px;
                  margin-bottom: 20px;
                  width: 180px;
                  height: 180px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  flex-direction: column;
                  background: ${theme.colors.gray4};
                  color: #fff;
                  border-radius: 50%;

                  ${submission.passed
                    ? css`
                        background: ${theme.colors.greenMain};
                      `
                    : css`
                        background: ${theme.colors.redMain};
                      `}
                `}
              `}
            >
              <div
                css={
                  isMobile &&
                  css`
                    margin-bottom: 30px;
                  `
                }
              >
                <Headers.H3
                  css={css`
                    font-size: 40px;
                    margin: 0;

                    ${isMobile &&
                    css`
                      font-size: 46px;
                      color: ${theme.colors.primary};
                      margin-bottom: 20px;
                    `}
                  `}
                >
                  {submission.score}%
                </Headers.H3>
                <Headers.H4
                  css={css`
                    margin: 0;

                    ${isMobile &&
                    css`
                      font-size: 20px;
                    `}
                  `}
                >
                  {t("exam.correct")}
                </Headers.H4>
              </div>

              {isMobile && (
                <div>
                  <div
                    css={css`
                      margin-bottom: 40px;
                    `}
                  >
                    {submission && (
                      <Headers.H4>
                        {submission.passed && t("exam.passed_message")}
                      </Headers.H4>
                    )}
                  </div>
                  <div>
                    {submission && (
                      <div>
                        {submission.passed ? (
                          <Button
                            disabled={isSubmitting}
                            onClick={() => {
                              exam.course.has_summary
                                ? history.push(
                                    `/course/${exam.course.uuid}/summary`
                                  )
                                : submission.next_course &&
                                  submission.next_course.uuid
                                ? history.push(
                                    `/course/${submission.next_course.uuid}/`
                                  )
                                : history.push(
                                    `/course/${exam.course.uuid}?congratulation`
                                  );
                            }}
                          >
                            {t("actions.continue")}
                          </Button>
                        ) : (
                          <div>
                            <div
                              css={css`
                                margin-bottom: 20px;
                                font-size: 14px;
                              `}
                            >
                              <Link to={`/course/${exam?.course.uuid}/`}>
                                {t("exam.return-to-course")}
                              </Link>
                            </div>

                            <Button
                              disabled={isSubmitting}
                              onClick={() => onRefresh()}
                            >
                              {t("actions.try-again")}
                            </Button>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export function ExamPage(props: {}) {
  const params = useParams<{
    enrolmentID: string;
  }>();

  const { t } = useTranslation();

  const {
    data: exam,
    error: examError,
    isLoading: examIsLoading,
    refetch,
  } = useFetch(getExam, {
    enrolmentID: params.enrolmentID,
  });

  if (!exam || examError || examIsLoading) {
    return <PageQueryHandler error={examError} isLoading={examIsLoading} />;
  }

  const breadcrumbsItems = [
    {
      label: t("lesson.courses"),
      url: "/courses",
    },
    {
      label: exam?.course.name,
      url: `/course/${exam?.course.uuid}/`,
    },
    {
      label: t("exam.title"),
      url: `/course-enrolments/${params.enrolmentID}/exam`,
    },
  ];

  return (
    <Wrapper>
      <SEO>
        <title>{t("course.exam")}</title>
      </SEO>
      <Header />
      <div
        css={css`
          display: flex;
          flex-direction: row;
          width: 100%;
          justify-content: space-between;
          align-items: center;
        `}
      >
        <Breadcrumbs items={breadcrumbsItems} />
      </div>
      <div>
        <TestFormProvider
          tests={exam.tests}
          onSubmit={async (examState) => {
            const response = await submitExam({
              enrolmentID: params.enrolmentID,
              data: examState,
            });

            return response.data;
          }}
          onRefetch={() => {
            refetch();
          }}
        >
          <ExamPageContent exam={exam} />
        </TestFormProvider>
      </div>
    </Wrapper>
  );
}
