import React, { useEffect, useRef, useState } from "react";
import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import Player from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { Dropdown, DropdownItem } from "./Dropdown";
import { theme } from "../themes/variables";
import { downloadResource } from "../helpers/download";
import { ReactComponent as DownloadSvg } from "../assets/svg/Download.svg";
import { ReactComponent as DownSvg } from "../assets/svg/Down.svg";
import { ReactComponent as VolumeSvg } from "../assets/svg/Volume.svg";
import { ReactComponent as MuteSvg } from "../assets/svg/Mute.svg";
import { ReactComponent as PlaySvg } from "../assets/svg/Play.svg";
import { ReactComponent as PauseSvg } from "../assets/svg/Pause.svg";
import { ReactComponent as PrevSvg } from "../assets/svg/Prev.svg";
import { ReactComponent as NextSvg } from "../assets/svg/Next.svg";
import { useResponsive } from "../hooks/useResponsive";

function PlaybackRate(props: {
  rate: number | undefined;
  onChange: (value: number) => void;
}) {
  const { t } = useTranslation();
  const values = [0.5, 0.75, 1, 1.5, 2];

  return (
    <Dropdown
      options={values.map((el, idx) => {
        return (
          <DropdownItem key={idx} onClick={() => props.onChange(el)}>
            {el}x
          </DropdownItem>
        );
      })}
    >
      <div
        css={css`
          display: inline-block;
          padding: 3px 20px;
          border: 1px solid ${theme.colors.dark};
          border-radius: 20px;
          cursor: pointer;
          :hover {
            background: ${theme.colors.gray2};
          }
        `}
        title={t("media.rate")}
      >
        <span>{props.rate}x</span>
        <DownSvg
          css={css`
            margin-left: 10px;
            vertical-align: middle;
          `}
          height={14}
          width={14}
        />
      </div>
    </Dropdown>
  );
}

function DownloadButton(props: { url: string; className?: string }) {
  const { t } = useTranslation();

  return (
    <button
      onClick={() => downloadResource(props.url)}
      type="button"
      title={t("actions.download")}
      css={css`
        display: inline-block;
        border: none;
        height: 20px;
        line-height: 1;
        background-color: transparent;
        cursor: pointer;
      `}
      className={props.className}
    >
      <DownloadSvg width={20} height={20} color={theme.colors.primary} />
    </button>
  );
}

export function AudioPlayer(props: {
  src?: string;
  title?: string;
  onListen?: (time: number) => void;
  onPause?: () => void;
  onPlay?: () => void;
  onError?: () => void;
  onFinish?: () => void;
}) {
  const { isMobile } = useResponsive();
  const player = useRef<Player | null>(null);
  const onListen = (e: Event) => {
    const time = player.current?.audio.current?.currentTime;

    if (typeof time === "number" && props.onListen) {
      props.onListen(time);
    }
  };
  const [rate, setRate] = useState(1);
  const [volume, setVolume] = useState(100);

  useEffect(() => {
    const volumeChange = (e: Event) => {
      setVolume((e.target as HTMLAudioElement).volume * 100);
    };
    const audioPlayer = player.current;

    audioPlayer?.audio.current?.addEventListener("volumechange", volumeChange);
    return () => {
      audioPlayer?.audio.current?.removeEventListener(
        "volumechange",
        volumeChange
      );
    };
  }, []);

  const onChange = (value: number) => {
    if (player.current && player.current.audio.current) {
      setRate(value);
      player.current.audio.current.playbackRate = value;
    }
  };

  const customAdditionalControls = [
    <PlaybackRate onChange={onChange} rate={rate}></PlaybackRate>,
  ];

  if (props.src) {
    customAdditionalControls.push(
      <DownloadButton
        css={css`
          margin-left: 10px;
        `}
        url={props.src}
      />
    );
  }

  return (
    <Player
      ref={player}
      src={props.src}
      layout="stacked-reverse"
      customAdditionalControls={customAdditionalControls}
      customIcons={{
        volume: <VolumeSvg width={20} height={20} />,
        volumeMute: <MuteSvg width={20} height={20} />,
        play: <PlaySvg width={36} height={36} />,
        pause: <PauseSvg width={36} height={36} />,
        rewind: <PrevSvg width={18} height={18} />,
        forward: <NextSvg width={18} height={18} />,
      }}
      onEnded={props.onFinish}
      loop={false}
      autoPlayAfterSrcChange={false}
      onListen={onListen}
      onPlaying={onListen}
      onPause={props.onPause}
      onError={props.onError}
      onSeeked={onListen}
      css={css`
        box-shadow: none;
        border: none;
        border-radius: 20px;
        background-color: transparent;
        .rhap_main-controls-button {
          width: 20px;
          height: 20px;
          font-size: 20px;
          color: ${theme.colors.dark};
          margin: 0 8.5px;
        }
        .rhap_play-pause-button {
          width: 40px;
          height: 40px;
          font-size: 40px;
          color: ${theme.colors.primary};
        }
        .rhap_volume-button {
          flex: 0 0 20px;
          font-size: 20px;
          width: 20px;
          height: 20px;
          margin-right: 8px;
          color: ${theme.colors.primary};
        }

        .rhap_controls-section {
          flex-flow: row wrap;
        }

        ${
          isMobile &&
          css`
            .rhap_main-controls {
              flex: 0 0 100%;
              margin: 20px 0;
            }
          `
        }

        .rhap_volume-controls {
          ${
            isMobile &&
            css`
              display: none;
            `
          }
        }
        .rhap_progress-indicator {
          box-shadow: none;
          background: ${theme.colors.primary};
        }
        .rhap_progress-filled {
          background-color: ${theme.colors.dark};
        }
        .rhap_volume-indicator {
          opacity: 1;
          box-shadow: none;
          background-color: ${theme.colors.primary};
        }
        .rhap_volume-button {
          color: ${theme.colors.dark};
        }
        .rhap_additional-controls {
          flex: 0.7 0 auto;

          ${
            isMobile &&
            css`
              flex: 0 0 100%;
              display: flex;
              justify-content: space-between;
            `
          }
        .rhap_volume-bar {
          border-radius: 20px;
          :before {
            content: "";
            position: absolute;
            width: ${volume}%;
            background: ${theme.colors.dark};
            height: 4px;
            border-radius: 20px;
          }
        }
      `}
    />
  );
}
