export type TestVariant = {
  title: string;
  id: string;
  answers: TestAnswer[];
};
export interface Test {
  question_type: EnumTestType;
  uuid: string;
  variant: TestVariant | null;
}

export type TestAnswer = {
  id: string;
  image: string;
  primary_text: string;
  secondary_text: string;
};

export enum EnumTestType {
  TRUE_FALSE = "TRUE_FALSE",
  SINGLE_CHOICE = "SINGLE_CHOICE",
  MULTIPLE_CHOICE = "MULTIPLE_CHOICE",
}
