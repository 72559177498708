import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { css } from "styled-components/macro";
import { updatePersonalInfo } from "../actions/account/updatePersonalInfo";
import { getProfile } from "../actions/auth/getProfile";
import { useFetch } from "../actions/useAPI";
import { ProfileHeader } from "../components/Account/ProfileHeader";
import { Header } from "../components/Header";
import { Wrapper } from "../components/Wrapper";
import { SEO } from "../components/SEO";
import {
  FormValues,
  PersonalInfoForm,
} from "../forms/profile/PersonalInfoForm";
import { PageQueryHandler } from "./PageQueryHandler";
import { useResponsive } from "../hooks/useResponsive";
import { Tabs } from "../components/Tabs";
import { theme } from "../themes/variables";

export function UserDataPage(props: {}) {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();

  const {
    data: user,
    error: userError,
    isLoading: userIsLoading,
    setData,
  } = useFetch(getProfile);

  const onSubmit = useCallback(
    async (values: FormValues) => {
      const response = await updatePersonalInfo(values);
      toast.success(t("status.success"));
      setData(response.data);
    },
    [setData, t]
  );

  if (!user || userError || userIsLoading) {
    return <PageQueryHandler error={userError} isLoading={userIsLoading} />;
  }

  const tabsData = [
    {
      title: "General information",
      link: `/account`,
    },
    {
      title: t("account.personal_info"),
      link: `/account/data`,
      forceHightlight: true,
    },
    {
      title: t("account.security"),
      link: `/account/security`,
    },
    {
      title: t("account.assessment"),
      link: `/account/self-assessment`,
    },
  ];

  return (
    <>
      <Wrapper>
        <SEO>
          <title>{t("account.personal_info")}</title>
        </SEO>

        <Header />
        <Tabs isWrapped tabs={tabsData} />

        <div
          css={css`
            display: flex;
            flex-direction: ${isMobile ? "column" : "row"};
            padding: ${isMobile ? "20px" : "60px 60px 60px 24px"};
            background-color: ${theme.colors.white};
            border-radius: 20px;
          `}
        >
          <ProfileHeader user={user} isEditable />
          <PersonalInfoForm data={user} onSubmit={onSubmit} />
        </div>
      </Wrapper>
    </>
  );
}
