import { notAuthorised } from "../request";

type Args = {
  token: string;
};

export async function getEmailInvitation({ token }: Args) {
  const API = notAuthorised;

  return API.request<{
    first_name: string;
    last_name: string;
    email: string;
  }>({
    method: "GET",
    url: `/api/campus/email-invitation/?token=${token}`,
  });
}
