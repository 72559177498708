import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { css } from "styled-components/macro";
import { useFetch } from "../actions/useAPI";
import { Breadcrumbs } from "../components/Breadcrumbs";
import { Header } from "../components/Header";
import { Wrapper } from "../components/Wrapper";
import { Button } from "../components/Button";
import { TextRenderer } from "../components/TextRenderer";
import { SEO } from "../components/SEO";
import { getCourseSummary } from "../actions/course/getCourseSummary";
import { Headers } from "../helpers/layout";
import { PageQueryHandler } from "./PageQueryHandler";
import { useResponsive } from "../hooks/useResponsive";

export function CourseSummaryPage(props: {}) {
  const params = useParams<{
    id: string;
  }>();

  const { t } = useTranslation();
  const history = useHistory();
  const { isMobile } = useResponsive();

  const {
    data: summary,
    error: summaryError,
    isLoading: summaryIsLoading,
  } = useFetch(getCourseSummary, {
    courseId: params.id,
  });

  if (!summary || summaryError || summaryIsLoading) {
    return (
      <PageQueryHandler error={summaryError} isLoading={summaryIsLoading} />
    );
  }

  const breadcrumbsItems = [
    {
      label: t("lesson.courses"),
      url: "/courses",
    },
    {
      label: summary.name,
      url: `/course/${summary.uuid}/`,
    },
  ];

  return (
    <Wrapper>
      <SEO>
        <title>{t("course-summary.syllabus.title")}</title>
      </SEO>
      <Header />
      <div
        css={css`
          display: flex;
          flex-direction: row;
          width: 100%;
          justify-content: space-between;
          align-items: center;
        `}
      >
        <Breadcrumbs items={breadcrumbsItems} />
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-top: 20px;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            width: 100%;
            background-color: #ffffff;
            border-radius: 40px;
            padding: 50px 30px;
          `}
        >
          <div
            css={css`
              margin-bottom: 50px;
            `}
          >
            <Headers.H1
              css={css`
                margin-bottom: ${isMobile ? "20px" : "30px"};
              `}
            >
              {t("course-summary.title")}
            </Headers.H1>
            <TextRenderer text={summary.summary_content_html} html />
          </div>
          <div
            css={css`
              display: flex;
              width: 100%;
            `}
          >
            <Button
              css={css`
                margin: 0 0 0 auto;
              `}
              onClick={() => {
                history.push(`/course/${summary.uuid}/`);
              }}
            >
              {t("actions.continue")}
            </Button>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
