import { useTranslation } from "react-i18next";
import { RouteComponentProps, useLocation } from "react-router-dom";
import { css } from "styled-components/macro";
import { Block } from "../../components/Block";
import { Header } from "../../components/Header";
import { Wrapper } from "../../components/Wrapper";
import { SEO } from "../../components/SEO";
import { RegistrationForm } from "../../forms/auth/RegistrationForm";
import { theme } from "../../themes/variables";
import { Headers } from "../../helpers/layout";
import { useResponsive } from "../../hooks/useResponsive";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { getEmailInvitation } from "../../actions/getEmailInvitation";
import { FormValues } from "../../actions/auth/registration";

export function RegistrationPage(props: RouteComponentProps) {
  const { t } = useTranslation();
  const params = new URLSearchParams(props.location.search);
  const referral = params.get("referral");
  const { isMobile } = useResponsive();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const [formData, setFormData] = useState<Partial<FormValues>>({});

  useEffect(() => {
    if (!queryParams.token) return;

    async function fetchData() {
      try {
        const response = await getEmailInvitation({
          token: queryParams.token,
        });

        setFormData(response.data);
      } catch {}
    }

    fetchData();
  }, [queryParams.token]);

  return (
    <Wrapper>
      <SEO>
        <title>{t("auth.register")}</title>
      </SEO>
      <Header />
      <Block
        css={css`
          background-color: ${theme.colors.white};
          padding: ${isMobile ? "20px" : "0"};
        `}
      >
        <div
          css={css`
            width: ${isMobile ? "100%" : "370px"};
            margin: 0 auto;
            padding: 20px 0;
          `}
        >
          <Headers.H3
            css={css`
              text-align: center;
              margin-bottom: 30px;
            `}
          >
            {t("auth.register")}
          </Headers.H3>
          <RegistrationForm referral={referral} initialValues={formData} />
        </div>
      </Block>
    </Wrapper>
  );
}
