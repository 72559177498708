import React, { useState, useRef } from "react";
import { css } from "styled-components/macro";
import { theme } from "../../themes/variables";
import { Headers } from "../../helpers/layout";
import { CollapseBlock } from "../../types/Block";
import { ReactComponent as OwlIcon } from "../../assets/svg/Owl.svg";
import { ReactComponent as PlusIcon } from "../../assets/svg/Plus.svg";
import { ReactComponent as MinusIcon } from "../../assets/svg/Minus.svg";

export function CollapseItem(props: {
  title: string;
  content: string;
  isActive: boolean;
  onChange: () => void;
}) {
  const content = useRef(null as null | HTMLDivElement);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        height: fit-content;
      `}
    >
      <button
        onClick={props.onChange}
        css={css`
          background-color: ${theme.colors.transparent};
          text-align: left;
          cursor: pointer;
          display: flex;
          align-items: center;
          border: none;
          outline: none;
          padding: 0;
          transition: background-color 0.6s ease;
        `}
      >
        {props.isActive ? (
          <MinusIcon width={20} height={20} color={theme.colors.dark} />
        ) : (
          <PlusIcon width={20} height={20} color={theme.colors.dark} />
        )}

        <Headers.H4
          css={css`
            width: 100%;
            margin-left: 12px;
            margin-bottom: 0;
          `}
        >
          {props.title}
        </Headers.H4>
      </button>

      <div
        css={css`
          overflow: hidden;
          transition: max-height 0.6s ease;
          max-height: ${!props.isActive
            ? "0px"
            : content &&
              content.current &&
              content.current.scrollHeight + "px"};
        `}
        ref={content}
      >
        <div
          css={css`
            font-size: 20px;
            line-height: 28px;
            font-weight: 400;
            margin-top: 12px;
            margin-left: 32px;
          `}
          dangerouslySetInnerHTML={{ __html: props.content }}
        ></div>
      </div>
    </div>
  );
}

export function LessonCollapse(props: {
  blocks: CollapseBlock[];
  title: string;
}) {
  const [activeIds, setActiveIds] = useState<number[]>([]);

  return (
    <div>
      <div
        css={css`
          display: flex;
          align-items: center;
          margin-bottom: 32px;
        `}
      >
        <OwlIcon width={18} height={24} color={theme.colors.primary} />
        <Headers.H3
          css={css`
            margin-left: 12px;
            margin-bottom: 0;
          `}
        >
          {props.title}
        </Headers.H3>
      </div>

      {props.blocks.map((el, key) => {
        return (
          <div
            key={key}
            css={css`
              margin-bottom: 32px;
              :last-of-type {
                margin-bottom: 0;
              }
            `}
          >
            <CollapseItem
              isActive={activeIds.includes(key)}
              onChange={() =>
                setActiveIds((prev) => {
                  return prev.includes(key)
                    ? prev.filter((el) => el !== key)
                    : prev.concat([key]);
                })
              }
              title={el.title}
              content={el.content}
            />
          </div>
        );
      })}
    </div>
  );
}
