import { authorised } from "../../request";
import { buildQueryString } from "../../helpers/buildQueryString";
import { Filters } from "../../hooks/useFilters";
import { CheatSheetDetails } from "../../types/CheatsSheets";

export async function getFilteredCheats(filters: Filters) {
  const API = authorised();

  const queryString = buildQueryString(filters);

  return API.request<CheatSheetDetails[]>({
    method: "GET",
    url: `/api/campus/cheatsheet/filter/?${queryString}`,
  }).then((r) => r.data);
}
