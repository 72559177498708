import { authorised } from "../../request";

type Args = {
  enrollmentId: string;
  lessonId: string;
};

export async function completeLesson({ enrollmentId, lessonId }: Args) {
  const API = authorised();

  return API.request({
    method: "POST",
    url: `/api/campus/course-enrolments/${enrollmentId}/lessons/${lessonId}/complete/`,
  });
}
