import { authorised } from "../../request";

type UserData = {
  password: string;
  new_password: string;
  confirm_new_password: string;
}

export async function updatePassword(userData: UserData) {
  const API = authorised();

  return await API.request<{
    token: string;
  }>({
    method: "PUT",
    url: `/api/campus/me/change-password/`,
    data: userData
  });
}
