import { useHistory } from "react-router-dom";
import { css } from "styled-components/macro";
import { Headers } from "../helpers/layout";
import { useResponsive } from "../hooks/useResponsive";

export function HeaderText(props: {
  title?: string;
  text?: string;
  isClickable?: boolean;
  link?: string;
}) {
  const history = useHistory();
  const { isMobile } = useResponsive();

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        min-width: ${isMobile ? "0" : "400px"};
        cursor: ${props.isClickable ? "pointer" : ""};
      `}
    >
      {props.link ? (
        <Headers.H2
          onClick={() => (props.link ? history.push(props.link) : "")}
        >
          {props.text}
        </Headers.H2>
      ) : (
        <Headers.H2>{props.text}</Headers.H2>
      )}
    </div>
  );
}
