import React from "react";
import { css } from "styled-components/macro";
import { Hint } from "../../components/Hint";
import { Field, FieldProps } from "formik";
import { theme } from "../../themes/variables";
import { CheckboxComponent } from "../../components/CheckboxComponent";

export interface CheckboxOption {
  label: string;
  value: string;
}

export function CheckboxFields(props: {
  name: string;
  label: string;
  hint?: string;
  choices: CheckboxOption[];
  labelRight?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  className?: string;
  validate?: (value: string) => Promise<void | string>;
}) {
  return (
    <Field name={props.name}>
      {({ meta, form, field }: FieldProps) => {
        return (
          <div>
            <div
              css={css`
                margin-bottom: 16px;
              `}
            >
              <div
                css={css`
                  margin-bottom: 16px;
                  font-size: 16px;
                  font-weight: bold;
                `}
              >
                {props.label}
              </div>
              {props.hint && <Hint>{props.hint}</Hint>}
            </div>

            {props.choices.map((choice, idx) => {
              const fieldName = `${props.name}[${choice.value}]`;

              return (
                <CheckboxComponent
                  key={idx}
                  label={choice.label}
                  name={fieldName}
                  labelRight={props.labelRight}
                  disabled={props.disabled}
                  readonly={props.readonly}
                  className={props.className}
                  css={css`
                    margin-right: 2rem;
                    margin-bottom: 1rem;
                    :last-of-type {
                      margin-right: 0;
                    }
                  `}
                  value={field.value[choice.value]}
                  onChange={(e) => {
                    const isChecked = e.target.checked;

                    form.setFieldValue(fieldName, isChecked);
                  }}
                />
              );
            })}
            {meta.error && (
              <div
                css={css`
                  color: ${theme.colors.error};
                  font-size: 16px;
                `}
              >
                {meta.error}
              </div>
            )}
          </div>
        );
      }}
    </Field>
  );
}
