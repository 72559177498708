import React from "react";
import { useParams } from "react-router-dom";
import { Order } from "../../types/Order";
import { PaymentMethod } from "../../components/Payment/PaymentMethod";

import { useFetch } from "../../actions/useAPI";
import { getOrder } from "../../actions/order";
import { PageQueryHandler } from "../PageQueryHandler";

export type FormValues = Pick<Order, "payment_type">;

export function PaymentMethodExistPage(props: {}) {
  const params = useParams<{
    orderId: string;
  }>();

  const {
    data: order,
    error: orderError,
    isLoading: orderIsLoading,
  } = useFetch(getOrder, {
    orderId: params.orderId,
  });

  if (!order || orderError || orderIsLoading) {
    return <PageQueryHandler error={orderError} isLoading={orderIsLoading} />;
  }

  return (
    <PaymentMethod
      payment_type={order.payment_type}
      courseId={order.items[0].course.uuid}
    />
  );
}
