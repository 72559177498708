import { authorised } from "../../request";

export async function createStripePaymentIntent(orderId: string) {
  const API = authorised();

  return API.request<{
    clientSecret: string;
  }>({
    method: "POST",
    url: `/api/campus/orders/${orderId}/stripe-create-payment-intent/`,
  });
}
