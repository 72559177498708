import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { css } from "styled-components/macro";
import { Helmet } from "react-helmet";
import { Check } from "react-feather";
import { getFilteredCheats } from "../actions/cheats/getFilteredCheats";
import { deleteCheat } from "../actions/cheats/deleteCheat";
import { useFilters } from "../hooks/useFilters";
import { serverErrorHandler } from "../helpers/serverErrorHandler";
import { Headers } from "../helpers/layout";
import { CheatsByJourney, CheatsSheet } from "../types/CheatsSheets";
import { theme } from "../themes/variables";

import { Header } from "../components/Header";
import { Wrapper } from "../components/Wrapper";
import CheatsActions from "../components/Cheats/CheatsActions";
import CheatsBlock from "../components/Cheats/CheatsBlock";
import { Button } from "../components/Button";
import { Modal } from "../components/Modal/Modal";
import { ModalHeader } from "../components/Modal/ModalHeader";
import { ModalBody } from "../components/Modal/ModalBody";
import { useFetch } from "../actions/useAPI";
import { getCoursesSuggestionsByCheats } from "../actions/cheats/getCoursesSuggestionsByCheats";
import SuggestionsBlock from "../components/SuggestionsBlock";
import { useResponsive } from "../hooks/useResponsive";
import { PageQueryHandler } from "./PageQueryHandler";

export function CheatsPage(props: {
  location: { pathname: string; search: string };
}) {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();

  const [cheats, setCheats] = useState<CheatsSheet[]>([]);
  const { filters, setFilter } = useFilters();

  const [deleteModal, setDeleteModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState("");

  const [selectedCheats, setSelectedCheats] = useState<CheatsSheet[]>([]);

  const {
    data: coursesSuggestions,
    error: coursesSuggestionsError,
    isLoading: coursesSuggestionsIsLoading,
  } = useFetch(getCoursesSuggestionsByCheats);

  useEffect(() => {
    getFilteredCheats(filters).then((results) => {
      setCheats(results.map((cheats) => cheats.cheat_sheet));
    });
  }, [filters]);

  const journeys: CheatsByJourney[] = cheats.reduce(
    (journeyGroups: CheatsByJourney[], cheat) => {
      if (!cheat.lesson.course.journey) {
        return journeyGroups;
      }

      const journeyGroup = journeyGroups.find(
        (group) =>
          group.journey &&
          cheat.lesson.course.journey &&
          group.journey.uuid === cheat.lesson.course.journey.uuid
      );

      if (journeyGroup) {
        return journeyGroups.map((group) => {
          if (
            group.journey &&
            group.journey.uuid === journeyGroup.journey.uuid
          ) {
            return {
              ...group,
              items: [...group.items, cheat],
            };
          }
          return group;
        });
      } else {
        journeyGroups.push({
          journey: cheat.lesson.course.journey,
          items: [cheat],
        });
      }

      return journeyGroups;
    },
    []
  );

  const onDelete = async (uuid: string) => {
    try {
      await deleteCheat(uuid);
      setCheats(cheats.filter((item) => item.uuid !== itemToDelete));
    } catch (error: any) {
      toast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    } finally {
      setDeleteModal(false);
      setItemToDelete("");
    }
  };

  const onOpenPDF = (cheats: CheatsSheet[]) => {
    if (cheats) {
      const ids = cheats.map((el) => el.uuid);

      window.open(
        `${process.env.REACT_APP_BACKEND_URL}/api/campus/cheatsheet/download/?ids=${ids}`
      );
    }
  };

  if (
    !coursesSuggestions ||
    coursesSuggestionsError ||
    coursesSuggestionsIsLoading
  ) {
    return (
      <PageQueryHandler
        error={coursesSuggestionsError}
        isLoading={coursesSuggestionsIsLoading}
      />
    );
  }

  return (
    <Wrapper>
      <Helmet title={t("cheats_sheet.cheats")}></Helmet>
      <Header />
      <div
        css={css`
          display: flex;
          flex-direction: column;
        `}
      >
        <CheatsActions
          items={cheats}
          activeFilters={filters}
          selectedCheats={selectedCheats}
          onFilterChange={setFilter}
          onOpenPDF={(cheats: CheatsSheet[]) => onOpenPDF(cheats)}
        />
        {cheats.length > 0 ? (
          <>
            <div
              css={css`
                display: flex;
                flex-direction: column;
                margin-top: 30px;
                padding: ${isMobile ? "24px" : "30px 40px"};
                background-color: ${theme.colors.white};
                border-radius: 20px;
              `}
            >
              {journeys &&
                journeys.map((item, id) => (
                  <CheatsBlock
                    key={id}
                    cheats={item.items}
                    journeyName={item.journey.name}
                    selectedCheats={selectedCheats}
                    setSelectedCheats={(cheats: CheatsSheet[]) =>
                      setSelectedCheats(cheats)
                    }
                    setDeleteModal={() => setDeleteModal(true)}
                    setItemToDelete={(uuid: string) => setItemToDelete(uuid)}
                  />
                ))}

              <Button
                color={
                  selectedCheats.length <= 0
                    ? theme.colors.gray5
                    : theme.colors.white
                }
                background={theme.colors.primary}
                border={`1px solid ${
                  selectedCheats.length <= 0
                    ? theme.colors.transparentBlack1
                    : theme.colors.primary
                }`}
                css={css`
                  margin: 0 auto;
                `}
                icon={
                  <Check
                    size={12}
                    color={
                      selectedCheats.length <= 0
                        ? theme.colors.gray5
                        : theme.colors.white
                    }
                  />
                }
                iconRight
                disabled={selectedCheats.length <= 0}
                onClick={() => onOpenPDF(selectedCheats)}
              >
                {t("cheats_sheet.print_selected")}
              </Button>
            </div>
            <SuggestionsBlock
              title={t("cheats_sheet.suggestion_miss_label")}
              subtitle={t("cheats_sheet.suggestion_text")}
              courses={coursesSuggestions}
            />
          </>
        ) : (
          <SuggestionsBlock
            title={t("cheats_sheet.no_results_label")}
            subtitle={
              coursesSuggestions.length
                ? t("cheats_sheet.suggestion_text_no_result")
                : t("cheats_sheet.no_results_text")
            }
            courses={coursesSuggestions}
            button_text={t("cheats_sheet.explore")}
            button_link={"/courses"}
          />
        )}
      </div>
      <Modal
        modalIsOpen={deleteModal}
        onClose={() => setDeleteModal(false)}
        contentLabel={t("actions.sure")}
      >
        <ModalHeader
          closeIcon
          onClose={() => setDeleteModal(false)}
          css={css`
            justify-content: flex-end;
          `}
        />

        <ModalBody>
          <div
            css={css`
              display: flex;
              width: 100%;
              flex-direction: column;
              justify-content: center;
              text-align: center;
            `}
          >
            <Headers.H4
              css={css`
                display: flex;
                width: 250px;
                justify-content: center;
                margin: 10px auto 24px auto;
              `}
            >
              {t("cheats_sheet.delete_confirm")}
            </Headers.H4>
            <div>
              <Button
                css={css`
                  font-size: 16px;
                  background-color: ${theme.colors.white};
                  color: ${theme.colors.error};
                  border: 1px solid ${theme.colors.error};
                `}
                onClick={() => onDelete(itemToDelete)}
                hoverStyles={`background-color: ${theme.colors.error}; color: ${theme.colors.white}`}
              >
                {t("actions.delete")}
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </Wrapper>
  );
}
