import { authorised } from "../../request";
import { ILessonData } from "../../types/Lesson";

type Args = {
  enrolmentID: string;
  lessonId: string;
};  

export async function getLesson({enrolmentID, lessonId}: Args) {
  const API = authorised();

  return API.request<ILessonData>({
    method: "GET",
    url: `/api/campus/course-enrolments/${enrolmentID}/lessons/${lessonId}/`
  });
}
