import React, { useEffect } from "react";
import ReactModal, { Styles } from "react-modal";

const customStyles: Styles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)", //mobile
    maxWidth: "840px",
    width: "90%",
    boxSizing: "border-box",
    maxHeight: "calc(100vh - 40px)",
    boxShadow:
      "rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px, rgba(0, 0, 0, 0.12) 0px 1px 10px",
    overflow: "auto",
    borderRadius: "20px",
    position: "relative",
    backgroundColor: "#fff",
    border: "none",
    willChange: "scroll-position",
  },
  overlay: {
    height: "100vh",
    overflowY: "hidden",
    backgroundColor: "rgba(133, 133, 133, 0.4)",
    outline: "none",
    zIndex: 100,
  },
};

ReactModal.setAppElement("#root");

const disableBodyScroll = () => {
  document.body.style.overflow = "hidden";
};

const enableBodyScroll = () => {
  document.body.style.overflow = "";
};

export function Modal(props: {
  modalIsOpen: boolean;
  onClose?: () => void;
  contentLabel?: string;
  children?: React.ReactNode | React.ReactNode[] | string | null;
  style?: Styles;
  isMobile?: boolean;
  overlayStyles?: Styles["overlay"];
  contentStyles?: Styles["content"];
}) {
  useEffect(() => {
    if (props.modalIsOpen) {
      disableBodyScroll();
    } else {
      enableBodyScroll();
    }

    return () => {
      enableBodyScroll();
    };
  }, [props.modalIsOpen]);

  function onClose() {
    if (props.onClose) {
      props.onClose();
    }

    enableBodyScroll();
  }

  if (props.contentStyles) {
    customStyles.content = {
      ...customStyles.content,
      ...props.contentStyles,
    };
  }

  if (props.overlayStyles) {
    customStyles.overlay = {
      ...customStyles.overlay,
      ...props.overlayStyles,
    };
  }

  return (
    <div>
      <ReactModal
        isOpen={props.modalIsOpen}
        onRequestClose={onClose}
        style={props.style || customStyles}
        contentLabel={props.contentLabel}
      >
        {props.children}
      </ReactModal>
    </div>
  );
}
