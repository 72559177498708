import React from "react";
import { ChevronLeft, ChevronRight } from "react-feather";
import { css } from "styled-components/macro";
import { theme } from "../themes/variables";

export function Pagination(props: {
  arrows?: boolean;
  infinite?: boolean;
  currentItemIndex: number;
  itemsCount: number;
  onNext?: () => void;
  onPrev?: () => void;
  onChose?: (index: number) => void;
  className?: string;
}) {
  const {
    arrows = true,
    currentItemIndex,
    itemsCount,
    onNext,
    onPrev,
    onChose = () => {},
    className,
    infinite,
  } = props;

  if (itemsCount <= 1) {
    return null;
  }

  const arr = Array(itemsCount)
    .fill(null)
    .map((_, i) => i);

  return (
    <div
      className={className}
      css={css`
        display: flex;
        align-items: center;
      `}
    >
      {arrows && (infinite || currentItemIndex > 0) && (
        <ChevronLeft
          size={30}
          color={theme.colors.dark}
          css={css`
            cursor: pointer;
            opacity: 0.7;
            :hover {
              opacity: 1;
            }
          `}
          onClick={onPrev}
        />
      )}

      <ul
        css={css`
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          list-style-type: none;
          margin: 0;
          padding: 0;
        `}
      >
        {arr.map((el) => (
          <li
            key={el}
            css={css`
              padding: 0 10px;
              line-height: 1;
            `}
            onClick={() => onChose(el)}
          >
            <div
              css={css`
                padding: 5px;
                border-radius: 50%;
                cursor: pointer;
                width: 1rem;
                height: 1rem;
                display: flex;
                align-items: center;
                justify-content: center;
                ${el === currentItemIndex
                  ? `background: rgba(0, 0, 0, 0.25);
                font-weight: bold;
              `
                  : `:hover {
                background: rgba(0, 0, 0, 0.15);
              }`}
              `}
            >
              {el + 1}
            </div>
          </li>
        ))}
      </ul>

      {arrows && (infinite || currentItemIndex < itemsCount - 1) && (
        <ChevronRight
          size={30}
          color={theme.colors.dark}
          css={css`
            cursor: pointer;
            opacity: 0.7;
            :hover {
              opacity: 1;
            }
          `}
          onClick={onNext}
        />
      )}
    </div>
  );
}
