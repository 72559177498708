import React, { useRef } from "react";
import { ReactComponent as ArrowLeft } from "../assets/svg/Arrow-Left.svg";
import { ReactComponent as ArrowRight } from "../assets/svg/Arrow-Right.svg";
import Slider from "react-slick";
import styled, { css } from "styled-components/macro";
import { useResponsive } from "../hooks/useResponsive";
import { theme } from "../themes/variables";

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  border: none;
  cursor: pointer;
  background-color: transparent;
  path {
    fill: ${theme.colors.black};
  }

  &:hover {
    path {
      fill: ${theme.colors.primary};
    }
  }

  &:last-child {
    margin-right: 0;
  }
`;

export default function FeaturedSlider<T>({
  items,
  onNameClick,
  children,
  title,
  className,
}: {
  className?: string;
  items: T[];
  title?: React.ReactNode;
  onNameClick?: () => void;
  children: (item: T, index: number) => React.ReactNode;
}) {
  const ref = useRef<any>(null);
  const { isMobile } = useResponsive();

  const settings = {
    infinite: false,
    className: "slider variable-width",
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    swipeToSlide: true,
    arrows: false,
  };

  return (
    <div className={className}>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: ${isMobile ? "20px" : "30px"};
        `}
      >
        {title}

        {items.length > 2 && (
          <div
            css={css`
              flex: 0 0 auto;
              display: flex;
              align-items: center;
            `}
          >
            <Button
              onClick={() => {
                if (!ref.current) return;

                ref.current.slickPrev();
              }}
            >
              <ArrowLeft />
            </Button>
            <Button
              onClick={() => {
                if (!ref.current) return;

                ref.current.slickNext();
              }}
            >
              <ArrowRight />
            </Button>
          </div>
        )}
      </div>
      <Slider {...settings} ref={ref}>
        {items.map((item, index) => children(item, index))}
      </Slider>
    </div>
  );
}
