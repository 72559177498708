import styled, { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { Trash2 } from "react-feather";
import { Headers } from "../../helpers/layout";
import { CheatsSheet } from "../../types/CheatsSheets";
import { theme } from "../../themes/variables";
import CheckMark from "./../../assets/img/CheckMark.png";
import { TextRenderer } from "../TextRenderer";
import { useResponsive } from "../../hooks/useResponsive";

const CheckboxButton = styled.input`
  appearance: none;
  border: 1px solid ${theme.colors.gray4};
  border-radius: 4px;
  cursor: pointer;
  width: 24px;
  height: 24px;
  margin: 0;
  &:after {
    display: none;
    color: white;
    font-size: 100%;
  }
  &:checked {
    background-image: url(${CheckMark});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 15px;

    &:after {
      display: block;
    }
  }
`;

function ColumnItems(props: {
  items: CheatsSheet[];
  selectedItems: CheatsSheet[];
  select: (item: CheatsSheet) => void;
  setItemToDelete?: (uuid: string) => void | undefined;
  setDeleteModal?: () => void | undefined;
}) {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();

  return (
    <div
      css={css`
        flex-wrap: wrap;
        flex-direction: column;
        width: ${isMobile ? "100%" : "32.5%"};
      `}
    >
      {props.items &&
        props.items.map((cheat, key) => (
          <div
            css={css`
              display: flex;
              flex-direction: column;
              padding: ${isMobile ? "24px" : "28px 32px"};
              margin-bottom: 15px;
              cursor: pointer;
              border-radius: 20px;
              font-size: 18px;
              background-color: ${props.selectedItems.includes(cheat)
                ? theme.colors.yellowSelected
                : theme.colors.gray1};
            `}
            key={key}
          >
            <div
              css={css`
                width: 100%;
                font-size: 20px;
              `}
            >
              <div
                css={css`
                  font-weight: bold;
                  margin-bottom: 10px;
                `}
              >
                {cheat.name}
              </div>
              <div
                css={css`
                  width: 100%;
                  overflow-wrap: break-word;
                `}
              >
                <TextRenderer text={cheat.text_content} withoutClassName html />
              </div>
            </div>
            {props.setDeleteModal && props.setItemToDelete && (
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  margin-top: 28px;
                `}
              >
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                  `}
                  onClick={() => props.select(cheat)}
                >
                  <CheckboxButton
                    name={cheat.name}
                    type="checkbox"
                    checked={props.selectedItems.includes(cheat)}
                    onChange={() => {}}
                  />
                  <div
                    css={css`
                      margin-left: 10px;
                      font-size: ${isMobile ? "14px" : "16px"};
                      font-weight: bold;
                    `}
                  >
                    {t("cheats_sheet.select_for_print")}
                  </div>
                </div>

                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    margin-right: 20px;
                  `}
                  onClick={() => {
                    if (props.setItemToDelete && props.setDeleteModal) {
                      props.setItemToDelete(cheat.uuid);
                      props.setDeleteModal();
                    }
                  }}
                >
                  <Trash2 size={20} color={theme.colors.error} />
                  <div
                    css={css`
                      margin-left: 10px;
                      font-size: ${isMobile ? "14px" : "16px"};
                      font-weight: bold;
                      color: ${theme.colors.error};
                      margin-top: 3px;
                    `}
                  >
                    {t("actions.delete")}
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
    </div>
  );
}

export default function CheatsBlock({
  cheats,
  journeyName,
  selectedCheats,
  setSelectedCheats,
  setDeleteModal,
  setItemToDelete,
}: {
  cheats: CheatsSheet[];
  journeyName: string;
  selectedCheats: CheatsSheet[];
  setSelectedCheats: (cheats: CheatsSheet[]) => void;
  setDeleteModal?: () => void;
  setItemToDelete?: (uuid: string) => void;
}) {
  const { isMobile } = useResponsive();
  const columns = isMobile ? 1 : 3;
  const divider = Math.round(cheats.length / columns);

  const leftItems = cheats.slice(0, divider === 0 ? 1 : divider);
  const middleItem = cheats.slice(divider, divider + divider);
  const rightItems = cheats.slice(
    divider + divider === 0 ? 1 : divider + divider
  );

  const selectItems = (item: CheatsSheet) => {
    if (!selectedCheats.includes(item)) {
      setSelectedCheats([...selectedCheats, item]);
    } else {
      setSelectedCheats(
        selectedCheats.filter((value) => value.uuid !== item.uuid)
      );
    }
  };

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 40px;
      `}
    >
      <Headers.H4
        css={css`
          margin-bottom: 32px;
        `}
      >
        {journeyName}
      </Headers.H4>
      <div
        css={css`
          display: flex;
          width: 100%;
          justify-content: space-between;
        `}
      >
        {isMobile ? (
          <ColumnItems
            items={cheats}
            selectedItems={selectedCheats}
            select={(item: CheatsSheet) => selectItems(item)}
            setItemToDelete={
              setItemToDelete
                ? (uuid: string) => setItemToDelete(uuid)
                : undefined
            }
            setDeleteModal={setDeleteModal ? () => setDeleteModal() : undefined}
          />
        ) : (
          <>
            <ColumnItems
              items={leftItems}
              selectedItems={selectedCheats}
              select={(item: CheatsSheet) => selectItems(item)}
              setItemToDelete={
                setItemToDelete
                  ? (uuid: string) => setItemToDelete(uuid)
                  : undefined
              }
              setDeleteModal={
                setDeleteModal ? () => setDeleteModal() : undefined
              }
            />
            <ColumnItems
              items={middleItem}
              selectedItems={selectedCheats}
              select={(item: CheatsSheet) => selectItems(item)}
              setItemToDelete={
                setItemToDelete
                  ? (uuid: string) => setItemToDelete(uuid)
                  : undefined
              }
              setDeleteModal={
                setDeleteModal ? () => setDeleteModal() : undefined
              }
            />
            <ColumnItems
              items={rightItems}
              selectedItems={selectedCheats}
              select={(item: CheatsSheet) => selectItems(item)}
              setItemToDelete={
                setItemToDelete
                  ? (uuid: string) => setItemToDelete(uuid)
                  : undefined
              }
              setDeleteModal={
                setDeleteModal ? () => setDeleteModal() : undefined
              }
            />
          </>
        )}
      </div>
    </div>
  );
}
