import styled from "styled-components";
import { css } from "styled-components";
import { theme } from "../themes/variables";

export enum Spacing {
  none = "0",
  xs = "0.25rem",
  s = "0.5rem",
  m = "1rem",
  l = "1.5rem",
  xl = "2rem",
  xxl = "4rem",
  xxxl = "8rem",
  xxxxl = "16rem",
}

export type Align =
  | "flex-start"
  | "flex-end"
  | "space-between"
  | "center"
  | "space-around";

export const Column = styled.div<{
  align?: Align;
  justify?: Align;
  gutter?: Spacing;
}>`
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => props.justify || "flex-start"};
  align-items: ${(props) => props.align || "flex-start"};

  > * {
    width: 100%;
  }

  > * + * {
    margin-top: ${(props) => props.gutter || Spacing.m};
  }
`;

export const Row = styled.div<{
  align?: Align;
  justify?: Align;
  gutter?: Spacing;
  width?: number | string;
  wrap?: "wrap" | "nowrap";
}>`
  display: flex;
  flex-wrap: ${(props) => props.wrap || "nowrap"};
  justify-content: ${(props) => props.justify || "flex-start"};
  align-items: ${(props) => props.align || "flex-start"};
  width: ${({ width }) => {
    if (width) {
      if (typeof width === "number") {
        if (width <= 1) {
          return width * 100 + "%";
        } else {
          return width + "px";
        }
      } else {
        return width;
      }
    }
    return undefined;
  }};

  ${(props) => {
    const gutter = props.gutter || Spacing.m;

    if (props.wrap) {
      return css`
        margin-right: ${"-" + gutter};
        > * {
          margin-right: ${gutter};
        }
      `;
    } else {
      return css`
        > * + * {
          margin-left: ${gutter};
        }
      `;
    }
  }};
`;

export const nl2br = (text: string) => {
  return text.split(/\n/).map((item, i) => {
    return <p key={i}>{item}</p>;
  });
};

const H1 = styled.h1`
  color: ${theme.colors.dark};
  font-size: 40px;
  font-weight: bold;
  line-height: 60px;
  margin: 0;

  @media (max-width: 670px) {
    font-size: 24px;
    line-height: 28.8px;
  } ;
`;

const H2 = styled.h2`
  color: ${theme.colors.dark};
  font-size: 30px;
  font-weight: 400;
  margin: 0;
  line-height: 46px;

  @media (max-width: 670px) {
    font-size: 26px;
    line-height: 31.2px;
  } ;
`;

const H3 = styled.h3`
  color: ${theme.colors.dark};
  font-size: 24px;
  font-weight: 400;
  line-height: 42px;
  margin: 0;

  @media (max-width: 670px) {
    font-size: 20px;
    line-height: 26px;
  } ;
`;

const H4 = styled.h4`
  color: ${theme.colors.dark};
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  margin: 0;

  @media (max-width: 670px) {
    font-size: 16px;
    line-height: 26px;
  } ;
`;

const H5 = styled.h5`
  color: ${theme.colors.dark};
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
  margin-bottom: 0.4em;
`;

const H6 = styled.h6`
  color: ${theme.colors.dark};
  font-size: 10px;
  font-weight: 400;
  line-height: 1.6;
  margin: 0;
  margin-bottom: 0.4em;
`;

const Paragraph = styled.p`
  color: ${theme.colors.dark};
  font-weight: 400;
  margin: 0;
  font-size: 16px;
  line-height: 1.4;

  @media (max-width: 670px) {
    font-size: 18px;
    line-height: 26px;
  } ;
`;

const ParagraphBold = styled.p`
  color: ${theme.colors.dark};
  font-weight: bold;
  margin: 0;
  font-size: 16px;
  line-height: 1.6;

  @media (max-width: 670px) {
    font-size: 16px;
    line-height: 16px;
  } ;
`;

const Notifications = styled.p`
  color: ${theme.colors.dark};
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;

  @media (max-width: 670px) {
    font-size: 14px;
    line-height: 16px;
  } ;
`;

const CTA = styled.p`
  color: ${theme.colors.dark};
  font-weight: bold;
  margin: 0;
  font-size: 16px;
  line-height: 18.42px;

  @media (max-width: 670px) {
    font-size: 14px;
    line-height: 16px;
  } ;
`;

export const Headers = {
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
};

export const Text = {
  Paragraph,
  ParagraphBold,
  Notifications,
  CTA,
};
