import { css } from "styled-components/macro";
import { ISliderItem } from "../types/SliderItem";
import { theme } from "../themes/variables";
import { Headers, Text } from "../helpers/layout";
import { Button } from "./Button";
import { InstructorElement } from "./InstructorCard";
import { useResponsive } from "../hooks/useResponsive";

export function SliderElement(props: { item: ISliderItem }) {
  const { isMobile } = useResponsive();

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        padding: ${isMobile ? "25px" : "40px 60px"};
        border-radius: 20px;
        background-color: ${theme.colors.white};
        background-image: url(${props.item.cover_image?.image});
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 515px;
        box-sizing: border-box;
        margin-bottom: ${isMobile ? "20px" : "0"};
        min-width: 0;
      `}
    >
      <Headers.H1
        css={css`
          margin-bottom: 12px;
          max-width: 100%;
        `}
      >
        {props.item.title}
      </Headers.H1>
      <Text.Paragraph
        css={css`
          max-width: 80%;
          margin-bottom: 32px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 7;
        `}
      >
        {props.item.description}
      </Text.Paragraph>

      <InstructorElement
        smallAvatar
        instructor={{
          avatar: props.item.author_avatar,
          first_name: props.item.testimonial_author_title,
          last_name: "",
          introduction: props.item.testimonial_author_subtitle,
          id: 0,
        }}
        css={css`
          margin-bottom: 54px;
        `}
      />
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          margin: auto 0 0 0;
          padding-right: 100px;
        `}
      >
        <Button
          color={theme.colors.white}
          onClick={() => (window.location.href = props.item.link_url)}
        >
          {props.item.link_label}
        </Button>
      </div>
    </div>
  );
}
