import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { theme } from "../themes/variables";
import { usePagination } from "../hooks/usePagination";
import { getMyCourses } from "../actions/course/getMyCourses";
import { CourseListItem } from "../types/Course";
import { Headers, Row, Text } from "../helpers/layout";
import { Header } from "../components/Header";
import { Wrapper } from "../components/Wrapper";
import CourseCard from "../components/CourseCard";
import { SEO } from "../components/SEO";
import { Pagination } from "../components/Pagination";
import { Button } from "../components/Button";
import { ReactComponent as SkillSvg } from "../assets/svg/Skills.svg";
import { useResponsive } from "../hooks/useResponsive";
import { PageQueryHandler } from "./PageQueryHandler";

enum CourseStatus {
  ALL = "all",
  INPROGRESS = "in_progress",
  COMPLETED = "completed",
}

function Tab({
  name,
  isActive,
  setCourseStatus,
}: {
  name: string;
  isActive: boolean;
  setCourseStatus: () => void;
}) {
  const { isMobile } = useResponsive();

  return (
    <Text.Paragraph
      css={css`
        margin-right: 24px;
        color: ${isActive ? theme.colors.black : theme.colors.gray5};
        border-bottom: 1px solid
          ${isActive ? theme.colors.primary : theme.colors.transparent};
        cursor: pointer;

        ${isMobile &&
        css`
          font-size: 16px;
        `}
      `}
      onClick={setCourseStatus}
    >
      {name}
    </Text.Paragraph>
  );
}

export function CoursesPage(props: {}) {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();
  const history = useHistory();
  const params = new URLSearchParams(window.location.search);

  const [courseStatus, setCourseStatus] = useState<CourseStatus | string>(
    params.get("status") || CourseStatus.ALL
  );

  const {
    response: {
      error: coursesError,
      data: courses,
      isLoading: coursesIsLoading,
    },
    pagination: { onChose, onNext, onPrev, count, page, setPage, setQueryArg },
  } = usePagination<
    {
      count: number;
      next: string | null;
      previous: string | null;
      results: CourseListItem[];
    },
    {
      page: string;
      status: string;
    }
  >(getMyCourses, { limit: 6 });

  useEffect(() => {
    setPage(1);
    setQueryArg("status", courseStatus);
  }, [courseStatus, setPage, setQueryArg]);

  if (!courses || coursesError || coursesIsLoading) {
    return (
      <PageQueryHandler error={coursesError} isLoading={coursesIsLoading} />
    );
  }

  return (
    <Wrapper>
      <SEO>
        <title>{t("header.navigation.courses")}</title>
      </SEO>
      <Header />
      <div
        css={css`
          display: flex;
          flex-direction: column;
          margin-top: ${isMobile ? "14px" : "40px"};
          padding: ${isMobile ? "24px 20px 32px 20px" : "60px"};
          border-radius: 20px;
          background-color: ${theme.colors.white};
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: ${isMobile ? "column" : "row"};
            width: 100%;
            align-items: ${isMobile ? "left" : "center"};
            margin-bottom: ${isMobile ? "20px" : "40px"};
          `}
        >
          <Headers.H3
            css={css`
              margin-right: ${isMobile ? "0" : "145px"};
              margin-bottom: ${isMobile ? "14px" : "0"};
            `}
          >
            {t("course.courses")}
          </Headers.H3>
          <div
            css={css`
              display: flex;
              flex-direction: row;
              align-items: center;
              margin-top: ${isMobile ? "0" : "5px"};
            `}
          >
            <Tab
              name={t("course.all")}
              isActive={courseStatus === CourseStatus.ALL}
              setCourseStatus={() => setCourseStatus(CourseStatus.ALL)}
            />
            <Tab
              name={t("course.in_progress")}
              isActive={courseStatus === CourseStatus.INPROGRESS}
              setCourseStatus={() => setCourseStatus(CourseStatus.INPROGRESS)}
            />
            <Tab
              name={t("course.completed_all")}
              isActive={courseStatus === CourseStatus.COMPLETED}
              setCourseStatus={() => setCourseStatus(CourseStatus.COMPLETED)}
            />
          </div>
          {!isMobile && (
            <Button
              css={css`
                margin: auto 0 auto auto;
                border: 1px solid ${theme.colors.black};
                background-color: ${theme.colors.white};
                color: ${theme.colors.black};
              `}
              hoverStyles={`background: ${theme.colors.primary}; color: ${theme.colors.white}; border: 1px solid ${theme.colors.primary};`}
              iconRight
              icon={
                <SkillSvg width={14} height={14} color={theme.colors.primary} />
              }
              onClick={() => history.push("/me/skills/")}
            >
              {t("course.check_skills")}
            </Button>
          )}
        </div>
        <div
          css={css`
            display: grid;
            grid-template-columns: ${isMobile ? "100%" : "repeat(2, 1fr)"};
            grid-template-rows: auto(3, 1fr);
            grid-gap: ${isMobile ? "20px" : "30px"};
          `}
        >
          {courses?.results.length > 0 &&
            courses.results.map((course, id) => (
              <CourseCard key={id} item={course} hasBorders />
            ))}
        </div>
        {isMobile && (
          <Button
            css={css`
              margin: ${isMobile ? "32px auto 0 0" : "auto 0 auto auto"};
              border: 1px solid ${theme.colors.black};
              background-color: ${theme.colors.white};
              color: ${theme.colors.black};
            `}
            hoverStyles={`background: ${theme.colors.primary}; color: ${theme.colors.white}; border: 1px solid ${theme.colors.primary};`}
            iconRight
            icon={
              <SkillSvg width={14} height={14} color={theme.colors.primary} />
            }
            onClick={() => history.push("/me/skills/")}
          >
            {t("course.check_skills")}
          </Button>
        )}
        <Row align="center" justify="center">
          <Pagination
            css={css`
              margin-top: 60px;
            `}
            arrows
            currentItemIndex={page}
            itemsCount={count}
            onChose={onChose}
            onNext={onNext}
            onPrev={onPrev}
          />
        </Row>
      </div>
    </Wrapper>
  );
}
