import { useHistory } from "react-router-dom";
import { css } from "styled-components/macro";
import { Check } from "react-feather";
import { HeaderText } from "../HeaderText";
import { theme } from "../../themes/variables";
import { useTranslation } from "react-i18next";
import { Journey } from "../../types/Journey";
import courseIcon from "../../assets/img/Lesson-Text.png";
import { useResponsive } from "../../hooks/useResponsive";

export function JourneyCourses(props: { journey: Journey }) {
  const { t } = useTranslation();
  const history = useHistory();
  const { isMobile } = useResponsive();
  const { journey } = props;

  return (
    <div
      css={css`
        display: flex;
        flex-direction: ${isMobile ? "column" : "row"};
        margin-bottom: 50px;
      `}
    >
      <HeaderText title={t("course.the")} text={t("course.syllabus")} />
      <div
        css={css`
          width: ${isMobile ? "auto" : "100%"};
          margin-top: 10px;
          background-color: ${theme.colors.white};
          border-radius: 40px;
          padding: 0 30px 10px;
        `}
      >
        <ul
          css={css`
            padding: 0;
          `}
        >
          {journey.courses &&
            journey.courses.map((course, key) => {
              return (
                <li
                  css={css`
                    display: flex;
                    flex-flow: row wrap;
                    list-style: none;
                    font-size: 18px;
                    border-bottom: 2px solid ${theme.colors.gray4};
                    padding: 20px;
                    cursor: pointer;
                  `}
                  key={key}
                  onClick={() => {
                    history.push(`/course/${course.uuid}/`);
                  }}
                >
                  <div
                    css={css`
                      flex: 0 0 auto;
                    `}
                  >
                    {course.is_completed ? (
                      <Check
                        size={30}
                        color={theme.colors.primary}
                        css={css`
                          margin-right: 30px;
                        `}
                      />
                    ) : (
                      <img
                        src={courseIcon}
                        alt={course.name}
                        css={css`
                          width: 30px;
                          margin-right: 30px;
                        `}
                      />
                    )}
                  </div>
                  <div
                    css={css`
                      padding: 2px;
                      flex: 1 0 10%;
                    `}
                  >
                    {course.name}
                  </div>
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
}
