import { useCallback, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import queryString from "query-string";
import { AxiosResponse } from "axios";
import { omit } from "lodash";
import { useFetch } from "../actions/useAPI";
import { buildQueryString } from "../helpers/buildQueryString";
import { scrollTop } from "../helpers/scroll";

type DefaultT = {
  count: number;
  next: null | string;
  previous: null | string;
};

export function usePagination<T extends DefaultT, Q>(
  getFunction: (
    args: Q & { limit: number; offset: number }
  ) => Promise<AxiosResponse<T>>,
  options?: {
    limit?: number;
    internalArgs?: Omit<Q, "limit" | "offset">;
  }
) {
  const limit = options?.limit || 50;
  const location = useLocation();
  const history = useHistory();
  const queryArgs = queryString.parse(location.search) as Q & {
    page: string;
    ordering: string;
  };
  const page = parseInt(queryArgs.page) - 1 || 0;

  const cleanQueryArgs = omit(queryArgs, ["page"]) as unknown as Q;

  const { data, error, refetch, isLoading, setData } = useFetch(getFunction, {
    ...options?.internalArgs,
    ...cleanQueryArgs,
    limit: limit,
    offset: page * limit,
  });

  const setQueryArg = useCallback(
    (
      key: keyof Omit<
        Q & { page: number; ordering: string },
        "limit" | "offset"
      >,
      value?: string
    ) => {
      const queryArgs = queryString.parse(window.location.search);
      const url =
        window.location.pathname +
        "?" +
        buildQueryString({
          ...queryArgs,
          [key]: value,
        });

      history.push(url);
    },
    [history]
  );

  const setPage = useCallback(
    (page: number) => {
      setQueryArg("page", page.toString());
    },
    [setQueryArg]
  );

  useEffect(() => {
    scrollTop();
  }, [data]);

  useEffect(() => {
    if (
      (data && data.count > 0 && Math.ceil(data.count / limit) <= page) ||
      page < 0
    ) {
      setPage(1);
    }
  }, [data, page, limit, setPage]);

  const onChose = useCallback(
    (page: number) => {
      if (!isLoading) setPage(page + 1);
    },
    [isLoading, setPage]
  );

  const onNext = useCallback(() => {
    if (!data || !data.next || isLoading) {
      return;
    }

    setPage(Number(queryArgs.page) + 1);
  }, [data, isLoading, queryArgs.page, setPage]);

  const onPrev = useCallback(() => {
    if (!data || !data.previous || isLoading) {
      return;
    }

    setPage(Number(queryArgs.page) - 1);
  }, [data, isLoading, queryArgs.page, setPage]);

  return {
    response: {
      error,
      isLoading,
      data,
      refetch,
      setData,
    },
    pagination: {
      onChose,
      onNext,
      onPrev,
      page,
      setPage,
      queryArgs,
      setQueryArg,
      count: data ? Math.ceil(data.count / limit) : 0,
    },
  };
}
