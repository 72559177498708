import { authorised } from "../../request";
import { Order } from "../../types/Order";

export async function createOrder(
  courseId: string,
  data: Partial<Order> & {
    courses: string[];
  }
) {
  const API = authorised();

  return API.request<Order>({
    method: "POST",
    url: `/api/campus/courses/${courseId}/create-order/`,
    data,
  });
}
