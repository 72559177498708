import { authorised } from "../../request";
import { Address } from "../../types/Address";

export async function updateOrderBillingAddress(
  orderId: string,
  data: Address
) {
  const API = authorised();

  return API.request<{}>({
    method: "PUT",
    url: `/api/campus/orders/${orderId}/billing-address/`,
    data,
  });
}
