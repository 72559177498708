import { useState } from "react";
import { css } from "styled-components/macro";
import { theme } from "../../themes/variables";
import {
  SelfAssessment,
  SelfAssessmentAnswer,
} from "../../types/SelfAssessment";
import { Headers } from "../../helpers/layout";
import { useResponsive } from "../../hooks/useResponsive";

export function OpenQuestion(props: {
  selfAssessment: SelfAssessment;
  state: SelfAssessmentAnswer[];
  onStateChange?: (state: SelfAssessmentAnswer[]) => void;
  isReadOnly?: boolean;
}) {
  const [active, setActive] = useState(false);
  const { isMobile } = useResponsive();

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        height: 100%;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
        `}
      >
        <Headers.H4
          css={css`
            margin: 0 0 20px 0;

            ${isMobile &&
            css`
              text-align: center;
            `}
          `}
        >
          {props.selfAssessment.title}
        </Headers.H4>
        <label
          css={css`
            display: inline-flex;
            align-items: center;
            cursor: pointer;
            margin-bottom: 1rem;
          `}
        >
          <input
            value={props.isReadOnly ? props.state[0]?.answer : undefined}
            readOnly={props.isReadOnly}
            css={css`
              width: 100%;
              height: 34px;
              border: 1px solid;
              border-color: ${active || props.isReadOnly
                ? `${theme.colors.primary};`
                : `${theme.colors.transparentBlack3};`};
              outline: none;
              padding: 10px 20px;
              border-radius: 15px;
              font-size: 20px;
            `}
            onChange={(e) => {
              e.target.value ? setActive(true) : setActive(false);
              if (props.onStateChange) {
                e.target.value
                  ? props.onStateChange([{ id: "", answer: e.target.value }])
                  : props.onStateChange([]);
              }
            }}
          ></input>
        </label>
      </div>
    </div>
  );
}
