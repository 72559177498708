import React, { useCallback, useState } from "react";
import { css } from "styled-components/macro";
import { toast } from "react-toastify";
import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IUserDetails } from "../../types/UserProfile";
import { SignupFieldType, SignupFieldOption } from "../../types/SignupField";
import { Column, Spacing } from "../../helpers/layout";
import { TextField } from "../fields/TextField";
import { theme } from "../../themes/variables";
import { Button } from "../../components/Button";
import { SelectField } from "../fields/SelectField2";
import { CheckboxFields } from "../fields/CheckboxFields";
import { RadioFields } from "../fields/RadioFields";
import { TextareaField } from "../fields/TextareaField";
import { DangerZone } from "../../components/DangerZone";
import { ConfirmModal } from "../../components/ConfirmModal";
import { deleteMe } from "../../actions/account/deleteMe";
import { useAuth } from "../../contexts/UserContext";
import { Headers } from "../../helpers/layout";
import {
  getSignupFieldsInitialValues,
  getSignupFieldsSchema,
  getSignupFieldsServerValues,
} from "../../helpers/signupFields";

import { serverErrorHandler } from "../../helpers/serverErrorHandler";
import { useResponsive } from "../../hooks/useResponsive";
import { ReactComponent as MailSvg } from "../../assets/svg/Mail.svg";

type BooleanObject = { [x: string]: boolean };

export type FormValues = {
  first_name: string;
  last_name: string;
  introduction?: string;
  city: string;
  email: string;
} & {
  signup_fields: { [key: string]: string[] | BooleanObject[] };
};

export function PersonalInfoForm(props: {
  onSubmit: (values: FormValues) => Promise<void>;
  data: IUserDetails;
}) {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();
  const history = useHistory();
  const re = new RegExp("[a-zA-Z-]");

  const [deleteModal, setDeleteModal] = useState(false);
  const { deauthenticate } = useAuth();

  const onConfirmDelete = async () => {
    await deleteMe();
    setDeleteModal(false);
    deauthenticate();
    history.push(`/`);
  };

  const onErrorDelete = () => {
    toast.error(t("status.error"));
  };

  const onSubmit = useCallback(
    async (
      values: FormValues,
      { setSubmitting }: FormikHelpers<FormValues>
    ) => {
      try {
        setSubmitting(true);

        const signupFields = getSignupFieldsServerValues(
          values.signup_fields,
          props.data.signup_field_values.map((field) => field.signup_field)
        );

        await props.onSubmit({
          ...values,
          signup_fields: signupFields,
        });
      } catch (error: any) {
        toast.error(
          t("status.error", {
            error: serverErrorHandler(error),
          })
        );
      } finally {
        setSubmitting(false);
      }
    },
    [props, t]
  );

  const dynamicSchema = getSignupFieldsSchema(
    props.data.signup_field_values.map((el) => el.signup_field),
    t
  );

  const validationSchema = Yup.object().shape({
    first_name: Yup.string()
      .matches(re)
      .label(t("account.firstname"))
      .required(),
    last_name: Yup.string().matches(re).label(t("account.lastname")).required(),
    introduction: Yup.string().label(t("account.introduction")).max(500),
    city: Yup.string().matches(re).label(t("account.city")).required(),
    signup_fields: Yup.object().shape(dynamicSchema),
  });

  const initialValues: FormValues = {
    first_name: props.data.first_name,
    last_name: props.data.last_name,
    introduction: props.data?.introduction,
    city: props.data.city,
    email: props.data.email,
    signup_fields: getSignupFieldsInitialValues(props.data.signup_field_values),
  };

  return (
    <div
      css={css`
        width: 100%;
        margin-top: ${isMobile ? "40px" : 0};
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          margin-bottom: ${isMobile ? "40px" : "60px"};
        `}
      >
        <Headers.H1
          css={css`
            margin: 0;
            font-size: ${isMobile ? "24px" : "48px"}; //Refactor
          `}
        >
          {props.data.first_name}&nbsp;{props.data.last_name}
        </Headers.H1>
        <Headers.H3
          css={css`
            margin: 0;
            font-size: ${isMobile ? "20px" : "30px"}; //Refactor
          `}
        >
          {props.data.city}
        </Headers.H3>
        <div
          css={css`
            margin-top: 24px;
            display: flex;
            flex-direction: row;
          `}
        >
          <MailSvg />
          <div
            css={css`
              font-size: 16px;
              margin-left: 8px;
            `}
          >
            {props.data.email}
          </div>
        </div>
      </div>
      <Formik<FormValues>
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, dirty, isValid }) => (
          <Form noValidate>
            <Column
              gutter={Spacing.xl}
              css={css`
                display: flex;
                width: ${isMobile ? "100%" : "70%"};
              `}
            >
              <TextField
                name={"first_name"}
                type="text"
                label={t("account.firstname")}
                css={css`
                  border-color: ${theme.colors.gray3};
                  border-radius: 20px;
                  background-color: ${theme.colors.white};
                `}
              />
              <TextField
                name={"last_name"}
                type="text"
                label={t("account.lastname")}
                css={css`
                  border-color: ${theme.colors.gray3};
                  border-radius: 20px;
                  background-color: ${theme.colors.white};
                `}
              />
              <TextField
                name="email"
                type="text"
                disabled
                label={t("account.email")}
                css={css`
                  border-color: ${theme.colors.gray3};
                  border-radius: 20px;
                  background-color: ${theme.colors.white};
                `}
              />
              <TextareaField
                label={`${t("account.introduction")}:`}
                name={"introduction"}
                maxLength={500}
              />
              <TextField
                name={"city"}
                type="text"
                label={`${t("account.city")}`}
                css={css`
                  border-color: ${theme.colors.gray3};
                  border-radius: 20px;
                  background-color: ${theme.colors.white};
                `}
              />

              {props.data.signup_field_values.map(({ signup_field: field }) => {
                return (
                  <React.Fragment key={field.order}>
                    {field.type === SignupFieldType.TEXT_FIELD && (
                      <TextField
                        label={field.name}
                        name={`signup_fields.${field.uuid}[0]`}
                        placeholder={field.placeholder || ""}
                        css={css`
                          border: none;
                          background-color: ${theme.colors.gray1};
                        `}
                      />
                    )}
                    {field.type === SignupFieldType.TEXT_AREA && (
                      <TextareaField
                        label={field.name}
                        name={`signup_fields.${field.uuid}[0]`}
                        hint={field.hint || ""}
                        placeholder={field.placeholder || ""}
                      />
                    )}
                    {field.type === SignupFieldType.SELECT && (
                      <SelectField
                        label={field.name}
                        name={`signup_fields.${field.uuid}[0]`}
                        hint={field.hint || ""}
                        choices={field.options.map(
                          (option: SignupFieldOption) => {
                            return {
                              label: option.value,
                              value: option.value,
                            };
                          }
                        )}
                      />
                    )}
                    {field.type === SignupFieldType.RADIO && (
                      <RadioFields
                        label={field.name}
                        labelRight
                        name={`signup_fields.${field.uuid}[0]`}
                        hint={field.hint || ""}
                        choices={field.options.map((option) => {
                          return {
                            label: option.value,
                            value: option.value,
                          };
                        })}
                      />
                    )}
                    {field.type === SignupFieldType.CHECKBOX && (
                      <CheckboxFields
                        label={field.name}
                        name={`signup_fields.${field.uuid}`}
                        hint={field.hint || ""}
                        choices={field.options.map((option) => {
                          return {
                            label: option.value,
                            value: String(option.id),
                          };
                        })}
                      />
                    )}
                  </React.Fragment>
                );
              })}

              <Button
                isSubmitting={isSubmitting}
                type="submit"
                color={theme.colors.primary}
                disabled={!dirty || !isValid}
                css={css`
                  width: fit-content;
                  color: ${theme.colors.white};
                  margin-top: 54px;
                  font-weight: 600;
                  font-size: 16px;
                  padding: 15px 25px;
                `}
              >
                {t("account.update")}
              </Button>

              <DangerZone
                title={null}
                subTitle={null}
                css={css`
                  margin-top: 5rem;
                `}
              >
                <Button onClick={() => setDeleteModal(true)}>
                  {t("account.delete")}
                </Button>

                <ConfirmModal
                  modalIsOpen={deleteModal}
                  onClose={() => setDeleteModal(false)}
                  onConfirm={onConfirmDelete}
                  onError={onErrorDelete}
                  confirmBtnText={t("actions.delete")}
                  title={`${t("actions.sure")}?`}
                >
                  {t("modal.account_body")}
                </ConfirmModal>
              </DangerZone>
            </Column>
          </Form>
        )}
      </Formik>
    </div>
  );
}
