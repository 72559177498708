import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { css } from "styled-components/macro";
import { toast } from "react-toastify";
import { getCourseSelfAssessments } from "../actions/course/getSelfAssessments";
import { useFetch } from "../actions/useAPI";
import { getCourse } from "../actions/course/getCourse";
import { postSelfAssessment } from "../actions/course/postSelfAssessments";
import { serverErrorHandler } from "../helpers/serverErrorHandler";
import { pageLoading } from "../helpers/pageLoading";
import { Headers } from "../helpers/layout";
import { theme } from "../themes/variables";
import {
  SelfAssessment,
  SelfAssessmentAnswer,
  SelfAssessmentSubmition,
} from "../types/SelfAssessment";
import { SelfAssessmentRender } from "./SelfAssessment/Renderer";
import { Button } from "./Button";

export function CourseSelfAssessment(props: {
  courseId: string;
  redirect_to: string | null;
}) {
  const [submit, setSubmit] = useState(false);
  const [isSubmiting, setSubmiting] = useState(false);
  const history = useHistory();

  const { t } = useTranslation();

  const { data: selfAssessments, error: selfAssessmentsError } = useFetch(
    getCourseSelfAssessments,
    { courseId: props.courseId }
  );

  const { data: course, error: courseError } = useFetch(getCourse, {
    courseId: props.courseId,
  });

  const [answersState, setAnswersState] = useState<SelfAssessmentSubmition[]>(
    []
  );

  useEffect(() => {
    setAnswersState(
      selfAssessments
        ? selfAssessments.map((item) => {
            return {
              item: item,
              answers: [],
            };
          })
        : []
    );
  }, [selfAssessments]);

  const onSubmit = async () => {
    try {
      setSubmiting(true);
      await postSelfAssessment({
        courseId: props.courseId,
        data: answersState,
      });
      toast.success(t("status.success"));
      if (props.redirect_to) {
        history.push(props.redirect_to);
      }
    } catch (error: any) {
      toast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    } finally {
      setSubmiting(false);
    }
  };

  const setNewState = (
    answers: SelfAssessmentAnswer[],
    item: SelfAssessment
  ) => {
    setAnswersState((answersState) => {
      const newState: SelfAssessmentSubmition[] = answersState.map((state) => {
        if (state.item.uuid === item.uuid) {
          return {
            ...state,
            answers,
          };
        }

        return state;
      });
      newState.every((item) => item.answers.length)
        ? setSubmit(true)
        : setSubmit(false);

      return newState;
    });
  };

  if (!selfAssessments || selfAssessmentsError) {
    return pageLoading(selfAssessmentsError);
  }

  if (!course || courseError) {
    return pageLoading(selfAssessmentsError);
  }

  return (
    <div>
      {course.self_assessment_passed_id ? (
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
          `}
        >
          <Headers.H3
            css={css`
              color: ${theme.colors.gray4};
              font-weight: 400;
              margin-top: 50px;
              margin-bottom: 50px;
            `}
          >
            {t("not_found.title")}
          </Headers.H3>
          <Button onClick={() => history.goBack()}>{t("actions.back")}</Button>
        </div>
      ) : (
        <>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: center;
              text-align: center;
              width: 100%;
            `}
          >
            <Headers.H3
              css={css`
                color: ${theme.colors.gray4};
                font-weight: 400;
              `}
            >
              {t("self_assessment.course_label_name", {
                course_name: course.name,
              })}
            </Headers.H3>
            <Headers.H3
              css={css`
                color: ${theme.colors.gray4};
                font-weight: 400;
                padding: 0 50px;
              `}
            >
              {t("self_assessment.course_label")}
            </Headers.H3>
          </div>
          <div>
            <div
              css={css`
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                width: 100%;
                max-width: 800px;
                margin: 3rem auto 0;
              `}
            >
              {selfAssessments &&
                selfAssessments.map((item, key) => {
                  const answers = answersState.filter((answer) => {
                    return answer.item.uuid === item.uuid;
                  });

                  const state = answers.length > 0 ? answers[0].answers : [];
                  return (
                    <div
                      key={key}
                      css={css`
                        margin-bottom: 40px;
                        width: 100%;
                      `}
                    >
                      <SelfAssessmentRender
                        item={item}
                        state={state}
                        onStateChange={(answers) => {
                          setNewState(answers, item);
                        }}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
          <div
            css={css`
              width: 100%;
              display: flex;
              flex-flow: row wrap;
              justify-content: center;
            `}
          >
            <Button
              isSubmitting={isSubmiting}
              disabled={!submit}
              onClick={onSubmit}
            >
              {t("actions.submit")}
            </Button>
          </div>
        </>
      )}
    </div>
  );
}
