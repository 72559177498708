import { css } from "styled-components/macro";
import * as S from "./style";

export function Tooltip(props: {
  text: string;
  children: JSX.Element | string;
  className?: string;
}) {
  return (
    <div
      css={css`
        position: relative;
        display: inline-block;
        margin-right: 10px;
        margin-top: 3px;
        z-index: 9999;
        :hover ${S.TooltipText} {
          visibility: visible;
        }
      `}
      className={props.className}
    >
      {props.children}
      <S.TooltipText>{props.text}</S.TooltipText>
    </div>
  );
}
