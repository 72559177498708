import { css } from "styled-components/macro";
import { theme } from "../../themes/variables";
import { CheckboxComponent } from "../CheckboxComponent";
import { SubmissionTestAnswer } from "../../types/LessonTest";
import { Test } from "../../types/Test";
import { TestTitle } from "./TestTitle";
import { useResponsive } from "../../hooks/useResponsive";

export function MultipleChoiceQuiz(props: {
  state: SubmissionTestAnswer[];
  test: Test;
  isCorrect?: boolean | null;
  styleVariant?: "default" | "card";
  onStateChange: (state: SubmissionTestAnswer[]) => void;
}) {
  const { onStateChange, test, state } = props;
  const { isMobile } = useResponsive();
  const styleVariant = props.styleVariant || "default";
  const answers = state.filter((s) => s.answer).map((s) => s.id);

  if (!test.variant) return null;

  return (
    <div css={css``}>
      <TestTitle styleVariant={styleVariant}>{test.variant.title}</TestTitle>

      <div
        css={css`
          display: flex;
          flex-direction: column;
          margin-top: 1.5rem;
        `}
      >
        {test.variant.answers.map((answer, i) => {
          const isChecked = answers.includes(answer.id);

          return (
            <label
              key={i}
              css={css`
                display: inline-flex;
                align-items: center;
                cursor: pointer;
                border-bottom: 3px solid white;

                :last-child {
                  border-bottom: none;
                }

                ${styleVariant === "card" &&
                css`
                  padding: 0 25px;
                `}
              `}
            >
              <CheckboxComponent
                value={isChecked}
                name={`quiz--multiple-choice--${answer.id}`}
                checkedColor={
                  styleVariant === "card" ? theme.colors.dark : undefined
                }
                css={css`
                  margin-right: 1rem;
                `}
                onChange={() => {
                  const newState = state.map((s) => {
                    if (s.id === answer.id) {
                      return {
                        ...s,
                        answer: !s.answer,
                      };
                    }

                    return s;
                  });

                  onStateChange(newState);
                }}
              />

              <span
                css={css`
                  width: 500px;
                  padding: 10px 20px;

                  ${isMobile &&
                  css`
                    width: 100%;
                    padding: 10px 0;
                  `}
                `}
              >
                {answer.primary_text}
              </span>
            </label>
          );
        })}
      </div>
    </div>
  );
}
