import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { getProfileGeneralInformation } from "../actions/getProfileGeneralInformation";
import { useFetch } from "../actions/useAPI";
import { pageLoading } from "../helpers/pageLoading";
import { ProfileHeaderVertical } from "../components/Account/ProfileHeaderVertical";
import { JourneyComponent } from "../components/Journey/JourneyComponent";
import CourseCard from "../components/CourseCard";
import { CompetenceCard } from "../components/CompetenceCard";
import { Header } from "../components/Header";
import { Wrapper } from "../components/Wrapper";
import { SEO } from "../components/SEO";
import { useResponsive } from "../hooks/useResponsive";
import { Tabs } from "../components/Tabs";
import { theme } from "../themes/variables";
import { Column, Headers, Row } from "../helpers/layout";
import { useAuth } from "../contexts/UserContext";
import DashedPng from "../assets/svg/Dashed.png";
import BadgeSvg from "../assets/svg/BadgeIcon.svg";
import { Button } from "../components/Button";
import { ReactComponent as PencilSvg } from "../assets/svg/Pencil.svg";
import { ReactComponent as PowerOffSvg } from "../assets/svg/PowerOff.svg";
import FeaturedSlider from "../components/FeaturedSlider";
import { ReactComponent as DownSvg } from "../assets/svg/Down.svg";

export function UserOverviewPage(props: {}) {
  const history = useHistory();
  const [isExpanded, setIsExpanded] = useState(false);
  const { t } = useTranslation();
  const { isMobile } = useResponsive();
  const { data, error: userError } = useFetch(getProfileGeneralInformation);
  const { deauthenticate } = useAuth();
  const tabsData = [
    {
      title: "General information",
      link: `/account`,
      forceHightlight: true,
    },
    {
      title: t("account.personal_info"),
      link: `/account/data`,
    },
    {
      title: t("account.security"),
      link: `/account/security`,
    },
    {
      title: t("account.assessment"),
      link: `/account/self-assessment`,
    },
  ];

  if (!data || userError) {
    return pageLoading(userError);
  }

  const courses = isExpanded ? data.my_courses : data.my_courses.slice(0, 3);

  return (
    <>
      <Wrapper>
        <SEO>
          <title>{t("account.personal_info")}</title>
        </SEO>

        <Header />
        <Tabs isWrapped tabs={tabsData} />
        <div
          css={css`
            padding: ${isMobile ? "20px" : "60px 30px 60px 24px"};
            background-color: ${theme.colors.white};
            border-radius: 20px;
          `}
        >
          <Row
            css={css`
              display: ${isMobile ? "block" : "flex"};
            `}
          >
            <div
              css={css`
                flex: 0 0 200px;
                margin-left: ${isMobile ? "0" : "20px"};
                padding-right: ${isMobile ? "10px" : "53px"};
                align-self: stretch;
                display: flex;
                flex-direction: column;
                position: relative;
                min-width: 0;
                ${!isMobile &&
                css`
                  border-right: 1px solid ${theme.colors.gray3};
                `};
              `}
            >
              {data && (
                <>
                  <ProfileHeaderVertical
                    user={data}
                    isEditable
                    css={css`
                      position: ${isMobile ? "" : "sticky"};
                      top: 2rem;
                    `}
                  />
                  <div
                    css={css`
                      width: 100%;
                      margin-top: ${isMobile ? "10px" : 0};
                      position: ${isMobile ? "" : "sticky"};
                      top: 10rem;
                    `}
                  >
                    <Headers.H2
                      css={css`
                        margin-top: 14px;
                        overflow-wrap: break-word;
                      `}
                    >
                      {data.first_name}
                      <br />
                      {data.last_name}
                    </Headers.H2>
                    <Headers.H5
                      css={css`
                        margin: 0;
                      `}
                    >
                      {data.introduction}
                    </Headers.H5>

                    <hr
                      css={css`
                        background-image: url(${DashedPng});
                        height: 1px;
                        border: 0;
                        margin-top: 31px;
                        margin-left: 0;
                      `}
                    />
                  </div>
                  <ul
                    css={css`
                      padding: 0;
                      position: ${isMobile ? "" : "sticky"};
                      top: 20rem;
                    `}
                  >
                    {data.certificates.map((data) => {
                      return (
                        <li
                          key={data.id}
                          css={css`
                            display: flex;
                            align-items: center;
                            list-style: none;
                            margin: 12px 0;
                          `}
                        >
                          <img src={BadgeSvg} alt="badge-icon" />
                          <div
                            css={css`
                              flex: 1 0 30%;
                              margin: 0 0 0 8px;
                            `}
                          >
                            <Headers.H5>{data.course.name}</Headers.H5>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                  <div
                    css={css`
                      flex: 1 0 10%;
                      position: relative;
                      display: flex;
                      width: 100%;
                      align-items: flex-end;
                    `}
                  >
                    <div
                      css={css`
                        position: ${isMobile ? "" : "sticky"};
                        align-self: flex-end;
                        bottom: 1rem;
                        justify-content: left;
                        width: 100%;
                      `}
                    >
                      {!isMobile && (
                        <hr
                          css={css`
                            background-image: url(${DashedPng});
                            height: 1px;
                            border: 0;
                            margin-top: 31px;
                            margin-left: 0;
                          `}
                        />
                      )}

                      <div
                        css={css`
                          display: flex;
                          flex-direction: column;
                          margin: 0;
                        `}
                      >
                        <Button
                          css={css`
                            margin: 0;
                            align-self: flex-start;
                            padding: 10px 0;
                            display: inline-block;
                          `}
                          color={theme.colors.dark}
                          background="transparent"
                          hoverStyles="background-color: transparent;"
                          icon={
                            <PencilSvg
                              css={css`
                                path {
                                  fill: ${theme.colors.dark};
                                }
                                margin-right: 7px;
                              `}
                            />
                          }
                          onClick={() => history.push("/account/data")}
                        >
                          {t("user_profile.edit_profile")}
                        </Button>
                        <Button
                          css={css`
                            margin: 0;
                            align-self: flex-start;
                            padding: 10px 0;
                            display: inline-block;
                          `}
                          color={theme.colors.dark}
                          background="transparent"
                          hoverStyles="background-color: transparent;"
                          icon={
                            <PowerOffSvg
                              css={css`
                                path {
                                  fill: ${theme.colors.orange};
                                }
                                margin-right: 2px;
                              `}
                            />
                          }
                          onClick={() => {
                            deauthenticate();
                            history.push("/login");
                          }}
                        >
                          {t("header.my_profile.logout")}
                        </Button>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div
              css={css`
                flex: 1 0 10%;
                min-width: 0;
                margin-left: ${isMobile ? "0" : "40px"};
                margin-right: ${isMobile ? "0" : "30px"};
                margin-top: ${isMobile ? "75px" : "0"};
              `}
            >
              <FeaturedSlider
                items={data.my_journeys}
                title={<Headers.H4>{t("user_profile.my_journeys")}</Headers.H4>}
              >
                {(item, index) => (
                  <JourneyComponent
                    key={index}
                    journey={item}
                    css={css`
                      width: ${isMobile ? "75vw" : "420px"};
                      min-width: 0;
                      margin-right: ${isMobile ? "20px" : "40px"};
                      min-height: 500px;
                      max-height: fit-content;
                      box-sizing: border-box;
                    `}
                  />
                )}
              </FeaturedSlider>
              <hr
                css={css`
                  margin-top: 35px;
                  background-color: ${theme.colors.gray3};
                  height: 1px;
                  border: none;
                `}
              />
              <Headers.H4
                css={css`
                  margin-bottom: 30px;
                  padding-top: 35px;
                `}
              >
                {t("user_profile.my_courses")}
              </Headers.H4>
              <Column
                css={css`
                  margin-top: 10px;
                `}
              >
                {courses &&
                  courses.map((course) => {
                    return (
                      <CourseCard
                        item={course}
                        key={course.uuid}
                        css={css`
                          min-width: 0;
                          background-color: ${theme.colors.gray7};
                          box-sizing: border-box;
                          :hover {
                            color: transparent;
                          }
                        `}
                      />
                    );
                  })}
              </Column>
              <div
                css={css`
                  display: flex;
                  justify-content: center;
                  margin-top: 40px;
                `}
              >
                {data.my_courses.length > 3 && (
                  <Button
                    css={css`
                      margin-left: 5px;
                      padding: 0 10px;
                    `}
                    color={theme.colors.dark}
                    background="transparent"
                    hoverStyles="background-color: transparent;"
                    icon={
                      <DownSvg
                        css={css`
                          margin-top: 4px;
                          path {
                            fill: ${theme.colors.black};
                          }
                        `}
                      />
                    }
                    iconRight
                    onClick={() => {
                      setIsExpanded((expanded) => !expanded);
                    }}
                  >
                    {t("actions.show-more")}
                  </Button>
                )}
              </div>

              <hr
                css={css`
                  margin-top: 53px;
                  background-color: ${theme.colors.gray3};
                  height: 1px;
                  border: none;
                `}
              />
              <FeaturedSlider
                css={css`
                  margin: 0;
                  padding-top: 30px;
                `}
                items={data.my_competencies}
                title={
                  <Headers.H4
                    css={css`
                      padding-top: 10px;
                      width: 100%;
                    `}
                  >
                    {t("user_profile.my_competencies")}
                  </Headers.H4>
                }
              >
                {(item, index) => (
                  <CompetenceCard
                    key={index}
                    competence={item}
                    css={css`
                      width: ${isMobile ? "65vw" : "414px"};
                      height: 400px;
                      margin-right: ${isMobile ? "15px" : "30px"};
                      box-sizing: border-box;
                    `}
                  />
                )}
              </FeaturedSlider>
            </div>
          </Row>
        </div>
      </Wrapper>
    </>
  );
}
