import React from "react";
import { css } from "styled-components/macro";
import { CustomColumnsBlock } from "../../types/Block";
import { Spacing } from "../../helpers/layout";
import { useResponsive } from "../../hooks/useResponsive";

export function LessonColumns(props: {
  block: CustomColumnsBlock;
  onComplete?: () => void;
}) {
  const { columns, layout } = props.block.data;
  const { isMobile } = useResponsive();

  return (
    <div
      css={css`
        display: flex;
        flex-flow: row wrap;
        width: 100%;
      `}
    >
      {columns &&
        columns.map((column, key) => {
          const blockLayout = layout ? layout.split(" ")[key] : "";

          return (
            <div
              key={key}
              css={css`
                width: ${isMobile ? "auto" : blockLayout};
                padding: ${isMobile ? "0" : `0 ${Spacing.l}`};
                box-sizing: border-box;

                &:first-child {
                  padding-left: 0;
                }

                &:last-child {
                  padding-right: 0;
                }

                p:first-child {
                  margin-top: ${isMobile ? "20px" : "0"};
                }
              `}
            >
              {column.image && (
                <img
                  css={css`
                    width: auto;
                    max-width: 100%;
                  `}
                  src={column.image.image}
                  alt={column.image.upload_name}
                />
              )}

              <div
                dangerouslySetInnerHTML={{
                  __html: column.content || "",
                }}
              ></div>
            </div>
          );
        })}
    </div>
  );
}
