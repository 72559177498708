import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { Headers } from "../../helpers/layout";
import { useResponsive } from "../../hooks/useResponsive";
import { ICourse } from "../../types/Course";
import { CourseCTA } from "./CourseCTA";

export function CourseMeta(props: { course: ICourse }) {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();

  if (!props.course.course_for && !props.course.course_starts) {
    return null;
  }

  return (
    <div
      css={css`
        margin-bottom: ${isMobile ? "40px" : "100px"};
        margin-top: ${isMobile ? "20px" : "0"};
        padding: ${isMobile ? "0 22px" : "0 60px"};
      `}
    >
      {props.course.course_for && (
        <div
          css={css`
            margin-bottom: 40px;
          `}
        >
          <Headers.H2
            css={css`
              margin-bottom: 12px;
            `}
          >
            {t("course.for")}
          </Headers.H2>

          <Headers.H4
            dangerouslySetInnerHTML={{
              __html: props.course.course_for,
            }}
          ></Headers.H4>
        </div>
      )}

      {props.course.course_starts && (
        <div
          css={css`
            display: flex;
            flex-direction: ${isMobile ? "column" : "row"};
            justify-content: space-between;
            align-items: ${isMobile ? "left" : "center"};
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              width: ${isMobile ? "auto" : "80%"};
            `}
          >
            <Headers.H2
              css={css`
                margin-bottom: 12px;
              `}
            >{`${t("course.starts")}:`}</Headers.H2>

            <div
              css={css`
                display: flex;
                flex-direction: ${isMobile ? "column" : "row"};
                align-items: ${isMobile ? "flex-start" : "flex-end"};
              `}
            >
              <Headers.H4
                dangerouslySetInnerHTML={{
                  __html: props.course.course_starts,
                }}
              ></Headers.H4>
            </div>
          </div>

          {!props.course.is_completed && (
            <div>
              <CourseCTA
                course={props.course}
                css={css`
                  margin-top: ${isMobile ? "32px" : "40px"};
                `}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
