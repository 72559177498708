import React from "react";
import { css } from "styled-components/macro";
import { useResponsive } from "../hooks/useResponsive";
import { Footer } from "./Footer";

export function Wrapper(props: {
  children: React.ReactNode | React.ReactNode[];
  hideFooter?: boolean;
}) {
  const { isMobile } = useResponsive();

  return (
    <div
      css={css`
        width: ${isMobile ? "auto" : "100%"};
        max-width: 1280px;
        margin: 0 auto;
        min-height: 100vh;
        padding: ${isMobile ? "20px" : "0"};
      `}
    >
      {props.children}
      {!props.hideFooter && <Footer />}
    </div>
  );
}
