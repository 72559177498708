import React from "react";
import { toast } from "react-toastify";
import { Form, Formik } from "formik";
import { css } from "styled-components/macro";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { FormValues, inviteUser } from "../actions/inviteUser";
import { Column, Row, Spacing } from "../helpers/layout";
import { serverErrorHandler } from "../helpers/serverErrorHandler";
import { TextField } from "./fields/TextField";
import { theme } from "../themes/variables";
import { Button } from "../components/Button";

export function EmailInvitationForm() {
  const { t } = useTranslation();
  const re = new RegExp("[a-zA-Z-]");
  const [submitted, setSubmitted] = React.useState(false);

  const validationSchema = Yup.object().shape({
    first_name: Yup.string()
      .matches(re)
      .label(t("email_invitation.first_name"))
      .required(),
    last_name: Yup.string()
      .matches(re)
      .label(t("email_invitation.last_name"))
      .required(),
    email: Yup.string().email().label(t("email_invitation.email")).required(),
  });

  const initialValues: FormValues = {
    first_name: "",
    last_name: "",
    email: "",
  };

  if (submitted) {
    return (
      <div
        css={css`
          text-align: center;

          p {
            margin-bottom: 40px;
          }
        `}
      >
        <p>
          <b>{t("email_invitation.success")}</b>
        </p>
        <Button
          onClick={() => {
            setSubmitted(false);
          }}
        >
          {t("email_invitation.invite_more")}
        </Button>
      </div>
    );
  }

  return (
    <div>
      <Formik<FormValues>
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values: FormValues, { setSubmitting }) => {
          try {
            setSubmitting(true);

            await inviteUser(values);

            setSubmitted(true);
          } catch (error: any) {
            toast.error(
              t("status.error", {
                error: serverErrorHandler(error),
              })
            );
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, dirty, isValid, values }) => (
          <Form noValidate>
            <Column gutter={Spacing.none}>
              <Row gutter={Spacing.none} justify="flex-start">
                <TextField
                  name="first_name"
                  type="text"
                  placeholder={t("email_invitation.first_name")}
                  css={css`
                    border-radius: 10px 0 0 0;
                  `}
                />
                <TextField
                  name="last_name"
                  type="text"
                  placeholder={t("email_invitation.last_name")}
                  css={css`
                    border-radius: 0 10px 0 0;
                    margin-left: -1px;
                  `}
                />
              </Row>
              <TextField
                name="email"
                placeholder={t("email_invitation.email_placeholder")}
                type="email"
                css={css`
                  margin-top: 1px;
                `}
              />
              <div
                css={css`
                  width: 100%;
                `}
              >
                <div
                  css={css`
                    background-color: ${theme.colors.gray1};
                    color: ${theme.colors.gray4};
                    margin-bottom: -1px;
                    padding: 7px 10px;
                    font-size: 14px;
                    border: 1px solid ${theme.colors.transparentBlack3};
                    margin-top: -1px;
                    margin-bottom: 20px;
                    border-radius: 0 0 10px 10px;
                  `}
                >
                  {t("email_invitation.email_hint")}
                </div>
              </div>
            </Column>

            <div
              css={css`
                margin-top: 10px;
                display: flex;
                flex-direction: column;
              `}
            >
              <Button
                isSubmitting={isSubmitting}
                type="submit"
                color={theme.colors.primary}
                disabled={!dirty || !isValid}
                css={css`
                  margin: 0 auto;
                  color: ${theme.colors.white};
                `}
              >
                {t("email_invitation.invite")}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
