import { css } from "styled-components/macro";
import { theme } from "../themes/variables";
import { NavigationFooter } from "./NavigationFooter";
import { useEnterprise } from "../contexts/EnterpriseContext";
import { useResponsive } from "../hooks/useResponsive";

export function Footer() {
  const { enterprise } = useEnterprise();

  const { isMobile } = useResponsive();

  return (
    <div
      css={css`
        display: flex;
        flex-direction: ${isMobile ? "column" : "row"};
        justify-content: space-between;
        width: 100%;
        height: ${isMobile ? "auto" : "40px"};
        background-color: ${theme.colors.white};
        border-radius: 20px;
        box-shadow: 0 3px 0 0 ${theme.colors.transparentBlack2};
        margin-top: ${isMobile ? "40px" : "60px"};
        margin-bottom: ${isMobile ? "0px" : "80px"};
        align-items: center;
        font-size: 16px;
        font-weight: 400;
      `}
    >
      <NavigationFooter />
      <div
        css={css`
          padding: 0 20px;
          margin-bottom: ${isMobile ? "20px" : "0"};
        `}
      >
        © {enterprise.name}
      </div>
    </div>
  );
}
