import { authorised } from "../request";

export type FormValues = {
  first_name: string;
  last_name: string;
  email: string;
};

export async function inviteUser(userData: FormValues) {
  const API = authorised();

  return await API.request({
    method: "POST",
    url: `/api/campus/invite-user/`,
    data: userData,
  });
}
