import React, { useState } from "react";
import { Flag, ThumbsUp, ThumbsDown } from "react-feather";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { css } from "styled-components/macro";
import { postLessonFeedback } from "../../actions/course/postLessonFeedback";
import { Text } from "../../helpers/layout";
import { serverErrorHandler } from "../../helpers/serverErrorHandler";
import { theme } from "../../themes/variables";
import { Feedback } from "../../types/Lesson";
import { Button } from "../Button";
import { Modal } from "../Modal/Modal";
import { ModalBody } from "../Modal/ModalBody";
import { ModalFooter } from "../Modal/ModalFooter";
import { ModalHeader } from "../Modal/ModalHeader";
import { useResponsive } from "../../hooks/useResponsive";

function FeedbackButton(props: {
  label: string;
  text: string;
  onChange: () => void;
  state: string;
}) {
  const { isMobile } = useResponsive();

  return (
    <div
      css={css`
        display: flex;
        padding: 10px 20px;
        background-color: ${props.state === props.label
          ? theme.colors.primary
          : theme.colors.gray2};
        color: ${props.state === props.label
          ? theme.colors.white
          : theme.colors.black};
        border-radius: 40px;
        align-items: center;
        margin-right: 20px;
        cursor: pointer;
      `}
      onClick={() => {
        props.onChange();
      }}
    >
      {props.label === Feedback.POSITIVE ? (
        <ThumbsUp
          css={
            !isMobile &&
            css`
              margin-right: 15px;
            `
          }
        />
      ) : (
        <ThumbsDown
          css={
            !isMobile &&
            css`
              margin-right: 15px;
            `
          }
        />
      )}
      {!isMobile && props.text}
    </div>
  );
}

export default function LessonFeedbackModal(props: {
  enrolmentId: string;
  lessonId: string;
}) {
  const { isMobile } = useResponsive();

  const { t } = useTranslation();

  const [deleteModal, setDeleteModal] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);

  const submitFeedback = async () => {
    try {
      setLoading(true);
      await postLessonFeedback({
        enrolmentId: props.enrolmentId,
        lessonId: props.lessonId,
        data: { feedback: feedback, comment: comment },
      });

      toast.success(t("status.success"));
    } catch (error: any) {
      toast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    } finally {
      setLoading(false);
      setDeleteModal(false);
    }
  };
  return (
    <div
      css={css`
        margin: auto 0 0 auto;
      `}
    >
      <Text.CTA
        css={css`
          display: flex;
          align-items: center;
          cursor: pointer;
          font-weight: 500;
          cursor: pointer;
        `}
        onClick={() => setDeleteModal(true)}
      >
        <Flag
          size={26}
          color={theme.colors.black}
          fill={theme.colors.black}
          css={css`
            margin-right: 10px;
          `}
        />
        {t("lesson.leave_feedback")}
      </Text.CTA>

      <Modal
        modalIsOpen={deleteModal}
        onClose={() => setDeleteModal(false)}
        isMobile={isMobile}
      >
        <ModalHeader
          css={css`
            padding-top: 30px;
            font-size: 20px;
          `}
        >
          {t("lesson.leave_feedback")}
        </ModalHeader>

        <ModalBody>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              width: 100%;
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: row;
                width: 100%;
                margin-top: 15px;
              `}
            >
              <FeedbackButton
                label={Feedback.POSITIVE}
                text={t("lesson.like")}
                onChange={() => {
                  setFeedback(Feedback.POSITIVE);
                }}
                state={feedback}
              />
              <FeedbackButton
                label={Feedback.NEGATIVE}
                text={t("lesson.dislike")}
                onChange={() => {
                  setFeedback(Feedback.NEGATIVE);
                }}
                state={feedback}
              />
            </div>
            <textarea
              placeholder={t("lesson.comment")}
              css={css`
                margin: 40px 0 20px;
                padding: 15px;
                height: 200px;
                font-size: 18px;
                border: 1px solid ${theme.colors.transparentBlack4};
                border-radius: 10px;
              `}
              onChange={(e) => setComment(e.target.value)}
            ></textarea>
          </div>
        </ModalBody>

        <ModalFooter>
          <Button
            background={theme.colors.white}
            color={theme.colors.dark}
            border={`1px solid ${theme.colors.dark}`}
            hoverStyles={`color: ${theme.colors.white}; background: ${theme.colors.primary}; border: 1px solid ${theme.colors.primary};`}
            onClick={() => setDeleteModal(false)}
          >
            {t("modal.cancel")}
          </Button>

          <Button
            disabled={feedback && comment ? false : true}
            isSubmitting={loading}
            onClick={() => {
              submitFeedback();
            }}
          >
            {t("modal.submit")}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
