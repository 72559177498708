import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { theme } from "../../themes/variables";
import { RadioComponent } from "../RadioComponent";
import { SubmissionTestAnswer } from "../../types/LessonTest";
import { Test } from "../../types/Test";
import { TestTitle } from "./TestTitle";
import { useResponsive } from "../../hooks/useResponsive";

const TrueFalseVariant = (props: {
  children: JSX.Element;
  value: boolean;
  styleVariant?: "default" | "card";
  name: string;
  onChange: () => void;
  isLastItem?: boolean;
}) => {
  const { isMobile } = useResponsive();

  return (
    <label
      css={css`
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        border-bottom: ${props.isLastItem ? "none" : "3px solid white"};

        ${props.styleVariant === "card" &&
        css`
          padding: 0 25px;
        `}
      `}
    >
      <RadioComponent
        value={props.value}
        name={props.name}
        checkedColor={
          props.styleVariant === "card" ? theme.colors.dark : undefined
        }
        css={css`
          margin-bottom: 5px;
          margin-right: 1.5rem;
        `}
        onChange={props.onChange}
      />
      <span
        css={css`
          width: 500px;
          padding: 10px 20px;

          ${isMobile &&
          css`
            width: 100%;
            padding: 10px 0;
          `}
        `}
      >
        {props.children}
      </span>
    </label>
  );
};

export function TrueFalse(props: {
  test: Test;
  isCorrect?: boolean | null;
  state: SubmissionTestAnswer;
  styleVariant?: "default" | "card";
  onStateChange: (state: SubmissionTestAnswer) => void;
}) {
  const { onStateChange, test } = props;
  const { t } = useTranslation();
  const styleVariant = props.styleVariant || "default";

  if (!test.variant) return null;

  return (
    <div>
      <TestTitle styleVariant={styleVariant}>
        {test.variant.answers[0].primary_text}
      </TestTitle>

      <div
        css={css`
          display: flex;
          flex-direction: column;
          margin-top: 1.5rem;
        `}
      >
        <TrueFalseVariant
          styleVariant={props.styleVariant}
          name={`quiz--${test.uuid}--true`}
          value={props.state.answer === true}
          onChange={() => {
            onStateChange({
              id: test.variant!.answers[0].id,
              answer: true,
            });
          }}
        >
          {t("quiz.true-false.choice.true")}
        </TrueFalseVariant>
        <TrueFalseVariant
          styleVariant={props.styleVariant}
          name={`quiz--${test.uuid}--false`}
          value={props.state.answer === false}
          onChange={() => {
            onStateChange({
              id: test.variant!.answers[0].id,
              answer: false,
            });
          }}
          isLastItem
        >
          {t("quiz.true-false.choice.false")}
        </TrueFalseVariant>
      </div>
    </div>
  );
}
