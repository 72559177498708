import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { theme } from "../themes/variables";
import { MyOrder, OrderStatus } from "../types/Order";
import { formatDate } from "../helpers/time";
import { Headers } from "../helpers/layout";
import { Header } from "../components/Header";
import { Wrapper } from "../components/Wrapper";
import { Block } from "../components/Block";
import { SEO } from "../components/SEO";
import { Button } from "../components/Button";
import { TableHandler, TableHandlerColumn } from "../components/TableHandler";

import { getMyOrders } from "../actions/order";

export function MyOrdersPage(props: {}) {
  const { t } = useTranslation();

  const ref = useRef<any>(null);

  const getStatus = (status: OrderStatus) => {
    return status === OrderStatus.COMPLETED
      ? t("orders.status.completed")
      : status === OrderStatus.PAYMENT_FAILED
      ? t("orders.status.failed")
      : status === OrderStatus.REFUNDED
      ? t("orders.status.refunded")
      : status === OrderStatus.PENDING_PAYMENT
      ? t("orders.status.pending")
      : status === OrderStatus.PROCESSING
      ? t("orders.status.processing")
      : status === OrderStatus.DRAFT
      ? t("orders.status.draft")
      : status === OrderStatus.CANCELED
      ? t("orders.status.canceled")
      : status;
  };

  const getStatusColor = (status: OrderStatus) => {
    return status === OrderStatus.COMPLETED
      ? theme.colors.haki
      : status === OrderStatus.PAYMENT_FAILED
      ? theme.colors.error
      : status === OrderStatus.REFUNDED
      ? theme.colors.violetMain
      : status === OrderStatus.PENDING_PAYMENT
      ? theme.colors.blueBackground
      : status === OrderStatus.PROCESSING
      ? theme.colors.blueBackground
      : status === OrderStatus.CANCELED
      ? theme.colors.redBackground
      : theme.colors.gray6;
  };

  const tableHandlerColumns: TableHandlerColumn<MyOrder>[] = [
    {
      label: t("orders.table.order_number"),
      render: (row) => <div>{row.invoice_number}</div>,
    },
    {
      label: t("orders.table.products"),
      render: (row) => <div>{row.items.map((el) => el.name).join(", ")}</div>,
    },

    {
      label: t("orders.table.date"),
      render: (row) => (
        <div>{row.payment_date ? formatDate(row.payment_date) : ""}</div>
      ),
    },
    {
      label: t("orders.table.status"),
      render: (row) => (
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <div
            css={css`
              width: 8px;
              height: 8px;
              margin-right: 8px;
              background-color: ${getStatusColor(row.status)};
              border-radius: 50%;
            `}
          ></div>
          {getStatus(row.status)}
        </div>
      ),
    },
    {
      label: t("orders.table.total_price"),
      render: (row) => (
        <div
          css={css`
            font-weight: bold;
          `}
        >
          {row.total + " EUR"}
        </div>
      ),
    },

    {
      label: t("orders.table.actions"),
      render: (row) => (
        <Button
          css={css`
            white-space: nowrap;
          `}
          onClick={() => {
            window.open(
              `${process.env.REACT_APP_BACKEND_URL}/api/campus/orders/${row.uuid}/invoice/`
            );
          }}
        >
          {t("orders.table.download_invoice")}
        </Button>
      ),
    },
  ];

  return (
    <Wrapper>
      <SEO>
        <title>{t("orders.my_orders")}</title>
      </SEO>
      <Header />
      <Block>
        <div
          css={css`
            padding: 40px 30px;
            border-radius: 20px;
            background-color: #ffffff;
          `}
        >
          <Headers.H3
            css={css`
              margin-bottom: 28px;
            `}
          >
            {t("orders.my_orders")}
          </Headers.H3>
          <TableHandler
            forwardRef={ref}
            tableData={{
              columns: tableHandlerColumns,
            }}
            getFunction={getMyOrders}
            options={{
              limit: 10,
            }}
          />
        </div>
      </Block>
    </Wrapper>
  );
}
