import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { getProfile } from "../actions/auth/getProfile";
import { useFetch } from "../actions/useAPI";
import { PasswordUpdateForm } from "../forms/profile/PasswordUpdateForm";
import { Header } from "../components/Header";
import { Wrapper } from "../components/Wrapper";
import { SEO } from "../components/SEO";
import { PageQueryHandler } from "./PageQueryHandler";
import { useResponsive } from "../hooks/useResponsive";
import { Tabs } from "../components/Tabs";
import { theme } from "../themes/variables";

export function UserSecurityPage(props: {}) {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();

  const {
    data: user,
    error: userError,
    isLoading: userIsLoading,
  } = useFetch(getProfile);

  if (!user || userError || userIsLoading) {
    return <PageQueryHandler error={userError} isLoading={userIsLoading} />;
  }

  const tabsData = [
    {
      title: "General information",
      link: `/account`,
    },
    {
      title: t("account.personal_info"),
      link: `/account/data`,
    },
    {
      title: t("account.security"),
      link: `/account/security`,
      forceHightlight: true,
    },
    {
      title: t("account.assessment"),
      link: `/account/self-assessment`,
    },
  ];

  return (
    <>
      <Wrapper>
        <SEO>
          <title>{t("account.security")}</title>
        </SEO>

        <Header />
        <Tabs isWrapped tabs={tabsData} />

        <div
          css={css`
            display: flex;
            padding: ${isMobile ? "20px" : "60px"};
            background-color: ${theme.colors.white};
            border-radius: 20px;
          `}
        >
          <PasswordUpdateForm data={user} />
        </div>
      </Wrapper>
    </>
  );
}
