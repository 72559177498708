import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { ICourse } from "../../types/Course";
import { Headers } from "../../helpers/layout";
import { InstructorCard } from "../InstructorCard";
import { useResponsive } from "../../hooks/useResponsive";

export function CourseExperts(props: { course: ICourse }) {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();

  if (!props.course.instructors.length) return null;

  return (
    <div
      css={css`
        margin-bottom: ${isMobile ? "40px" : "60px"};
      `}
    >
      <Headers.H2
        css={css`
          margin-bottom: ${isMobile ? "20px" : "30px"};
        `}
      >
        {t("course.experts")}
      </Headers.H2>

      <div
        css={css`
          display: flex;
          flex-direction: ${isMobile ? "column" : "row"};
          flex-wrap: wrap;
          margin-right: ${isMobile ? "0" : "-40px"};
        `}
      >
        {props.course.instructors.map((instructor, key) => {
          return (
            <div
              key={key}
              css={css`
                width: ${isMobile ? "auto" : "50%"};
              `}
            >
              <InstructorCard
                key={`experts--${instructor.id}`}
                instructor={instructor}
                css={css`
                  margin-right: ${isMobile ? "0" : "40px"};
                  margin-bottom: ${isMobile ? "12px" : "0"};
                `}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
