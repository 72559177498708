import React, { Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { AccessibilityProvider } from "./contexts/AccessibilityContext";
import { UserProvider } from "./contexts/UserContext";
import { DRM } from "./components/DRM";
import { LoginPage } from "./pages/Auth/Login";
import { Page404 } from "./pages/Page404";
import { PrivateRoute, PublicRoute } from "./helpers/router";
import { DashboardPage } from "./pages/Dashboard";
import { RegistrationPage } from "./pages/Auth/Registration";
import { VerificationInfoPage } from "./pages/Auth/VerificationInfo";
import { LessonPage } from "./pages/LessonPage";
import { CoursePage } from "./pages/CoursePage";
import { UserDataPage } from "./pages/UserDataPage";
import { UserSecurityPage } from "./pages/UserSecurityPage";
import { toast, ToastContainer } from "react-toastify";
import { ResetPasswordPage } from "./pages/Auth/ResetPassword";
import { SetPasswordPage } from "./pages/Auth/SetPassword";
import { FaqPage } from "./pages/FaqPage";
import { ExamPage } from "./pages/ExamPage";
import { CoursesPage } from "./pages/CoursesPage";
import { JourneyPage } from "./pages/JourneyPage";
import { BadgePage } from "./pages/Badge";
import SelfAssessmentPage from "./pages/SelfAssessmentPage";
import { ConfirmEmailPage } from "./pages/Auth/ConfirmEmail";

import { CheckVersionUpdate } from "./hooks/useCheckVersionUpdate";
import { CourseSummaryPage } from "./pages/CourseSummaryPage";
import { CoursePurchasePage } from "./pages/Payment/CoursePurchasePage";
import { PaymentMethodExistPage } from "./pages/Payment/PaymentMethodExist";
import { BillingInformationPage } from "./pages/Payment/BillingInformation";
import { PurchasePage } from "./pages/Payment/Purchase";
import { PaymentStatusPage } from "./pages/Payment/PaymentStatus";
import { UserSelfAssessment } from "./pages/UserSelfAssessment";
import { EnterpriseProvider } from "./contexts/EnterpriseContext";
import { LessonPreviewPage } from "./pages/LessonPreviewPage";
import { CheatsPage } from "./pages/CheatsPage";
import { SkillsPage } from "./pages/Skills";
import { MyOrdersPage } from "./pages/MyOrders";

import { CustomPage } from "./pages/CustomPage";
import { ResponsiveProvider } from "./hooks/useResponsive";
import { UserOverviewPage } from "./pages/UserOverviewPage";
import { EmailInvitationPage } from "./pages/EmailInvitation";

function App() {
  const { enableLinkTracking } = useMatomo();
  enableLinkTracking();

  return (
    <Suspense fallback="loading">
      <EnterpriseProvider>
        <AccessibilityProvider>
          <ResponsiveProvider>
            <UserProvider>
              <DRM />

              <Router>
                <Switch>
                  <PublicRoute path="/login" component={LoginPage} />
                  <PublicRoute
                    path="/register"
                    exact
                    component={RegistrationPage}
                  />
                  <PublicRoute
                    path="/verification-info"
                    exact
                    component={VerificationInfoPage}
                  />
                  <PublicRoute
                    path="/confirm-email"
                    exact
                    component={ConfirmEmailPage}
                  />
                  <PublicRoute
                    path="/reset-password"
                    exact
                    component={ResetPasswordPage}
                  />
                  <PublicRoute
                    path="/reset-password/:uuid/"
                    exact
                    component={SetPasswordPage}
                  />
                  <PrivateRoute
                    path="/journey/:id/"
                    exact
                    component={JourneyPage}
                  />
                  <Route path="/course/:id/" exact component={CoursePage} />
                  <PrivateRoute
                    path="/course/:id/self-assessment"
                    exact
                    component={SelfAssessmentPage}
                  />
                  <PrivateRoute
                    path="/course/:id/summary"
                    exact
                    component={CourseSummaryPage}
                  />
                  <PrivateRoute
                    path="/course/:courseId/purchase"
                    exact
                    component={CoursePurchasePage}
                  />
                  <PrivateRoute
                    path="/order/:orderId/payment-method"
                    exact
                    component={PaymentMethodExistPage}
                  />
                  <PrivateRoute
                    path="/order/:orderId/billing-information"
                    exact
                    component={BillingInformationPage}
                  />
                  <PrivateRoute
                    path="/order/:orderId/purchase"
                    exact
                    component={PurchasePage}
                  />
                  <PrivateRoute
                    path="/order/:orderId/payment-status"
                    exact
                    component={PaymentStatusPage}
                  />
                  <PrivateRoute
                    path="/course/:courseId/lesson-preview/:lessonId"
                    exact
                    component={LessonPreviewPage}
                  />
                  <PrivateRoute
                    path="/course-enrolments/:enrolmentID/lessons/:lessonID"
                    exact
                    component={LessonPage}
                  />
                  <PrivateRoute
                    path="/course-enrolments/:enrolmentID/exam"
                    exact
                    component={ExamPage}
                  />
                  <PrivateRoute
                    path="/account"
                    exact
                    component={UserOverviewPage}
                    
                  />
                  <PrivateRoute
                    path="/account/data"
                    exact
                    component={UserDataPage}
                  />
                  <PrivateRoute
                    path="/account/security"
                    exact
                    component={UserSecurityPage}
                  />
                  <PrivateRoute
                    path="/account/self-assessment"
                    exact
                    component={UserSelfAssessment}
                  />
                  <PrivateRoute path="/" exact component={DashboardPage} />
                  <PrivateRoute path="/courses" exact component={CoursesPage} />
                  <PrivateRoute
                    path="/self-assessment"
                    exact
                    component={SelfAssessmentPage}
                  />
                  <PrivateRoute path="/cheats" exact component={CheatsPage} />
                  <PrivateRoute path="/faq" exact component={FaqPage} />
                  <PrivateRoute path="/badges" exact component={BadgePage} />
                  <PrivateRoute
                    path="/email-invitation"
                    exact
                    component={EmailInvitationPage}
                  />
                  <Route path="/pages/:pageSlug" exact component={CustomPage} />
                  <PrivateRoute
                    path="/me/skills/:competenceId?"
                    exact
                    component={SkillsPage}
                  />
                  <PrivateRoute
                    path="/me/orders"
                    exact
                    component={MyOrdersPage}
                  />
                  <Route path="/" component={Page404} />
                </Switch>
              </Router>
            </UserProvider>
            <ToastContainer
              position={toast.POSITION.TOP_RIGHT}
              autoClose={5000}
            />
            <CheckVersionUpdate />
          </ResponsiveProvider>
        </AccessibilityProvider>
      </EnterpriseProvider>
    </Suspense>
  );
}

export default App;
