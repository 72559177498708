import React from "react";
import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { theme } from "../../themes/variables";
import { Headers, Text } from "../../helpers/layout";
import { Order, OrderPaymentOptions } from "../../types/Order";
import { LongDashedSeparator } from "../Separator";

import { useFetch } from "../../actions/useAPI";
import { pageLoading } from "../../helpers/pageLoading";
import { getCourse } from "../../actions/course/getCourse";

import PaymentPreview from "../../assets/img/Payment-Preview.png";

export function OrderReview<T extends Partial<Order>>(props: {
  values: T;
  isSubmitting: boolean;
  isValid?: boolean;
  courseId: string;
  children: React.ReactNode;
}) {
  const { t } = useTranslation();

  const { data: course, error: courseError } = useFetch(getCourse, {
    courseId: props.courseId,
  });

  if (!course || courseError) {
    return pageLoading(courseError);
  }

  const getPaymentMethod = (paymentType: OrderPaymentOptions | undefined) => {
    switch (paymentType) {
      case OrderPaymentOptions.PAYPAL:
        return "PayPal";
      case OrderPaymentOptions.CREDIT_CARD:
        return t("payment.credit-card");
      case OrderPaymentOptions.DIRECT_DEBIT:
        return t("payment.direct-debit");
      case OrderPaymentOptions.BANK_TRANSFER:
        return t("payment.invoice");
      default:
        return "";
    }
  };

  const vatRate =
    props.values.vat_price &&
    props.values.net_price &&
    +props.values.net_price > 0
      ? Math.round(
          (+props.values.vat_price / +props.values.net_price) * 100 * 100
        ) / 100
      : 0;

  return (
    <div
      css={css`
        flex: 0 0 calc(50% - 100px);
      `}
    >
      <div
        css={css`
          position: sticky;
          top: 60px;
        `}
      >
        <Headers.H4
          css={css`
            margin-bottom: 40px;
            font-size: 20px;
            line-height: 30px;
          `}
        >
          {t("payment.review-order")}:
        </Headers.H4>

        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;
            `}
          >
            <div
              css={css`
                display: flex;
                align-items: center;
              `}
            >
              <img
                src={PaymentPreview}
                alt="preview"
                width={40}
                height={40}
                css={css`
                  margin-right: 16px;
                `}
              />

              <Text.ParagraphBold>{course.name}</Text.ParagraphBold>
            </div>

            <Text.CTA>{course.price} €</Text.CTA>
          </div>
        </div>

        <LongDashedSeparator
          color={theme.colors.gray2}
          css={css`
            margin-top: 24px;
            margin-bottom: 16px;
          `}
        />

        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 16px;
          `}
        >
          <Text.Notifications>{t("payment.subtotal")}</Text.Notifications>
          <Text.CTA>{course.price} €</Text.CTA>
        </div>

        {props.values.payment_type && (
          <div
            css={css`
              display: flex;
              align-items: center;
              justify-content: space-between;
            `}
          >
            <Text.Notifications>
              {getPaymentMethod(props.values.payment_type)}
            </Text.Notifications>
            <Text.CTA>{t("payment.for-free")}</Text.CTA>
          </div>
        )}

        {props.values.billing_address && (
          <div
            css={css`
              margin-top: 16px;
            `}
          >
            <Text.Notifications>
              {props.values.billing_address.first_name && (
                <Text.Notifications>
                  {props.values.billing_address.first_name}{" "}
                </Text.Notifications>
              )}
              {props.values.billing_address.last_name && (
                <Text.Notifications>
                  {props.values.billing_address.last_name}
                </Text.Notifications>
              )}
            </Text.Notifications>

            {props.values.billing_address.company_name && (
              <Text.Notifications
                css={css`
                  margin-bottom: 15px;
                `}
              >
                {props.values.billing_address.company_name}
              </Text.Notifications>
            )}

            {props.values.billing_address.email && (
              <Text.Notifications>
                {props.values.billing_address.email}
              </Text.Notifications>
            )}

            {props.values.billing_address.phone_number && (
              <Text.Notifications
                css={css`
                  margin-bottom: 15px;
                `}
              >
                {props.values.billing_address.phone_number}
              </Text.Notifications>
            )}

            <Text.Notifications>
              {props.values.billing_address.country && (
                <Text.Notifications>
                  {t(`countries.${props.values.billing_address.country}`) +
                    ", "}
                </Text.Notifications>
              )}
              {props.values.billing_address.city && (
                <Text.Notifications>
                  {props.values.billing_address.city + ", "}
                </Text.Notifications>
              )}
              {props.values.billing_address.street_address && (
                <Text.Notifications>
                  {props.values.billing_address.street_address}
                </Text.Notifications>
              )}
            </Text.Notifications>

            <Text.Notifications>
              {props.values.billing_address.state && (
                <Text.Notifications>
                  {props.values.billing_address.state + ", "}
                </Text.Notifications>
              )}
              {props.values.billing_address.postal_code && (
                <Text.Notifications>
                  {props.values.billing_address.postal_code}
                </Text.Notifications>
              )}
            </Text.Notifications>
          </div>
        )}

        {props.values.total && (
          <>
            <LongDashedSeparator
              color={theme.colors.gray2}
              css={css`
                margin-top: 24px;
                margin-bottom: 24px;
              `}
            />

            <div
              css={css`
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 16px;
              `}
            >
              <div>{t("payment.net-price")}</div>
              <Text.CTA>{props.values.net_price} €</Text.CTA>
            </div>

            <div
              css={css`
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 8px;
                font-size: 16px;
              `}
            >
              <div>
                {t("payment.vat-price")}

                {vatRate && (
                  <span
                    css={css`
                      margin-left: 4px;
                    `}
                  >
                    ({vatRate} %)
                  </span>
                )}
              </div>
              <Text.CTA>{props.values.vat_price} €</Text.CTA>
            </div>

            <LongDashedSeparator
              color={theme.colors.gray2}
              css={css`
                margin-top: 24px;
                margin-bottom: 24px;
              `}
            />

            <div
              css={css`
                display: flex;
                align-items: center;
                justify-content: space-between;
              `}
            >
              <Text.Paragraph>{t("payment.grand-total-price")}</Text.Paragraph>

              <Headers.H3
                css={css`
                  font-weight: bold;
                `}
              >
                {props.values.total} €
              </Headers.H3>
            </div>
          </>
        )}

        {props.children}
      </div>
    </div>
  );
}
