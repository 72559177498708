import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { IUserDetails } from "../../types/UserProfile";
import { Column, Row, Spacing } from "../../helpers/layout";
import { TextField } from "../fields/TextField";
import { theme } from "../../themes/variables";
import { Button } from "../../components/Button";
import { updatePassword } from "../../actions/account/updatePassword";
import { toast } from "react-toastify";

import { serverErrorHandler } from "../../helpers/serverErrorHandler";
import { useResponsive } from "../../hooks/useResponsive";

interface FormValues {
  password: string;
  new_password: string;
  confirm_new_password: string;
}

export function PasswordUpdateForm(props: { data: IUserDetails }) {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();

  return (
    <div
      css={css`
        width: 100%;
      `}
    >
      {props.data && (
        <Formik<FormValues>
          initialValues={{
            password: "",
            new_password: "",
            confirm_new_password: "",
          }}
          validationSchema={Yup.object().shape({
            password: Yup.string().label(t("account.currentpwd")).required(),
            new_password: Yup.string()
              .min(8)
              .label(t("account.newpwd"))
              .required(),
            confirm_new_password: Yup.string()
              .label(t("account.repeatpwd"))
              .oneOf([Yup.ref("new_password"), null], t("auth.pwd_not_match"))
              .required(),
          })}
          onSubmit={async (
            values: FormValues,
            { setSubmitting }: FormikHelpers<FormValues>
          ) => {
            try {
              setSubmitting(true);

              await updatePassword(values);
              toast.success(t("status.success"));
            } catch (error) {
              toast.error(
                t("status.error", {
                  error: serverErrorHandler(error),
                })
              );
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting, dirty, isValid }) => (
            <Form noValidate>
              <Row>
                <Column
                  gutter={Spacing.l}
                  css={css`
                    display: flex;
                    width: ${isMobile ? "100%" : "50%"};
                  `}
                >
                  <TextField
                    name="password"
                    type="password"
                    label={t("account.currentpwd")}
                    css={css`
                      border-color: ${theme.colors.gray3};
                      border-radius: 20px;
                      background-color: ${theme.colors.white};
                    `}
                  />
                  <TextField
                    name="new_password"
                    type="password"
                    label={t("account.newpwd")}
                    css={css`
                      border-color: ${theme.colors.gray3};
                      border-radius: 20px;
                      background-color: ${theme.colors.white};
                    `}
                  />
                  <TextField
                    name="confirm_new_password"
                    type="password"
                    label={t("account.repeatpwd")}
                    css={css`
                      border-color: ${theme.colors.gray3};
                      border-radius: 20px;
                      background-color: ${theme.colors.white};
                    `}
                  />
                  <Button
                    isSubmitting={isSubmitting}
                    type="submit"
                    background={theme.colors.primary}
                    disabled={!dirty || !isValid}
                    css={css`
                      width: fit-content;
                      color: ${theme.colors.white};
                      margin-top: 54px;
                      font-weight: 600;
                      font-size: 16px;
                      padding: 15px 25px;
                    `}
                    hoverStyles={`background-color: ${theme.colors.primary}`}
                  >
                    {t("account.changepwd")}
                  </Button>
                </Column>
              </Row>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
}
