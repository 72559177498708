import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { Header } from "../components/Header";
import { Wrapper } from "../components/Wrapper";
import { SEO } from "../components/SEO";
import { ExpansionContainer } from "../components/ExpansionContainer";
import { ExpansionItem } from "../components/ExpansionItem";
import { IUserBadges } from "../types/UserBadge";
import { getBadgeList } from "../actions/badge";
import { useFetch } from "../actions/useAPI";
import { PageQueryHandler } from "./PageQueryHandler";

const itemWidth = 296;
const arrowTop = 200;

export function BadgePage(props: {}) {
  const { t } = useTranslation();

  const {
    data: badges,
    error: badgesError,
    isLoading: badgesIsLoading,
  } = useFetch(getBadgeList);

  if (!badges || badgesError || badgesIsLoading) {
    return <PageQueryHandler error={badgesError} isLoading={badgesIsLoading} />;
  }

  return (
    <Wrapper>
      <SEO>
        <title>{t("dashboard.achievements")}</title>
      </SEO>
      <Header />
      <div
        css={css`
          display: flex;
          flex-direction: column;
          margin-bottom: 100px;
        `}
      >
        <div>
          {badges && badges.length > 0 && (
            <ExpansionContainer<IUserBadges>
              hasArrow
              values={badges}
              journeyName={t("dashboard.achievements")}
              arrowTop={arrowTop}
            >
              {(data) => (
                <ExpansionItem
                  title={data.value.title}
                  icon={data.value.image}
                  width={itemWidth}
                  hasLockedState
                  isCompleted={data.value.has_received}
                />
              )}
            </ExpansionContainer>
          )}
        </div>
      </div>
    </Wrapper>
  );
}
