import React from "react";
import { Helmet } from "react-helmet";
import { useEnterprise } from "../contexts/EnterpriseContext";

export function SEO(props: { children?: React.ReactNode | React.ReactNode[] }) {
  const { enterprise } = useEnterprise();

  return (
    <Helmet titleTemplate={`%s | ${enterprise.name}`}>
      <meta name="description" content={enterprise.description} />
      <meta property="og:url" content={enterprise.campus_url} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={enterprise.name} />
      <meta property="og:description" content={enterprise.description} />
      <meta property="og:image" content={enterprise.preview_image?.image} />
      <meta property="og:image:alt" content={enterprise.description} />
      <meta property="og:site_name" content={enterprise.name} />
      <link rel="apple-touch-icon" href={enterprise.logo?.image} />
      {props.children}
    </Helmet>
  );
}
