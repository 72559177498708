import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Steps, Step } from "../../components/Steps";
import { Header } from "../../components/Header";
import { Wrapper } from "../../components/Wrapper";
import { SEO } from "../../components/SEO";
import { Card } from "../../components/Card";
import { StripeElements } from "../../components/Payment/StripeElements";
import { PaymentStatusWrapper } from "../../components/Payment/PaymentStatusWrapper";

import { useFetch } from "../../actions/useAPI";
import { getOrder } from "../../actions/order";
import { PageQueryHandler } from "../PageQueryHandler";

export function PaymentStatusPage(props: {}) {
  const params = useParams<{
    orderId: string;
  }>();

  const { t } = useTranslation();

  const clientSecret = new URLSearchParams(window.location.search).get(
    "payment_intent_client_secret"
  );

  const {
    data: order,
    error: orderError,
    isLoading: orderIsLoading,
  } = useFetch(getOrder, {
    orderId: params.orderId,
  });

  if (!order || orderError || orderIsLoading) {
    return <PageQueryHandler error={orderError} isLoading={orderIsLoading} />;
  }

  const stepsData: Step[] = [
    {
      title: t("payment.payment-method"),
      complete: true,
    },
    {
      title: t("payment.billing-information"),
      complete: true,
    },
    {
      title: t("payment.purchase"),
      complete: true,
    },
    {
      title: t("payment.start-learning"),
      current: true,
    },
  ];

  return (
    <Wrapper>
      <SEO>
        <title>{t("payment.start-learning")}</title>
      </SEO>
      <Header />

      <Steps steps={stepsData} />

      <Card>
        {clientSecret && (
          <StripeElements clientSecret={clientSecret}>
            <PaymentStatusWrapper order={order} clientSecret={clientSecret} />
          </StripeElements>
        )}
      </Card>
    </Wrapper>
  );
}
