import { css } from "styled-components/macro";
import { Row, Spacing, Text } from "../helpers/layout";
import { theme } from "../themes/variables";
import { Avatar } from "./Avatar";
import { Instructor } from "../types/Instructor";

export function InstructorElement(props: {
  instructor: Instructor;
  className?: string;
  small?: boolean;
  smallAvatar?: boolean;
}) {
  return (
    <Row gutter={Spacing.l} align="flex-start" className={props.className}>
      <div
        css={css`
          flex: 0 0 ${props.smallAvatar ? "40px" : "90px"};
        `}
      >
        <Avatar path={props.instructor.avatar?.image} />
      </div>
      <div
        css={css`
          text-overflow: ellipsis;
          overflow: hidden;
        `}
      >
        <Text.CTA css={css``}>
          {props.instructor.first_name} {props.instructor.last_name}
        </Text.CTA>

        <Text.Notifications
          css={css`
            margin: 0;
            color: ${theme.colors.gray6};

            text-overflow: ellipsis;
            overflow: hidden;

            ${props.small &&
            css`
              font-size: 14px;
            `}
          `}
        >
          {props.instructor.introduction}
        </Text.Notifications>
      </div>
    </Row>
  );
}

export function InstructorCard(props: {
  instructor: Instructor;
  small?: boolean;
  className?: string;
}) {
  const { instructor } = props;

  return (
    <div
      css={css`
        background-color: ${theme.colors.white};
        border-radius: 20px;
        padding: 24px;

        ${props.small &&
        css`
          background-color: ${theme.colors.lightBlue};
          padding: 15px;
          border-radius: 50px;
          max-width: 300px;
        `}
      `}
      className={props.className}
    >
      <InstructorElement instructor={instructor} small={props.small} />
    </div>
  );
}
