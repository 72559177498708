import { useState } from "react";
import { css } from "styled-components/macro";
import avatar from "../../assets/img/Default_Avatar.png";
import { ImageUploadModal } from "../../modals/ImageUploadModal";
import { theme } from "../../themes/variables";
import { IUserDetails } from "../../types/UserProfile";
import { Button } from "../Button";
import { ReactComponent as PencilSvg } from "../../assets/svg/Pencil.svg";

export function ProfileHeader(props: {
  user: IUserDetails;
  isEditable?: boolean;
}) {
  const [modal, setModal] = useState(false);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-right: 60px;
      `}
    >
      <div
        css={css`
          position: relative;
          display: flex;
          border-radius: 50%;
          width: 140px;
          height: 140px;

          background-repeat: no-repeat;
          background-size: cover;
          background-position: center center;

          ${props.user.avatar
            ? css`
                background-image: url(${props.user.avatar.image});
              `
            : css`
                background-image: url(${avatar});
              `}
        `}
      >
        {props.isEditable && (
          <Button
            css={css`
              display: flex;
              position: absolute;
              bottom: 0;
              right: 0;
              padding: 14px;
              background-color: ${theme.colors.primary};
              align-items: center;
              justify-content: center;
            `}
            icon={<PencilSvg />}
            onClick={() => setModal(true)}
          />
        )}
      </div>

      <ImageUploadModal
        isVisible={modal}
        onClose={() => {
          setModal(false);
        }}
      />
    </div>
  );
}
