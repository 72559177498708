import { css } from "styled-components/macro";
import { ChevronLeft } from "react-feather";
import { ChevronRight } from "react-feather";
import { useTranslation } from "react-i18next";
import { theme } from "../../themes/variables";
import { ILessonData } from "../../types/Lesson";
import { Link } from "react-router-dom";
import { useResponsive } from "../../hooks/useResponsive";

export function LessonNavigation(props: {
  item: ILessonData;
  onComplete: () => void;
}) {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();

  return (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
        white-space: nowrap;
        width: 100%;
        max-width: ${isMobile ? "auto" : "300px"};
        margin-top: ${isMobile ? "0" : "14px"};
      `}
    >
      {props.item.lesson.previous ? (
        <Link
          to={`/course-enrolments/${props.item.lesson.course.enrolment_id}/lessons/${props.item.lesson.previous.uuid}`}
          css={css`
            display: flex;
            align-items: center;
            cursor: pointer;
            font-weight: bold;
            font-size: 14px;
            color: ${theme.colors.black};
          `}
        >
          <ChevronLeft
            size={20}
            color={theme.colors.primary}
            css={css`
              margin-right: 10px;
            `}
          />
          {t("lesson.previous")}
        </Link>
      ) : isMobile ? (
        ""
      ) : (
        <div></div>
      )}
      <button
        onClick={props.onComplete}
        css={css`
          background: none;
          border: none;
          display: flex;
          align-items: center;
          cursor: pointer;
          font-size: 14px;
          font-weight: bold;
          font-family: inherit;
          color: ${theme.colors.black};
        `}
      >
        {props.item.lesson.next
          ? t("lesson.next")
          : props.item.lesson.has_exam_tests
          ? t("lesson.go_to_exam")
          : t("lesson.next")}
        <ChevronRight
          size={20}
          color={theme.colors.primary}
          css={css`
            margin-left: 10px;
          `}
        />
      </button>
    </div>
  );
}
