import {
  SelfAssessment,
  SelfAssessmentAnswer,
  SelfAssessmentType,
} from "../../types/SelfAssessment";
import { MultipleChoice } from "./MultipleChoice";
import { OpenQuestion } from "./OpenQuestion";
import { SingleChoice } from "./SingleChoice";

export function SelfAssessmentRender(props: {
  item: SelfAssessment;
  state: SelfAssessmentAnswer[];
  onStateChange?: (state: SelfAssessmentAnswer[]) => void;
  isReadOnly?: boolean;
}) {
  const isChecked = (
    items: SelfAssessmentAnswer[],
    value: SelfAssessmentAnswer
  ): boolean => {
    return (
      items.filter((item) => {
        return item.id === value.id;
      }).length > 0
    );
  };

  return (
    <>
      {props.item.type === SelfAssessmentType.OPEN_QUESTION && (
        <OpenQuestion
          selfAssessment={props.item}
          state={props.state}
          onStateChange={props.onStateChange}
          isReadOnly={props.isReadOnly}
        />
      )}
      {props.item.type === SelfAssessmentType.SINGLE_CHOICE && (
        <SingleChoice
          selfAssessment={props.item}
          state={props.state}
          onStateChange={props.onStateChange}
          isReadOnly={props.isReadOnly}
          isChecked={isChecked}
        />
      )}
      {props.item.type === SelfAssessmentType.MULTIPLE_CHOICE && (
        <MultipleChoice
          selfAssessment={props.item}
          state={props.state}
          onStateChange={props.onStateChange}
          isReadOnly={props.isReadOnly}
          isChecked={isChecked}
        />
      )}
    </>
  );
}
