import { AxiosResponse } from "axios";
import { useState, useCallback } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";

export function useFetch<T, Y>(
  action: (args: Y) => Promise<AxiosResponse<T>>,
  args?: Y
) {
  const [data, setData] = useState<T | null>(null);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const localArgs = args || {};

  const fetchData = useCallback(
    async (funcArgs: Y) => {
      try {
        setLoading(true);
        const response = await action(funcArgs);
        setData(response.data);
      } catch (e: any) {
        setError(e.response.data.detail || "Error occured");
      } finally {
        setLoading(false);
      }
    },
    [action]
  );

  useDeepCompareEffect(() => {
    fetchData(localArgs as Y);
  }, [localArgs]);

  return {
    data,
    isLoading,
    setData: (data: T) => setData(data),
    error,
    refetch: () => fetchData(localArgs as Y),
  };
}
