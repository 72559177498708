import { authorised } from "../request";
import { SelfAssessmentSubmition } from "../types/SelfAssessment";

type Args = {
  data: SelfAssessmentSubmition[];
};

export async function postSelfAssessment({ data }: Args) {
  const API = authorised();

  return API.request<SelfAssessmentSubmition>({
    method: "POST",
    url: `/api/campus/me/self-assessment/`,
    data,
  });
}
