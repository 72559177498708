import React from "react";
import { css } from "styled-components/macro";
import { CustomPPTBlock } from "../../types/Block";
import { useResponsive } from "../../hooks/useResponsive";

export function LessonPPTBlock(props: {
  block: CustomPPTBlock;
  onComplete?: () => void;
}) {
  const { file } = props.block.data;
  const { isMobile } = useResponsive();

  if (!file) return null;

  return (
    <div
      css={css`
        display: flex;
        flex-flow: row wrap;
        width: 100%;
      `}
    >
      <iframe
        src={`https://view.officeapps.live.com/op/embed.aspx?src=${file.file}`}
        width="100%"
        height={isMobile ? "300px" : "600px"}
        frameBorder="0"
        title="slides"
      ></iframe>
    </div>
  );
}
