import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { theme } from "../../themes/variables";
import { Headers } from "../../helpers/layout";
import { downloadResource } from "../../helpers/download";
import { ICourse } from "../../types/Course";
import {
  AdditionalMaterialIcon,
  AdditionalMaterialType,
} from "../../types/AdditionalMaterial";
import { Button } from "../Button";
import { Card } from "../Card";
import { ReactComponent as VideoSvg } from "../../assets/svg/Video.svg";
import { ReactComponent as LinkSvg } from "../../assets/svg/Link.svg";
import { ReactComponent as PodcastSvg } from "../../assets/svg/Podcast.svg";
import { ReactComponent as PDFSvg } from "../../assets/svg/PDF.svg";
import { useResponsive } from "../../hooks/useResponsive";

export function CourseAdditionalMaterial(props: { course: ICourse }) {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();

  if (!props.course.additional_materials.length || !props.course.enrolment_id) {
    return null;
  }

  return (
    <Card
      css={css`
        margin-bottom: 60px;
      `}
    >
      <Headers.H2
        css={css`
          margin-bottom: 40px;
        `}
      >
        {t("course.additional_material")}
      </Headers.H2>

      {props.course.additional_materials.map((material) => {
        const attachment = material.additional_material;

        const onMaterialClick = () => {
          if (attachment.type === AdditionalMaterialType.LINK) {
            if (attachment.link) {
              window.open(attachment.link);
            }
          } else if (attachment.type === AdditionalMaterialType.FILE) {
            if (attachment.file) {
              downloadResource(
                attachment.file.file,
                attachment.file.title || attachment.file.upload_name
              );
            }
          }
        };
        return (
          <Card
            key={material.order}
            css={css`
              padding: 25px;
              background-color: ${theme.colors.gray1};
              margin-bottom: 12px;

              :last-of-type {
                margin-bottom: 0;
              }
            `}
          >
            <div
              css={css`
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-flow: row wrap;

                ${isMobile &&
                css`
                  flex-direction: column;
                  align-items: flex-start;
                `}
              `}
            >
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  margin-right: 15px;
                  flex: 1 0 10%;

                  ${isMobile &&
                  css`
                    margin-right: 0;
                    margin-bottom: 15px;
                  `}
                `}
              >
                <div
                  css={css`
                    flex: 0 0 20px;
                  `}
                >
                  {attachment.icon === AdditionalMaterialIcon.LINK ? (
                    <LinkSvg color={theme.colors.primary} />
                  ) : attachment.icon === AdditionalMaterialIcon.PDF ? (
                    <PDFSvg color={theme.colors.primary} />
                  ) : attachment.icon === AdditionalMaterialIcon.PODCAST ? (
                    <PodcastSvg color={theme.colors.primary} />
                  ) : attachment.icon === AdditionalMaterialIcon.VIDEO ? (
                    <VideoSvg color={theme.colors.primary} />
                  ) : null}
                </div>

                <div
                  css={css`
                    margin-left: 16px;
                    cursor: pointer;
                  `}
                  onClick={onMaterialClick}
                >
                  {attachment.title}
                </div>
              </div>
              <div
                css={css`
                  flex: 0 0 auto;
                `}
              >
                <Button
                  color={theme.colors.dark}
                  background={theme.colors.transparent}
                  border={`1px solid ${theme.colors.dark}`}
                  hoverStyles={`background: ${theme.colors.primary}; color: ${theme.colors.white}; border: 1px solid ${theme.colors.transparent};`}
                  size="small"
                  onClick={onMaterialClick}
                  css={css`
                    text-transform: capitalize;
                  `}
                >
                  {attachment.type === AdditionalMaterialType.FILE
                    ? t("actions.download")
                    : t("actions.open")}
                </Button>
              </div>
            </div>
          </Card>
        );
      })}
    </Card>
  );
}
