import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { useHistory } from "react-router";
import { ChevronLeft, Check } from "react-feather";
import { Button } from "../Button";
import { ICourse } from "../../types/Course";
import { humanize } from "../../helpers/humanize";
import { Headers, Text } from "../../helpers/layout";
import { theme } from "../../themes/variables";
import play from "../../assets/img/play_light.svg";
import { ReactComponent as StudySvg } from "../../assets/svg/Study.svg";
import { ReactComponent as WatchSvg } from "../../assets/svg/Watch.svg";
import { ReactComponent as DownloadSvg } from "../../assets/svg/Download.svg";
import { ReactComponent as SummarySvg } from "../../assets/svg/Summary.svg";
import { CourseCTA } from "./CourseCTA";
import { useResponsive } from "../../hooks/useResponsive";
import { ReactComponent as CertificateSvg } from "../../assets/svg/CertificateMini.svg";

export function CourseHeader(props: { course: ICourse }) {
  const history = useHistory();
  const { t } = useTranslation();
  const { isMobile } = useResponsive();
  const { course } = props;
  const humanizedDuration = humanize(Number(course.duration) * 1000);

  const getSummary = () => {
    history.push(`/course/${course.uuid}/summary/`);
  };

  const downloadCertificate = () => {
    window.open(
      `${process.env.REACT_APP_BACKEND_URL}/api/campus/course-enrolments/${props.course.enrolment_id}/certificate/`
    );
  };

  const showPrice = !course.is_purchased && Number(course.price) > 0;

  return (
    <div
      css={css`
        position: relative;
        display: flex;
        flex-direction: column;
        border-radius: 20px;
        background-repeat: no-repeat;
        background-size: cover;
        padding: ${isMobile ? "40px 24px" : "40px 60px"};
        margin-bottom: ${isMobile ? "20px" : "40px"};

        ${course.image &&
        css`
          background-image: url(${course.image.image});
        `}
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
        `}
      >
        {course.journey && (
          <div
            css={css`
              display: flex;
              align-items: center;
            `}
          >
            <button
              css={css`
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                margin-right: 10px;
                margin-left: -10px;
                cursor: pointer;
                border: none;
                outline: none;
                background: none;
                :hover {
                  background-color: ${theme.colors.transparentBlack1};
                }
                :active {
                  background-color: ${theme.colors.transparentBlack2};
                }
              `}
              onClick={() => history.push(`/journey/${course.journey!.uuid}`)}
            >
              <ChevronLeft size={30} />
            </button>

            <Text.Paragraph>{course.journey.name}</Text.Paragraph>
          </div>
        )}

        {course.is_completed && !isMobile && (
          <div
            css={css`
              display: flex;
              align-items: center;
              padding: 3px 20px;
              border-radius: 20px;
              background-color: ${theme.colors.white};
            `}
          >
            <Check size={20} color={theme.colors.primary} />

            <Text.CTA
              css={css`
                margin-left: 10px;
              `}
            >
              {t("status.completed")}
            </Text.CTA>
          </div>
        )}
      </div>

      <Headers.H1
        css={css`
          margin: 90px 0 0 0;
        `}
      >
        {course.name}
      </Headers.H1>

      <Text.ParagraphBold
        css={css`
          margin: 4px 0 0 0;
        `}
      >
        {course.subtitle}
      </Text.ParagraphBold>

      <div
        css={css`
          display: flex;
          flex-direction: ${isMobile ? "column" : "row"};
          align-items: ${isMobile ? "flex-start" : "center"};
          margin-top: ${isMobile ? "36px" : "20px"};
        `}
      >
        <div
          css={css`
            display: flex;
            padding: ${isMobile ? "4px 16px" : "3px 20px"};
            align-items: center;
            background-color: ${theme.colors.white};
            border-radius: 20px;
            font-size: 20px;
            font-weight: bold;
          `}
        >
          <Text.CTA>{t(`course.level.${course.level}`)}</Text.CTA>
        </div>

        <div
          css={css`
            display: flex;
            flex-flow: row wrap;
            margin-left: ${isMobile ? "0" : "30px"};
            margin-top: ${isMobile ? "25px" : "0"};
          `}
        >
          <div
            css={css`
              display: flex;
              margin: 5px 0;
              margin-right: 30px;
              white-space: nowrap;
              align-items: center;
            `}
          >
            <StudySvg
              css={css`
                margin-right: 4px;
              `}
            />
            <Text.Notifications>
              {course.lessons.length} {t("course.lessons")}
            </Text.Notifications>
          </div>

          <div
            css={css`
              display: flex;
              margin: 5px 0;
              margin-right: 30px;
              white-space: nowrap;
              align-items: center;
            `}
          >
            <WatchSvg
              css={css`
                margin-right: 4px;
              `}
            />
            <Text.Notifications>{humanizedDuration}</Text.Notifications>
          </div>
          {course.has_certificate && (
            <div
              css={css`
                display: flex;
                margin: 5px 0;
                white-space: nowrap;
                align-items: center;

                p {
                  margin-left: 5px;
                }
              `}
            >
              <CertificateSvg />
              <Text.Notifications>
                {t("course.with_certificate")}
              </Text.Notifications>
            </div>
          )}
        </div>
      </div>

      {showPrice && (
        <div>
          <div
            css={css`
              position: absolute;
              top: 40px;
              right: 60px;
              background: ${theme.colors.white};
              border-radius: 20px;
              padding: 12px 20px;
              text-align: center;

              ${isMobile &&
              css`
                position: relative;
                top: 0;
                right: 0;
                display: inline-flex;
                flex-flow: row wrap;
                align-items: center;
                width: auto;
                margin-top: 20px;
              `}

              h5 {
                margin: 0;
              }
            `}
          >
            <Headers.H3
              css={css`
                margin: 0;

                ${isMobile &&
                css`
                  margin-right: 5px;
                `}
              `}
            >
              €{Number(course.price)}
            </Headers.H3>
            <Headers.H5 style={{ color: theme.colors.gray5, fontSize: 12 }}>
              zzgl. MwSt.
            </Headers.H5>
          </div>
        </div>
      )}

      {course.lessons.length === 0 ? null : !course.is_purchased ? (
        <div
          css={css`
            position: relative;
          `}
        >
          <CourseCTA
            css={css`
              background: ${theme.colors.black};
              color: ${theme.colors.white};
              padding: 3px 30px;
            `}
            icon={
              <img
                css={css`
                  width: 30px;
                  height: 30px;
                  margin-left: 10px;
                `}
                src={play}
                alt={t("course.start_course")}
              />
            }
            course={course}
          />
        </div>
      ) : course.enrolment_id ? (
        <div
          css={css`
            display: flex;
            width: 100%;
          `}
        >
          {!course.is_completed && (
            <div
              css={css`
                position: relative;
              `}
            >
              <CourseCTA course={course} />
            </div>
          )}

          {course.is_completed && course.has_certificate && (
            <Button
              color={theme.colors.white}
              css={css`
                display: flex;
                align-items: center;
                cursor: pointer;
                font-size: 12px;
                height: 40px;
                margin-top: ${isMobile ? "90px" : "40px"};
                margin-right: 30px;
                width: auto;
              `}
              icon={<DownloadSvg color={theme.colors.white} />}
              onClick={() => downloadCertificate()}
            >
              <span
                css={css`
                  margin-left: 10px;
                `}
              >
                {t("course.certificate")}
              </span>
            </Button>
          )}

          {course.is_completed &&
            (!course.has_exam_tests || course.is_exam_passed) &&
            course.has_summary && (
              <Button
                color={theme.colors.white}
                background={theme.colors.dark}
                css={css`
                  display: flex;
                  align-items: center;
                  cursor: pointer;
                  font-size: 12px;
                  height: 40px;
                  margin-top: ${isMobile ? "90px" : "40px"};
                `}
                icon={<SummarySvg color={theme.colors.white} />}
                onClick={() => getSummary()}
              >
                <span
                  css={css`
                    margin-left: 10px;
                  `}
                >
                  {isMobile ? "Summary" : t("course-summary.read")}
                </span>
              </Button>
            )}
        </div>
      ) : (
        <div
          css={css`
            position: relative;
          `}
        >
          <CourseCTA
            course={course}
            css={css`
              background: ${theme.colors.black};
              color: ${theme.colors.white};
              padding: 3px 30px;
            `}
            icon={
              <img
                css={css`
                  width: 30px;
                  height: 30px;
                  margin-left: 10px;
                `}
                src={play}
                alt={t("course.start_course")}
              />
            }
          />
        </div>
      )}
    </div>
  );
}
