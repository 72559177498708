export enum ImageType {
  LIBRARY = "LIBRARY",
  USER_UPLOADS = "USER_UPLOADS",
  HIDDEN = "HIDDEN",
}

export type Image = {
  uuid: string;
  image: string;
  upload_name: string;
  mime_type: string;
  type?: ImageType;
  width: number;
  height: number;
};
