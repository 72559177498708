import { useParams } from "react-router-dom";
import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { getJourney } from "../actions/journey/getJourney";
import { useFetch } from "../actions/useAPI";
import { Header } from "../components/Header";
import { JourneyAbout } from "../components/Journey/JourneyAbout";
import { JourneyCourses } from "../components/Journey/JourneyCourses";
import { FAQColumns } from "../components/FAQColumns";
import { JourneyHeader } from "../components/Journey/JourneyHeader";
import { Wrapper } from "../components/Wrapper";
import { HeaderText } from "../components/HeaderText";
import { SEO } from "../components/SEO";
import { PageQueryHandler } from "./PageQueryHandler";
import { useResponsive } from "../hooks/useResponsive";

export function JourneyPage(props: {}) {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();

  const params = useParams<{
    id: string;
  }>();

  const {
    data: journey,
    error: journeyError,
    isLoading: journeyIsLoading,
  } = useFetch(getJourney, {
    journeyId: params.id,
  });

  if (!journey || journeyError || journeyIsLoading) {
    return (
      <PageQueryHandler error={journeyError} isLoading={journeyIsLoading} />
    );
  }

  return (
    <Wrapper>
      <SEO>
        <title>{journey?.name}</title>
      </SEO>
      <Header />
      <div
        css={css`
          display: flex;
          flex-direction: column;
          margin-bottom: ${isMobile ? "0" : "100px"};
        `}
      >
        {journey && <JourneyHeader journey={journey} />}
        {journey && <JourneyAbout journey={journey} />}
        {journey && <JourneyCourses journey={journey} />}
        {journey && journey.faq_items && (
          <FAQColumns
            items={journey.faq_items}
            header={
              <HeaderText title={t("course.our")} text={t("course.faq")} />
            }
          />
        )}
      </div>
    </Wrapper>
  );
}
