import React, { useState } from "react";
import { css } from "styled-components/macro";
import { useResponsive } from "../hooks/useResponsive";
import { IFAQItems } from "../types/Course";
import { FAQ } from "./FAQ";

function ColumnItems(props: {
  items: IFAQItems[];
  faqItem: number | null;
  toggleFaq: (id: number) => void;
}) {
  const { isMobile } = useResponsive();

  return (
    <div
      css={css`
        flex: 0 0 48%;

        ${isMobile &&
        css`
          flex: 0 0 100%;
        `}
      `}
    >
      {props.items &&
        props.items.map((faq, key) => (
          <div
            css={css`
              font-size: 18px;
              font-weight: 400;
              margin: 0;
              margin-bottom: 20px;
            `}
            key={key}
          >
            <FAQ
              question={faq.question}
              answer={faq.answer}
              isActive={faq.id === props.faqItem}
              onChange={() => props.toggleFaq(faq.id)}
            />
          </div>
        ))}
    </div>
  );
}

export function FAQColumns(props: {
  items: IFAQItems[];
  header?: React.ReactNode | string | null;
}) {
  const { isMobile } = useResponsive();
  const middleItem = Math.round(props.items.length / 2);
  const leftItems = props.items.slice(0, middleItem);
  const rightItems = props.items.slice(middleItem);

  const [faqItem, setFaqItem] = useState<number | null>(null);

  const toggleFaq = (idx: number) => {
    if (idx === faqItem) {
      setFaqItem(null);
    } else {
      setFaqItem(idx);
    }
  };

  return (
    <div
      css={css`
        display: flex;
        flex-direction: ${isMobile ? "column" : "row"};
      `}
    >
      {props.header}

      <div
        css={css`
          margin-top: 10px;
          display: flex;
          flex-flow: row wrap;
          justify-content: space-between;
          width: 100%;
        `}
      >
        <ColumnItems
          items={leftItems}
          faqItem={faqItem}
          toggleFaq={toggleFaq}
        />
        <ColumnItems
          items={rightItems}
          faqItem={faqItem}
          toggleFaq={toggleFaq}
        />
      </div>
    </div>
  );
}
