import React from "react";
import { useHistory } from "react-router";
import { css } from "styled-components/macro";
import { Navigation } from "./Navigation";
import { UserDropdown } from "./UserDropdown";
import { useAuth } from "../contexts/UserContext";
import { theme } from "../themes/variables";
import { SearchComponent } from "./Search";
import { Link } from "react-router-dom";
import { useEnterprise } from "../contexts/EnterpriseContext";
import { useResponsive } from "../hooks/useResponsive";
import { HeaderMobile } from "./HeaderMobile";

export function Header(props: {}) {
  const history = useHistory();
  const { isAuthenticated } = useAuth();
  const { enterprise } = useEnterprise();
  const { isMobile } = useResponsive();

  if (isMobile) {
    return <HeaderMobile />;
  }

  if (!isAuthenticated) {
    return (
      <header
        css={css`
          padding: 30px 0;
          display: flex;
          justify-content: center;
          align-items: center;
        `}
      >
        <Link to="/">
          <img
            css={css`
              width: auto;
              height: auto;
              max-width: 134px;
              max-height: 40px;
            `}
            src={enterprise.logo?.image}
            alt={`${enterprise?.name} Logo`}
          />
        </Link>
      </header>
    );
  }

  return (
    <header
      css={css`
        margin-top: 44px;
        margin-bottom: 30px;
        height: 70px;
        padding: 0 30px;
        border-radius: 40px;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
        background-color: ${theme.colors.white};
      `}
    >
      <img
        css={css`
          cursor: pointer;
          width: auto;
          height: auto;
          max-width: 134px;
          max-height: 40px;
        `}
        src={enterprise.logo?.image}
        alt={`${enterprise?.name} Logo`}
        onClick={() => history.push("/")}
      />
      <Navigation />
      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <SearchComponent
          hasDropDown
          css={css`
            width: 319px;
            padding: 10px 20px;
          `}
        />
      </div>
      <div
        css={css`
          display: flex;
          align-items: center;
          flex-flow: row wrap;
        `}
      >
        <div
          css={css`
            margin-right: 15px;
          `}
        ></div>
        <UserDropdown />
      </div>
    </header>
  );
}
