import React from "react";
import { css } from "styled-components/macro";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { theme } from "../../themes/variables";
import { Card } from "../Card";

import { ReactComponent as ErrorIcon } from "../../assets/svg/Error.svg";

export function PaymentError(props: {
  children: string;
  orderId: string;
  className?: string;
}) {
  const { t } = useTranslation();

  return (
    <Card
      css={css`
        padding: 24px;
        background: ${theme.colors.lightRedBackground};
      `}
      className={props.className}
    >
      <div
        css={css`
          display: flex;
        `}
      >
        <ErrorIcon width={20} height={20} color={theme.colors.lightRedMain} />

        <div
          css={css`
            margin-left: 24px;
            font-size: 16px;
            line-height: 22px;
          `}
        >
          <div>{props.children}</div>

          <Link
            to={`/order/${props.orderId}/payment-method`}
            css={css`
              margin-top: 24px;
              font-size: 16px;
              font-weight: bold;
            `}
          >
            {t("payment.change-payment-method")}
          </Link>
        </div>
      </div>
    </Card>
  );
}
