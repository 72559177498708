import { authorised } from "../../request";
import { SelfAssessmentSubmition } from "../../types/SelfAssessment";

type Args = {
  courseId: string;
  data: SelfAssessmentSubmition[];
};

export async function postSelfAssessment({ courseId, data }: Args) {
  const API = authorised();

  return API.request<SelfAssessmentSubmition>({
    method: "POST",
    url: `/api/campus/courses/${courseId}/self-assessments/`,
    data,
  });
}
