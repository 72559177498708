import { css } from "styled-components/macro";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { theme } from "../../themes/variables";
import arrowBack from "../../assets/img/arrow_back.svg";
import { Button } from "../Button";
import { humanize } from "../../helpers/humanize";
import { Journey } from "../../types/Journey";
import { Headers } from "../../helpers/layout";
import { useResponsive } from "../../hooks/useResponsive";
import Pluralize from "react-pluralize";

export function JourneyHeader(props: { journey: Journey }) {
  const history = useHistory();
  const { t } = useTranslation();
  const { isMobile } = useResponsive();
  const { journey } = props;
  const humanizedDuration = humanize(Number(journey.duration) * 1000);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        border-radius: 20px;
        background-repeat: no-repeat;
        background-size: cover;
        padding: ${isMobile ? "40px 28px" : "40px"};
        margin-bottom: 50px;

        ${journey.image &&
        css`
          background-image: url(${journey.image.image});
        `}
      `}
    >
      <div
        css={css`
          display: flex;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-color: ${theme.colors.white};
        `}
      >
        <img
          css={css`
            width: 25px;
            margin: 0 auto;
            cursor: pointer;
          `}
          src={arrowBack}
          alt={t("actions.back")}
          onClick={history.goBack}
        />
      </div>
      <Headers.H1
        css={css`
          margin: 20px 0 0;
        `}
      >
        {journey.name}
      </Headers.H1>
      <Headers.H3
        css={css`
          margin: 20px 0 0;
        `}
      >
        {journey.subtitle}
      </Headers.H3>
      <div
        css={css`
          margin: 10px 0 20px;
          text-transform: lowercase;
        `}
      >
        <Pluralize
          singular={t("course.singular")}
          plural={t("course.plural")}
          count={journey.courses.length}
          zero={t("course.empty")}
        />{" "}
        &bull; {humanizedDuration}
      </div>
      <div
        css={css`
          position: relative;
        `}
      >
        <Button
          type="button"
          css={css`
            margin: auto 0 0;
            margin-top: 30px;
            cursor: pointer;
            font-size: 16px;
            font-weight: 600;
          `}
          onClick={() => {
            history.push(`/course/${journey.courses[0].uuid}/`);
          }}
        >
          {t("course.go_to_study")}
        </Button>
      </div>
    </div>
  );
}
