import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { theme } from "../../themes/variables";
import { Headers, Row, Text } from "../../helpers/layout";
import { ICourse } from "../../types/Course";
import { Card } from "../Card";
import { ReactComponent as ReadSvg } from "../../assets/svg/Read.svg";
import { CourseCTA } from "./CourseCTA";
import { useResponsive } from "../../hooks/useResponsive";

export function CourseAbout(props: { course: ICourse }) {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();

  return (
    <div
      css={css`
        display: flex;
        flex-direction: ${isMobile ? "column" : "row"};
        margin-bottom: ${isMobile ? "20px" : "80px"};
      `}
    >
      <Card
        css={css`
          flex: 1;
          margin-bottom: ${isMobile ? "20px" : "0"};
        `}
      >
        <Headers.H1>{t("course.the_course")}</Headers.H1>

        <Text.Paragraph
          css={css`
            margin-top: 32px;
          `}
        >
          {props.course.description}
        </Text.Paragraph>

        {!props.course.is_completed && <CourseCTA course={props.course} />}
      </Card>

      {props.course.learning_points.length > 0 && (
        <Card
          css={css`
            margin-left: ${isMobile ? "0" : "40px"};
            flex: 1;
            background-color: ${theme.colors.yellowBackground};
          `}
        >
          <Headers.H2>{t("course.learning_points")}</Headers.H2>

          {props.course.learning_points.map((point, key) => (
            <li
              css={css`
                display: flex;
                font-size: 20px;
                font-weight: 500;
                margin: ${isMobile ? "24px 0" : "32px 0"};
                :last-of-type {
                  margin: 0;
                }
                :first-of-type {
                  margin-top: ${isMobile ? "24px" : "52px"};
                }
              `}
              key={key}
            >
              <Row>
                <div
                  css={css`
                    margin-right: 20px;
                  `}
                >
                  <ReadSvg
                    color={theme.colors.primary}
                    width="20"
                    css={css`
                      min-width: 20px;
                    `}
                  />
                </div>
                <Text.Paragraph
                  css={css`
                    margin-left: ${isMobile ? "0" : "1rem"};
                  `}
                >
                  {point}
                </Text.Paragraph>
              </Row>
            </li>
          ))}
        </Card>
      )}
    </div>
  );
}
