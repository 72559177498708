import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useStripe } from "@stripe/react-stripe-js";
import { Order, OrderStatus } from "../../types/Order";
import { PaymentSuccess } from "../../components/Payment/PaymentSuccess";
import { PaymentProcessing } from "../../components/Payment/PaymentProcessing";
import { PaymentFailed } from "../../components/Payment/PaymentFailed";

import { confirmStripePaymentIntent } from "../../actions/order";

export function PaymentStatusWrapper(props: {
  order: Order;
  clientSecret: string;
}) {
  const { t } = useTranslation();

  const stripe = useStripe();

  const [message, setMessage] = useState<string | null>(null);

  const [status, setStatus] = useState<OrderStatus>(props.order.status);

  useEffect(() => {
    async function confirmOrder(orderId: string) {
      try {
        await confirmStripePaymentIntent({
          orderId,
        });
        setMessage(t("payment.status.succeeded"));
        setStatus(OrderStatus.COMPLETED);
      } catch (e) {
        setMessage(t("payment.status.something-wrong"));
        setStatus(OrderStatus.PAYMENT_FAILED);
      }
    }

    if (!stripe) {
      return;
    }

    if (!props.clientSecret) {
      return;
    }

    stripe
      .retrievePaymentIntent(props.clientSecret)
      .then(({ paymentIntent }) => {
        switch (paymentIntent && paymentIntent.status) {
          case "succeeded":
            if (props.order.status !== OrderStatus.COMPLETED) {
              confirmOrder(props.order.uuid);
            }
            break;
          case "processing":
            setMessage(t("payment.status.processing"));
            setStatus(OrderStatus.PROCESSING);
            break;
          case "requires_payment_method":
            setMessage(t("payment.status.not-successful"));
            setStatus(OrderStatus.PAYMENT_FAILED);
            break;
          default:
            setMessage(t("payment.status.something-wrong"));
            setStatus(OrderStatus.PAYMENT_FAILED);
            break;
        }
      });
  }, [props.clientSecret, props.order.status, props.order.uuid, stripe, t]);

  return (
    <>
      {(status === OrderStatus.COMPLETED ||
        status === OrderStatus.PROCESSING) && (
        <PaymentSuccess order={props.order} />
      )}
      {status === OrderStatus.DRAFT && message && (
        <PaymentProcessing message={message} />
      )}
      {status === OrderStatus.PAYMENT_FAILED && message && (
        <PaymentFailed message={message} />
      )}
    </>
  );
}
