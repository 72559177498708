import { authorised } from "../../request";
import { Competence } from "../../types/Competence";

export async function getCompetencies() {
  const API = authorised();

  return API.request<Competence[]>({
    method: "GET",
    url: `/api/campus/me/competencies/`,
  });
}
