import { authorised } from "../request";
import {IUserProfileGeneralInformation} from "../types/UserProfileGeneralInformation";


export async function getProfileGeneralInformation() {
  const API = authorised();

  return API.request<IUserProfileGeneralInformation>({
    method: "GET",
    url: `/api/campus/me/general-information/`,
  });
}