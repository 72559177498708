import { Block } from "./Block";
import { IFile } from "./File";
import { Instructor } from "./Instructor";
import { LessonTest } from "./LessonTest";
import { Journey } from "./Journey";
import { CheatsSheet } from "./CheatsSheets";
import { MetaCourse } from "./Course";

export interface ILesson {
  uuid: string;
  type: ELessonType;
  name: string;
  description: string;
  text_content: string;
  carousel_content: string[];
  video: string;
  video_url: string;
  video_download_url?: string;
  audio: IFile | null;
  duration: number;
  course: Omit<ILessonCourse, "duration">;
  next: Omit<ILessonCourse, "duration" | "enrolment_id">;
  previous: Omit<ILessonCourse, "duration" | "enrolment_id">;
  tests: LessonTest[];
  custom_code: string;
  has_exam_tests: boolean;
  lesson_blocks: LessonBlock[];
  is_bookmarked: boolean;
  instructors: Instructor[];
  cheat_sheets: CheatsSheet[];
  is_completed: boolean;
  without_enrolment: boolean;
  icon: ELessonIcon;
}

export type LessonBlock = {
  id: number;
  order: number;
  block: Block;
};

export interface ILessonData {
  completed: string;
  lesson: ILesson;
  started: string;
}

export interface ILessonCourse {
  uuid: string;
  name: string;
  description: string;
  duration: number;
  enrolment_id: string;
  is_completed: boolean;
  is_purchased: boolean;
  journey: Pick<Journey, "uuid" | "name">;
  self_assessment_passed_id: number;
  has_self_assessment: boolean;
}

export interface LessonFeedback {
  feedback: string;
  comment: string;
}

export type MetaLesson = {
  name: string;
  uuid: string;
  course: MetaCourse;
};

export enum ELessonType {
  TEXT = "TEXT",
  INTERACTIVE = "INTERACTIVE",
  EMBED_VIDEO = "EMBED_VIDEO",
  CUSTOM_CODE = "CUSTOM_CODE",
  TEST = "TEST",
  CAROUSEL = "CAROUSEL",
  PODCAST = "PODCAST",
  BLOCK = "BLOCK",
}

export enum ELessonIcon {
  TEXT = "TEXT",
  EMBED = "EMBED_VIDEO",
  QUIZ = "QUIZ",
  PODCAST = "PODCAST",
}

export enum Feedback {
  POSITIVE = "POSITIVE",
  NEGATIVE = "NEGATIVE",
}
