import { authorised } from "../../request";
import { CheatsSheet } from "../../types/CheatsSheets";

type Args = {
  data: {
    items: string[];
  };
};

export async function postCheatSheet({ data }: Args) {
  const API = authorised();

  return API.request<CheatsSheet[]>({
    method: "POST",
    url: `/api/campus/cheatsheet/`,
    data,
  });
}
