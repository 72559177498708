import { MultipleChoiceQuiz } from "./MultipleChoiceQuiz";
import { SingleChoiceQuiz } from "./SingleChoiceQuiz";
import { TrueFalse } from "./TrueFalse";
import React from "react";
import {
  SubmissionResponse,
  SubmissionTestAnswer,
} from "../../types/LessonTest";
import { EnumTestType, Test } from "../../types/Test";
import { css } from "styled-components/macro";
import { theme } from "../../themes/variables";

function TextExplanation({
  submission,
  test,
}: {
  submission: SubmissionResponse;
  test: Test;
}) {
  return (
    <div
      css={css`
        display: flex;
        color: ${theme.colors.error};
      `}
    >
      {
        submission.answers.find(
          (el) => !el.is_correct && el.variant_id === test.variant?.id
        )?.explanation
      }
    </div>
  );
}

export const TestRenderer = React.memo(
  ({
    test,
    state,
    styleVariant,
    onStateChange,
    submission,
  }: {
    test: Test;
    state: SubmissionTestAnswer[];
    styleVariant?: "default" | "card";
    onStateChange: (state: SubmissionTestAnswer[]) => void;
    submission?: SubmissionResponse | null;
  }) => {
    return (
      <>
        {test.question_type === EnumTestType.TRUE_FALSE && state[0] && (
          <>
            <TrueFalse
              test={test}
              state={state[0]}
              onStateChange={(state) => {
                onStateChange([state]);
              }}
              styleVariant={styleVariant}
            />
            {submission && (
              <TextExplanation submission={submission} test={test} />
            )}
          </>
        )}
        {test.question_type === EnumTestType.SINGLE_CHOICE && (
          <>
            <SingleChoiceQuiz
              test={test}
              state={state}
              onStateChange={onStateChange}
              styleVariant={styleVariant}
            />
            {submission && (
              <TextExplanation submission={submission} test={test} />
            )}
          </>
        )}
        {test.question_type === EnumTestType.MULTIPLE_CHOICE && (
          <>
            <MultipleChoiceQuiz
              test={test}
              state={state}
              onStateChange={onStateChange}
              styleVariant={styleVariant}
            />
            {submission && (
              <TextExplanation submission={submission} test={test} />
            )}
          </>
        )}
      </>
    );
  }
);
