import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { css } from "styled-components/macro";
import { toast } from "react-toastify";
import { useFetch } from "../actions/useAPI";
import { getSelfAssessments } from "../actions/getSelfAssessments";
import { postSelfAssessment } from "../actions/postSelfAssessments";
import { Headers } from "../helpers/layout";
import { serverErrorHandler } from "../helpers/serverErrorHandler";
import { pageLoading } from "../helpers/pageLoading";
import { theme } from "../themes/variables";
import {
  SelfAssessment,
  SelfAssessmentAnswer,
  SelfAssessmentSubmition,
} from "../types/SelfAssessment";
import { SelfAssessmentRender } from "./SelfAssessment/Renderer";
import { Button } from "./Button";
import { useResponsive } from "../hooks/useResponsive";

export function SignupSelfAssessment(props: { redirect_to?: string | null }) {
  const [submit, setSubmit] = useState(false);
  const [isSubmiting, setSubmiting] = useState(false);
  const { isMobile } = useResponsive();

  const { t } = useTranslation();
  const history = useHistory();

  const { data: selfAssessments, error: selfAssessmentsError } =
    useFetch(getSelfAssessments);

  const [answersState, setAnswersState] = useState<SelfAssessmentSubmition[]>(
    []
  );

  useEffect(() => {
    setAnswersState(
      selfAssessments
        ? selfAssessments.map((item) => {
            return {
              item: item,
              answers: [],
            };
          })
        : []
    );
  }, [selfAssessments]);

  const onSubmit = async () => {
    try {
      setSubmiting(true);
      await postSelfAssessment({
        data: answersState,
      });
      toast.success(t("status.success"));
      if (props.redirect_to) {
        history.push(props.redirect_to);
      }
    } catch (error: any) {
      toast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    } finally {
      setSubmiting(false);
    }
  };

  const setNewState = (
    answers: SelfAssessmentAnswer[],
    item: SelfAssessment
  ) => {
    setAnswersState((answersState) => {
      const newState: SelfAssessmentSubmition[] = answersState.map((state) => {
        if (state.item.uuid === item.uuid) {
          return {
            ...state,
            answers,
          };
        }

        return state;
      });
      newState.every((item) => item.answers.length)
        ? setSubmit(true)
        : setSubmit(false);
      return newState;
    });
  };

  if (!selfAssessments || selfAssessmentsError) {
    return pageLoading(selfAssessmentsError);
  }

  if (!selfAssessments.length) {
    history.push("/");
  }

  return (
    <div
      css={css`
        width: 100%;
        padding: 0 50px;
        box-sizing: border-box;

        ${isMobile &&
        css`
          padding: 0 20px;
        `}
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          width: 100%;
        `}
      >
        <Headers.H3
          css={css`
            color: ${theme.colors.gray4};
            font-weight: 400;
            margin-top: 50px;
          `}
        >
          {t("self_assessment.label")}
        </Headers.H3>
      </div>
      <div>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            max-width: 800px;
            margin: 3rem auto 0;
          `}
        >
          {selfAssessments &&
            selfAssessments.map((item, key) => {
              const answers = answersState.filter((answer) => {
                return answer.item.uuid === item.uuid;
              });

              const state = answers.length > 0 ? answers[0].answers : [];

              return (
                <div
                  key={key}
                  css={css`
                    margin-bottom: 40px;
                    width: 100%;
                  `}
                >
                  <SelfAssessmentRender
                    item={item}
                    state={state}
                    onStateChange={(answers) => {
                      setNewState(answers, item);
                    }}
                  />
                </div>
              );
            })}
        </div>
      </div>
      <div
        css={css`
          width: 100%;
          display: flex;
          flex-flow: row wrap;
          justify-content: center;
        `}
      >
        <Button
          disabled={!submit}
          isSubmitting={isSubmiting}
          onClick={onSubmit}
        >
          {t("actions.submit")}
        </Button>
      </div>
    </div>
  );
}
