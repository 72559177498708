import styled, { css } from "styled-components/macro";
import { Link } from "react-router-dom";
import { useResponsive } from "../hooks/useResponsive";
import { useEnterprise } from "../contexts/EnterpriseContext";

export function NavigationFooter(props: {}) {
  const { isMobile } = useResponsive();
  const { enterprise } = useEnterprise();

  return (
    <Nav>
      <ul
        css={css`
          flex-direction: ${isMobile ? "column" : "row"};

          ${!isMobile &&
          css`
            li {
              &:after {
                content: "|";
              }

              &:last-child:after {
                content: "";
              }
            }
          `}
        `}
      >
        {enterprise.campus_footer_menu &&
          enterprise.campus_footer_menu.items.map((item) => {
            if (item.item.url.match(/^http/)) {
              return (
                <li key={item.id}>
                  <a target="_blank" rel="noreferrer" href={item.item.url}>
                    {item.item.name}
                  </a>
                </li>
              );
            }

            return (
              <li key={item.id}>
                <Link to={item.item.url}>{item.item.name}</Link>
              </li>
            );
          })}
      </ul>
    </Nav>
  );
}

const Nav = styled.nav`
  ul {
    list-style: none;
    display: flex;
    align-items: center;
    padding: 0;
  }

  a {
    padding: 0 20px;
    text-decoration: none;

    :hover {
      font-weight: 600;
    }
  }
`;
