// If you want to add new accepted type, you should also add the same at backend model
export enum EnumAcceptedMimeTypes {
  MP3 = "audio/mpeg",
}

export type ITranscript = {
  timecode: string;
  content: string;
}

export type IFile = {
  uuid: string;
  file: string;
  upload_name: string;
  title?: string;
  mime_type: EnumAcceptedMimeTypes;
  transcript: ITranscript[] | null;
}
