import React from "react";
import { css, FlattenSimpleInterpolation } from "styled-components/macro";
import { Loader } from "./Loader";
import { theme } from "../themes/variables";

import { ReactComponent as UpIcon } from "../assets/svg/Up.svg";

type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<
  T,
  Exclude<keyof T, Keys>
> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>;
  }[Keys];

export type TableColumn = {
  label: string;
  orderFields?: string[];
  align?: "left" | "center" | "right";
  columnCss?: FlattenSimpleInterpolation;
  format?: (label: string) => React.ReactNode | string | null;
};

export type TableRow<T> = {
  data: T;
  row: {
    rowCss?: FlattenSimpleInterpolation;
    value: React.ReactNode | React.ReactNode[] | string | null;
  }[];
};

export type TableColumnStats = {
  index: number;
  label: string;
  orderFields?: TableColumn["orderFields"];
  sort: TableOrderingSort;
};

export enum TableOrderingSort {
  ASC = "ASC",
  DESC = "DESC",
}

export type TableOrdering = RequireAtLeastOne<{
  index: number | null;
  orderFields: TableColumn["orderFields"] | null;
  sort: TableOrderingSort;
}>;

const isArraysEqual = (a: any[], b: any[]) => {
  if (a.length !== b.length) return false;
  return a.every((value, index) => value === b[index]);
};

export function Table<T>(props: {
  columns: TableColumn[];
  rows?: TableRow<T>[];
  loading?: boolean;
  noDataMessage?: string;
  loadingMessage?: string;
  className?: string;
  ordering?: TableOrdering;
  onClickColumn?: (
    e: React.MouseEvent<HTMLTableCellElement, MouseEvent>,
    stats: TableColumnStats
  ) => void;
}) {
  if (props.loading) {
    return <Loader absoluteCenter>{props.loadingMessage}</Loader>;
  }

  if (props.rows?.length === 0) {
    return (
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: center;
        `}
      >
        {props.noDataMessage}
      </div>
    );
  }

  return (
    <table
      css={css`
        border-spacing: 0;
        width: 100%;
        background: ${theme.colors.white};
        border: 1px solid ${theme.colors.gray2};
        border-radius: 20px;
        overflow: hidden;
        th {
          font-size: 16px;
          line-height: 18px;
          padding: 25px 24px;
        }
        td {
          height: 60px;
          margin: 0 auto;
          font-size: 16px;
          line-height: 22px;
          padding: 16px 24px;
        }
        th,
        td {
          text-align: left;
          margin: 0;
          border-bottom: 1px solid ${theme.colors.gray2};
          border-right: 1px solid ${theme.colors.gray2};
          ${props.rows?.length === 0 &&
          css`
            border-bottom: none;
          `}
        }
        tr th {
          background: #f9f9f9;
          :first-of-type {
            text-align: left;
            border-top-left-radius: 20px;
            ${props.rows?.length === 0 &&
            css`
              border-bottom-left-radius: 20px;
            `}
          }
          :last-of-type {
            border-right: none;
            border-top-right-radius: 20px;
            ${props.rows?.length === 0 &&
            css`
              border-bottom-right-radius: 20px;
            `}
          }
        }
        tr td {
          :first-of-type {
            text-align: left;
          }
          :last-of-type {
            border-right: none;
            width: 10%;
          }
        }
        tbody tr:last-of-type td {
          border-bottom: none;
        }
      `}
      className={props.className}
      role="table"
    >
      <thead>
        <tr>
          {props.columns.map(
            (
              { label, orderFields, format, align, columnCss: cssStyles },
              idx
            ) => (
              <th
                key={idx}
                css={css`
                  ${!!(orderFields && orderFields.length) &&
                  css`
                    cursor: pointer;
                  `}
                  ${align &&
                  css`
                    text-align: ${align}!important;
                  `}
                  ${cssStyles}
                `}
                onClick={(e) => {
                  if (
                    props.onClickColumn &&
                    !!(orderFields && orderFields.length)
                  ) {
                    const stats: TableColumnStats = {
                      index: idx,
                      label,
                      orderFields,
                      sort: props.ordering?.sort || TableOrderingSort.ASC,
                    };

                    props.onClickColumn(e, stats);
                  }
                }}
              >
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    justify-content: ${align === "left"
                      ? "flex-start"
                      : align === "right"
                      ? "flex-end"
                      : align === "center"
                      ? "center"
                      : idx === 0
                      ? "flex-start"
                      : "flex-start"};
                  `}
                >
                  {format ? format(label) : label}

                  {!!(orderFields && orderFields.length) && (
                    <div
                      css={css`
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        margin-left: 10px;
                      `}
                    >
                      <UpIcon
                        width={12}
                        height={12}
                        color={
                          props.ordering?.orderFields &&
                          isArraysEqual(
                            props.ordering.orderFields,
                            orderFields
                          ) &&
                          props.ordering?.sort === TableOrderingSort.DESC
                            ? theme.colors.primary
                            : theme.colors.gray4
                        }
                      />

                      <UpIcon
                        width={12}
                        height={12}
                        color={
                          props.ordering?.orderFields &&
                          isArraysEqual(
                            props.ordering.orderFields,
                            orderFields
                          ) &&
                          props.ordering?.sort === TableOrderingSort.ASC
                            ? theme.colors.primary
                            : theme.colors.gray4
                        }
                        css={css`
                          transform: rotate(180deg);
                        `}
                      />
                    </div>
                  )}
                </div>
              </th>
            )
          )}
        </tr>
      </thead>

      <tbody>
        {props.rows?.map((el, idx) => {
          return (
            <tr
              key={idx}
              css={css`
                :hover {
                  background: ${theme.colors.violetBackground};
                }
              `}
            >
              {el.row.map(({ value, rowCss }, key) => {
                return (
                  <td key={key} css={rowCss}>
                    {value}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

/*
  HOW DOES IT WORK?
  
  Table Props
    columns: [
      label: string;                        Label to display in the table header
      orderFields?: string;                 Field's name to use as a key in ordering. Allow ordering for this column
      format?: () => Node | string | null;  Function to format the value
      align?: "left" | "center" | "right";  Align the value
      css?:                                 CSS styles for <th>
    ]

    rows: [
      data:                                 Data to use in the table
      row: {
        value: Node | string | null;        Value to display in the table
        css:                                CSS styles for <td>
      }
    ]

    loading?: boolean;                      Show loader
    noDataMessage?: string;                 Message to display when there is no data
    loadingMessage?: string;                Message to display when loading
    className?: string;                     CSS styles for <Table />
    ordering?: TableOrdering;               Ordering
    onClickColumn?: (e: Event, stats: TableColumnStats) => void;        Callback to execute when a column is clicked. It will receive the event and the stats of the column. Work only on orderable columns.
      stats contains such information:
        index: number;                      Index of the column
        label: string;                      Label of the column
        orderFields: string;                Fields of the column
        sort: TableOrderingSort;            Sort of the column


    Example:
      const tableColumns: TableColumn[] = [
        {
          label: "id",
          align: "left",
          css: css`
            width: 50px;
          `
        },
        {
          label: "name",
        },
        {
          label: "Is Admin",
          format: (value: string) => <span css={css`color: red;`}>{value}</span>,
        },
      ];

      const tableRows: TableRow<User>[] = users.results.map(
        (user) => {
          return {
            data: user,
            row: [
              {
                value: <div>{user.id}</div>,
              },
              {
                value: <div>{user.name}</div>,
              },
              {
                value: <div>{user.is_admin ? <CheckIcon /> : <StopIcon />}</div>,
                css: css`
                  padding: 10px 30px;
                `
              },
            ],
          };
        }
      );

      JSX:

      <Table
        columns={tableColumns}
        rows={tableRows}
        loadingMessage="Loading..."
        noDataMessage="No data yet"
      />
*/
