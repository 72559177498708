import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { Header } from "../components/Header";
import { Wrapper } from "../components/Wrapper";
import { SEO } from "../components/SEO";
import { theme } from "../themes/variables";
import { useResponsive } from "../hooks/useResponsive";
import { Headers } from "../helpers/layout";
import { EmailInvitationForm } from "../forms/EmailInvitationForm";
import { useEnterprise } from "../contexts/EnterpriseContext";

export function EmailInvitationPage(props: {}) {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();
  const { enterprise } = useEnterprise();

  return (
    <Wrapper>
      <SEO>
        <title>{t("email_invitation.page-title")}</title>
      </SEO>
      <Header />
      <div
        css={css`
          padding: ${isMobile ? "20px" : "60px 60px 60px 24px"};
          background-color: ${theme.colors.white};
          border-radius: 20px;
        `}
      >
        <div
          css={css`
            width: 100%;
            max-width: 500px;
            margin: 0 auto;
            text-align: center;
          `}
        >
          <Headers.H1>
            {t("email_invitation.page-title", {
              name: enterprise.name,
            })}
          </Headers.H1>
          <p>
            {t("email_invitation.page-description")} <br />
            {t("email_invitation.page-description_2")}
          </p>
        </div>
        <div
          css={css`
            width: 100%;
            max-width: 370px;
            margin: 0 auto;
            margin-top: 30px;
          `}
        >
          <EmailInvitationForm />
        </div>
      </div>
    </Wrapper>
  );
}
