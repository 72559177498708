import redraft from "redraft";
import { css } from "styled-components/macro";
import { renderers } from "../helpers/textLessonFormatter";

export function TextRenderer(props: {
  text: string;
  withoutClassName?: boolean;
  html?: boolean;
}) {
  const rendered = redraft(props.text, renderers);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        width: 100%;

        p {
          margin-top: 0;
          margin-bottom: 0;
        }

        ul,
        ol {
          padding-top: 0;
          padding-bottom: 0;
        }

        ul + p,
        ol + p {
          margin-top: 0;
        }

        blockquote {
          font-size: 30px;
        }

        li {
          margin-bottom: 10px;
        }

        img {
          max-width: 80%;
        }
      `}
      className={props.withoutClassName ? "" : "editor-container"}
    >
      {props.html ? (
        <div
          dangerouslySetInnerHTML={{
            __html: props.text,
          }}
        ></div>
      ) : (
        rendered
      )}
    </div>
  );
}
