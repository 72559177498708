import { authorised } from "../../request";
import {
  SubmissionResponseTest,
  SubmissionTestAnswer,
} from "../../types/LessonTest";

export async function postQuizAnswer(
  test_id: string,
  test_variant_id: string,
  data: SubmissionTestAnswer[]
) {
  const API = authorised();

  return API.request<SubmissionResponseTest>({
    method: "POST",
    url: `/api/campus/tests/${test_id}/variant/${test_variant_id}/`,
    data,
  });
}
