import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Styles } from "react-modal";
import { css } from "styled-components/macro";
import { toast } from "react-toastify";
import { postQuizAnswer } from "../actions/quiz/postQuizAnswer";
import { Headers } from "../helpers/layout";
import { serverErrorHandler } from "../helpers/serverErrorHandler";
import { TestRenderer } from "./Test/Renderer";
import { theme } from "../themes/variables";
import { Modal } from "./Modal/Modal";
import { ModalBody } from "./Modal/ModalBody";
import { ModalHeader } from "./Modal/ModalHeader";
import { Button } from "./Button";
import modalBackground from "../assets/img/spaced_rep_bg.png";
import { LessonTest, SubmissionTestAnswer } from "../types/LessonTest";
import { EnumTestType } from "../types/Test";
import { useResponsive } from "../hooks/useResponsive";

export default function SpacedRepetitionModal(props: {
  isActive: boolean;
  tests: LessonTest[];
  onClose: () => void;
}) {
  const localStorageKey = "THEMIS_SPACED_REPETITION";

  const { t } = useTranslation();
  const { tests } = props;

  const [index, setIndex] = useState(0);
  const [isCorrect, setIsCorrect] = useState<boolean | null>(null);
  const [explanation, setExplanation] = useState<string>("");
  const [testAnswers, setTestAnswers] = useState<SubmissionTestAnswer[]>([]);
  const [backgroundColor, setBackgroudColor] = useState("");
  const [firstScreenPassed, setFirstScreenPassed] = useState(
    localStorage.getItem(localStorageKey)
  );
  const { isMobile } = useResponsive();

  const test = tests[index] || null;

  useEffect(() => {
    if (!test) return;

    setTestAnswers(
      test.variant!.answers.map((answer) => {
        return {
          id: answer.id,
          answer:
            test.question_type === EnumTestType.MULTIPLE_CHOICE ? false : null,
        };
      })
    );
  }, [test]);

  useEffect(() => {
    setBackgroudColor(
      isCorrect === null
        ? theme.colors.lightBlue
        : !isCorrect
        ? theme.colors.redBackground
        : theme.colors.greenBackground
    );
  }, [isCorrect]);

  const onSubmit = async (test_id: string, test_variant_id: string) => {
    if (!testAnswers.length) return;

    try {
      const response = await postQuizAnswer(
        test_id,
        test_variant_id,
        testAnswers
      );

      setIsCorrect(response.data.is_correct);

      if (!response.data.is_correct) {
        setExplanation(response.data.explanation);
      }
    } catch (error: any) {
      toast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    }
  };

  const skipQuestion = () => {
    setIsCorrect(null);
    setExplanation("");
    setTestAnswers([]);

    setIndex(index + 1);

    if (tests.length === index) {
      props.onClose();
    }
  };

  const addToLocalStorage = () => {
    if (!localStorage.getItem(localStorageKey)) {
      localStorage.setItem(localStorageKey, "opened");
      setFirstScreenPassed(localStorage.getItem(localStorageKey));
    }
  };

  const customStyles: Styles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      maxWidth: "800px",
      width: "90%",
      boxSizing: "border-box",
      maxHeight: "calc(100vh - 40px)",
      boxShadow:
        "rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px, rgba(0, 0, 0, 0.12) 0px 1px 10px",
      overflow: "auto",
      borderRadius: "20px",
      position: "relative",
      backgroundColor: backgroundColor,
      background: `url(${modalBackground})`,
      backgroundSize: "330px",
      backgroundRepeat: "no-repeat",
      backgroundPositionX: "right",
      backgroundPositionY: "bottom",
      border: "none",
      willChange: "scroll-position",
      padding: "20px",
    },
    overlay: {
      height: "100vh",
      overflowY: "hidden",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      outline: "none",
      zIndex: 9999,
    },
  };

  return (
    <Modal
      modalIsOpen={props.isActive}
      onClose={() => props.onClose()}
      style={customStyles}
    >
      <ModalHeader
        closeIcon
        onClose={() => props.onClose()}
        css={css`
          justify-content: flex-end;
        `}
      ></ModalHeader>

      {firstScreenPassed ? (
        <>
          <ModalBody>
            <div
              css={css`
                margin-bottom: 40px;
                padding: 0 100px;

                ${isMobile &&
                css`
                  padding: 0 20px;
                `}
              `}
            >
              {tests.length ? (
                tests[index] ? (
                  <TestRenderer
                    test={tests[index]}
                    styleVariant="card"
                    state={testAnswers}
                    onStateChange={(state) => {
                      if (isCorrect === null) {
                        setTestAnswers(state);
                      }
                    }}
                  />
                ) : (
                  <div
                    css={css`
                      margin-top: 50px;
                      margin-bottom: 80px;
                      text-align: center;
                    `}
                  >
                    <Headers.H2>{t("repetition.done")}</Headers.H2>
                  </div>
                )
              ) : (
                <div
                  css={css`
                    margin-top: 50px;
                    margin-bottom: 80px;
                    text-align: center;
                  `}
                >
                  <Headers.H2>{t("repetition.no_exercises")}</Headers.H2>
                </div>
              )}
            </div>
          </ModalBody>

          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-top: 3rem;
              margin-bottom: 2rem;
            `}
          >
            {isCorrect !== null && (
              <div
                css={css`
                  display: flex;
                  font-weight: bold;
                `}
              >
                {isCorrect ? t("repetition.correct") : t("repetition.wrong")}
              </div>
            )}

            {isCorrect !== null && !isCorrect && explanation && (
              <div
                css={css`
                  display: flex;
                  text-align: center;
                  margin-top: 2rem;
                  margin-bottom: 2rem;
                  padding: 0 80px;
                  font-size: 18px;
                `}
              >
                {explanation}
              </div>
            )}

            {isCorrect === null && tests.length && tests[index] ? (
              <div
                css={css`
                  display: flex;
                  align-items: center;
                `}
              >
                <Button
                  onClick={() => skipQuestion()}
                  css={css`
                    margin-right: 20px;
                    background-color: ${theme.colors.lightBlue};
                    color: ${theme.colors.dark};
                    border: 1px solid ${theme.colors.dark};
                    padding: 15px 30px;
                  `}
                  hoverStyles={`color: ${theme.colors.white}; border: 1px solid ${theme.colors.transparent}; background: ${theme.colors.primary};`}
                >
                  {t("repetition.skip")}
                </Button>
                <Button
                  disabled={!testAnswers.length}
                  onClick={() =>
                    onSubmit(tests[index].uuid, tests[index].variant!.id)
                  }
                  css={css`
                    margin-right: 20px;
                    padding: 15px 30px;
                    background-color: ${theme.colors.dark};
                    color: ${theme.colors.white};
                  `}
                >
                  {t("repetition.submit")}
                </Button>
              </div>
            ) : tests.length && tests[index] ? (
              <Button
                onClick={() => skipQuestion()}
                css={css`
                  margin-top: 2rem;
                  padding: 15px 30px;
                  background-color: ${theme.colors.dark};
                  color: ${theme.colors.white};
                `}
              >
                {t("repetition.next")}
              </Button>
            ) : (
              <Button
                onClick={() => props.onClose()}
                css={css`
                  margin-top: 2rem;
                  padding: 15px 30px;
                  background-color: ${theme.colors.dark};
                  color: ${theme.colors.white};
                `}
              >
                {t("repetition.close")}
              </Button>
            )}
          </div>
        </>
      ) : (
        <>
          <ModalBody>
            <div
              css={css`
                margin-bottom: 40px;
                padding: 0 100px;

                ${isMobile &&
                css`
                  padding: 0 20px;
                `}
              `}
            >
              <div
                css={css`
                  margin-top: 50px;
                  text-align: center;
                `}
              >
                <Headers.H2>{t("repetition.first_screen_title")}</Headers.H2>
              </div>
              <div
                css={css`
                  margin-bottom: 80px;
                  text-align: center;
                `}
              >
                {t("repetition.first_screen_body")}
              </div>
            </div>
          </ModalBody>

          {tests.length > 0 && (
            <div
              css={css`
                display: flex;
                width: 100%;
              `}
            >
              <Button
                onClick={() => addToLocalStorage()}
                css={css`
                  margin: 1.5rem auto;
                  padding: 15px 30px;
                  background-color: ${theme.colors.dark};
                  color: ${theme.colors.white};
                `}
              >
                {t("repetition.start")}
              </Button>
            </div>
          )}
        </>
      )}
    </Modal>
  );
}
