import { authorised } from "../../request";
import { IData } from "../../types/HomePage";

export async function getDashboard() {
  const API = authorised();

  return await API.request<IData>({
    method: "GET",
    url: `/api/campus/homepage/`,
  });
}
