import { authorised } from "../../request";
import { MyOrder } from "../../types/Order";
import { buildQueryString } from "../../helpers/buildQueryString";

type Args = {
  limit?: number;
  offset?: number;
};

export async function getMyOrders(args: Args) {
  const API = authorised();

  return API.request<{
    count: number;
    next: string | null;
    previous: string | null;
    results: MyOrder[];
  }>({
    method: "GET",
    url: `/api/campus/me/orders/?${buildQueryString(args)}`,
  });
}
