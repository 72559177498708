import { authorised } from "../../request";
import { ILesson } from "../../types/Lesson";

type Args = {
  courseId: string;
  lessonId: string;
  previewToken?: string;
};

export async function getLessonPreview({
  courseId,
  lessonId,
  previewToken,
}: Args) {
  const API = authorised();

  return API.request<ILesson>({
    method: "GET",
    url: `/api/campus/courses/${courseId}/lesson-preview/${lessonId}/${
      previewToken ? `?preview_token=${previewToken}` : ""
    }`,
  });
}
