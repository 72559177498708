import { authorised } from "../../request";
import { Order, OrderPaymentOptions } from "../../types/Order";

export async function updateOrderPaymentType(
  orderId: string,
  data: {
    payment_type: OrderPaymentOptions;
  }
) {
  const API = authorised();

  return API.request<Order>({
    method: "PUT",
    url: `/api/campus/orders/${orderId}/payment-type/`,
    data,
  });
}
