import { authorised } from "../../request";
import { SkillAnalytics } from "../../types/Skill";
import { buildQueryString } from "../../helpers/buildQueryString";

type Args = {
  competence_id?: number;
};

export async function getSkillAnalytics(args: Args) {
  const API = authorised();

  return API.request<SkillAnalytics>({
    method: "GET",
    url: `/api/campus/me/skills/?${buildQueryString(args)}`,
  });
}
