import React, { Suspense, lazy } from "react";
import { useTranslation } from "react-i18next";
import { useResponsive } from "../../hooks/useResponsive";

export function LessonCustomCode(props: {
  code: string;
  onComplete: () => void;
}) {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();

  if (isMobile) {
    return (
      <p>
        Interaktives Element wird auf Mobilgeräten nicht unterstützt. Bitte
        öffnen Sie die Desktop-Version
      </p>
    );
  }

  const AverageGame = lazy(() =>
    import(
      /* webpackChunkName: 'AverageGame' */ "../../components/Lesson/Games/Average"
    ).then(({ AverageGame }) => {
      return { default: AverageGame };
    })
  );

  const PollResultGame = lazy(() =>
    import(
      /* webpackChunkName: 'PollResultGame' */ "../../components/Lesson/Games/PollResult"
    ).then(({ PollResultGame }) => {
      return { default: PollResultGame };
    })
  );

  const UseCaseGame = lazy(() =>
    import(
      /* webpackChunkName: 'UseCaseGame' */ "../../components/Lesson/Games/UseCase"
    ).then(({ UseCaseGame }) => {
      return { default: UseCaseGame };
    })
  );

  const ComputerVisionGame = lazy(() =>
    import(
      /* webpackChunkName: 'ComputerVisionGame' */ "../../components/Lesson/Games/ComputerVision"
    ).then(({ ComputerVisionGame }) => {
      return { default: ComputerVisionGame };
    })
  );

  const MachineLearningGame = lazy(() =>
    import(
      /* webpackChunkName: 'MachineLearningGame' */ "../../components/Lesson/Games/MachineLearning"
    ).then(({ MachineLearningGame }) => {
      return { default: MachineLearningGame };
    })
  );

  return (
    <>
      {props.code === "average" && (
        <Suspense fallback={<div>{t("actions.loading")}</div>}>
          <AverageGame />
        </Suspense>
      )}
      {props.code === "poll-result" && (
        <Suspense fallback={<div>{t("actions.loading")}</div>}>
          <PollResultGame onComplete={props.onComplete} />
        </Suspense>
      )}
      {props.code === "use-case" && (
        <Suspense fallback={<div>{t("actions.loading")}</div>}>
          <UseCaseGame onComplete={props.onComplete} />
        </Suspense>
      )}
      {props.code === "computer-vision" && (
        <Suspense fallback={<div>{t("actions.loading")}</div>}>
          <ComputerVisionGame onComplete={props.onComplete} />
        </Suspense>
      )}
      {props.code === "machine-learning" && (
        <Suspense fallback={<div>{t("actions.loading")}</div>}>
          <MachineLearningGame onComplete={props.onComplete} />
        </Suspense>
      )}
    </>
  );
}
