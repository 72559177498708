import { useState, useEffect } from "react";
import { css } from "styled-components/macro";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Button } from "../components/Button";
import { Row } from "../helpers/layout";
import { theme } from "../themes/variables";
import { useResponsive } from "./useResponsive";

const toastId = "check-version-toast";

export function CheckVersionUpdate(props: {}) {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();

  const [closed, setClosed] = useState(false);

  const getMainChunkName = (
    scripts: HTMLCollectionOf<HTMLScriptElement> | HTMLOrSVGScriptElement
  ) => {
    let chunkName = "";
    for (let val of Object.values(scripts)) {
      if (val.src.match(/main.(.*?).?chunk.js/)) {
        chunkName = val.src;
      }
    }

    return chunkName;
  };

  useEffect(() => {
    const checkVersion = async () => {
      try {
        const response = await fetch("/");

        if (response.status === 200) {
          const pageScripts =
            document.currentScript || document.getElementsByTagName("script");

          const mainChunkName = getMainChunkName(pageScripts);

          const data = await response.text();

          const parser = new DOMParser();
          const htmlDoc = parser.parseFromString(data, "text/html");
          const parsedScripts = htmlDoc.getElementsByTagName("script");

          const parsedChunkName = getMainChunkName(parsedScripts);

          if (mainChunkName !== parsedChunkName) {
            toast(
              <Row justify="space-between" align="center">
                <div
                  css={css`
                    font-size: 14px;
                    color: ${theme.colors.dark};
                  `}
                >
                  {t("actions.new-version")}
                </div>
                <Button onClick={() => window.location.reload()} size="small">
                  {t("actions.reload")}
                </Button>
              </Row>,
              {
                autoClose: false,
                toastId: toastId,
                onClose: () => {
                  setClosed(true);
                },
              }
            );
          }
        }
      } catch (error) {}
    };

    const interval = setInterval(() => {
      if (!closed && !isMobile) checkVersion();
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, [closed, t, isMobile]);

  return null;
}
