import { ICourse } from "../../types/Course";
import { useTranslation } from "react-i18next";
import { Button } from "../Button";
import { theme } from "../../themes/variables";
import { css } from "styled-components/macro";
import { ChevronRight } from "react-feather";
import { useHistory, useLocation } from "react-router";
import { toast } from "react-toastify";
import { enrolToCourse } from "../../actions/course/enrolToCourse";
import { serverErrorHandler } from "../../helpers/serverErrorHandler";
import { TFunction } from "i18next";
import queryString from "query-string";
import { useResponsive } from "../../hooks/useResponsive";
import { useAuth } from "../../contexts/UserContext";

export enum CourseStates {
  NOT_PURCHASED = "not-purchased",
  SELF_ASSESSMENT = "self-assessment",
  EXAM = "exam",
  SUMMARY = "summary",
  NEXT_LESSON = "next-lesson",
  FIRST_LESSON = "first-lesson",
  ENROLL = "enroll",
  DO_NOTHING = "do-nothing",
}

export const getCourseState = (course: ICourse): CourseStates => {
  if (!course.is_purchased && Number(course.price) > 0) {
    return CourseStates.NOT_PURCHASED;
  } else if (!course.enrolment_id) {
    return CourseStates.ENROLL;
  } else if (course.has_self_assessment && !course.self_assessment_passed_id) {
    return CourseStates.SELF_ASSESSMENT;
  } else if (course.is_completed && course.has_summary) {
    return CourseStates.SUMMARY;
  } else if (!course.is_completed && course.next_lesson_uuid) {
    return CourseStates.NEXT_LESSON;
  } else if (!course.is_completed && course.lessons[0]) {
    return CourseStates.FIRST_LESSON;
  } else if (course.has_exam_tests && !course.is_exam_passed) {
    return CourseStates.EXAM;
  } else {
    return CourseStates.DO_NOTHING;
  }
};

export const getCourseLink = (course: ICourse, state: CourseStates) => {
  switch (state) {
    case CourseStates.NOT_PURCHASED:
      return `/course/${course.uuid}/purchase/`;

    case CourseStates.SELF_ASSESSMENT:
      return `/course/${course.uuid}/self-assessment?redirect_to=/course-enrolments/${course.enrolment_id}/lessons/${course.lessons[0].uuid}`;

    case CourseStates.EXAM:
      return `/course-enrolments/${course.enrolment_id}/exam/`;

    case CourseStates.SUMMARY:
      return `/course/${course.uuid}/summary/`;

    case CourseStates.NEXT_LESSON:
      return `/course-enrolments/${course.enrolment_id}/lessons/${course.next_lesson_uuid}/`;

    case CourseStates.FIRST_LESSON:
      return `/course-enrolments/${course.enrolment_id}/lessons/${course.lessons[0].uuid}/`;

    default:
      return `/course/${course.uuid}/`;
  }
};

export const onEnroll = async (course: ICourse, history: any, t: TFunction) => {
  try {
    const enrolment = await enrolToCourse({
      courseId: course.uuid,
    });

    toast.success(t("status.success"));

    !course.self_assessment_passed_id && course.has_self_assessment
      ? history.push(
          `/course/${course.uuid}/self-assessment?redirect_to=/course-enrolments/${enrolment.data.id}/lessons/${course.lessons[0].uuid}`
        )
      : history.push(
          `/course-enrolments/${enrolment.data.id}/lessons/${course.lessons[0].uuid}`
        );
  } catch (error: any) {
    toast.error(
      t("status.error", {
        error: serverErrorHandler(error),
      })
    );
  }
};

export function CourseCTA(props: {
  course: ICourse;
  className?: string;
  icon?: JSX.Element;
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { isMobile } = useResponsive();
  const { course } = props;
  const { isAuthenticated } = useAuth();

  const queryParams = queryString.parse(location.search);

  if (queryParams.preview_token) return null;

  if (!course || course.lessons.length === 0) return null;

  const state = getCourseState(course);

  if (state === CourseStates.DO_NOTHING) return null;

  return (
    <Button
      className={props.className}
      css={css`
        display: flex;
        align-items: center;
        margin-top: ${isMobile ? "90px" : "40px"};
        color: ${theme.colors.white};
        font-size: 16px;
        font-weight: bold;
      `}
      onClick={() => {
        if (!course) return;

        if (state === CourseStates.ENROLL) {
          if (isAuthenticated) {
            onEnroll(course, history, t);
          } else {
            history.push(`/login?redirect_to=${location.pathname}`);
          }
        } else {
          history.push(getCourseLink(course, state));
        }
      }}
      icon={props.icon ? props.icon : <ChevronRight size={20} />}
      iconRight
    >
      <span>
        {!course.is_purchased
          ? t("course.purchase_course")
          : course.enrolment_id
          ? course.is_completed
            ? course.has_exam_tests && !course.is_exam_passed
              ? t("course.take_exam")
              : t("course.completed")
            : t("course.go_to_study")
          : t("course.start_course")}
      </span>
    </Button>
  );
}
