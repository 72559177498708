export enum SignupFieldType {
  TEXT_FIELD = "TEXT_FIELD",
  TEXT_AREA = "TEXT_AREA",
  SELECT = "SELECT",
  RADIO = "RADIO",
  CHECKBOX = "CHECKBOX",
}

export type SignupFieldOption = {
  id: number;
  value: string;
};

export type SignupField = {
  uuid: string;
  order?: number;
  type: SignupFieldType;
  name: string;
  hint: string | null;
  placeholder: string | null;
  required: boolean;
  options: SignupFieldOption[];
};

export type SignupFieldValue = {
  signup_field: SignupField;
  values: string[];
};
