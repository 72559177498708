import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { theme } from "../themes/variables";
import { Header } from "../components/Header";
import { Wrapper } from "../components/Wrapper";
import { Block } from "../components/Block";
import { Button } from "../components/Button";
import { SEO } from "../components/SEO";
import { useResponsive } from "../hooks/useResponsive";

export function Page404(props: {}) {
  const { t } = useTranslation();
  const history = useHistory();
  const { isMobile } = useResponsive();

  const goHome = () => {
    history.push("/");
  };

  return (
    <Wrapper>
      <SEO>
        <title>{t("page-404.title")}</title>
        <body className="bg-orange"></body>
      </SEO>
      <Header />
      <Block>
        <div
          css={css`
            max-width: 50%;

            ${isMobile &&
            css`
              max-width: 100%;
              background: ${theme.colors.white};
              padding: 60px 20px;
              border-radius: 20px;
            `}
          `}
        >
          <div
            css={css`
              margin-top: 100px;

              ${isMobile &&
              css`
                margin-top: 0;
              `}
            `}
          >
            <div
              css={css`
                color: ${theme.colors.primary};
                font-weight: bold;
                line-height: 1.25;

                ${!isMobile &&
                css`
                  font-size: 48px;
                `}
              `}
            >
              {t("page-404.sorry")}.
            </div>
            <div
              css={css`
                color: ${theme.colors.dark};
                font-weight: bold;
                line-height: 1.25;

                ${!isMobile &&
                css`
                  font-size: 48px;
                `}
              `}
            >
              {t("page-404.not-exist")}
            </div>
          </div>

          <div
            css={css`
              margin-top: 50px;
              font-weight: 400;

              ${!isMobile &&
              css`
                font-size: 36px;
              `}
            `}
          >
            {t("page-404.advice")}
          </div>

          <Button
            color={theme.colors.dark}
            background={theme.colors.transparent}
            border={`2px solid ${theme.colors.dark}`}
            css={css`
              margin-top: 28px;
              padding: 10px 20px;
              font-weight: bold;
            `}
            onClick={() => goHome()}
          >
            {t("page-404.homepage")}
          </Button>
        </div>
      </Block>
    </Wrapper>
  );
}
