import { useState } from "react";
import styled, { css } from "styled-components/macro";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { theme } from "../themes/variables";
import SpacedRepetitionModal from "./SpacedRepetitionModal";
import { useFetch } from "../actions/useAPI";
import { getScheduledTests } from "../actions/testScheduler";
import { useEnterprise } from "../contexts/EnterpriseContext";

export function Navigation(props: {}) {
  const { t } = useTranslation();
  const { enterprise } = useEnterprise();
  const history = useHistory();
  const { data: testsData, error: testsError } = useFetch(getScheduledTests);
  const tests = testsError
    ? []
    : testsData
    ? testsData.filter((test) => test.variant)
    : [];

  const [modal, setModal] = useState(false);

  const items = enterprise.campus_header_menu
    ? enterprise.campus_header_menu.items.map((item) => {
        return {
          name: item.item.name,
          path: item.item.url,
        };
      })
    : [];

  return (
    <Nav>
      <ul
        css={css`
          li {
            border-bottom: 3px solid white;
            margin-right: 30px;
          }
          a,
          span {
            font-size: 16px;
            line-height: 28px;
            text-decoration: none;
            cursor: pointer;
          }
        `}
      >
        {items.map((item) => {
          const isActive = history.location.pathname === item.path;

          return (
            <li
              key={item.name}
              css={
                isActive &&
                css`
                  border-color: ${theme.colors.primary} !important;
                `
              }
            >
              <Link to={item.path}>{item.name}</Link>
            </li>
          );
        })}
        <li
          css={css`
            position: relative;
          `}
        >
          <span onClick={() => setModal(true)}>
            {t("header.navigation.updates")}

            {tests.length > 0 && (
              <div
                css={css`
                  width: 8px;
                  height: 8px;
                  background-color: ${theme.colors.primary};
                  border-radius: 50%;
                  position: absolute;
                  top: 2px;
                  right: -17px;
                `}
              />
            )}
          </span>
        </li>
      </ul>
      {modal && (
        <SpacedRepetitionModal
          isActive={modal}
          tests={tests}
          onClose={() => setModal(false)}
        />
      )}
    </Nav>
  );
}

const Nav = styled.nav`
  ul {
    list-style: none;
    display: flex;
    align-items: center;
  }
`;
