import { css } from "styled-components/macro";
import { theme } from "../../themes/variables";
import { RadioComponent } from "../RadioComponent";
import {
  SelfAssessment,
  SelfAssessmentAnswer,
} from "../../types/SelfAssessment";
import { Headers } from "../../helpers/layout";
import { useResponsive } from "../../hooks/useResponsive";

export function SingleChoice(props: {
  selfAssessment: SelfAssessment;
  state: SelfAssessmentAnswer[];
  onStateChange?: (state: SelfAssessmentAnswer[]) => void;
  isReadOnly?: boolean;
  isChecked: (
    items: SelfAssessmentAnswer[],
    value: SelfAssessmentAnswer
  ) => boolean;
}) {
  const { isMobile } = useResponsive();

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        height: 100%;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
        `}
      >
        <Headers.H4
          css={css`
            margin: 0 0 20px 0;

            ${isMobile &&
            css`
              text-align: center;
            `}
          `}
        >
          {props.selfAssessment.title}
        </Headers.H4>
        {props.selfAssessment.answers.map((answer, i) => {
          return (
            <label
              key={i}
              css={css`
                display: flex;
                align-items: center;
                cursor: pointer;
                margin-bottom: 1rem;
              `}
            >
              <RadioComponent
                value={props.isChecked(props.state, answer)}
                name={`selfassessment--single-choice--${answer.id}`}
                css={css`
                  margin-right: 2rem;
                `}
                onChange={() => {
                  if (props.onStateChange) {
                    props.onStateChange([answer]);
                  }
                }}
              />
              <div
                css={css`
                  width: 100%;
                  padding: 10px 20px;
                  background-color: ${theme.colors.white};
                  border: 1px solid;
                  border-radius: 15px;
                  border-color: ${props.isChecked(props.state, answer)
                    ? `${theme.colors.primary};`
                    : `${theme.colors.transparentBlack3};`};
                `}
              >
                {answer.answer}
              </div>
            </label>
          );
        })}
      </div>
    </div>
  );
}
