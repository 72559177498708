import { useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { Link } from "react-router-dom";
import { css } from "styled-components/macro";
import { getSearchResults } from "../actions/getSearchResults";
import useDebounce from "../actions/useDebounce";
import { theme } from "../themes/variables";
import { CourseListItem } from "../types/Course";
import NoSearchResults from "../assets/img/no-search-results.png";
import { ReactComponent as SearchSvg } from "../assets/svg/Search.svg";
import { Loader } from "./Loader";
import { useResponsive } from "../hooks/useResponsive";

export function SearchNoResults(props: {
  show: boolean;
  text: string;
  className?: string;
}) {
  const { t } = useTranslation();

  if (!props.show) {
    return null;
  }

  return (
    <div
      css={css`
        top: 30px;
        left: 0;
        position: absolute;
        width: calc(100% - 106px);
        padding: 20px;
        text-align: center;
        background: ${theme.colors.white};
        opacity: 0.9;
      `}
      className={props.className}
    >
      <img src={NoSearchResults} alt={t("search.no-results")} />

      <div
        css={css`
          margin: 20px 0;
        `}
      >
        <Trans i18nKey="search.no-match">
          Sorry, there were no matches for
          <strong>{{ searchTerm: props.text }}</strong>
        </Trans>
      </div>

      <div>
        <Trans i18nKey="search.contact-us">
          If you still can't get the information you're looking for please{" "}
          <a href="mailto:info@themis.eu">contact us</a>.
        </Trans>
      </div>
    </div>
  );
}

export function SearchComponent(props: {
  className?: string;
  hasPlaceholder?: boolean;
  hasDropDown?: boolean;
}) {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();

  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState<CourseListItem[]>([]);
  const [focused, setFocused] = useState(false);

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    setLoading(true);
    if (debouncedSearchTerm) {
      getSearchResults(debouncedSearchTerm, 5, "popular").then((results) => {
        setResults(results);
        setLoading(false);
      });
    } else {
      setResults([]);

      setLoading(false);
    }
  }, [debouncedSearchTerm]);

  const trimmedSearchArr = searchTerm.trim().toLowerCase().split(" ");

  return (
    <div
      className={props.className}
      css={css`
        width: 100%;
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
        ${focused && `background-color: ${theme.colors.gray1};`}
        border-radius: 20px;
        transition: background-color 0.4s ease;
        z-index: 1;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          position: relative;
          border-bottom: ${isMobile
            ? `1px solid ${theme.colors.gray3}`
            : `2px solid ${theme.colors.gray2}`};
          width: 100%;
        `}
      >
        <input
          placeholder={t("my-courses.search")}
          onChange={(e) => setSearchTerm(e.target.value)}
          onFocus={() => setFocused(true)}
          onBlur={() => {
            setFocused(false);
          }}
          value={searchTerm}
          css={css`
            width: 100%;
            height: 30px;
            box-sizing: border-box;
            background: none;
            border: none;
            font-size: 14px;
            outline: none;
            color: ${theme.colors.dark};

            ::placeholder {
              font-size: 14px;
              color: ${theme.colors.gray4};
            }
          `}
        />
        <SearchSvg
          width={20}
          height="20"
          display="flex"
          color={isMobile ? theme.colors.primary : theme.colors.black}
        />
      </div>

      {props.hasDropDown && (
        <div
          className={`search-dropdown ${focused ? "focused" : ""}`}
          css={css`
            position: absolute;
            max-height: ${focused ? 300 : 0}px;
            top: 55px;
            width: calc(100% - 40px);
            background: ${theme.colors.gray1};
            padding: 0 20px;
            border-bottom-right-radius: 20px;
            border-bottom-left-radius: 20px;
            transition: max-height 0.6s ease;
            overflow: hidden;
            font-size: 16px;
          `}
        >
          {loading ? (
            <Loader size={20} />
          ) : results.length ? (
            <div
              css={css`
                padding: 20px 0;
              `}
            >
              {results.map((result, idx) => (
                <Link
                  css={css`
                    width: 100%;
                    cursor: pointer;
                    display: block;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    margin-bottom: 8px;
                  `}
                  key={idx}
                  to={`/course/${result.uuid}`}
                >
                  {result.name.split(" ").map((el, key) => {
                    return !!trimmedSearchArr.length &&
                      trimmedSearchArr.some((trimmedEl) =>
                        el.trim().toLowerCase().includes(trimmedEl)
                      ) ? (
                      <span key={key}>
                        {(() => {
                          return trimmedSearchArr.map((trimmedElem, idx) => {
                            const regexp = new RegExp(`(${trimmedElem})`, "gi");
                            if (!el.match(regexp)) {
                              return null;
                            }
                            const replaced = el.replace(
                              regexp,
                              `<strong>$1</strong>`
                            );

                            return (
                              <span
                                key={idx}
                                dangerouslySetInnerHTML={{
                                  __html: replaced + "&nbsp;",
                                }}
                              ></span>
                            );
                          });
                        })()}
                      </span>
                    ) : (
                      <span key={key}>{el}&nbsp;</span>
                    );
                  })}
                </Link>
              ))}
            </div>
          ) : debouncedSearchTerm && !loading ? (
            <div
              css={css`
                padding: 20px 0;
                line-height: 22px;
              `}
            >
              <Trans i18nKey="search.no-match">
                Sorry, there were no matches for
                <strong>{{ searchTerm }}</strong>
              </Trans>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
}
