import React from "react";
import { toast } from "react-toastify";
import { css } from "styled-components/macro";
import { Formik, Form } from "formik";
import { useHistory, useLocation } from "react-router";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button } from "../../components/Button";
import { TextField } from "../fields/TextField";
import { Column, Row, Spacing } from "../../helpers/layout";
import { login } from "../../actions/auth/login";
import { useAuth } from "../../contexts/UserContext";
import { theme } from "../../themes/variables";
import { Headers } from "../../helpers/layout";
import { USER_CONFIRM_EMAIL } from "../../actions/auth/resendConfirmationEmail";
import queryString from "query-string";
import { serverErrorHandler } from "../../helpers/serverErrorHandler";

interface FormValues {
  username: string;
  password: string;
}

export function LoginForm() {
  const { authenticate } = useAuth();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);

  return (
    <div>
      <Formik<FormValues>
        initialValues={{
          username: "",
          password: "",
        }}
        validationSchema={Yup.object().shape({
          username: Yup.string().email().label(t("auth.username")).required(),
          password: Yup.string().label(t("auth.password")).required(),
        })}
        onSubmit={async (values: FormValues, { setSubmitting }) => {
          try {
            setSubmitting(true);

            const response = await login(values.username, values.password);

            if (!response.data.email_confirmed) {
              localStorage.setItem(USER_CONFIRM_EMAIL, values.username);

              history.push("/confirm-email");
            } else if (!response.data.is_verified) {
              history.push("/verification-info");
            } else {
              authenticate(response.data.token, response.data.userprofile);

              const redirect_to = queryParams.redirect_to || "/";

              if (!response.data.userprofile.is_self_assessment_passed) {
                history.push(`/self-assessment?redirect_to=${redirect_to}`);
              } else {
                history.push(redirect_to);
              }
            }
          } catch (error: any) {
            toast.error(
              t("status.error", {
                error: serverErrorHandler(error),
              })
            );
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, dirty, isValid }) => (
          <Form noValidate>
            <Column gutter={Spacing.none}>
              <TextField
                name="username"
                type="text"
                placeholder={t("auth.username")}
                css={css`
                  border-radius: 10px 10px 0 0;
                `}
              />
              <TextField
                name="password"
                type="password"
                placeholder={t("auth.password")}
                css={css`
                  margin-top: 1px;
                  border-radius: 0 0 10px 10px;
                `}
              />

              <Row
                justify="flex-end"
                css={css`
                  margin-top: 20px;
                `}
              >
                <Button
                  isSubmitting={isSubmitting}
                  type="submit"
                  color={theme.colors.primary}
                  disabled={!dirty || !isValid}
                  css={css`
                    margin: 0 auto;
                    margin-top: 20px;
                    color: ${theme.colors.white};
                    width: fit-content;
                  `}
                >
                  {t("auth.login")}
                </Button>
              </Row>
            </Column>
            <Headers.H4
              css={css`
                display: flex;
                justify-content: center;
                margin-top: 50px;
                margin-bottom: 10px;
              `}
            >
              {t("auth.or")}
            </Headers.H4>
            <Link
              css={css`
                display: flex;
                text-decoration: underline;
                justify-content: center;
              `}
              to={
                queryParams.redirect_to
                  ? `/register?redirect_to=${queryParams.redirect_to}`
                  : "/register"
              }
            >
              {t("auth.register")}
            </Link>
            <Link
              css={css`
                display: flex;
                margin-top: 50px;
                font-size: 18px;
                justify-content: center;

                :hover {
                  text-decoration: underline;
                }
              `}
              to="/reset-password"
            >
              {t("auth.forget_password")}
            </Link>
          </Form>
        )}
      </Formik>
    </div>
  );
}
