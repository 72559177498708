import React, { useState } from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { Headers, Row, Text } from "../../helpers/layout";
import { theme } from "../../themes/variables";
import { ReactComponent as DownSvg } from "../../assets/svg/Down.svg";
import { Button } from "../Button";
import { Journey } from "../../types/Journey";
import { useResponsive } from "../../hooks/useResponsive";
import { CourseCTA } from "../Course/CourseCTA";

export function JourneyComponent(props: {
  className?: string;
  journey: Pick<Journey, "uuid" | "name" | "description" | "courses">;
}) {
  const { journey } = props;

  const [isExpanded, setIsExpanded] = useState(false);
  const { isMobile } = useResponsive();
  const { t } = useTranslation();
  const history = useHistory();

  const courses = isExpanded ? journey.courses : journey.courses.slice(0, 3);
  const found = journey.courses.find((course) => course.is_completed === false);
  return (
    <div
      className={props.className}
      key={journey.uuid}
      css={css`
        padding: ${isMobile ? "20px" : "30px"};
        margin-top: ${isMobile ? "20px" : "0"};
        margin-bottom: ${isMobile ? "10px" : "20px"};
        border: 1px solid ${theme.colors.dark};
        border-radius: 20px;
      `}
    >
      <Headers.H3
        css={css`
          font-weight: 600;
          margin-bottom: ${isMobile ? "10px" : "0"};
          cursor: pointer;
        `}
        onClick={() => history.push(`/journey/${journey.uuid}`)}
      >
        {journey.name}
      </Headers.H3>
      <Text.Paragraph
        css={css`
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
        `}
      >
        {journey.description}
      </Text.Paragraph>
      <Headers.H5
        css={css`
          padding-top: 25px;
        `}
      >
        {t("journey.contents")}:
      </Headers.H5>
      <ol
        css={css`
          padding: 8px;
        `}
      >
        {courses.map((course) => {
          return (
            <li
              key={course.uuid}
              css={css`
                font-size: 12px;
                font-weight: 700;
                width: 100%;
                padding-bottom: 20px;
                border-bottom: 1px solid ${theme.colors.gray3};
                margin-bottom: 20px;
              `}
            >
              <Row
                css={css`
                  justify-content: space-between;
                  align-items: center;
                `}
              >
                <div
                  css={css`
                    flex: 1 0 10%;
                  `}
                >
                  <Headers.H5
                    css={css`
                      font-size: 14px;
                      font-weight: 700;
                      align-items: center;
                      padding-left: 11px;
                      cursor: pointer;
                    `}
                    onClick={() => {
                      history.push("/course/" + course.uuid);
                    }}
                  >
                    {course.name}
                  </Headers.H5>
                </div>
                {course.logo ? (
                  <div
                    css={css`
                      flex: 0 0 70px;
                    `}
                  >
                    <img
                      src={course.logo.image}
                      alt="Themis-logo"
                      css={css`
                        min-width: 0;
                        max-width: 100%;
                        max-height: 50px;
                      `}
                    />
                  </div>
                ) : (
                  ""
                )}
              </Row>
            </li>
          );
        })}
      </ol>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
        `}
      >
        {found && (
          <CourseCTA
            course={found}
            css={css`
              margin-top: 0;
              color: ${theme.colors.black};
              background-color: transparent;
              border: 1px solid black;
              :hover {
                background-color: transparent;
              }
            `}
          />
        )}
        {journey.courses.length > 3 && (
          <Button
            css={css`
              margin-left: 5px;
              padding: 0 10px;
            `}
            color={theme.colors.dark}
            background="transparent"
            hoverStyles="background-color: transparent;"
            icon={
              <DownSvg
                css={css`
                  margin-top: 4px;
                  path {
                    fill: ${theme.colors.black};
                  }
                `}
              />
            }
            iconRight
            onClick={() => {
              setIsExpanded((expanded) => !expanded);
            }}
          >
            {t("actions.more-info")}
          </Button>
        )}
      </div>
    </div>
  );
}
