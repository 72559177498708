import { maybeAuthorised } from "../../request";
import { ICourse } from "../../types/Course";

type Args = {
  courseId: string;
  previewToken?: string;
};

export async function getCourse({ courseId, previewToken }: Args) {
  const API = maybeAuthorised();

  return API.request<ICourse>({
    method: "GET",
    url: `/api/campus/courses/${courseId}/${
      previewToken ? `?preview_token=${previewToken}` : ""
    }`,
  });
}
