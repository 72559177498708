import { authorised } from "../request";
import { SelfAssessment } from "../types/SelfAssessment";

export async function getSelfAssessments() {
  const API = authorised();

  return API.request<SelfAssessment[]>({
    method: "GET",
    url: `/api/campus/me/self-assessment/`,
  });
}
