import { css } from "styled-components/macro";
import { theme } from "../../themes/variables";
import { CheckboxComponent } from "../CheckboxComponent";
import {
  SelfAssessment,
  SelfAssessmentAnswer,
} from "../../types/SelfAssessment";
import { Headers } from "../../helpers/layout";
import { useResponsive } from "../../hooks/useResponsive";

export function MultipleChoice(props: {
  selfAssessment: SelfAssessment;
  state: SelfAssessmentAnswer[];
  onStateChange?: (state: SelfAssessmentAnswer[]) => void;
  isReadOnly?: boolean;
  isChecked: (
    items: SelfAssessmentAnswer[],
    value: SelfAssessmentAnswer
  ) => boolean;
}) {
  const { isMobile } = useResponsive();

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        height: 100%;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
        `}
      >
        <Headers.H4
          css={css`
            margin: 0 0 20px 0;

            ${isMobile &&
            css`
              text-align: center;
            `}
          `}
        >
          {props.selfAssessment.title}
        </Headers.H4>
        {props.selfAssessment &&
          props.selfAssessment.answers.map((answer, i) => (
            <label
              key={i}
              css={css`
                display: inline-flex;
                align-items: center;
                cursor: pointer;
                margin-bottom: 1rem;
              `}
            >
              <CheckboxComponent
                value={props.isChecked(props.state, answer)}
                name={`selfassessment--mul-choice--${answer.id}`}
                css={css`
                  margin-right: 1.5rem;
                `}
                onChange={() => {
                  if (props.onStateChange) {
                    props.state.length
                      ? props.state.includes(answer)
                        ? props.onStateChange([
                            ...props.state.filter(
                              (item) => answer.id !== item.id
                            ),
                          ])
                        : props.onStateChange([...props.state, answer])
                      : props.onStateChange([answer]);
                  }
                }}
              />
              <span
                css={css`
                  width: 100%;
                  padding: 10px 20px;
                  background-color: ${theme.colors.white};
                  border: 1px solid;
                  border-radius: 15px;
                  border-color: ${props.isChecked(props.state, answer)
                    ? `${theme.colors.primary};`
                    : `${theme.colors.transparentBlack3};`};
                `}
              >
                {answer.answer}
              </span>
            </label>
          ))}
      </div>
    </div>
  );
}
