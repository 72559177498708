import React from "react";
import { css } from "styled-components/macro";
import { theme } from "../../themes/variables";

export function ModalBody(props: {
  children?: React.ReactNode | React.ReactNode[] | string | null;
}) {
  return (
    <div
      css={css`
        color: ${theme.colors.dark};
      `}
    >
      {props.children}
    </div>
  );
}
