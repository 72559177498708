import { theme } from "../themes/variables";

export enum SkillLevel {
  BEGINNER = "BEGINNER",
  INTERMEDIATE = "INTERMEDIATE",
  ADVANCED = "ADVANCED",
}

export const getLevelColor = (level: SkillLevel, colors?: [string, string, string]) => {
  const defaultColors: [string, string, string] = colors ? colors : [theme.colors.blueBackground, theme.colors.lightRedMain, theme.colors.violetMain];

  switch (level) {
    case SkillLevel.BEGINNER:
      return defaultColors[0];
    case SkillLevel.INTERMEDIATE:
      return defaultColors[1];
    default:
      return defaultColors[2];
  }
};

export const getLevel = (completness: number | null) => {
  if (!completness) {
    return SkillLevel.BEGINNER;
  }

  switch (true) {
    case completness < 33:
      return SkillLevel.BEGINNER;
    case completness < 66:
      return SkillLevel.INTERMEDIATE;
    default:
      return SkillLevel.ADVANCED;
  }
};