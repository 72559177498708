import React from "react";
import { css } from "styled-components/macro";
import { theme } from "../themes/variables";
import { Header } from "../components/Header";
import { Wrapper } from "../components/Wrapper";
import { SEO } from "../components/SEO";
import { Loader } from "../components/Loader";
import { Headers } from "../helpers/layout";
import { ReactComponent as BugIcon } from "../assets/svg/Bug.svg";
import { Button } from "../components/Button";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

export function PageQueryHandler(props: {
  error: string | null;
  isLoading: boolean;
}) {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Wrapper>
      <SEO>
        <title>
          {props.isLoading ? t("page-error.loading") : t("page-error.title")}
        </title>
      </SEO>
      <Header />
      <div
        css={css`
          border-radius: 20px;
          min-height: calc(100vh - 440px);
          background: ${theme.colors.white};
          border-radius: 20px;
          position: relative;
          padding: 30px 40px;
          display: flex;
          align-items: center;
          justify-content: center;
        `}
      >
        {props.isLoading ? (
          <Loader absoluteCenter></Loader>
        ) : props.error ? (
          <div
            css={css`
              text-align: center;
            `}
          >
            <div
              css={css`
                margin-bottom: 25px;
              `}
            >
              <BugIcon />
            </div>
            <Headers.H1>{t("page-error.title")}</Headers.H1>
            <div
              css={css`
                position: relative;
                max-width: 700px;
                margin: 0 auto;
              `}
            >
              <Headers.H4>{t("page-error.text")}</Headers.H4>
            </div>
            <Button
              background={theme.colors.black}
              css={css`
                margin-top: 28px;
                font-weight: bold;
              `}
              onClick={() => history.goBack()}
            >
              {t("actions.back")}
            </Button>
          </div>
        ) : null}
      </div>
    </Wrapper>
  );
}
