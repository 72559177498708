import { authorised } from "../request";
import { CourseListItem } from "../types/Course";

export enum Filter {
  POPULAR = "popular",
  RECENTLY = "recently",
  DEFAULT = "",
}

type searchResponse = {
  count: number;
  next: string;
  previous: string;
  results: CourseListItem[];
};

export async function getSearchResults(
  search: string,
  limit: number,
  filter: string
) {
  const API = authorised();

  return API.request<searchResponse>({
    method: "GET",
    url: `/api/campus/courses/search/?order_by=${filter}&limit=${limit}${
      search ? `&name=${search}` : ""
    }`,
  }).then((r) => r.data.results);
}
