import React, { useState, useEffect, useCallback } from "react";
import { getEnterprise } from "../actions/enterprise/getEnterprise";
import { Enterprise } from "../types/Enterprise";
import { ImageType } from "../types/Image";
import { createGlobalStyle } from "styled-components";
import logoImg from "../assets/img/logo.png";

const defaultState: Enterprise = {
  name: "",
  description: "",
  background_color: "#e8f0fa",
  logo: {
    height: 0,
    mime_type: "images/png",
    type: ImageType.HIDDEN,
    upload_name: "logo",
    width: 0,
    uuid: "",
    image: logoImg,
  },
  preview_image: {
    height: 0,
    mime_type: "images/png",
    type: ImageType.HIDDEN,
    upload_name: "logo",
    width: 0,
    uuid: "",
    image: logoImg,
  },
  campus_url: "",
  stripe_public_api_key: "",
  is_image_saving_disabled: false,
  is_page_download_disabled: false,
  is_printing_disabled: false,
  is_text_copying_disabled: false,
  data_protection_link: null,
  imprint_link: null,
  agb_link: null,
  campus_footer_menu: null,
  campus_header_menu: null,
  studio_footer_menu: null,
  studio_header_menu: null,
};

const BodyStyle = createGlobalStyle<{
  backgroundColor: string;
}>`
  ${(p) => p.backgroundColor && `body { background: ${p.backgroundColor}; }`};
`;
type State = {
  enterprise: Enterprise;
  refetch: () => void;
};

const emptyState = {
  enterprise: defaultState,
  refetch: () => {},
};

type Context = State;

const EnterpriseStateContext = React.createContext<Context>(emptyState);

export function EnterpriseProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [state, setState] = useState<State>(emptyState);

  const fetchEnterprise = useCallback(async function () {
    try {
      const request = await getEnterprise();

      setState((state) => ({
        ...state,
        enterprise: request.data,
      }));
    } catch {
      setState((state) => ({
        ...state,
        enterprise: defaultState,
      }));
    }
  }, []);

  useEffect(() => {
    fetchEnterprise();
  }, [fetchEnterprise]);
  return (
    <EnterpriseStateContext.Provider
      value={{
        ...state,
        refetch: fetchEnterprise,
      }}
    >
      <BodyStyle backgroundColor={state.enterprise?.background_color} />
      {children}
    </EnterpriseStateContext.Provider>
  );
}

export function useEnterprise() {
  const context = React.useContext(EnterpriseStateContext);

  if (context === undefined) {
    throw new Error("useEnterprise must be used within a EnterpriseProvider");
  }

  return context;
}
