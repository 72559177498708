import React, { useState } from "react";
import { css } from "styled-components/macro";
import { theme } from "../themes/variables";
import { CourseCheatSheetSuggestions } from "../types/CourseSuggestions";
import { ReactComponent as ReadSvg } from "../assets/svg/ReadWhite.svg";
import { ReactComponent as CheatSvg } from "../assets/svg/CheetSheet.svg";
import { ReactComponent as LeftSvg } from "../assets/svg/LeftOrange.svg";
import { Headers } from "../helpers/layout";
import { useHistory } from "react-router";
import { Button } from "./Button";
import CheatsSheetsModal from "./CheatsSheetsModal";
import { useTranslation } from "react-i18next";
import { useResponsive } from "../hooks/useResponsive";

export default function CourseCardSuggestion({
  course,
}: {
  course: CourseCheatSheetSuggestions;
}) {
  const history = useHistory();
  const { t } = useTranslation();
  const { isMobile } = useResponsive();

  const [modal, setModal] = useState(false);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        margin-right: ${isMobile ? "0" : "10px"};
        margin-left: ${isMobile ? "0" : "10px"};
        margin-bottom: ${isMobile ? "20px" : "0"};
        padding: ${isMobile ? "32px 24px 24px 24px" : "40px"};
        border: 1px solid ${theme.colors.gray2};
        border-radius: 20px;
        background-color: ${theme.colors.white};

        ${!isMobile &&
        css`
          height: 300px;
        `}

        :last-child {
          margin-bottom: 0;
        }
      `}
    >
      <div
        css={css`
          display: flex;
          width: 100%;
          flex-flow: row wrap;
          justify-content: space-between;
          align-items: flex-start;
          margin-bottom: 30px;
        `}
      >
        {course.course.logo && (
          <div
            css={css`
              flex: 0 0 auto;
              margin-right: 20px;
              margin-bottom: 15px;
            `}
          >
            <img
              src={course.course.logo.image}
              css={css`
                max-height: 50px;
              `}
              alt={course.course.name}
            />
          </div>
        )}
        <div
          css={css`
            flex: 0 0 auto;
            display: flex;
            align-items: center;
            background-color: ${course.completed
              ? theme.colors.primary
              : theme.colors.info};
            color: ${theme.colors.white};
            padding: 3px 20px;
            font-size: 16px;
            line-height: 18px;
            border-radius: 20px;
            margin-bottom: 15px;
          `}
        >
          {course.completed && (
            <ReadSvg
              css={css`
                margin-right: 12px;
              `}
            />
          )}
          {course.completed
            ? t("cheats_sheet.completed")
            : t("cheats_sheet.in_progress")}
        </div>
      </div>
      {course.course.journey && (
        <div
          css={css`
            font-size: 16px;
            line-height: 22px;
            color: ${theme.colors.gray6};
            cursor: pointer;
            width: fit-content;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;

            :hover {
              color: ${theme.colors.black};
            }
          `}
          onClick={() =>
            history.push(`/journey/${course.course.journey!.uuid}`)
          }
        >
          {course.course.journey.name}
        </div>
      )}
      <Headers.H3
        css={css`
          margin-bottom: 50px;
          cursor: pointer;
        `}
      >
        {course.course.name}
      </Headers.H3>
      <div
        css={css`
          display: flex;
          margin: auto auto 0 0;
        `}
      >
        <Button
          background={theme.colors.white}
          border={`1px solid ${theme.colors.dark}`}
          color={theme.colors.dark}
          hoverStyles={`background: ${theme.colors.primary}; color: ${theme.colors.white}; border: 1px solid ${theme.colors.primary};`}
          css={css`
            display: flex;
            flex-direction: row;
            padding: 10px 20px;
            border-radius: 20px;
            align-items: center;
          `}
          icon={
            course.completed ? (
              <CheatSvg color={theme.colors.primary} />
            ) : (
              <LeftSvg color={theme.colors.primary} />
            )
          }
          iconRight
          onClick={() =>
            course.completed
              ? setModal(true)
              : history.push(
                  `/course-enrolments/${course.course.enrolment_id}/lessons/${course.course.next_lesson_uuid}/`
                )
          }
        >
          <div>
            {course.completed
              ? t("cheats_sheet.suggestions")
              : t("cheats_sheet.continue")}
          </div>
        </Button>
      </div>
      {modal && (
        <CheatsSheetsModal
          cheatsSheets={course.course.cheat_sheets}
          isActive={modal}
          onClose={() => setModal(false)}
          onComplete={() => {
            setModal(false);
            window.location.reload();
          }}
        />
      )}
    </div>
  );
}
