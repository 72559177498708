import React from "react";
import { css } from "styled-components/macro";
import { X } from "react-feather";
import { Row, Spacing, Headers } from "../../helpers/layout";

export function ModalHeader(props: {
  className?: string;
  children?: React.ReactNode[] | React.ReactNode | string | null;
  closeIcon?: boolean;
  onClose?: () => void;
}) {
  return (
    <Row
      align={"center"}
      justify={"space-between"}
      css={css`
        margin-bottom: ${props.children ? Spacing.m : 0};
      `}
      className={props.className}
    >
      {props.children && (
        <Headers.H2
          css={css`
            font-size: 1.5rem;
          `}
        >
          {props.children}
        </Headers.H2>
      )}

      {props.closeIcon && (
        <button
          css={css`
            padding: 0;
            margin: 0;
            cursor: pointer;
            white-space: nowrap;
            border: 0;
            text-transform: none;
            -webkit-appearance: none;
            outline: none;
            background: transparent;
            position: relative;
            border-radius: 50%;
            min-width: 30px;
            min-height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: background-color 0.2s linear 0s, opacity 0.2s linear 0s,
              box-shadow 0.2s linear 0s;
            :hover {
              background: rgba(0, 0, 0, 0.06);
              box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 5px,
                rgba(0, 0, 0, 0.14) 0px 2px 2px,
                rgba(0, 0, 0, 0.12) 0px 3px 1px -2px;
            }
            :active {
              background: rgba(0, 0, 0, 0.12);
              box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 5px,
                rgba(0, 0, 0, 0.14) 0px 2px 2px,
                rgba(0, 0, 0, 0.12) 0px 3px 1px -2px;
            }
          `}
          onClick={() => {
            if (props.onClose) {
              props.onClose();
            }
          }}
        >
          <X size={20}></X>
        </button>
      )}
    </Row>
  );
}
