import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { FilePlus } from "react-feather";
import { theme } from "../../themes/variables";
import { ILessonData } from "../../types/Lesson";
import { Button } from "../Button";
import LessonFeedbackModal from "./LessonFeedbackModal";
import { ReactComponent as ReadSvg } from "../../assets/svg/Read.svg";
import { Text } from "../../helpers/layout";

export function LessonFooter(props: {
  item: ILessonData;
  enrolmentId: string;
  lessonId: string;
  onComplete: (timeout?: number | null) => void;
  onShowCheats: () => void;
  finished?: boolean;
}) {
  const { lesson } = props.item;
  const { t } = useTranslation();

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 40px;
      `}
    >
      <div
        css={css`
          width: 100%;
          border: 1px solid ${theme.colors.gray4};
        `}
      />
      <div
        css={css`
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-top: 20px;
        `}
      >
        {lesson.cheat_sheets.length > 0 && (
          <Text.CTA
            css={css`
              display: flex;
              align-items: center;
              cursor: pointer;
              font-weight: 500;
            `}
            onClick={() => {
              props.onShowCheats();
            }}
          >
            <FilePlus
              size={26}
              color={theme.colors.white}
              fill={theme.colors.black}
              css={css`
                margin-right: 10px;
              `}
            />
            {t("lesson.add_to_cheets")}
          </Text.CTA>
        )}
        <LessonFeedbackModal
          enrolmentId={props.enrolmentId}
          lessonId={props.lessonId}
        />
      </div>
      <div
        css={css`
          display: flex;
          width: 100%;
          margin-top: 50px;
        `}
      >
        <Button
          css={css`
            margin: auto 0 0 auto;
          `}
          icon={
            <ReadSvg
              width={20}
              css={css`
                min-width: 20px;
              `}
              color={theme.colors.white}
            />
          }
          background={theme.colors.greenMain}
          color={theme.colors.white}
          hoverStyles={`background: ${theme.colors.greenHover};`}
          size="large"
          disabled={!props.finished}
          onClick={() => {
            props.onComplete();
          }}
        >
          {!props.item.completed
            ? t("lesson.mark_completed")
            : lesson.next
            ? t("lesson.next")
            : lesson.has_exam_tests
            ? t("lesson.go_to_exam")
            : t("lesson.go_to_course_overview")}
        </Button>
      </div>
    </div>
  );
}
