import { authorised } from "../../request";

export async function deleteMe() {
  const API = authorised();

  return API.request({
    method: "DELETE",
    url: `/api/campus/me/`,
  });
}
