import React from "react";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { Link } from "react-router-dom";
import { SkillLevel, getLevel, getLevelColor } from "../helpers/level";
import { HomepageCompetence } from "../types/HomePage";
import { theme } from "../themes/variables";
import { Card } from "./Card";
import { CircularProgressBar } from "./CircularProgressBar";
import { ProgressBarSection } from "./ProgressBarSection";
import { Button } from "./Button";
import { Headers, Text } from "../helpers/layout";

export function CompetenceCard(props: {
  competence: HomepageCompetence;
  className?: string;
}) {
  const { competence } = props;
  const { t } = useTranslation();

  return (
    <Card
      className={props.className}
      css={css`
        padding: 24px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border: 1px solid ${theme.colors.black};
      `}
    >
      <div>
        <div
          css={css`
            display: flex;
          `}
        >
          <CircularProgressBar
            size={64}
            percent={competence.points}
            strokeWidth={4}
            strokeWidthInner={2}
            color={theme.colors.primary}
          >
            <div
              css={css`
                font-size: 20px;
                line-height: 46px;
                color: ${theme.colors.primary};
              `}
            >
              {competence.points}%
            </div>
          </CircularProgressBar>

          <Headers.H4
            css={css`
              margin-top: 8px;
              margin-left: 24px;
            `}
          >
            {competence.id ? (
              <Link to={`/me/skills/${competence.id}`}>{competence.name}</Link>
            ) : (
              competence.name
            )}
          </Headers.H4>
        </div>

        <Text.Notifications
          css={css`
            margin-top: 24px;
          `}
        >
          {competence.description}
        </Text.Notifications>
      </div>
      <div
        css={css`
          margin-top: 24px;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <Text.Notifications
            css={css`
              color: ${theme.colors.gray6};
            `}
          >
            {t("skills.current-skill-level")}:
          </Text.Notifications>
          <Text.CTA
            css={css`
              margin-left: 4px;
            `}
          >
            {getLevel(competence.points) === SkillLevel.BEGINNER
              ? t("skills.skill-level.beginner")
              : getLevel(competence.points) === SkillLevel.INTERMEDIATE
              ? t("skills.skill-level.intermediate")
              : t("skills.skill-level.advanced")}
          </Text.CTA>
        </div>

        <div
          css={css`
            margin-top: 12px;
          `}
        >
          <ProgressBarSection
            numberOfSections={3}
            percent={competence.points || 0}
            padding={9}
            color={getLevelColor(getLevel(competence.points))}
            emptyColor={theme.colors.gray1}
          />
        </div>

        <div
          css={css`
            min-height: 35px;
            margin-top: 24px;
          `}
        >
          {competence.course_id && (
            <Link to={`/course/${competence.course_id}`}>
              <Button background={theme.colors.dark} color={theme.colors.white}>
                {t("cheats_sheet.continue")}
              </Button>
            </Link>
          )}
        </div>
      </div>
    </Card>
  );
}
