import React from "react";
import { css } from "styled-components/macro";
import * as Formik from "formik";
import { Tooltip } from "../../components/Tooltip/index";
import { theme } from "../../themes/variables";
import { AlertCircle } from "react-feather";
import { Hint } from "../../components/Hint";

export const FieldWrapper = (props: {
  name: string;
  label?: string;
  children: React.ReactNode | React.ReactNode[];
  className?: string;
}) => {
  return (
    <div className={props.className}>
      {props.label && (
        <label
          css={css`
            font-size: 16px;
            color: ${theme.colors.black};
            font-weight: bold;
          `}
        >
          {props.label}
        </label>
      )}
      {props.children}
    </div>
  );
};

type TextFieldTypes =
  | "text"
  | "email"
  | "search"
  | "password"
  | "number"
  | "tel"
  | "url"
  | "select";

export const TextField = (props: {
  name: string;
  type?: TextFieldTypes;
  label?: string;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  hideTooltip?: boolean;
  onChange?: (event: any) => void;
  validate?: (value: string) => Promise<void | string>;
  hint?: string;
}) => {
  const { type = "text", ...rest } = props;
  return (
    <FieldWrapper
      name={props.name}
      label={props.label}
      css={css`
        width: 100%;
      `}
    >
      <Formik.Field name={props.name} validate={props.validate}>
        {({ field, meta }: { field: any; meta: any }) => {
          return (
            <div>
              <div
                className={props.className}
                css={css`
                  display: flex;
                  position: relative;
                  align-items: center;
                  flex-wrap: nowrap;
                  background-color: ${theme.colors.white};
                  border: ${meta.error && meta.touched && !props.hideTooltip
                    ? `1px solid ${theme.colors.primary}`
                    : `1px solid ${theme.colors.transparentBlack4};`};
                  margin-bottom: -2px;
                  margin-top: ${props.label ? "16px" : "0"};
                `}
              >
                <input
                  css={css`
                    width: 100%;
                    height: 30px;
                    background: none;
                    border: none;
                    font-size: 14px;
                    outline: none;
                    padding: 4px 16px;
                    color: ${theme.colors.dark};

                    ::placeholder {
                      font-size: 14px;
                      color: ${theme.colors.dark};
                    }
                  `}
                  {...{ type }}
                  {...rest}
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);

                    if (props.onChange) {
                      props.onChange(e);
                    }
                  }}
                />
                {meta.touched && meta.error && !props.hideTooltip && (
                  <div
                    css={css`
                      cursor: pointer;
                    `}
                  >
                    <Tooltip text={meta.error}>
                      <AlertCircle size={18} color={theme.colors.primary} />
                    </Tooltip>
                  </div>
                )}
              </div>
              {props.hint && <Hint>{props.hint}</Hint>}
            </div>
          );
        }}
      </Formik.Field>
    </FieldWrapper>
  );
};
