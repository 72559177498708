import { css } from "styled-components/macro";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button } from "../../components/Button";
import { TextField } from "../fields/TextField";
import { Column, Spacing } from "../../helpers/layout";
import { theme } from "../../themes/variables";
import { setNewPassword } from "../../actions/auth/setNewPassword";
import { Headers } from "../../helpers/layout";

import { serverErrorHandler } from "../../helpers/serverErrorHandler";

interface FormValues {
  new_password: string;
  confirm_password: string;
}

export function SetNewPasswordForm() {
  const params = useParams<{
    uuid: string;
  }>();

  const history = useHistory();
  const { t } = useTranslation();

  return (
    <div>
      <Formik<FormValues>
        initialValues={{
          new_password: "",
          confirm_password: "",
        }}
        validationSchema={Yup.object().shape({
          new_password: Yup.string()
            .min(8)
            .label(t("auth.new_password"))
            .required(),
          confirm_password: Yup.string()
            .label(t("auth.confirm_new_password"))
            .oneOf([Yup.ref("new_password"), null], t("auth.pwd_not_match"))
            .required(),
        })}
        onSubmit={async (values: FormValues, { setSubmitting }) => {
          try {
            setSubmitting(true);

            await setNewPassword(values.new_password, params.uuid);
            toast.success(t("status.success"));
            history.push("/login");
          } catch (error: any) {
            toast.error(
              t("status.error", {
                error: serverErrorHandler(error),
              })
            );
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, dirty, isValid }) => (
          <Form noValidate>
            <Column gutter={Spacing.none}>
              <TextField
                name="new_password"
                type="password"
                placeholder={t("auth.new_password")}
                css={css`
                  border-radius: 10px 10px 0 0;
                `}
              />
              <TextField
                name="confirm_password"
                type="password"
                placeholder={t("auth.confirm_new_password")}
                css={css`
                  border-radius: 0 0 10px 10px;
                  margin-top: 1px;
                `}
              />

              <Button
                isSubmitting={isSubmitting}
                type="submit"
                background={theme.colors.primary}
                disabled={!dirty || !isValid}
                css={css`
                  margin: 0 auto;
                  margin-top: 40px;
                  width: fit-content;
                  color: ${theme.colors.white};
                `}
              >
                {t("auth.save_password")}
              </Button>
            </Column>
            <Headers.H4
              css={css`
                display: flex;
                justify-content: center;
                margin-top: 50px;
                margin-bottom: 10px;
              `}
            >
              {t("auth.or")}
            </Headers.H4>
            <Link
              css={css`
                display: flex;
                text-decoration: underline;
                justify-content: center;
              `}
              to="/login"
            >
              {t("auth.back_to_login")}
            </Link>
          </Form>
        )}
      </Formik>
    </div>
  );
}
