import { useRef } from "react";
import { css } from "styled-components/macro";
import { ChevronDown } from "react-feather";
import { theme } from "../themes/variables";
import { Headers } from "../helpers/layout";

export function FAQ(props: {
  question?: string;
  answer?: string;
  isActive: boolean;
  onChange: () => void;
}) {
  const content = useRef(null as null | HTMLDivElement);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        height: fit-content;
        border-radius: 20px;
        padding: 20px;
        background-color: ${theme.colors.white};
      `}
    >
      <button
        onClick={props.onChange}
        css={css`
          background-color: ${theme.colors.white};
          text-align: left;
          cursor: pointer;
          display: flex;
          align-items: center;
          border: none;
          outline: none;
          padding: 0;
          transition: background-color 0.6s ease;
        `}
      >
        <Headers.H3
          css={css`
            font-size: 14px;
            width: 100%;
            margin: 0;
          `}
        >
          {props.question}
        </Headers.H3>
        <ChevronDown
          size={28}
          color={theme.colors.black}
          css={css`
            margin-left: auto;
            transition: transform 0.6s ease;
            transform: ${props.isActive ? `none` : `rotate(-180deg)`};
          `}
        />
      </button>
      <div
        css={css`
          overflow: hidden;
          transition: max-height 0.6s ease;
          max-height: ${!props.isActive
            ? "0px"
            : content &&
              content.current &&
              content.current.scrollHeight + "px"};
        `}
        ref={content}
      >
        <div
          css={css`
            font-size: 14px; //mobile
            font-weight: 400;
            margin-top: 20px;
          `}
        >
          {props.answer}
        </div>
      </div>
    </div>
  );
}
