import { authorised } from "../../request";
import { CheatsSheet } from "../../types/CheatsSheets";

export async function deleteCheat(uuid: string) {
  const API = authorised();

  return API.request<CheatsSheet[]>({
    method: "DELETE",
    url: `/api/campus/cheatsheet/${uuid}/delete/`,
  });
}
