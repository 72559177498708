import React, { ChangeEvent } from "react";
import { css } from "styled-components/macro";
import { FieldInputProps } from "formik";
import { theme } from "../themes/variables";
import { Spacing, Text } from "../helpers/layout";
import { ReactComponent as ReadIcon } from "../assets/svg/Read.svg";

export function RadioComponent(props: {
  name?: string;
  id?: string;
  label?: React.ReactNode | React.ReactNode[] | string;
  labelRight?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  value?: boolean;
  className?: string;
  checkedColor?: string;
  notSelectedTransparent?: boolean;
  field?: FieldInputProps<any>;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}) {
  return (
    <label
      css={css`
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        min-width: ${Spacing.l};
        min-height: ${Spacing.l};
        position: relative;
        ${props.labelRight ? "flex-direction: row-reverse;" : ""}
        ${props.disabled &&
        css`
          opacity: 0.6;
        `}
      `}
      className={props.className || ""}
    >
      {props.label && (
        <Text.Notifications
          css={css`
            opacity: ${props.notSelectedTransparent
              ? props.value || props.field?.checked
                ? 1
                : 0.6
              : 1};
          `}
        >
          {props.label}
        </Text.Notifications>
      )}

      <input
        id={props.id}
        name={props.name || ""}
        type="radio"
        readOnly={props.readonly || false}
        disabled={props.disabled || false}
        checked={props.value || false}
        css={css`
          position: relative;
          margin: ${props.label
            ? props.labelRight
              ? "0 1rem 0 0;"
              : "0 0 0 1rem;"
            : "0"};
          border-radius: 50%;
          width: ${Spacing.l};
          height: ${Spacing.l};
          border: 1px solid ${theme.colors.gray4};
          transition: 0.2s all linear;
          cursor: pointer;
          outline: none;
          appearance: none;
          :checked {
            background-color: ${props.checkedColor || theme.colors.primary};
            border: 1px solid ${theme.colors.transparent};
          }
        `}
        {...props.field}
        onChange={(e) => {
          if (props.field) {
            props.field.onChange(e);
          }
          if (props.onChange) {
            props.onChange(e);
          }
        }}
      />

      {(props.field?.checked || props.value) && (
        <ReadIcon
          width={12}
          height={12}
          color={theme.colors.white}
          css={css`
            position: absolute;
            left: 6px;
          `}
        />
      )}
    </label>
  );
}
