import { useEffect, useState } from "react";
import { useParams, RouteComponentProps, useHistory } from "react-router-dom";
import styled, { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { getCourse } from "../actions/course/getCourse";
import { useFetch } from "../actions/useAPI";
import { FAQColumns } from "../components/FAQColumns";
import { CourseAbout } from "../components/Course/CourseAbout";
import { CourseMeta } from "../components/Course/CourseMeta";
import { CourseHeader } from "../components/Course/CourseHeader";
import { CourseSyllabus } from "../components/Course/CourseSyllabus";
import { CourseNavigation } from "../components/Course/CourseNavigation";
import { CourseExperts } from "../components/Course/CourseExperts";
import { CourseAdditionalMaterial } from "../components/Course/CourseAdditionalMaterial";
import { Header } from "../components/Header";
import { Wrapper } from "../components/Wrapper";
import { HeaderText } from "../components/HeaderText";
import { Modal, ModalBody, ModalHeader } from "../components/Modal/";
import { SEO } from "../components/SEO";
import { Headers } from "../helpers/layout";
import { theme } from "../themes/variables";
import { ReactComponent as CongratsSvg } from "../assets/svg/Congrats.svg";
import { ReactComponent as CertificateSvg } from "../assets/svg/Certificate.svg";
import { ReactComponent as JourneyOverviewSvg } from "../assets/svg/JourneyOverview.svg";
import { ReactComponent as CourseSummarySvg } from "../assets/svg/CourseSummary.svg";
import queryString from "query-string";
import { useResponsive } from "../hooks/useResponsive";
import { PageQueryHandler } from "./PageQueryHandler";
import { ScrollToTopOnMount } from "../components/ScrollToTopOnMount";

export function CoursePage(props: RouteComponentProps) {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();

  const history = useHistory();

  const [congratulationModal, setCongratulationModal] = useState(false);

  useEffect(() => {
    if (props.location.search.includes("congratulation")) {
      setCongratulationModal(true);
    }
  }, [props.location.search]);

  const params = useParams<{
    id: string;
  }>();

  const queryParams = queryString.parse(props.location.search);
  const previewToken = queryParams.preview_token;

  const {
    data: course,
    error: courseError,
    isLoading: courseIsLoading,
  } = useFetch(getCourse, {
    courseId: params.id,
    previewToken: previewToken,
  });

  if (!course || courseError || courseIsLoading) {
    return <PageQueryHandler error={courseError} isLoading={courseIsLoading} />;
  }

  const downloadCertificate = () => {
    window.open(
      `${process.env.REACT_APP_BACKEND_URL}/api/campus/course-enrolments/${course.enrolment_id}/certificate/`
    );
  };

  const getSummary = () => {
    history.push(`/course/${course.uuid}/summary/`);
  };

  const getJourneyOverview = () => {
    history.push(`/journey/${course.journey!.uuid}/`);
  };

  return (
    <Wrapper>
      <ScrollToTopOnMount />
      <SEO>
        <title>{course?.name}</title>
      </SEO>
      <Header />
      <div
        css={css`
          display: flex;
          flex-direction: column;
          margin-bottom: ${isMobile ? "0" : "60px"};
        `}
      >
        {course && (
          <>
            {!isMobile && <CourseNavigation course={course} />}

            <CourseHeader course={course} />

            <CourseAbout course={course} />

            <CourseMeta course={course} />

            <CourseSyllabus
              course={course}
              previewToken={queryParams.preview_token}
            />

            <CourseExperts course={course} />

            <CourseAdditionalMaterial course={course} />

            {!!course.faq_items.length && (
              <div>
                <HeaderText title={t("course.our")} text={t("course.faq")} />

                <FAQColumns items={course.faq_items} />
              </div>
            )}
          </>
        )}
      </div>

      <Modal
        modalIsOpen={congratulationModal}
        onClose={() => setCongratulationModal(false)}
      >
        <ModalHeader
          css={css`
            justify-content: flex-end;
          `}
          closeIcon
          onClose={() => setCongratulationModal(false)}
        ></ModalHeader>

        <ModalBody>
          <div
            css={
              !isMobile &&
              css`
                padding: 40px;
              `
            }
          >
            <div
              css={css`
                display: flex;
                align-items: center;
              `}
            >
              <Headers.H1
                css={css`
                  font-size: ${isMobile ? "30px" : "43px"};
                  margin-right: 20px;
                `}
              >
                {t("course.congratulation.modal.title")}!
              </Headers.H1>
              <div
                css={
                  isMobile &&
                  css`
                    svg {
                      width: 30px;
                    }
                  `
                }
              >
                <CongratsSvg
                  css={css`
                    @keyframes scale {
                      0% {
                        transform: rotateZ(45deg) scale(0);
                        opacity: 0;
                      }
                      50% {
                        transform: rotateZ(0deg) scale(1.2);
                      }
                      100% {
                        transform: rotate(0deg) scale(1);
                        opacity: 1;
                      }
                    }

                    opacity: 0;
                    animation-fill-mode: forwards;
                    animation-name: scale;
                    animation-duration: 0.3s;
                    animation-delay: 1s;
                    animation-timing-function: linear;
                  `}
                />
              </div>
            </div>

            <div>{t("course.congratulation.modal.subtitle")}!</div>

            <div
              css={css`
                display: flex;
                flex-flow: row wrap;
                align-items: center;
                justify-content: space-around;
                margin-top: 60px;

                ${isMobile &&
                css`
                  padding-left: 0;
                  padding-right: 0;
                `}
              `}
            >
              {course.has_certificate && course.enrolment_id && (
                <Card onClick={downloadCertificate}>
                  <CertificateSvg />
                  <div
                    css={css`
                      margin-top: 40px;
                      font-size: 16px;
                      font-weight: bold;
                    `}
                  >
                    {t("course.certificate")}
                  </div>
                </Card>
              )}

              <Card onClick={getSummary}>
                <CourseSummarySvg />

                <div
                  css={css`
                    margin-top: 40px;
                    font-size: 16px;
                    font-weight: bold;
                  `}
                >
                  {t("course-summary.read")}
                </div>
              </Card>

              {course.journey && (
                <Card onClick={getJourneyOverview}>
                  <JourneyOverviewSvg />

                  <div
                    css={css`
                      margin-top: 40px;
                      font-size: 16px;
                      font-weight: bold;
                    `}
                  >
                    {t("course.congratulation.modal.journey_overview")}
                  </div>
                </Card>
              )}
            </div>
          </div>
        </ModalBody>
      </Modal>
    </Wrapper>
  );
}

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 150px;
  height: 146px;
  padding: 30px 15px;
  text-align: center;
  cursor: pointer;
  border: 1px solid ${theme.colors.gray2};
  border-radius: 20px;
  margin: 10px;

  :hover {
    background-color: ${theme.colors.yellowBackground};
  }
`;
