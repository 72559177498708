import { notAuthorised } from "../../request";

export async function setNewPassword(password: string, uuid: string) {
  const API = notAuthorised;

  return await API.request<{
    token: string;
  }>({
    method: "POST",
    url: `/api/campus/set-password/${uuid}/`,
    data: {
      password,
    },
  });
}
