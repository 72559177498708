import { css } from "styled-components/macro";
import { ChevronLeft } from "react-feather";
import { ChevronRight } from "react-feather";
import { useTranslation } from "react-i18next";
import { theme } from "../../themes/variables";
import { ICourse } from "../../types/Course";
import { Link } from "react-router-dom";

export function CourseNavigation(props: { course: ICourse }) {
  const { t } = useTranslation();

  return (
    <div
      css={css`
        display: flex;
        width: 100%;
        margin-bottom: 20px;
      `}
    >
      {props.course.previous ? (
        <Link
          to={`/course/${props.course.previous.uuid}`}
          css={css`
            display: flex;
            align-items: center;
            cursor: pointer;
            font-size: 18px;
            color: ${theme.colors.black};
          `}
        >
          <ChevronLeft
            size={24}
            color={theme.colors.black}
            css={css`
              margin-right: 10px;
            `}
          />
          {t("course.previous")}
        </Link>
      ) : null}

      {props.course.next ? (
        <Link
          to={`/course/${props.course.next.uuid}`}
          css={css`
            display: flex;
            align-items: center;
            cursor: pointer;
            margin-left: auto;
            font-size: 18px;
            color: ${theme.colors.black};
          `}
        >
          {t("course.next")}
          <ChevronRight
            size={24}
            color={theme.colors.black}
            css={css`
              margin-left: 10px;
            `}
          />
        </Link>
      ) : null}
    </div>
  );
}
