import React, { useState, useEffect } from "react";
import { getProfile } from "../actions/auth/getProfile";
import { IUserDetails } from "../types/UserProfile";
import { useEnterprise } from "./EnterpriseContext";

export const THEMIS_AUTH_TOKEN = "THEMIS_AUTH_TOKEN";

type State = {
  isAuthenticated: boolean | null;
  userprofile: IUserDetails | null;
};

const emptyState = {
  isAuthenticated: null,
  userprofile: null,
  authenticate: () => {},
  deauthenticate: () => {},
};

type Actions = {
  authenticate: (token: string, user: IUserDetails) => void;
  deauthenticate: () => void;
};

type Context = State & Actions;

const UserStateContext = React.createContext<Context>(emptyState);

const tokenExist = function () {
  return !!localStorage.getItem(THEMIS_AUTH_TOKEN);
};

export function UserProvider({ children }: { children: React.ReactNode }) {
  const [state, setState] = useState<State>(emptyState);
  const { refetch: refetchEnterprise } = useEnterprise();

  // TODO: check token validity
  useEffect(() => {
    async function fetchProfile() {
      try {
        const request = await getProfile();

        setState({
          userprofile: request.data,
          isAuthenticated: true,
        });
      } catch {
        setState({
          isAuthenticated: false,
          userprofile: null,
        });
      }
    }

    if (tokenExist()) {
      fetchProfile();
    } else {
      setState({
        isAuthenticated: false,
        userprofile: null,
      });
    }
  }, []);

  const actions = {
    authenticate: (token: string, userprofile: IUserDetails) => {
      localStorage.setItem(THEMIS_AUTH_TOKEN, token);

      setState({
        isAuthenticated: true,
        userprofile,
      });

      refetchEnterprise();
    },
    deauthenticate: () => {
      setState({
        isAuthenticated: false,
        userprofile: null,
      });

      localStorage.removeItem(THEMIS_AUTH_TOKEN);
    },
  };

  return (
    <UserStateContext.Provider value={{ ...state, ...actions }}>
      {children}
    </UserStateContext.Provider>
  );
}

export function useAuth() {
  const context = React.useContext(UserStateContext);

  if (context === undefined) {
    throw new Error("useAuth must be used within a UserProvider");
  }

  return context;
}
